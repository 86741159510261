import React, { Dispatch, SetStateAction } from "react";

const CancelPromptGetStarted = ({
  cancelOnboarding,
  setPromptModal,
}: {
  cancelOnboarding: () => void;
  setPromptModal: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div
      className="popup-box"
      id="cancelprompt"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="cancelpromptlabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="cancelpromptTitle">
              Cancel Onboarding
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setPromptModal(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want cancel</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn advancly-white-btn"
              onClick={() => {
                setPromptModal(false);
              }}
            >
              No
            </button>
            <button
              type="button"
              className="btn advancly-btn"
              onClick={cancelOnboarding}
            >
              Yes{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPromptGetStarted;
