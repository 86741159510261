import React, { Fragment } from "react";
import { Route, withRouter } from "react-router-dom";
import BorrowerProfile from "../../../components/BorrowerProfile";
import LoanRequestDisbursement from "../LoanRequestDisbursement";
import AllDisbursedLoansMainNew from "./AllDisbursedLoansMainNew";

const AllDisbursedLoans = (props: any) => {
  let match = props.match;
  return (
    <div>
      <Route
        exact
        path={match.path}
        render={() => (
          <Fragment>
            <LoanRequestDisbursement />
            <div className="mt-3">
              <AllDisbursedLoansMainNew />
              {/* <AllDisbursedLoansMain /> */}
            </div>
          </Fragment>
        )}
      />
      <Route
        path={match.path + "/:borrower_id"}
        render={() => <BorrowerProfile />}
      />
    </div>
  );
};
export default withRouter(AllDisbursedLoans);
