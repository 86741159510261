import { useEffect, useState } from "react";
import BusinessInfo from "../../components/BusinessInfo/BusinessInfo.component";
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo.component";
import WalletSignatorySetup from "../../components/WalletSignatorySetup/WalletSignatorySetup";
import BusinessBankInfo from "../../components/BusinessBankInfo/BusinessBankInfo.component";
import ChangePassword from "../../components/ChangePassword/ChangePassword.component";
import OtherAccountSettings from "./../../components/OtherAccountSettings/OtherAccountSettings.component";
import { useHistory } from "react-router-dom";
import DeveloperMainComponent from "../../components/Developer/DeveloperMainComponent";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";

const Settings = () => {
  const [changePasswordText, setChangePasswordText] = useState("");
  const [activeTab, setActiveTab] = useState("businessInfo");
  const history = useHistory();
  useEffect(() => {
    // if the user is being forced to change his password during login, do the things below.
    //@ts-ignore
    if (history?.location?.state?.forcedChangeOfPassword) {
      setChangePasswordText(
        "We have improved security on all accounts as part of our commitment to you. Kindly update your password."
      );
      const changePasswordSection = document.querySelector("#change_password");
      changePasswordSection?.scrollIntoView({
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">Settings </h3>
      </div>
      <div>
        <div className="">
          <div className="">
            <div className="alignToggleTabItems  ">
              <ToggleTab
                text="Business Information
                "
                id="businessInfo"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Banking Information
                "
                id="businessBankInfo"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Wallet Transfer Settings
                "
                id="walletSignatorySetup"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="User Information
                "
                id="personalInfo"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Other Account Settings
                "
                id="otherAccountSettings"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />

              <ToggleTab
                text="Developer"
                id="developerMainComponent"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
              <ToggleTab
                text="Change Password

                "
                id="changePassword"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div className="row table-responsive">
              <div className="leftApprovalstyles">
                {(() => {
                  switch (activeTab) {
                    case "businessInfo":
                      return <BusinessInfo />;
                    case "businessBankInfo":
                      return <BusinessBankInfo />;
                    case "walletSignatorySetup":
                      return <WalletSignatorySetup />;
                    case "personalInfo":
                      return <PersonalInfo />;
                    case "otherAccountSettings":
                      return <OtherAccountSettings />;

                    case "developerMainComponent":
                      return <DeveloperMainComponent />;
                    case "changePassword":
                      return (
                        <div id="change_password">
                          <ChangePassword
                            changePasswordText={changePasswordText}
                          />
                        </div>
                      );

                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
