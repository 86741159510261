import React, { useEffect, useState } from "react";
import Paginator from "../../../components/Paginator/Paginator.component";
import NoTableDataAvailable from "../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import PageLoader from "../../../components/PageLoader/PageLoader.component";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import useDisbursementLoanDetails from "../../../custom-hooks/useDisbursementLoanDetails";
import { useAuth } from "../../../Context/auth.context";
import { formatMoney } from "../../../helpers/formatter";
import { useHistory } from "react-router-dom";

interface IDisbursedPagestate {
  page: number;
  PageSize: number;
  customer_id?: number;
}
export interface IDisbursedataLoanDetailsList {
  loan_application_detail_id: number;
  loan_application_ref: string;
  loan_application_status: string;
  product: string;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  approved_amount: number;
  utilized_amount?: number;
  remaining_amount: number;
  interest_rate: number;
  loan_tenure: number;
  product_name: string;
  loan_product_detail_id: number;
  repayment_frequency: string;
}
const AllDisbursedLoansMainNew = () => {
  const { aggregatorDetails } = useAuth();

  const [count, setCount] = useState<any>(null);
  const [preState, setPreState] = useState<IDisbursedPagestate>({
    page: 1,
    PageSize: 10,
    customer_id: aggregatorDetails?.customer_id,
  });
  const history = useHistory();
  const {
    data: disbursedLoansData,
    status: disbursedLoansStatus,
    error: disbursedLoansError,
  } = useDisbursementLoanDetails(preState);

  useEffect(() => {
    appInsights.trackPageView({
      name: "AllDisbursedLoansMainNew.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    if (disbursedLoansData && count === null) {
      setCount(disbursedLoansData.totalCount);
    }
  }, [disbursedLoansData, count]);
  if (disbursedLoansStatus === "loading") return <PageLoader />;
  return (
    <div>
      {disbursedLoansError && (
        <p className="alert alert-danger small">
          {disbursedLoansError as string}
        </p>
      )}

      {
        //@ts-ignore
        !disbursedLoansError && disbursedLoansStatus !== "loading" && (
          <div>
            <div className="advancly-wrapper-container">
              <div className="advancly-sub-wrapper">
                <div
                  className="d-flex justify-content-between align-items-center flex-wrap"
                  style={{ position: "relative" }}
                >
                  <div className="d-flex align-items-center">
                    <p className="pr-2 pt-1">Showing:</p>
                    <CustomSelectDropdown
                      onChange={(e) =>
                        //@ts-ignore
                        setPreState({ ...preState, PageSize: e.target?.value })
                      }
                      value={preState.PageSize}
                      //@ts-ignore
                    >
                      <option value={10}>10 Entries</option>
                      <option value={20}>20 Entries</option>
                      <option value={50}>50 Entries</option>
                      <option value={100}>100 Entries</option>
                    </CustomSelectDropdown>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table-style">
                    <thead className="">
                      <tr>
                        <th className="th-td-style th-style ">
                          Application Ref No
                        </th>
                        <th className="th-td-style th-style ">
                          Interest Rate(%)
                        </th>
                        <th className="th-td-style th-style ">Product</th>
                        <th className="th-td-style th-style ">Tenure</th>
                        <th className="th-td-style th-style ">Currency</th>
                        <th className="th-td-style th-style ">
                          Approved Amount
                        </th>
                        <th className="th-td-style th-style ">
                          Utilized Amount
                        </th>
                        <th className="th-td-style th-style ">
                          Amount Remaining
                        </th>
                        <th className="th-td-style th-style ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {disbursedLoansData &&
                      disbursedLoansData?.data?.length > 0 ? (
                        disbursedLoansData?.data?.map(
                          (
                            disburseData: IDisbursedataLoanDetailsList,
                            id: number
                          ) => {
                            const {
                              loan_application_detail_id,
                              loan_application_ref,
                              product_name,
                              currency,
                              approved_amount,
                              utilized_amount,
                              remaining_amount,
                              interest_rate,
                              loan_tenure,
                            } = disburseData;
                            return (
                              <tr key={id}>
                                <td className="th-td-style ">
                                  {loan_application_ref}
                                </td>
                                <td
                                  style={{ minWidth: "180px" }}
                                  className="text-capitalize th-td-style "
                                >
                                  {interest_rate}
                                </td>
                                <td
                                  className="th-td-style "
                                  style={{ minWidth: "150px" }}
                                >
                                  {product_name}
                                </td>
                                <td className="th-td-style ">{loan_tenure}</td>

                                <td className="th-td-style ">{currency}</td>
                                <td className="th-td-style ">
                                  {formatMoney(approved_amount, currency)}
                                </td>
                                <td className="th-td-style ">
                                  {formatMoney(
                                    utilized_amount as any,
                                    currency
                                  )}
                                </td>
                                <td
                                  className="th-td-style "
                                  style={{ minWidth: "120px" }}
                                >
                                  {formatMoney(remaining_amount, currency)}
                                </td>

                                {remaining_amount > 0 ? (
                                  <td
                                    className=" cursor-pointer th-td-style"
                                    style={{
                                      minWidth: "120px",
                                      color: "#713fff",
                                    }}
                                    onClick={() => {
                                      history.push({
                                        pathname: `disbursement-request/${loan_application_detail_id}`,
                                        state: disburseData,
                                      });
                                    }}
                                  >
                                    Request Disbursement
                                  </td>
                                ) : (
                                  <td>Not Available</td>
                                )}
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {disbursedLoansData && (
                  <Paginator
                    size={preState.PageSize}
                    page={preState.page}
                    count={count}
                    changeCurrentPage={(page) =>
                      setPreState({ ...preState, page })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default AllDisbursedLoansMainNew;
