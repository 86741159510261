import React from "react";
import CreateWebHooks from "./CreateWebHooks";
import WebHookList from "./WebHookList";

const webHookMainComponent = () => {
  return (
    <div className="">
      <h5 className="page-subtitle pt-4 pb-4">Webhooks</h5>
      <CreateWebHooks />
      <WebHookList />
    </div>
  );
};

export default webHookMainComponent;
