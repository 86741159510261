import React, {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import AddNewUserModalContent from "../../AddNewUserModal/AddNewUserModalContent";
import SingleSignatoryModalStep1 from "./SingleSignatoryModalStep1";
import SingleSignatoryModalStep2 from "./SingleSignatoryModalStep2";
import SingleSignatoryModalStep3 from "./SingleSignatoryModalStep3";
import SingleSignatoryModalStep4 from "./SingleSignatoryModalStep4";
export interface IsignatoryModalStep {
  setSingleSignatory: Dispatch<SetStateAction<number | undefined>>;
  step: number;
  singleSignatory: number;
  setSignatoryType: Dispatch<SetStateAction<string>>;
}
export type IsingleSignatoryModalStep2Body = {
  email: string;
  levelNo: number;
};
const SingleSignatoryLandingPage = ({
  setSignatoryType,
  dismissRef,
}: {
  setSignatoryType: Dispatch<SetStateAction<string>>;
  dismissRef: LegacyRef<HTMLButtonElement> | undefined;
}) => {
  const [singleSignatory, setSingleSignatory] = useState<number | undefined>();
  const [singleSignatoryName, setSinglesSignatoryName] = useState<
    string | IsingleSignatoryModalStep2Body[]
  >("");
  const [agreementTick, setAgreementTick] = useState<string | number>("");
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const step = 1;
  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useAggregatorSignatoryUsers();
  useEffect(() => {
    if (usersData?.length === 0) {
      setSingleSignatory(1);
      setAddNewUserModal(true);
    } else {
      setSingleSignatory(3);
      setAddNewUserModal(false);
    }
  }, [usersData]);

  return (
    <div>
      {(() => {
        switch (singleSignatory) {
          case 1:
            return (
              <SingleSignatoryModalStep1
                singleSignatory={singleSignatory}
                setSingleSignatory={setSingleSignatory}
                setSignatoryType={setSignatoryType}
                step={step}
              />
            );
          case 2:
            return (
              <AddNewUserModalContent
                singleSignatory={singleSignatory}
                setSingleSignatory={setSingleSignatory}
                setSignatoryType={setSignatoryType}
                step={step}
                addNewUserModal={addNewUserModal}
              />
            );
          case 3:
            return (
              <SingleSignatoryModalStep2
                usersData={usersData}
                usersStatus={usersStatus}
                usersError={usersError}
                singleSignatory={singleSignatory}
                setSingleSignatory={setSingleSignatory}
                setSinglesSignatoryName={setSinglesSignatoryName}
                setSignatoryType={setSignatoryType}
                step={step}
                setAddNewUserModal={setAddNewUserModal}
              />
            );

          case 4:
            return (
              <SingleSignatoryModalStep3
                singleSignatory={singleSignatory}
                setSingleSignatory={setSingleSignatory}
                setAgreementTick={setAgreementTick}
                step={step}
              />
            );
          case 5:
            return (
              <SingleSignatoryModalStep4
                singleSignatory={singleSignatory}
                setSingleSignatory={setSingleSignatory}
                singleSignatoryName={singleSignatoryName}
                agreementTick={agreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default SingleSignatoryLandingPage;
