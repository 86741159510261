import React, { FC } from "react";
import { ReactComponent as NoDataIcon } from "../../svg/icons/no-data.svg";
import styles from "./NoTableDataAvailable.module.scss";

interface INoTableDataAvailable {
  text?: string;
}

const NoTableDataAvailable: FC<INoTableDataAvailable> = ({ text }) => {
  return (
    <tr>
      <td colSpan={100}>
        <div className={styles.noDataWrapper}>
          <NoDataIcon />
          <span>{text ? text : "There is no data available"}</span>
        </div>
      </td>
    </tr>
  );
};

export default NoTableDataAvailable;
