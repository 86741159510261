import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IeachInvestorInvestment {
  id: number;
  walletAccountId: string;
  investmentProductId: number;
}
const getEachInvestorsInvestment = async ({
  id,
  walletAccountId,
  investmentProductId,
}: IeachInvestorInvestment) => {
  if (investmentProductId && walletAccountId && id) {
    const queryParams = `?funderId=${Number(id)}&investmentProductId=${Number(
      investmentProductId
    )}&walletAccountId=${Number(walletAccountId)}`;

    const data = await getDataWithDotNet(
      `${apiEndpoints.INVESTORS_INVESTMENT}${queryParams}`
    );
    return data.data;
  }
};
export default function useEachInvestorsInvestment({
  id,
  walletAccountId,
  investmentProductId,
}: IeachInvestorInvestment) {
  return useQuery(
    [
      { id, walletAccountId, investmentProductId },
      "getEachInvestorsInvestment",
    ],
    getEachInvestorsInvestment,
    {
      refetchOnWindowFocus: false,
    }
  );
}
