import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getSecurityQuestions = async () => {
  const { data } = await getData(apiEndpoints.GET_ALL_SECURITY_QUESTIONS);
  return data;
};

export default function useSecurityQuestions() {
  return useQuery("getSecurityQuestions", getSecurityQuestions, {
    refetchOnWindowFocus: false,
  });
}
