export const GRAPH_SCALE = 100000;
export const BAR_CHART_SCALE = 1000;
export const BAR_CHART_SCALE2 = 10000;

export const barChartSingleOptions = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: "index",
      callbacks: {
        label: function (item: any) {
          const itemAmount = Math.round(item.raw * GRAPH_SCALE);
          return itemAmount.toLocaleString("en-NG");
        },
      },
    },
  },
  responsive: true,

  scales: {
    x: {
      gridLines: {
        lineWidth: 1,
        color: "#F0F0F0",
      },
    },

    y: {
      gridLines: {
        lineWidth: 1,
        color: "#F0F0F0",
      },
      ticks: {
        beginAtZero: true,
        callback: (value: number) => Math.round(value * GRAPH_SCALE),
      },
    },
  },
};
export const barChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  type: "bar",
  responsive: true,
  tooltip: {
    callbacks: {
      label: function (tooltipItem: { yLabel: number }) {
        const label = Math.round(tooltipItem.yLabel * BAR_CHART_SCALE);
        return label;
      },
    },
  },
  scales: {
    x: {
      gridLines: {
        lineWidth: 1,
        color: "#F0F0F0",
      },
    },

    y: {
      gridLines: {
        lineWidth: 1,
        color: "#F0F0F0",
      },
      ticks: {
        beginAtZero: true,
        callback: (value: number) => Math.round(value * BAR_CHART_SCALE),
      },
    },
  },
};
export const emptyBarChartSingleOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  tooltip: {
    callbacks: {
      label: function () {
        return "";
      },
    },
  },
  scales: {
    x: {
      gridLines: {
        lineWidth: 0,
        color: "#fff",
      },
    },

    y: {
      gridLines: {
        lineWidth: 0,
        color: "#fff",
      },
      ticks: {
        beginAtZero: true,
        callback: (value: number) => Math.round(value * GRAPH_SCALE),
      },
    },
  },
};
export const emptyBarChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  type: "bar",
  responsive: true,
  tooltip: {
    callbacks: {
      label: function () {
        return "";
      },
    },
  },
  scales: {
    x: {
      gridLines: {
        lineWidth: 0,
        color: "#fff",
      },
    },

    y: {
      gridLines: {
        lineWidth: 0,
        color: "#fff",
      },
      ticks: {
        beginAtZero: true,
        callback: (value: number) => Math.round(value * BAR_CHART_SCALE),
      },
    },
  },
};
