import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

interface IdashboardRepaymenttGraph {
  aggregatorId: number;
  filter: number;
  currentCurrencyValue: string;
  // activeProductName: string;
}
const getDashboardRepaymentsGraph = async ({
  aggregatorId,
  filter,
  currentCurrencyValue,
}: // activeProductName,
IdashboardRepaymenttGraph) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.dashboardRepaymentsGraph}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&filter=${filter}
    `
  );
  return data;
};

export default function useDashboardRepaymentGraph({
  aggregatorId,
  filter,
  currentCurrencyValue,
}: // activeProductName,
IdashboardRepaymenttGraph) {
  return useQuery(
    [
      { aggregatorId, filter, currentCurrencyValue },
      "getDashboardRepaymentsGraph",
    ],
    getDashboardRepaymentsGraph,
    {
      enabled: currentCurrencyValue ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
