import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IofferLetterAndSla {
  id:string
  viewHistory?:boolean
}
const getOfferLetterAndSlaHistory = async ({id}:IofferLetterAndSla) => {
 const data = await getDataWithDotNet(
    `${apiEndpoints.OfferLetterAndSlaHistory}?aggregatorId=${id}`,
    true
  );
  return data.data;
};

export default function useOfferLetterAndSlaHistory({ id, viewHistory }:IofferLetterAndSla) {
  return useQuery(
    [{id}, "getOfferLetterAndSlaHistory"],
    getOfferLetterAndSlaHistory,
    {
      enabled:viewHistory?true:false,
      refetchOnWindowFocus: false,
    }
  );
}
