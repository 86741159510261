import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";
import { useQueryCache } from "react-query";
import { useAuth } from "../../../Context/auth.context";
import { deleteData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
const ConfirmationDeleteCollateralModal = ({
  id,
  setEditCollateral,
  setEditCollateralDetails,
  reset,
}: {
  id: number | null;
  setEditCollateralDetails: any;
  setEditCollateral: Dispatch<SetStateAction<boolean>>;
  reset: () => void;
}) => {
  const { currentLoanApplicationId } = useAuth();

  const [confirmationDeleteLoading, setConfirmationDeleteLoading] =
    useState<boolean>(false);
  const [confirmationSuccessDelete, setConfirmationSuccessDelete] =
    useState<boolean>(false);

  const [confirmationSuccessDeleteMsg, setConfirmationSuccessDeleteMsg] =
    useState<string | null>(null);

  const [confirmationDeleteError, setConfirmationDeleteError] =
    useState<{} | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const queryCache = useQueryCache();
  const DeleteFinancialInfo = async () => {
    try {
      setConfirmationDeleteLoading(true);
      setConfirmationSuccessDelete(false);
      const res = await deleteData(
        `${apiEndpoints.LOAN_APPLICATIONS}/collateral?id=${id}&collateral?id=1&loan_application_id=${currentLoanApplicationId}`,
        {},

        true
      );
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(res?.message);
      setEditCollateralDetails(null);
      reset();
      setEditCollateral(false);
      queryCache.invalidateQueries([
        { id: currentLoanApplicationId },
        "getLoanApplicationById",
      ]);
      setTimeout(() => {
        setConfirmationSuccessDelete(false);
        setConfirmationSuccessDeleteMsg(null);

        closeModalRef?.current?.click();
      }, 2000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "ConfirmationDeleteCollateralModal.tsx",
        },
      });
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(null);
      setConfirmationSuccessDelete(false);
      setConfirmationDeleteError(errorHandler(error));
      setTimeout(() => {
        closeModalRef?.current?.click();
        setConfirmationDeleteError(null);
      }, 5000);
    }
  };

  return (
    <div
      className="modal fade"
      id="confirmationDeleteCollateralModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmationDeleteFinancialModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {confirmationDeleteError && (
            <p className="alert alert-danger small text-center">
              {confirmationDeleteError}
            </p>
          )}
          {(confirmationSuccessDeleteMsg || confirmationSuccessDelete) && (
            <p className="alert alert-success small text-center">
              {" "}
              {confirmationSuccessDeleteMsg}
            </p>
          )}
          <div className="modal-body" style={{ color: "#150849" }}>
            {confirmationDeleteLoading ? (
              <PageLoader />
            ) : (
              <>
                <button
                  type="button"
                  className={`d-flex justify-content-end close`}
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div>
                  <p className="text-center">
                    Are you sure you want to delete?
                  </p>

                  <div className={`d-flex justify-content-around  `}>
                    <small
                      className="text-center cursor-pointer transition-3d-hover "
                      onClick={DeleteFinancialInfo}
                    >
                      Yes
                    </small>
                    <small
                      className={`
                   text-center cursor-pointer  transition-3d-hover`}
                      data-dismiss="modal"
                    >
                      No
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteCollateralModal;
