import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getRecentBorrowers = async () => {
  const data = await getDataWithDotNet(apiEndpoints.getRecentBorrowers);
  return data.data;
};

export default function useRecentBorrowers() {
  return useQuery(["getRecentBorrowers"], getRecentBorrowers, {
    refetchOnWindowFocus: false,
  });
}
