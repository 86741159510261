import React from "react";
import Loader from "../../../components/Loader/Loader.component";
import CustomInputField from "../../../components/CustomHTMLElements/CustomInputField";

interface IinvestorData {
  funder_type_id: number | string;
  funder_type: string;
  country: string;
  account_name: string;
  account_number: string;
  bank_name: string;
  biz_name: string;
  bvn: string;
  first_name: string;
  email: string;
  gender: string;
  last_name: string;
  phone_number: string;
  pub_date: string;
  biz_email: string;
  rc_number: number;
}
export default function InvestorInfo({
  investorData,
  status,
  error,
}: {
  investorData: IinvestorData;
  status: string;
  error: unknown;
}) {
  if (status === "loading") {
    return <Loader />;
  }
  if (error) {
    return (
      <div className="advancly-wrapper-container">
        <div className="grid__padding animated fadeInRight advancly-sub-wrapper">
          <h6 className="center-txt p-y-5">
            <i className="fas fa-bell" /> There was an issue while loading the
            Investor's Information, please try again.
          </h6>
        </div>
      </div>
    );
  }
  const {
    funder_type_id,
    funder_type,
    country,
    account_name,
    account_number,
    bank_name,
    biz_name,
    bvn,
    first_name,
    email,
    gender,
    last_name,
    phone_number,
    pub_date,
    biz_email,
    rc_number,
  } = investorData;
  return (
    <div className="onboardingOuterLayout advancly-wrapper-container">
      <div className="onboardingInnerLayout advancly-sub-wrapper">
        <form>
          <div className="row">
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Investor Type"
                defaultValue={funder_type}
                readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Investor's First Name"
                defaultValue={first_name}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Investor's Last Name"
                defaultValue={last_name}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Email address"
                defaultValue={email}
                readOnly={true}
              />
            </div>
            {(funder_type_id === 2 || funder_type_id === "2") && (
              <>
                <div className="col-md-6">
                  <CustomInputField
                    type="text"
                    label="Business Email"
                    defaultValue={biz_email}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInputField
                    type="text"
                    label="Business name"
                    defaultValue={biz_name}
                    readOnly={true}
                  />
                </div>

                <div className="col-md-6">
                  <CustomInputField
                    type="text"
                    label="Registration Number"
                    defaultValue={rc_number}
                    readOnly={true}
                  />
                </div>
              </>
            )}
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Country"
                defaultValue={country}
                readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Gender"
                defaultValue={gender}
                readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Investor Phone Number"
                defaultValue={phone_number}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Bank"
                defaultValue={bank_name}
                readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Account"
                defaultValue={account_name}
                readOnly={true}
              ></CustomInputField>
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Account Number"
                defaultValue={account_number}
                readOnly={true}
              />
            </div>
            <div className="col-md-6">
              <CustomInputField
                type="text"
                label="Created Date"
                defaultValue={pub_date?.substring(0, 10)}
                readOnly={true}
              />
            </div>

            {(funder_type_id === 1 || funder_type_id === "1") && (
              <div className="col-md-6">
                <CustomInputField
                  type="text"
                  label="BVN"
                  defaultValue={bvn}
                  readOnly={true}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
