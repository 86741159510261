import PageLoader from "../../PageLoader/PageLoader.component";
import { ReactComponent as DeleteIcon } from "../../../img/DeleteIcon2.svg";
import { ReactComponent as EditIcon } from "../../../img/EditIcon.svg";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
import { useAuth } from "../../../Context/auth.context";
import { formatMoney } from "../../../helpers/formatter";
import ConfirmationDeleteCollateralModal from "./ConfirmationDeleteCollateralModal";
import { useState } from "react";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";

const CollateralExistingList = ({
  setEditCollateral,
  setEditCollateralDetails,
  reset,
}: any) => {
  const { currentLoanApplicationId } = useAuth();
  const [collateralId, setCollateralId] = useState<number | null>(null);
  const {
    data: existingCollateralData,
    status: existingCollateralStatus,
    error: existingCollateralError,
  } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;

  return (
    <div>
      {existingCollateralError && (
        <p className="alert alert-danger small">{existingCollateralError}</p>
      )}

      {existingCollateralStatus === "loading" ? (
        <PageLoader />
      ) : (
        existingCollateralData?.collateral_information?.map(
          (loanInfoList: any) => {
            const { collateral_type, value, id, currency } = loanInfoList;

            return (
              <div className="my-4" key={id}>
                <div className="card-border-wrapper-medium d-flex justify-content-between align-items-center">
                  <div className="mt-4">
                    <h5 className="color-dark-purple">
                      {collateral_type ? collateral_type : "unavailable"}
                    </h5>
                    <p className="bg-light-grey">
                      {currency && value >= 0
                        ? formatMoney(value, currency)
                        : "unavailable"}
                    </p>
                  </div>
                  {!LoanApplicationEditOrWrite && (
                    <div>
                      <span
                        title="Edit Loan information List"
                        className="cursor-pointer  transition-3d-hover "
                        onClick={() => {
                          setEditCollateral(true);
                          setEditCollateralDetails(loanInfoList);
                        }}
                      >
                        <EditIcon className={`transition-3d-hover  `} />{" "}
                      </span>
                      <span
                        title="Delete Collateral List"
                        className="cursor-pointer  transition-3d-hover "
                        data-toggle="modal"
                        data-target="#confirmationDeleteCollateralModal"
                        onClick={() => {
                          setCollateralId(id);
                        }}
                      >
                        {" "}
                        <DeleteIcon className={`transition-3d-hover  `} />{" "}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )
      )}
      <ConfirmationDeleteCollateralModal
        id={collateralId}
        setEditCollateral={setEditCollateral}
        setEditCollateralDetails={setEditCollateralDetails}
        reset={reset}
      />
    </div>
  );
};

export default CollateralExistingList;
