import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorActiveCurrencies = async () => {
  const data = await getDataWithDotNet(apiEndpoints.USER_ACTIVE_CURRENCIES);
  return data;
};

export default function useAggregatorActiveCurrencies() {
  return useQuery(
    ["getAggregatorActiveCurrencies"],
    getAggregatorActiveCurrencies
  );
}
