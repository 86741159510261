import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { queryCache } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { postData, putData } from "../../../../../apis/apiMethods";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../../../components/CustomHTMLElements/CustomTextArea";
import Loader from "../../../../../components/Loader/Loader.component";
import { errorHandler } from "../../../../../helpers/errorHandler";
import { yupValidators } from "../../../../../helpers/yupValidators";
import { useAuth } from "../../../../../Context/auth.context";
import LoaderColored from "../../../../../components/Loader/LoaderColored";
const schema = yup.object().shape({
  firstname: yupValidators.firstName,
  lastname: yupValidators.lastName,
  email: yupValidators.email,

  role: yup.string().required("Please enter role"),
  academicQualifications: yup
    .string()
    .required("Please enter academic qualifications"),
  periodOnCompanyBoard: yup
    .string()
    .required("Please enter period on company board"),
  workExperience: yup.string().required("Please enter work experience"),
  experienceTotheRole: yup
    .string()
    .required("Please enter experience with relation to role"),
});

const SeniorManagersForm = ({
  setIsAddMoreSeniorManager,
  setIsSeniorManagerComplete,
  loading,
  setLoading,
  setError,
  setSuccess,
  editAndPopulateManagerForm,
  setEditAndPopulateManagerForm,
  scrollToTop,
  addAnotherLoading,
  setAddAnotherLoading,
}: any) => {
  const { aggregatorDetails } = useAuth();
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const history = useHistory();

  const onAddMoreSeniorManagerHandler = async (data: any) => {
    const {
      firstname,
      email,
      lastname,
      role,
      academicQualifications,
      periodOnCompanyBoard,
      workExperience,
      experienceTotheRole,
    } = data;
    const ReqBody = {
      first_name: firstname,
      last_name: lastname,
      email,
      role,
      educational_qualification: academicQualifications,
      period_in_company_board: periodOnCompanyBoard,
      work_experience: workExperience,
      experience_relevant_to_role: experienceTotheRole,
      funder_person_type: 5,
    };

    try {
      setAddAnotherLoading(true);
      setError(null);
      if (editAndPopulateManagerForm) {
        const res = await putData(
          apiEndpoints.FUNDER_PEOPLE,
          [{ ...ReqBody, id: editAndPopulateManagerForm.id }],
          true
        );
        setSuccess(res?.message);
        scrollToTop();
      } else {
        const res = await postData(apiEndpoints.FUNDER_PEOPLE, [ReqBody], true);
        setSuccess(res?.message);
        scrollToTop();
      }

      setAddAnotherLoading(false);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.aggregator_id, funderPeopleType: 5 },
        "getOnboardingManagement",
      ]);

      setEditAndPopulateManagerForm(null);
      reset();
      setIsAddMoreSeniorManager(true);

      setTimeout(() => {
        setSuccess(null);
      }, 5000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "SeniorManagerForm.tsx",
        },
      });
      setAddAnotherLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      scrollToTop();
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onSaveContinueHandler = async (data: any) => {
    const {
      firstname,
      lastname,
      email,
      role,
      academicQualifications,
      periodOnCompanyBoard,
      workExperience,
      experienceTotheRole,
    } = data;
    const ReqBody = {
      first_name: firstname,
      last_name: lastname,
      email,
      role,
      educational_qualification: academicQualifications,
      period_in_company_board: periodOnCompanyBoard,
      work_experience: workExperience,
      experience_relevant_to_role: experienceTotheRole,
      funder_person_type: 5,
    };

    try {
      setLoading(true);
      setError(null);
      if (editAndPopulateManagerForm) {
        const res = await putData(
          apiEndpoints.FUNDER_PEOPLE,
          [{ ...ReqBody, id: editAndPopulateManagerForm.id }],
          true
        );
        setSuccess(res?.message);
        scrollToTop();
      } else {
        const res = await postData(apiEndpoints.FUNDER_PEOPLE, [ReqBody], true);
        setSuccess(res?.message);
        scrollToTop();
      }

      setLoading(false);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.aggregator_id, funderPeopleType: 5 },
        "getOnboardingManagement",
      ]);
      setEditAndPopulateManagerForm(null);
      reset();
      setIsSeniorManagerComplete(true);

      setTimeout(() => {
        setSuccess(null);
        history.push({
          pathname: "/get-started/onboarding",
          state: {
            successMessage: "Your account has been updated successfully",
          },
        });
      }, 2000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "SeniorManagerForm.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      scrollToTop();
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  useEffect(() => {
    if (editAndPopulateManagerForm) {
      setValue("firstname", editAndPopulateManagerForm.first_name);
      setValue("lastname", editAndPopulateManagerForm.last_name);
      setValue("email", editAndPopulateManagerForm.email);
      setTimeout(() => {
        setValue("country", editAndPopulateManagerForm.country_code);
      }, 2000);
      setValue("role", editAndPopulateManagerForm.role);
      setValue(
        "academicQualifications",
        editAndPopulateManagerForm.educational_qualification
      );
      setValue("bvnOrNationalId", editAndPopulateManagerForm.bvn);
      setValue("governmentID", editAndPopulateManagerForm.identity_number);
      setValue(
        "periodOnCompanyBoard",
        editAndPopulateManagerForm.period_in_company_board
      );
      setValue("workExperience", editAndPopulateManagerForm.work_experience);
      setValue(
        "experienceTotheRole",
        editAndPopulateManagerForm.experience_relevant_to_role
      );
    }
  }, [editAndPopulateManagerForm, setValue]);
  return (
    <div className="py-4">
      <form>
        <div className="row">
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="firstname"
              placeholder="Enter  your first name"
              label="First Name"
              errors={errors.firstname}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="lastname"
              placeholder="Enter your last name"
              label="Last Name"
              errors={errors.lastname}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="email"
              maxLength={128}
              ref={register}
              name="email"
              placeholder="Enter your email"
              label="Email"
              errors={errors.email}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="role"
              placeholder="E.g. Chairman"
              label="Role"
              errors={errors.role}
              showRequiredIcon={true}
            />
          </div>
          <div className="col-12">
            <CustomInputField
              type="text"
              maxLength={128}
              ref={register}
              name="academicQualifications"
              placeholder="E.g. PH.D ...."
              label="Academic & Professional Qualifications"
              errors={errors.academicQualifications}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12 col-lg-12">
            <CustomTextArea
              maxLength={256}
              ref={register}
              placeholder="E.g. Former MD of Fidelity Bank - Jan, 2002 - June, 2022"
              label="Work Experience"
              name="workExperience"
              errors={errors.workExperience}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12">
            <CustomInputField
              type="number"
              maxLength={2}
              ref={register}
              name="periodOnCompanyBoard"
              placeholder="E.g. 4 "
              label={`Period on the company’s board (years)`}
              errors={errors.periodOnCompanyBoard}
              showRequiredIcon={true}
            />
          </div>

          <div className="col-12 col-lg-12 pt-2">
            <CustomTextArea
              maxLength={256}
              ref={register}
              placeholder="E.g. Special Adviser on finance, Fidelity Bank. July 2014 - June, 2021"
              label="Experience relevant to the role/business"
              name="experienceTotheRole"
              errors={errors.experienceTotheRole}
              showRequiredIcon={true}
            />
          </div>

          <div className=" ">
            <button
              id="buttonAddMore"
              type="submit"
              className="fa fa-plus  advancly-text-btn my-3"
              onClick={handleSubmit(onAddMoreSeniorManagerHandler)}
            >
              <span className="pl-1"> Add Another Senior Manager </span>
              {addAnotherLoading && <LoaderColored />}
            </button>
            <div>
              <button
                type="submit"
                onClick={handleSubmit(onSaveContinueHandler)}
                id="saveAndContinue"
                className="advancly-btn my-3"
                disabled={loading}
              >
                Save and Continue
                {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SeniorManagersForm;
