import React from "react";
import { Link } from "react-router-dom";
import styles from "./MakeLoanRequest.module.scss";

const LoanRequestComponent = ({
  loanRequestHeader,
  loanRequestDetails,
  loanRequestLoanType,
  resetBorrowerDetails,
  children,
}: any) => {
  return (
    <div className=" ">
      <div className={`${styles.loanRequestCard}`}>
        <div className={` ${styles.loanRequestCardSub} `}>
          <div className="d-flex ml-4">
            {children}
            <div className="ml-3 ">
              <h6 className="color-blue-hover-none font-weight-bold">
                {loanRequestHeader}
              </h6>
              <p>{loanRequestDetails}</p>
            </div>
          </div>

          <div
            className={` ${styles.loanrequestButton}  mr-4  `}
            onClick={() => resetBorrowerDetails}
          >
            <Link to={loanRequestLoanType}>
              <button className="advancly-white-btn ">Proceed</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanRequestComponent;
