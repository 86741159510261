import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IgetDisbursementDetails {
  page: number;
  PageSize: number;
  customer_id?: number;
}
const getDisbursementLoanDetails = async ({
  page,
  PageSize,
  customer_id,
}: IgetDisbursementDetails) => {
  let queryParams = `?customerId=${customer_id}&Page=${page}&PageSize=${PageSize}`;

  const data = await getData(
    `${apiEndpoints.loanApplicationDetailDisbursement}${queryParams}`
  );
  return data;
};

export default function useDisbursementLoanDetails({
  page,
  PageSize,
  customer_id,
}: IgetDisbursementDetails) {
  return useQuery(
    [{ customer_id, page, PageSize }, "getDisbursementLoanDetails"],
    getDisbursementLoanDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
