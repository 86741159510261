import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { useAuth } from "../../Context/auth.context";
import useTransactions from "../../custom-hooks/useTransactions";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { decrypt } from "../../helpers/encryptor";
import CONFIG from "../../components/config";
import Loader from "../../components/Loader/Loader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";

interface Itransactions {
  funderFirstName: string;
  funderLastName: string;
  amount: number;
  transactionType: string;
  transactionID: number;
  transactionDate: string;
  currencyCode: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
}
function AllInvestorsMain() {
  const { aggregatorDetails } = useAuth();
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });
  const [downloadLoading, setDownloadLoading] = useState(false);

  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    aggregatorId: aggregatorDetails?.aggregator_id,
    startDate: "",
    endDate: "",
    referenceNumber: "",
  });
  const [count, setCount] = useState<any>(null);
  const { data, status, error } = useTransactions(preState);
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    const getToken = sessionStorage.getItem("token");
    const token = decrypt(getToken);
    let queryParams = `?AggregatorId=${state.aggregatorId}`;
    if (state.startDate && state.endDate) {
      queryParams = `${queryParams}&FromDate=${state.startDate}&ToDate=${state.endDate}`;
    }
    setPreState(state);
    fetch(
      CONFIG.BASE_URL +
        `/account/download_aggregator_transactions${queryParams}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token,
        },
        // body: JSON.stringify(data)
      }
    )
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                state.startDate && state.endDate
                  ? `Transactions from ${state.startDate} to ${state.endDate}.csv`
                  : `Transactions.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch(() => {
        appInsights.trackException({
          //@ts-ignore
          exception: error,
          properties: {
            fileName: "Transactions.jsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "Transactions.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
            <h3 className="page-title ">Transactions</h3>
            <button
              type="button"
              className="btn btn-sm advancly-white-btn m-0"
              onClick={handleDownload}
              disabled={status === "loading" || downloadLoading}
            >
              <i className="fas fa-download mr-1" />
              Download {downloadLoading && <Loader />}
            </button>
          </div>
          <div className="advancly-wrapper-container mt-3">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="">
                <div className="d-flex justify-content-between align-items-center py-3 advancly-sub-wrapper">
                  <form
                    className="d-flex flex-row flex-wrap"
                    onSubmit={filterData}
                  >
                    {/* <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px", marginTop: "10px" }}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Name..."
                        value={state.referenceNumber}
                        name="referenceNumber"
                        onChange={(e) => handleChange(e)}
                      />
                    </div> */}
                    <div className=" mr-3">
                      <div className="input-group mt-2">
                        <label className="mr-2 mt-2">From </label>
                        <CustomInputField
                          type="date"
                          name="startDate"
                          value={state.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3 mt-2">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <CustomInputField
                          type="date"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn  mt-2"
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="advancly-sub-wrapper mt-3">
                  <div className="table-responsive">
                    <table className="table-style">
                      <thead className="">
                        <tr>
                          <th className="th-td-style th-style ">Investor</th>
                          <th className="th-td-style th-style ">Amount</th>
                          <th className="th-td-style th-style ">
                            Transaction Type
                          </th>
                          <th className="th-td-style th-style ">
                            Transaction Date
                          </th>
                          <th className="th-td-style th-style ">
                            Transaction Id
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.data?.length ? (
                          data?.data?.map(
                            (request: Itransactions, _id: number) => {
                              const {
                                funderFirstName,
                                funderLastName,
                                amount,
                                transactionType,
                                transactionID,
                                transactionDate,
                                currencyCode,
                              } = request;
                              return (
                                <tr key={_id}>
                                  <td className="th-td-style ">
                                    {funderFirstName} {funderLastName}
                                  </td>
                                  <td className="th-td-style ">
                                    {formatMoney(amount, currencyCode)}
                                  </td>
                                  <td className="th-td-style ">
                                    {transactionType}
                                  </td>
                                  <td className="th-td-style ">
                                    {transactionDate.substring(0, 10)}
                                  </td>
                                  <td className="th-td-style ">
                                    {transactionID}
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...state, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(AllInvestorsMain);
