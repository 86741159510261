import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import { yupValidators } from "../../helpers/yupValidators";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { useHistory } from "react-router-dom";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  currentPassword: yup.string().required("Please enter your current password"),
  newPassword1: yupValidators.newPassword,
  newPassword2: yupValidators.newPassword,
});

const ChangePassword = ({
  changePasswordText,
}: {
  changePasswordText: string;
}) => {
  const [error, setError] = useState<string | number>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: {
    currentPassword: string;
    newPassword1: string;
    newPassword2: string;
  }) => {
    const { currentPassword, newPassword1, newPassword2 } = data;
    setLoading(true);
    setError("");
    setMessage("");

    const reqBody = {
      old_password: currentPassword,
      password1: newPassword1,
      password2: newPassword2,
    };

    try {
      const res = await postDataWithDotNet(
        apiEndpoints.changePasswordUrl,
        reqBody,
        true
      );
      setLoading(false);
      if (res.status && res.status === true) {
        setMessage(
          "Your password has been changed successfully. You will be required to log in with your new password."
        );

        setTimeout(() => {
          setMessage("");
          sessionStorage.removeItem("token");
          history.push("/login");
        }, 5000);
      } else {
        setError(
          res.message ||
            "An error occured while changing your password, please try again."
        );
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ChangePassword.component.jsx" },
      });
      setLoading(false);
      //@ts-ignore
      if (error?.response?.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
      }
    }
  };

  return (
    <div className="advancly-wrapper-container">
      <div className="advancly-sub-wrapper">
        <h5 className="page-subtitle">Change Password</h5>

        <p className="text-center">
          <small className="text-danger font-weight-bold ">
            {changePasswordText}
          </small>
        </p>

        {message && <p className="alert alert-success small">{message}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        <form className="form mt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 col-md-5">
              <CustomInputField
                type="password"
                maxLength={128}
                ref={register}
                name="currentPassword"
                placeholder="Enter your current password"
                label="Current Password"
                errors={errors.currentPassword}
                hasActionButton={true}
              />
            </div>
            <div className="col-12 col-md-5">
              <CustomInputField
                type="password"
                maxLength={128}
                ref={register}
                name="newPassword1"
                placeholder="Enter your new password"
                label="New Password"
                errors={errors.newPassword1}
                hasActionButton={true}
              />
            </div>
            <div className="col-12 col-md-5">
              <CustomInputField
                type="password"
                maxLength={128}
                ref={register}
                name="newPassword2"
                placeholder="Re-enter your new password"
                label="Re-enter New Password"
                errors={errors.newPassword2}
                hasActionButton={true}
              />
            </div>

            <div className="col-12">
              <div className="d-flex justify-content-start mt-3 flex-wrap">
                <button
                  type="button"
                  disabled={loading}
                  className="btn advancly-nobg-btn  mr-4 transition-3d-hover"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn advancly-btn  transition-3d-hover"
                >
                  Update
                  {loading && <Loader />}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
