import React from "react";
import DeveloperAPIKeys from "./DeveloperAPIKeys/DeveloperAPIKeys";
import WebHookMainComponent from "./WebHooks/WebHookMainComponent";

const DeveloperMainComponent = () => {
  return (
    <div>
      <div>
        <DeveloperAPIKeys />
        <WebHookMainComponent />
      </div>
    </div>
  );
};

export default DeveloperMainComponent;
