import CONFIG from "../components/config";

const documentationCode = `
<form>
    <button type="button" onclick="initAdvanclyBorrower()"> Request Loan </button> 
</form>
    
<script>
    function initAdvanclyBorrower() {
        let iframe = document.createElement("iframe");
        iframe.src = https://${CONFIG.BORROWER_HOST_NAME};
        iframe.style = "border: none; border-radius: 12px; margin: auto; display: block; margin-top: 14vh; width: 75vw; height: 75vh";

        let iframeContainer = document.createElement("div");
        iframeContainer.classList.add("iframe-container");
        iframeContainer.style = "position: fixed; height: 100vh; width: 100vw; background-color: rgba(0, 0, 0, .5); top: 0; left: 0; z-index: 9999999";

        let closeButton = document.createElement("p");
        closeButton.innerText = "+";
        closeButton.style = "position: absolute; font-size: 24px; color: white; top: 32px; right: 32px; cursor: pointer; transform: rotate(45deg)";

        closeButton.addEventListener("click", function() {
            iframeContainer.remove();
        })

        iframeContainer.appendChild(closeButton);
        iframeContainer.appendChild(iframe);
        document.body.appendChild(iframeContainer);
    }

    window.initAdvanclyBorrower = initAdvanclyBorrower;
</script>`;

export default documentationCode;
