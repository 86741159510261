import { useEffect, useState } from "react";
import styles from "../InvestorsInvestment.module.scss";
import useEachInvestorsInvestment from "../../../../custom-hooks/useEachInvestorsInvestment";
import useEachInvestorInvestmentSummary from "../../../../custom-hooks/useEachInvestorInvestmentSummary";
import { formatMoney } from "../../../../helpers/formatter";
import Loader from "../../../../components/Loader/Loader.component";
import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "../../../../components/NoTableDataAvailable/NoTableDataAvailable.component";

const InvestorDollarInvestment = ({
  id,
  data,
  investment_product_id,
}: {
  id: number;
  data: {
    funder_details: {
      usd_wallet_id: string;
    };
  };
  investment_product_id: number;
}) => {
  const [walletAccountId, setWalletAccountId] = useState("");

  useEffect(() => {
    if (data.funder_details.usd_wallet_id) {
      setWalletAccountId(data.funder_details.usd_wallet_id);
    }
  }, [data]);

  const {
    data: investmentsData,
    status: investmentsStatus,
    error,
  } = useEachInvestorsInvestment({
    id,
    walletAccountId,
    investmentProductId: investment_product_id,
  });

  const { data: investmentSummaryData, status: investmentSummaryStatus } =
    useEachInvestorInvestmentSummary({
      id,
      walletAccountId,
      investmentProductId: investment_product_id,
    });

  return (
    <div>
      {investmentsStatus === "loading" ? (
        <Loader />
      ) : error ? (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt py-5">
              <i className="fas fa-bell" /> Unable to Fetch Investor's
              Investment
            </h4>
          </div>
        </div>
      ) : investmentsData &&
        //@ts-ignore
        investmentsStatus !== "loading" &&
        data?.funder_details?.usd_wallet_id ? (
        <div className="datatable-wrapper">
          <div className=" speed-1x animated fadeInRight">
            {/*Datatable || Loader || Error Message*/}
            <div className="row mt-4">
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Active Investments
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <PageLoader />
                    ) : (
                      investmentSummaryData?.total_active_investment || "N/A"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Amount Invested
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <PageLoader />
                    ) : (
                      formatMoney(
                        investmentSummaryData?.total_amount_invested,
                        "$"
                      ) || "N/A"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column">
                <div className={styles.rightBorder}>
                  <div className={styles.investmentTitle}>
                    Total Interest Payable
                  </div>
                  <div className={styles.investmentSubTitle}>
                    {investmentSummaryStatus === "loading" ? (
                      <PageLoader />
                    ) : (
                      formatMoney(
                        investmentSummaryData?.total_interest_payable,
                        "$"
                      ) || "N/A"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <h5 className={`${styles.portfolio} mb-3`}>Investments</h5>

            <div className="row m-x-sm m-y-2 grid__padding">
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-lighter-grey">
                    <tr>
                      <th>Amount Invested</th>
                      <th>Tenor</th>
                      <th>Total Interest Payable</th>
                      <th>Interest Repayment Type</th>
                      <th>Monthly Yield</th>
                      <th>Reference No</th>
                      <th>Start Date</th>
                      <th>MaturityDate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {investmentsData &&
                      investmentsData.map(
                        (
                          request: {
                            principal_amount: number;
                            tenure: number;
                            interest_amount: number;
                            interest_repayment_type: string;
                            monthly_interest_repayment: number;
                            reference_number: string;
                            effective_date: string;
                            maturity_date: string;
                          },
                          _id: number
                        ) => {
                          const {
                            principal_amount,
                            tenure,
                            interest_amount,
                            interest_repayment_type,
                            monthly_interest_repayment,
                            reference_number,
                            effective_date,
                            maturity_date,
                          } = request;
                          return (
                            <tr>
                              <td>{formatMoney(principal_amount, "$")}</td>
                              <td>{tenure} days</td>
                              <td>{formatMoney(interest_amount, "$")}</td>
                              <td>{interest_repayment_type}</td>
                              <td>
                                {formatMoney(monthly_interest_repayment, "$")}
                              </td>
                              <td>{reference_number}</td>
                              <td>{effective_date?.substring(0, 10)}</td>
                              <td>{maturity_date?.substring(0, 10)}</td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : !investmentsData ? (
        <NoTableDataAvailable />
      ) : (
        <NoTableDataAvailable />
      )}
    </div>
  );
};

export default InvestorDollarInvestment;
