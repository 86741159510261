import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorSignatoryUsers = async () => {
  const { data } = await getDataWithDotNet(
    apiEndpoints.GET_AGGREGATOR_SIGNATORY_USERS,
    true
  );
  return data;
};

export default function useAggregatorSignatoryUsers() {
  return useQuery(
    ["getAggregatorSignatoryUsers"],
    getAggregatorSignatoryUsers,
    {
      refetchOnWindowFocus: false,
    }
  );
}
