import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

interface IwalletDisbursementTransactions {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  searchStatus?: string;
}
const getWalletDisbursementTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  searchStatus,
}: IwalletDisbursementTransactions) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&start=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&end=${endDate}`;
  }
  if (searchStatus) {
    queryParams = `${queryParams}&status=${Number(searchStatus)}`;
  }

  const data = await getData(
    `${apiEndpoints.WALLET_DISBURSEMENT_TRANSACTIONS}?${queryParams}`
  );

  return data;
};

export default function useWalletDisbursementTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  searchStatus,
}: IwalletDisbursementTransactions) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, searchStatus },
      "getWalletDisbursementTransactions",
    ],
    getWalletDisbursementTransactions
  );
}
