import React, { useEffect, useState } from "react";
import styles from "./AllInvestment.module.scss";
import { ReactComponent as ProgressIconTop } from "../../svg/icons/progress_icon_top_colored.svg";
import { ReactComponent as ProgressIconDown } from "../../svg/icons/progress_icon_down.svg";
import AllInvestments from "./AllInvestments";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import { queryCache } from "react-query";
import useAggregatorActiveCurrencies from "../../custom-hooks/useAggregatorActiveCurrencies";
import Loader from "../../components/Loader/Loader.component";
import useInvestmentsOverviewMenu from "../../custom-hooks/useInvestmentOverviewMenu";
import LoaderColored from "../../components/Loader/LoaderColored";
import { formatMoney } from "../../helpers/formatter";

const AllInvestmentsOverview = () => {
  const [currentCurrency, setCurrentCurrency] = useState<any>("");
  const {
    data: aggregatorCurrencies,
    status: aggregatorCurrenciesStatus,
    // error: aggregatorCurrenciesError,
  } = useAggregatorActiveCurrencies();
  function currencyChangeHandler(e: any) {
    setCurrentCurrency(e.target.value);
    queryCache.invalidateQueries("getTransactionsOverview");
    queryCache.invalidateQueries("getTotalOutstanding");
    queryCache.invalidateQueries("getInvestmentOverview");
  }

  const {
    data: investmentOverviewData,
    status: investmentOverviewStatus,
    error: investmentOverviewError,
  } = useInvestmentsOverviewMenu({
    currentCurrencyValue: currentCurrency,
  });
  console.log(investmentOverviewData);
  useEffect(() => {
    let defaultCurrency;
    aggregatorCurrencies?.data !== undefined
      ? (defaultCurrency = aggregatorCurrencies?.data?.includes("NGN")
          ? "NGN"
          : aggregatorCurrencies?.data?.includes("USD")
          ? "USD"
          : aggregatorCurrencies?.data.includes("KES")
          ? "KES"
          : "NGN")
      : (defaultCurrency = "");

    setCurrentCurrency(defaultCurrency);
  }, [aggregatorCurrencies]);
  return (
    <div>
      {investmentOverviewError && (
        <p className="alert alert-danger small text-center">
          {investmentOverviewError as string}
        </p>
      )}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="d-flex align-items-center">
          <h3 className="page-title ">Investments </h3>
        </div>
      </div>
      <div
        className={`advancly-wrapper-container mt-3 ${styles.mainAllInvestments}`}
      >
        <div className="d-flex align-items-center">
          <h5 className="color-advancly-dark-persian-indigo ">Overview</h5>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="d-flex">
              <CustomSelectDropdown
                value={currentCurrency}
                onChange={currencyChangeHandler}
                label="Currency"
                className="ml-4 mr-2"
              >
                {aggregatorCurrencies?.data?.map(
                  (currency: string, id: number) => (
                    <option key={id}>{currency}</option>
                  )
                )}
              </CustomSelectDropdown>

              {aggregatorCurrenciesStatus === "loading" && <Loader />}
            </div>
          </div>
        </div>
        <div className={`advancly-sub-wrapper ${styles.mainSubWrapper}`}>
          <div className={styles.investmentOverviewContainer}>
            <div className={styles.investmentOverViewSubContainer}>
              {investmentOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <ProgressIconTop />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.investmentOverviewHeading}>
                      Total Active Investment Count
                    </p>
                    {investmentOverviewData?.total_active_investment >= 0 ? (
                      <p className={styles.investmentOverviewParagraph}>
                        {investmentOverviewData?.total_active_investment}{" "}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={styles.investmentOverViewSubContainer}>
              {investmentOverviewStatus === "loading" ? (
                <LoaderColored />
              ) : (
                <>
                  <ProgressIconDown />
                  <div className={styles.overviewTextContainer}>
                    <p className={styles.investmentOverviewHeading}>
                      Total Amount Invested{" "}
                    </p>
                    {investmentOverviewData?.total_amount_invested >= 0 ? (
                      <p className={styles.investmentOverviewParagraph}>
                        {formatMoney(
                          investmentOverviewData?.total_amount_invested,
                          currentCurrency
                        )}
                      </p>
                    ) : (
                      <small>Unavailable</small>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <AllInvestments />
      </div>
    </div>
  );
};

export default AllInvestmentsOverview;
