import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getListOfDocumentsToUpload = async () => {
  const { data } = await getDataWithDotNet(
    `${
      apiEndpoints.getListOfDocumentsToUpload
    }?provider_type=${1}&is_active=${true}`,
    true
  );
  return data;
};

export default function useListOfDocumentsToUpload() {
  return useQuery(["getListOfDocumentsToUpload"], getListOfDocumentsToUpload, {
    refetchOnWindowFocus: false,
  });
}
