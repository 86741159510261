import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getBusinessTypes = async () => {
  const data = await getData(apiEndpoints.BUSINESS_TYPES);
  return data.data;
};

export default function useBusinessTypes() {
  return useQuery(["getBusinessTypes"], getBusinessTypes, {
    refetchOnWindowFocus: false,
  });
}
