import React, { useState } from "react";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
  //@ts-ignore
} from "draft-js";

//@ts-ignore
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//@ts-ignore
import draftToHtml from "draftjs-to-html";

const RichTextBox = (props: any) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML(props?.defaultValue) as any
      )
    )
  );

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    return props.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  return (
    <div>
      <div
        className="px-1 py-0"
        style={{
          minHeight: "250px",
          border: "none",
        }}
      >
        <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: ["inline", "blockType", "list", "textAlign"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            blockType: {
              inDropdown: false,
              options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
            },
            list: { options: ["unordered", "ordered"] },
            textAlign: {
              options: ["left", "center", "right", "justify"],
            },
          }}
        />
      </div>
    </div>
  );
};

export default RichTextBox;
