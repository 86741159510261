import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getListOfFinancialDocument = async () => {
  const { data } = await getData(
    `${apiEndpoints.FINANCIAL_INFORMATION_LIST}`,
    true
  );
  return data;
};

export default function useListOfFinancialDocument() {
  return useQuery(["getListOfFinancialDocument"], getListOfFinancialDocument, {
    refetchOnWindowFocus: false,
  });
}
