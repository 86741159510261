import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import styles from "../LoanApplication.module.scss";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import Loader from "../../Loader/Loader.component";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData, postData, putData } from "../../../apis/apiMethods";
import CollateralExistingList from "./CollateralExistingList";
import { useForm } from "react-hook-form";
import { IstepLoanApp } from "../LoanApplicationMain";
import { useAuth } from "../../../Context/auth.context";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import LoaderColored from "../../Loader/LoaderColored";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { ReactComponent as Folder } from "../../../svg/icons/folder.svg";
import { ReactComponent as Bin } from "../../../svg/icons/bin.svg";
import { decrypt } from "../../../helpers/encryptor";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
import useCollateralList from "../../../custom-hooks/useCollateralList";
import { handleFormatAmountNoCurrency } from "../../../helpers/formatterNoDecimal";
import { useQueryCache } from "react-query";
import moment from "moment";
import ConfirmationSubmitLoanApplicationModal from "./ConfirmationSubmitLoanApplicationModal";
import useSpecificCurrencies from "../../../custom-hooks/useSpecificCurrencies";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";

const schema = yup.object().shape({
  // collateralvalueorAmount: yup.string().required("please enter value"),
  // registeredowner: yup.string().required("please enter register owner"),
  // location: yup.string().required("please fill this"),
  // developmentUseOfLand: yup.string().required("please fill this"),
  // commentsOnSimilarAssets: yup.string().required("please fill this"),
  // availableTitleDocument: yup.string().required("please fill this"),
  // financialInstrumentID: yup.string().required("please fill this"),
  // nameofvaluer: yup.string().required("please fill this"),
  // whenlastvalued: yup.string().required("please fill this"),
  // forcedsalevalue: yup.string().required("please fill this"),
  // bank: yup.string().required("please fill this"),
  // tenure: yup.string().required("please fill this"),
  // effectivedate: yup.string().required("please fill this"),
  // maturitydate: yup.string().required("please fill this"),
});
interface IreqBody {
  collateralvalueorAmount: string;
  registeredowner: string;
  location: string;
  developmentUseOfLand: string;
  commentsOnSimilarAssets: string;
  availableTitleDocument: string;
  collateralTypeID: number;
  nameofvaluer: string;
  whenlastvalued: string;
  forcedsalevalue: string;
  bank: string;
  tenure: string;
  effectivedate: string;
  maturitydate: string;
  currency: string;
  marketvalue: string;
}
interface IexistingData {
  base64_file_string: string;
  filename_with_extension: string;
  description: string;
}
const Collateral = ({ scrollToTop }: IstepLoanApp) => {
  const [loading, setLoading] = useState(false);
  const [addAnotherloading, setAddAnotherLoading] = useState(false);
  const [collateralDocTypeValue, setCollateralDocTypeValue] =
    useState<any>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [editCollateral, setEditCollateral] = useState<boolean>(false);
  const [editCollateralDetails, setEditCollateralDetails] = useState<any>();
  const queryCache = useQueryCache();
  const [currentEffectiveDate, setCurrentEffectiveDate] = useState<string>("");
  const [currentMaturityDate, setCurrentMaturityDate] = useState<string>("");
  const [amountValue, setAmountValue] = useState("");
  const [forcedValue, setForcedValue] = useState("");
  const [marketValue, setMarketValue] = useState("");
  const [existingEditDocumentData, setExistingEditDocumentData] =
    useState<IexistingData[]>();
  const [existingEditDocumentDataError, setExistingEditDocumentDataError] =
    useState("");
  const [existingDocumentDataLoader, setExistingDocumentDataLoader] =
    useState(false);

  const {
    currentLoanApplicationId,
    setCurrentLoanApplicationId,
    setEditLoanApplicationGlobal,
  } = useAuth();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileTexts, setFileTexts] = useState<string[]>([]);
  useState<any>();
  const OpenModalRef = useRef<HTMLButtonElement | null>(null);
  const {
    data: CollateralDocumentListData,
    status: CollateralDocumentListStatus,
    error: CollateralDocumentListError,
  } = useCollateralList();
  function convertToInputDateFormat(dateTimeString: string) {
    const momentDate = moment(dateTimeString);
    return momentDate.format("YYYY-MM-DD");
  }
  const {
    data: currencyData,
    status: currencyStatus,
    error: currencyErrors,
  } = useSpecificCurrencies();
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;
  useEffect(() => {
    const currentEffectiveDateObj = new Date(currentEffectiveDate);
    const currentMaturityDateObj = new Date(currentMaturityDate);
    const calCulateTimeDifference =
      currentMaturityDateObj.getTime() - currentEffectiveDateObj.getTime();
    const daysDifference = calCulateTimeDifference / (1000 * 60 * 60 * 24);
    setValue("tenure", daysDifference);
  }, [currentMaturityDate, currentEffectiveDate, setValue]);
  const resetAmountValue = () => {
    setAmountValue("");
    setForcedValue("");
    setMarketValue("");
  };
  const findCollateraltype =
    CollateralDocumentListData?.find((data: { id: number }) => {
      if (Number(collateralDocTypeValue)) {
        return Number(collateralDocTypeValue) === Number(data.id);
      } else if (Number(editCollateralDetails?.collateral_type_id)) {
        return (
          Number(editCollateralDetails?.collateral_type_id) === Number(data.id)
        );
      } else {
        return false;
      }
    }) || null;

  useEffect(() => {
    const storedCurrentId = decrypt(sessionStorage.getItem("currentLoanAppId"));
    const storedEditLoanApplicationGlobal = decrypt(
      sessionStorage.getItem("globalEditLoanApplication")
    );
    if (storedCurrentId) {
      setCurrentLoanApplicationId(storedCurrentId);
    }
    if (storedEditLoanApplicationGlobal) {
      setEditLoanApplicationGlobal(storedEditLoanApplicationGlobal);
    }
  }, [setCurrentLoanApplicationId, setEditLoanApplicationGlobal]);

  // start of collateral file
  const handleAddButtonClick = (event: any) => {
    event.preventDefault();
    // Trigger the file input click event when the "Add" button is clicked
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };
  const handleFileChange = async (event: React.ChangeEvent | any) => {
    event.preventDefault(); // Prevent the default form submission

    const newFiles = [...selectedFiles, ...(event.target.files || [])];
    setSelectedFiles(newFiles);

    // Initialize text for each newly added file
    const newTexts = Array.from(
      { length: (event.target.files || []).length },
      () => ""
    );
    setFileTexts([...fileTexts, ...newTexts]);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...selectedFiles];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);

    const newTexts = [...fileTexts];
    newTexts.splice(index, 1);
    setFileTexts(newTexts);
  };

  const handleFileTextChange = (index: number, newText: string) => {
    const newTexts = [...fileTexts];
    newTexts[index] = newText;
    setFileTexts(newTexts);
  };
  // For getting existing document data and add to payload
  const ExistingCollateralEditDocuments = useCallback(async () => {
    try {
      if (editCollateral && editCollateralDetails?.documents.length > 0) {
        setExistingDocumentDataLoader(true);
        setExistingEditDocumentDataError("");
        const existingDocs = editCollateralDetails?.documents.map(
          async (file: any) => {
            const { upload_path } = file;
            const dataResponse = await getData(
              `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${upload_path}`
            );
            const finalDataResponse = {
              base64_file_string: dataResponse.base64_string,
              filename_with_extension: dataResponse.file_name,
              description: dataResponse.description || "",
            };
            return finalDataResponse;
          }
        );
        const allResponses = await Promise.all(existingDocs);
        setExistingEditDocumentData(allResponses);
        setExistingDocumentDataLoader(false);
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "Collateral.tsx",
        },
      });
      setExistingEditDocumentDataError(errorHandler(error));
      setTimeout(() => {
        setExistingEditDocumentDataError("");
      }, 6000);
    }
  }, [editCollateralDetails, editCollateral]);

  useEffect(() => {
    if (editCollateral) {
      ExistingCollateralEditDocuments();

      if (editCollateralDetails?.collateral_type_id) {
        setValue("collateralTypeID", editCollateralDetails?.collateral_type_id);
      }
      if (editCollateralDetails?.value) {
        setValue(
          "collateralvalueorAmount",
          Number(editCollateralDetails?.value)
        );
      }
      if (editCollateralDetails?.registered_owner) {
        setValue("registeredowner", editCollateralDetails?.registered_owner);
      }
      if (editCollateralDetails?.location) {
        setValue("location", editCollateralDetails?.location);
      }

      if (editCollateralDetails?.development_and_use_of_land) {
        setValue(
          "developmentUseOfLand",
          editCollateralDetails?.development_and_use_of_land
        );
      }
      if (editCollateralDetails?.name_of_valuer) {
        setValue("nameofvaluer", editCollateralDetails?.name_of_valuer);
      }
      if (editCollateralDetails?.when_last_valued) {
        const whenLastDateValue = convertToInputDateFormat(
          editCollateralDetails?.when_last_valued
        );
        setValue("whenlastvalued", whenLastDateValue);
      }
      if (editCollateralDetails?.forced_sale_value) {
        setValue(
          "forcedsalevalue",
          Number(editCollateralDetails?.forced_sale_value)
        );
      }
      if (editCollateralDetails?.market_value) {
        setValue("marketvalue", Number(editCollateralDetails?.market_value));
      }

      if (editCollateralDetails?.bank) {
        setValue("bank", editCollateralDetails?.bank);
      }

      if (editCollateralDetails?.tenure) {
        setValue("tenure", editCollateralDetails?.tenure);
      }
      if (editCollateralDetails?.effective_date) {
        const effectiveDateValue = convertToInputDateFormat(
          editCollateralDetails?.effective_date
        );

        setValue("effectivedate", effectiveDateValue);
      }
      if (editCollateralDetails?.maturity_date) {
        const maturityDateValue = convertToInputDateFormat(
          editCollateralDetails?.maturity_date
        );
        setValue("maturitydate", maturityDateValue);
      }

      if (editCollateralDetails?.comment_on_asset) {
        setValue(
          "commentsOnSimilarAssets",
          editCollateralDetails?.comment_on_asset
        );
      }
      if (editCollateralDetails?.available_title_document) {
        setValue(
          "availableTitleDocument",
          editCollateralDetails?.available_title_document
        );
      }
      if (editCollateralDetails?.currency) {
        setValue("currency", editCollateralDetails?.currency);
      }
    }
  }, [
    editCollateralDetails,
    editCollateral,
    setValue,
    ExistingCollateralEditDocuments,
  ]);
  const onAddCollateralHandler = async (data: IreqBody) => {
    const {
      collateralTypeID,
      collateralvalueorAmount,
      registeredowner,
      location,
      developmentUseOfLand,
      nameofvaluer,
      whenlastvalued,
      forcedsalevalue,
      bank,
      effectivedate,
      maturitydate,
      commentsOnSimilarAssets,
      availableTitleDocument,
      currency,
      marketvalue,
    } = data;

    const documents = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileDescription = fileTexts[i];

      const base64String = await convertToBase64(file);

      documents.push({
        base64_file_string: base64String,
        filename_with_extension: file.name,
        description: fileDescription,
      });
    }

    if (existingEditDocumentData) {
      documents.push(...existingEditDocumentData);
    }
    const ReqBody = {
      loan_application_id: currentLoanApplicationId,
      value: +handleFormatAmountNoCurrency(String(collateralvalueorAmount)),
      registered_owner: registeredowner ? registeredowner : null,
      location: location ? location : null,
      development_and_use_of_land: developmentUseOfLand
        ? developmentUseOfLand
        : null,
      available_title_document: availableTitleDocument
        ? availableTitleDocument
        : null,
      collateral_type_id: collateralTypeID ? Number(collateralTypeID) : 0,
      name_of_valuer: nameofvaluer ? nameofvaluer : null,
      when_last_valued: whenlastvalued ? whenlastvalued : null,
      market_value: marketvalue
        ? +handleFormatAmountNoCurrency(String(marketvalue))
        : 0,
      forced_sale_value: forcedsalevalue
        ? +handleFormatAmountNoCurrency(String(forcedsalevalue))
        : 0,
      bank: bank ? bank : null,
      effective_date: effectivedate ? effectivedate : null,
      maturity_date: maturitydate ? maturitydate : null,
      comment_on_asset: commentsOnSimilarAssets
        ? commentsOnSimilarAssets
        : null,
      currency,
      documents,
      id: editCollateralDetails?.id ? editCollateralDetails?.id : 0,
    };

    try {
      setAddAnotherLoading(true);
      setError(null);
      if (editCollateral) {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATION_COLLATERAL,
          ReqBody,
          true
        );
        setAddAnotherLoading(false);
        setSuccess(res?.message);
        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        if (scrollToTop) {
          scrollToTop();
        }
        reset();
        setSelectedFiles([]);
        setEditCollateral(false);
        // setEditLoanApplicationGlobal(false);
        setEditCollateralDetails(null);
        setExistingEditDocumentData([]);
        setExistingDocumentDataLoader(false);

        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      } else {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATION_COLLATERAL,
          ReqBody,
          true
        );
        setAddAnotherLoading(false);
        setSuccess(res?.message);
        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        if (scrollToTop) {
          scrollToTop();
        }
        reset();
        setSelectedFiles([]);
        setEditLoanApplicationGlobal(false);

        resetAmountValue();

        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "Collateral.tsx",
        },
      });

      setAddAnotherLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onContinueCollateralHandler = async () => {
    if (OpenModalRef.current) {
      OpenModalRef.current.click();
    }
  };

  return (
    <>
      <div className={`${styles.center_container} `}>
        <div className="py-3">
          <h2 className={`${styles.loanInformationHeading}`}>Collateral</h2>
          {!LoanApplicationEditOrWrite ? (
            <p className={`${styles.InfoParagraph}`}>
              Select and upload multiple required Financial Information provided
            </p>
          ) : (
            <p className={`${styles.InfoParagraph}`}>
              Below are a list documents uploaded by you
            </p>
          )}
        </div>
        <div>
          {success && <p className="alert alert-success small">{success}</p>}
          {error && <p className="alert alert-danger small">{error}</p>}
          {existingEditDocumentDataError && (
            <p className="alert alert-danger small">
              {existingEditDocumentDataError}
            </p>
          )}
          {CollateralDocumentListError && (
            <p className="alert alert-danger small">
              {CollateralDocumentListError}
            </p>
          )}
          {currencyErrors && (
            <p className="alert alert-danger small">
              {errorHandler(currencyErrors)}
            </p>
          )}
        </div>
        <CollateralExistingList
          setEditCollateral={setEditCollateral}
          setEditCollateralDetails={setEditCollateralDetails}
          reset={reset}
        />
        {!LoanApplicationEditOrWrite && (
          <form>
            <div className="row">
              <div className="col-12">
                <CustomSelectDropdown
                  ref={register}
                  name="collateralTypeID"
                  label="Select Collateral type "
                  errors={errors.collateralTypeID}
                  showRequiredIcon={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    setCollateralDocTypeValue(e.target.value)
                  }
                >
                  <option value="">Select Collateral type</option>
                  {CollateralDocumentListData?.map(
                    ({ name, id }: { id: number; name: string }) => {
                      return (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      );
                    }
                  )}
                </CustomSelectDropdown>
                {CollateralDocumentListStatus === "loading" && (
                  <div className="d-flex justify-content-end">
                    <LoaderColored />
                  </div>
                )}
              </div>
              <div className="col-4">
                <CustomSelectDropdown
                  label="Currency"
                  ref={register}
                  name="currency"
                  errors={errors.currency}
                  style={{ borderRadius: "10px" }}
                >
                  {" "}
                  <option value="">Currency</option>
                  {currencyData &&
                    currencyData?.currencies?.length &&
                    currencyData?.currencies?.map(
                      ({ currency_code }: { currency_code: string }) => (
                        <option key={currency_code} value={currency_code}>
                          {currency_code}
                        </option>
                      )
                    )}
                </CustomSelectDropdown>
                {currencyStatus === "loading" && (
                  <div className=" d-flex justify-content-end">
                    {" "}
                    <LoaderColored />{" "}
                  </div>
                )}
              </div>
              <div className="col-8 ">
                <CustomInputField
                  type="number"
                  ref={register}
                  name="collateralvalueorAmount"
                  placeholder="Enter collateral value"
                  value={amountValue}
                  enableSeparator
                  onChange={(values: any) => {
                    const { formattedValue } = values;
                    setAmountValue(formattedValue);
                  }}
                  showRequiredIcon
                  errors={errors.collateralvalueorAmount}
                  label={`Value ${
                    findCollateraltype ? ` of  ${findCollateraltype.name}` : ""
                  }`}
                />
              </div>

              {findCollateraltype?.id === 1 && (
                <div className="col-12">
                  <CustomInputField
                    type="date"
                    ref={register}
                    name="effectivedate"
                    placeholder="Enter Effective Date "
                    label="effective date"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setCurrentEffectiveDate(e.target.value);
                    }}
                    errors={errors.effectivedate}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 1 && (
                <div className="col-12">
                  <CustomInputField
                    type="date"
                    ref={register}
                    name="maturitydate"
                    placeholder="Enter Maturity Date "
                    label="maturity date"
                    errors={errors.maturitydate}
                    showRequiredIcon
                    min={currentEffectiveDate}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setCurrentMaturityDate(e.target.value);
                    }}
                  />
                </div>
              )}

              {findCollateraltype?.id === 1 && (
                <div className="col-12">
                  <CustomInputField
                    type="number"
                    ref={register}
                    name="tenure"
                    placeholder="Enter Tenure "
                    label="Tenure (in days)"
                    maxLength={3}
                    errors={errors.tenure}
                    showRequiredIcon
                    readOnly
                  />
                </div>
              )}
              {findCollateraltype?.id === 2 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="bank"
                    placeholder="Enter Bank "
                    label="Bank Name"
                    errors={errors.bank}
                    showRequiredIcon
                  />
                </div>
              )}

              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="number"
                    ref={register}
                    name="forcedsalevalue"
                    placeholder="Enter Forced Sale Value"
                    label="Forced Sale Value"
                    value={forcedValue}
                    enableSeparator
                    onChange={(values: any) => {
                      const { formattedValue } = values;
                      setForcedValue(formattedValue);
                    }}
                    errors={errors.forcedsalevalue}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="number"
                    ref={register}
                    name="marketvalue"
                    placeholder="Enter Market Value"
                    label="Market Value"
                    value={marketValue}
                    enableSeparator
                    onChange={(values: any) => {
                      const { formattedValue } = values;
                      setMarketValue(formattedValue);
                    }}
                    errors={errors.marketvalue}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="date"
                    ref={register}
                    name="whenlastvalued"
                    placeholder="Enter When Last Valued"
                    label="When Last Valued"
                    errors={errors.whenlastvalued}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="nameofvaluer"
                    placeholder="Enter Name Of Valuer"
                    label="Name Of Valuer"
                    errors={errors.nameofvaluer}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="registeredowner"
                    placeholder="Enter Registered Owner"
                    label="Registered owner "
                    errors={errors.registeredowner}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="location"
                    placeholder="Enter Location"
                    label="Location"
                    errors={errors.location}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="developmentUseOfLand"
                    placeholder="Enter Developments and use of Land "
                    label="Developments and use of land"
                    errors={errors.developmentUseOfLand}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="commentsOnSimilarAssets"
                    placeholder="Enter Similar Assets"
                    label="Comment on similar assets"
                    errors={errors.commentsOnSimilarAssets}
                    showRequiredIcon
                  />
                </div>
              )}
              {findCollateraltype?.id === 4 && (
                <div className="col-12">
                  <CustomInputField
                    type="text"
                    ref={register}
                    name="availableTitleDocument"
                    placeholder="Enter  Available Title Document  "
                    label="Available Title Document"
                    errors={errors.availableTitleDocument}
                    showRequiredIcon
                  />
                </div>
              )}
              <div className="col-12">
                {selectedFiles.map((file, index) => (
                  <div key={index}>
                    <div>
                      <label htmlFor="" className="text-muted">
                        Collateral Document
                      </label>

                      <div className={styles.uploadedDocCard}>
                        <div className={styles.left}>
                          {<Folder />}
                          <span>{file.name}</span>
                        </div>

                        <div
                          onClick={() => handleRemoveFile(index)}
                          className={styles.right}
                        >
                          <Bin />
                        </div>
                      </div>
                    </div>
                    <div>
                      <CustomTextArea
                        rows={4}
                        label={`Description for  ${file.name}`}
                        value={fileTexts[index]}
                        onChange={(e: any) =>
                          handleFileTextChange(index, e.target?.value)
                        }
                        placeholder={`Enter  Description for ${file.name}`}
                      ></CustomTextArea>
                    </div>
                  </div>
                ))}

                <input
                  type="file"
                  id="fileInput"
                  accept="application/pdf, application/msword, image/*,.xlsx, .xls,.csv"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button
                  className="btn advancly-white-btn btn-sm transition-3d-hover mt-2"
                  onClick={handleAddButtonClick}
                >
                  {selectedFiles.length <= 0
                    ? " Click to Add Collateral File"
                    : "Click to Add Another Collateral File"}
                </button>
              </div>
              {/* this is for showing existing available documents */}
              <label htmlFor="" className="col-12 text-muted mt-3">
                Existing Collateral Document
              </label>
              {existingDocumentDataLoader ? (
                <div className="col-12">
                  <div className="d-flex  flex-column justify-content-center align-items-center">
                    <LoaderColored />
                    <div>
                      {" "}
                      <small>Loading existing document</small>
                    </div>
                  </div>
                </div>
              ) : editCollateral &&
                editCollateralDetails?.documents.length > 0 ? (
                <div className="col-12">
                  {editCollateralDetails?.documents.map((file: any) => (
                    <div key={file?.id}>
                      <div>
                        <div className={styles.uploadedDocCard}>
                          <div className={styles.left}>
                            {<Folder />}
                            <span>{file?.file_name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div> </div>
              )}
              <div className="col-12">
                <button
                  type="button"
                  style={{ display: "none" }}
                  title="Confirmation on submit button"
                  className="cursor-pointer  transition-3d-hover "
                  ref={OpenModalRef}
                  data-toggle="modal"
                  data-target="#confirmationOnSubmitLoanApplication"
                ></button>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="fa fa-plus  advancly-text-btn my-3 ml-auto"
                  onClick={handleSubmit(onAddCollateralHandler)}
                >
                  <span className={` pl-1`}>
                    Save Collateral {addAnotherloading && <LoaderColored />}
                  </span>
                </button>
              </div>
              <div className={`col-12`}>
                <div className=" d-flex justify-content-between flex-wrap ">
                  <button
                    type="button"
                    className=" btn advancly-btn  transition-3d-hover m-2"
                    onClick={onContinueCollateralHandler}
                    disabled={loading}
                  >
                    Submit Loan Application
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <ConfirmationSubmitLoanApplicationModal
        setLoading={setLoading}
        loading={loading}
        setSuccess={setSuccess}
        setError={setError}
        setSelectedFiles={setSelectedFiles}
        resetAmountValue={resetAmountValue}
        scrollToTop={scrollToTop}
        // closeApplicationModalRef={closeApplicationModalRef}
        reset={reset}
      />
    </>
  );
};
export default Collateral;
