import React, { useState } from "react";
import { Link, Route, useRouteMatch } from "react-router-dom";
import styles from "./ResetPassword.module.scss";
import sparkImage from "../../img/spark_sign_up.svg";
import sparkLogo from "../../img/spark_logo_login.svg";

import StepOne from "./StepOne.component";
import StepThree from "./StepThree.component";
import StepTwo from "./StepTwo.component";
import ROUTES from "../../helpers/ROUTES";

const ResetPassword = () => {
  const [stateToken, setStateToken] = useState<string>("");
  const match = useRouteMatch();

  const updateStateToken = (token: string) => {
    setStateToken(token);
  };

  return (
    <main role="main" className={`bg-white ${styles.resetPasswordLayout}`}>
      <div className={styles.left}>
        <Link className={styles.sparkLogoLink} to="/login">
          <img
            className={styles.sparkLogo}
            src={sparkLogo}
            alt="forgot password Illustration"
          />
        </Link>
        <Route
          path={match.path}
          exact
          render={() => <StepOne updateStateToken={updateStateToken} />}
        />
        <Route
          path={ROUTES.RESET_PASSWORD_VERIFY}
          render={() => (
            <StepTwo
              //@ts-ignore
              updateStateToken={updateStateToken}
              stateToken={stateToken}
            />
          )}
        />
        <Route
          path={ROUTES.RESET_PASSWORD_FINAL}
          render={() => <StepThree stateToken={stateToken} />}
        />
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImage}
            alt="Reset Password Illustration"
          />
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
