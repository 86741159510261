import React, { useRef, useState } from "react";
import { errorHandler } from "../../helpers/errorHandler";
import { yupValidators } from "../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { formatMoney } from "../../helpers/formatter";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  amount: yupValidators.amount,
  description: yup.string().trim().required("Please enter a description"),
});

const OverdraftModal = ({
  getBorrowerProfileDetails,
  loanRef,
  loanDetails,
}: {
  getBorrowerProfileDetails: () => void;
  loanRef: string;
  loanDetails: {
    overdraftLimit: number;
    summary: {
      accountBalance: number;
    };
  };
}) => {
  const [state, setState] = useState({
    message: "",
    error: null,
    loading: false,
  });
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const dismissRef = useRef<HTMLButtonElement | null>(null);

  const handleSubmitScore = async ({
    amount,
    description,
  }: {
    amount: string;
    description: string;
  }) => {
    setState({
      ...state,
      message: "",
      error: null,
      loading: true,
    });

    const data = {
      transactionAmount: Number(amount),
      loan_Ref: loanRef,
      transactionDate: new Date(),
      paymentTypeId: 1, // 1 is bank transfer
      description: description,
    };

    try {
      await postDataWithDotNet(apiEndpoints.withdrawFromOverdraft, data);
      window.scrollTo({ top: 0, behavior: "smooth" });
      setState({ ...state, message: "Withdrawal successful" });
      setTimeout(() => {
        reset();
        dismissRef.current?.click();
        setState({ ...state, message: "" });
        getBorrowerProfileDetails();
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "OverdraftModal.component.tsx" },
      });
      setState({
        ...state,
        error: errorHandler(error),
        loading: false,
      });
    }
  };

  const balance =
    loanDetails.overdraftLimit + loanDetails.summary.accountBalance;
  return (
    <div
      className="modal fade"
      id="overdraftModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="overdraftModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h6 className="text-center font-weight-bold">
              Overdraft Information
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            {state.message && (
              <p className="alert alert-success small">{state.message}</p>
            )}
            {state.error && (
              <p className="alert alert-danger small">{state.error}</p>
            )}
            <h3 className="page-subtitle mb-3">
              Balance: {formatMoney(balance)}
            </h3>
            <form onSubmit={handleSubmit(handleSubmitScore)}>
              <div className="form-group">
                <CustomInputField
                  type="number"
                  placeholder="Enter amount to withdraw"
                  label="Amount"
                  required
                  maxLength={8}
                  ref={register}
                  name="amount"
                  errors={errors.amount}
                />
                <CustomTextArea
                  maxLength={128}
                  ref={register}
                  placeholder="Add a description"
                  label="Description"
                  name="description"
                  errors={errors.description}
                />
                <button
                  className="btn advancly-btn btn-sm transition-3d-hover w-100"
                  type="submit"
                  disabled={state.loading}
                >
                  Withdraw
                  {state.loading && <Loader />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverdraftModal;
