import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
type TborrowerId = number | null;
const getSearchBorrowerDetails = async ({
  borrowerId,
}: {
  borrowerId: TborrowerId;
}) => {
  const data = await getData(`${apiEndpoints.aggregatorDetails}/${borrowerId}`);
  return data?.data;
};

export default function useSearchBorrowerDetails({
  borrowerId,
}: {
  borrowerId: TborrowerId;
}) {
  return useQuery(
    [{ borrowerId }, "getSearchBorrowerDetails"],
    getSearchBorrowerDetails,
    { enabled: borrowerId && borrowerId > 0 ? true : false }
  );
}
