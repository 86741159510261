import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import moment from "moment";
import Paginator from "../Paginator/Paginator.component";
import { resolveCorrectPageNumber } from "../../helpers/resolveCorrectPageNumber";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import useSearchHistory from "../../custom-hooks/useSearchHistory";
import AccountSuccessModal from "../AccountSearch/AccountSuccessModal.component";
import BVNSuccessModal from "../BVNSearch/BVNSuccessModal.component";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
//@ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../TableDataLoading/TableDataLoading.component";
import { useAuth } from "../../Context/auth.context";

export default function SearchHistory() {
  const { aggregatorDetails } = useAuth();
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDatePre, setStartDatePre] = useState("");
  const [endDatePre, setEndDatePre] = useState("");
  const [kycTypeId, setKycTypeId] = useState<any>("");
  const [modalMessage, setModalMessage] = useState<any>("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValuePre, setSearchValuePre] = useState("");

  useEffect(() => {
    //  Deboucing search value for 1 second
    const timer = setTimeout(async () => {
      setSearchValue(searchValuePre);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValuePre]);

  const bvnModal = useRef<HTMLButtonElement | null>(null);
  const accountModal = useRef<HTMLButtonElement | null>(null);

  const setDateItems = () => {
    if (startDatePre && endDatePre) {
      setStartDate(startDatePre);
      setEndDate(endDatePre);
    }
  };

  const { data, status, error } = useSearchHistory({
    Page: pageNumber,
    PageSize: size,
    start_date: startDate,
    end_date: endDate,
    kyc_type_id: kycTypeId,
    aggregator_id: aggregatorDetails.aggregator_id,
    searchValue: searchValue,
  });

  const showResultModal = ({
    kyc_type_id,
    response,
  }: {
    kyc_type_id: number;
    response: string;
  }) => {
    if (kyc_type_id === 1) {
      const { FirstName, LastName, Bvn, Mobile, FormattedDob, Message } =
        JSON.parse(response).Data;

      if (!Bvn || !FirstName || !LastName) {
        setModalMessage(Message);
      } else {
        setModalMessage({
          first_name: FirstName,
          last_name: LastName,
          bvn: Bvn,
          mobile: Mobile,
          formatted_dob: FormattedDob,
        });
      }
      bvnModal.current?.click();
    } else if (kyc_type_id === 2) {
      const { BankAccountNumber, BankAccountName, Message } =
        JSON.parse(response);

      if (!BankAccountNumber || !BankAccountName) {
        setModalMessage(Message);
      } else {
        setModalMessage({
          bank_account_num: BankAccountNumber,
          bank_account_name: BankAccountName,
        });
      }

      accountModal.current?.click();
    }
  };

  const resolveStatus = ({
    kyc_type_id,
    response,
  }: {
    kyc_type_id: number;
    response: any;
  }) => {
    if (kyc_type_id === 1) {
      const { FirstName, LastName, Bvn } = JSON.parse(response).Data;

      if (!Bvn || !FirstName || !LastName) {
        return "failed";
      } else {
        return "success";
      }
    } else if (kyc_type_id === 2) {
      const { BankAccountNumber, BankAccountName } = JSON.parse(response);

      if (!BankAccountNumber || !BankAccountName) {
        return "failed";
      } else {
        return "success";
      }
    }
  };

  const fileName = `kyc-search-history-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;

  if (error)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(error)}
      </p>
    );

  return (
    <div className="wide">
      <div className="d-flex justify-content-end align-items-center flex-wrap">
        <div className="d-flex align-items-center flex-wrap">
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setStartDatePre(e.target.value)}
              value={startDatePre}
              max={moment(new Date()).format("DD-MM-YYYY")}
              placeholder="DD-MM-YYYY"
            />
          </div>
          <div className="form-group ml-2">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setEndDatePre(e.target.value)}
              value={endDatePre}
              max={moment(new Date()).format("DD-MM-YYYY")}
              placeholder="DD-MM-YYYY"
            />
          </div>
          <button
            type="button"
            onClick={setDateItems}
            className="btn advancly-btn btn-sm ml-2 mt-3"
          >
            Filter
          </button>
        </div>
      </div>

      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex justify-content-end align-items-center flex-wrap">
            <div className="d-flex align-items-center flex-wrap">
              {kycTypeId && (
                <div className="d-flex align-items-center mr-3 mt-3">
                  <CustomInputField
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setSearchValuePre(e.target.value)
                    }
                    value={searchValuePre}
                    placeholder="Search..."
                    maxLength={11}
                  />
                </div>
              )}
              <div className="d-flex align-items-center mr-3">
                <p className="pr-2 pt-1">KYC Type:</p>
                <CustomSelectDropdown
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setPageNumber(1);
                    setKycTypeId(e.target.value);
                  }}
                  value={kycTypeId}
                >
                  <option value="">All</option>
                  <option value={1}>BVN</option>
                  <option value={2}>Account</option>
                </CustomSelectDropdown>
              </div>
              <div className="d-flex align-items-center mr-3">
                <p className="pr-2 pt-1">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setPageNumber(1);
                    setSize(e.target.value);
                  }}
                  value={size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                  <option value={data && data.totalCount * 2}>All</option>
                </CustomSelectDropdown>
              </div>

              <ReactHTMLTableToExcel
                className={`btn advancly-btn btn-sm transition-3d-hover mb-2 ${
                  status === "loading" && "disabled"
                }`}
                table="table-to-xls"
                filename={fileName}
                sheet="tablexls"
                buttonText="Download as Excel"
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table" id="table-to-xls">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>KYC Type</th>
                  <th>Search Value</th>
                  <th>Initiated By</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <TableDataLoading />
                ) : data && data.data && data.data.length ? (
                  data.data.map((data: any, _id: number) => {
                    const {
                      kyc_type_name,
                      request,
                      response,
                      username,
                      kyc_type_id,
                      bvn,
                      bank_account_number,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>
                          {resolveCorrectPageNumber({
                            pageNumber,
                            size,
                            id: _id,
                          })}
                        </td>
                        <td>{kyc_type_name.split("_").join(" ")}</td>
                        <td>
                          {kyc_type_id === 1 ? (
                            <span>BVN: {bvn}</span>
                          ) : kyc_type_id === 2 ? (
                            <ul className="pl-0">
                              <li>Account Number: {bank_account_number}</li>
                              <li>Bank Code: {JSON.parse(request).BankCode}</li>
                            </ul>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>{username}</td>
                        <td>
                          <span
                            className={attachClassNameToTag(
                              //@ts-ignore

                              resolveStatus({ kyc_type_id, response })
                            )}
                          >
                            {attachClassNameToTag(
                              //@ts-ignore

                              resolveStatus({ kyc_type_id, response })
                            )}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn advancly-btn btn-sm"
                            onClick={() =>
                              showResultModal({ kyc_type_id, response })
                            }
                          >
                            View Result
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.totalCount : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
      <button
        className="d-none"
        data-toggle="modal"
        data-target="#accountSuccessModal"
        ref={accountModal}
      ></button>
      <button
        className="d-none"
        data-toggle="modal"
        data-target="#bvnSuccessModal"
        ref={bvnModal}
      ></button>
      <AccountSuccessModal modalMessage={modalMessage} />
      <BVNSuccessModal modalMessage={modalMessage} />
    </div>
  );
}
