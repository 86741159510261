import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
type IFunderPeople = {
  funderId: number;
  funderPeopleType: number;
};
const getOnboardingManagement = async ({
  funderId,
  funderPeopleType,
}: IFunderPeople) => {
  const data = await getData(
    `${apiEndpoints.FUNDER_PEOPLE}?funder_people_type=${funderPeopleType}&funder_id=${funderId}`,
    true
  );
  return data?.data;
};

export default function useGetOnboardingManagement({
  funderId,
  funderPeopleType,
}: IFunderPeople) {
  return useQuery(
    [{ funderId, funderPeopleType }, "getOnboardingManagement"],
    getOnboardingManagement,
    {
      enabled: funderId && funderPeopleType ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
