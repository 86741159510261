import React from "react";
// @ts-ignore
import mixpanel from "mixpanel-browser";
// @ts-ignore

import { createRoot } from "react-dom/client";

import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import App from "./App";
import "./style/index.scss";
import CONFIG from "./components/config";

const isProduction = process.env.NODE_ENV === "production";
const container = document.getElementById("root");
const root = createRoot(container);

// Initialize Mixpanel
mixpanel.init(CONFIG.MIXPANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={isProduction}
    isVerboseMode={true}
    loadingComponent={null}
  >
    <App />
  </CacheBuster>
);
