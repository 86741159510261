import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getAggregatorRoles = async () => {
  const { data } = await getData(apiEndpoints.AGGREGATOR_ROLES);
  return data;
};

export default function useAggregatorRoles() {
  return useQuery("getAggregatorRoles", getAggregatorRoles, {
    refetchOnWindowFocus: false,
  });
}
