import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Alert from "../Alert/Alert.component";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";
import { ReactComponent as BackButtonModal } from "../../svg/icons/modal-left-arrow.svg";
import { ReactComponent as AdvanclyIcon } from "../../svg/icons/advancly-3d-icon-1.svg";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import PageLoader from "../PageLoader/PageLoader.component";
import Loader from "../Loader/Loader.component";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../AppInsight/AppInsight";
import style from "./WalletSet.module.scss";

const schema = yup.object().shape({
  pin: yup.number().required("Please Enter your PIN").max(4),
  confirmPin: yup.number().required("Please Enter your PIN again").max(4),
});
const SetPinModal = ({
  setSection,
}: {
  setSection: Dispatch<SetStateAction<string>>;
}) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldsMatch, setFieldsMatch] = useState(false);

  const queryCache = useQueryCache();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError: setFormError,
    //@ts-ignore
  } = useForm({ resolvers: yupResolver(schema) });
  const pin = watch("pin");
  const confirmPin = watch("confirmPin");

  useEffect(() => {
    if (pin?.length === 4 && confirmPin?.length === 4 && pin === confirmPin) {
      setFieldsMatch(true);
    } else {
      setFieldsMatch(false);
    }
  }, [pin, confirmPin]);

  const onSubmit = async ({
    pin,
    confirmPin,
  }: {
    pin: string;
    confirmPin: string;
  }) => {
    if (pin !== confirmPin) {
      return setFormError("confirmPin", {
        type: "manual",
        message: "Pins do not match",
      });
    }

    setError("");
    setLoading(true);
    setSuccess(false);

    const reqBody = {
      pin: String(pin),
      type: 2,
    };

    try {
      await postData(apiEndpoints.SAVE_SECURITY_PIN, reqBody);
      setLoading(false);
      setSuccess(true);
      queryCache.invalidateQueries("getAggregatorDetails");
      queryCache.invalidateQueries("getSavedSecurityQuestion");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "SetPinModal.tsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <div className="modal-content">
      <Fragment>
        {error && <Alert type="danger" message={error} />}
        {success ? (
          <div>
            <div className="modal-header">
              <div
                className="cursor-pointer"
                onClick={() => {
                  setSection("1");
                }}
              >
                <BackButtonModal />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setSection("1");
                }}
              >
                <CloseModal />
              </button>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-between flex-column mt-4">
              <AdvanclyIcon />
              <h5 className="mt-5 color-dark-purple font-weight-bold">
                Transaction PIN Set
              </h5>
            </div>
            <div className="modal-footer">
              <div className="col-12 pb-4 pt-4">
                <button
                  type="button"
                  className="btn  advancly-btn mt-5 w-100  ml-0 "
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setSection("1");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Fragment>
            {loading ? (
              <div className="p-5">
                <PageLoader />
              </div>
            ) : (
              <div>
                <div className="modal-header">
                  <h4 className="modal-title">Transaction PIN</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={style.paragraphText}>
                      Create your four digit transaction PIN
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <CustomInputField
                          type="password"
                          name="pin"
                          maxLength={4}
                          ref={register}
                          label="Transaction PIN"
                          errors={errors.pin}
                        />
                      </div>
                      <div className="col-12">
                        <CustomInputField
                          type="password"
                          name="confirmPin"
                          maxLength={4}
                          ref={register}
                          label="Confirm Transaction PIN"
                          errors={errors.confirmPin}
                        />
                      </div>

                      {pin && confirmPin && (
                        <div className="col-12 d-flex align-items-center justify-content-between color-dark-purple">
                          <small>PIN should be a four digit number</small>
                          <div>
                            <i
                              className={`ri-checkbox-circle-fill ${
                                fieldsMatch ? "text-success" : "text-danger"
                              }`}
                            ></i>
                          </div>
                        </div>
                      )}
                      {pin && confirmPin && confirmPin !== pin ? (
                        <div className="col-12 d-flex align-items-center justify-content-between color-dark-purple">
                          <small>PINS do not match</small>
                          <div>
                            <i
                              className={`ri-checkbox-circle-fill ${
                                fieldsMatch ? "text-success" : "text-danger"
                              }`}
                            ></i>
                          </div>
                        </div>
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <button
                      className="btn  advancly-btn mt-5 w-100"
                      disabled={!fieldsMatch || loading}
                    >
                      Save
                      {loading && <Loader />}
                    </button>
                  </form>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default SetPinModal;
