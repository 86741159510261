import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import ToggleTab from "./ToggleTab/ToggleTab.component";
import { apiEndpoints } from "../apis/apiEndpoints";

import {
  getDataWithDotNet,
  token,
  // postDataWithDotNet,
} from "../apis/dotNetApiMethods";
import LoanDetail from "./LoanDetail/LoanDetail.component";
import AllABorrowerLoans from "./AllABorrowerLoans/AllABorrowerLoans.component";
import RollOverLoanDetails from "./RollOverLoanDetails/RollOverLoanDetails.component";
import AllABorrowerRepayments from "./AllABorrowerRepayments/AllABorrowerRepayments.component";
import ABorrowerSingleLoanRepayments from "./ABorrowerSingleLoanRepayments/ABorrowerSingleLoanRepayments.component";
import BorrowerProfileCard from "./BorrowerProfileCard/BorrowerProfileCard.component";
import Loader from "./Loader/Loader.component";
import { errorHandler } from "../helpers/errorHandler";
// import TrustScoreModal from "./TrustScoreModal/TrustScoreModal.component";
// import { ReactComponent as Flag } from "../svg/icons/flag.svg";
import BackButtonComponent from "./BackButton/BackButton.component";
import ROUTES from "../helpers/ROUTES";
import OverdraftModal from "./Overdraft/OverdraftModal.component";
import CustomerWallet from "./CustomerWalletBorrower/CustomerWallet";
import CONFIG from "./config";
import { appInsights } from "./AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";
// import ExistingIndividualBorrowerLoanRequest from "../ExistingBorrowerLoanRequest/ExistingBorrowerLoanRequest";

const BorrowerProfile = ({ location, match, history }) => {
  useEffect(() => {
    mixpanel.track_pageview({ page: "Borrower Profile-Spark" });
  }, []);
  const [state, setState] = useState({
    loading: false,
    loadingTrustScore: false,
    error: "",
    profileDetails: false,
    message: false,
    activeTab:
      location && location.state && location.state.loanRef
        ? "profile-loanDetails-tab"
        : "profile-disbursements-tab",
  });

  const [loanDetails, setLoanDetails] = useState("");
  const [dataForRepayment, setDataForRepayment] = useState("");
  const [loanDetailsSummary, setLoanDetailsSummary] = useState("");
  const [paidRepayments, setPaidRepayments] = useState("");
  const [error, setError] = useState("");
  const [downloadError, setDownloadError] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  // const handleAccount = async (status) => {
  //   setState({ ...state, message: false, error: "", loading: true });
  //   const data = {
  //     borrower_id: Number(match.params.borrower_id),
  //     account_status: status,
  //   };

  //   try {
  //     const res = await postDataWithDotNet(
  //       "/account/aggr_account_check/",
  //       data
  //     );
  //     setState({ ...state, message: res.message, loading: false });
  //     setTimeout(() => {
  //       setState({ ...state, message: false });
  //       getBorrowerProfileDetails();
  //     }, 5000);
  //   } catch (error) {
  //     setState({ ...state, loading: false, error: errorHandler(error) });
  //   }
  // };

  const getBorrowerProfileDetails = async () => {
    const id = match.params.borrower_id;
    try {
      const result = await getDataWithDotNet(
        `${apiEndpoints.aggregatorDetails}${id}`
      );
      setState({ ...state, profileDetails: result.data });
    } catch (error) {
      setState({ ...state, error: errorHandler(error) });
    }
  };

  const setActiveTab = (tab) => {
    setState({ ...state, activeTab: tab });
  };
  useEffect(() => {
    console.error(downloadError);
  }, [downloadError]);
  useEffect(() => {
    if (location && location.state && location.state.loanRef) {
      getMifosLoanDetails(location.state.loanRef);
    }
    getBorrowerProfileDetails();
    // eslint-disable-next-line
  }, []);

  //get mifos details
  const getMifosLoanDetails = async (loan_ref) => {
    setLoading(true);
    try {
      const res = await getDataWithDotNet(
        `${apiEndpoints.queryLoanDetails}?externalId=${loan_ref}`
      );
      setLoading(false);
      let result = res.data;
      if (result.responseCode === "200") {
        getRepaymentByLoanRef(loan_ref);
        setPaidRepayments(
          result.responseData.repaymentSchedule
            ? result.responseData.repaymentSchedule.periods
            : false
        );
        setLoanDetails(result.responseData);
        setLoanDetailsSummary(result.responseData.summary);
      }
      if (result.responseCode === "400") {
        setPaidRepayments(false);
        setLoanDetails([]);
        setLoanDetailsSummary(loanDetailsSummary);
      }
      if (result.responseCode === "401" || result.responseCode === "403") {
        this.props.history.push("/login");
      }

      if (result.responseCode >= "500") {
        setError("Unable to connect to Advancly services");
      }
    } catch (error) {
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  //get repayment by loan ref
  const getRepaymentByLoanRef = async (loan_ref) => {
    try {
      const res = await getDataWithDotNet(
        `/eco/agg_search_loans/?loan_ref=${loan_ref}`
      );
      setDataForRepayment(res.data);
    } catch (error) {
      setError(errorHandler(error));
    }
  };

  const { profileDetails, activeTab } = state;

  if (state.error) {
    return (
      <h3 className="text-danger text-center font-weight-bold">
        {errorHandler(state.error) || "An error occured."}
      </h3>
    );
  }

  const handleDownloadCustomerDetailsReport = async () => {
    setDownloading(true);
    fetch(
      CONFIG.BASE_URL +
        `${apiEndpoints.GETCUSTOMERDETAILS}?customerId=${profileDetails?.customer_id}&format=pdf`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "Token " + token(),
        },
      }
    )
      .then((response) => {
        setDownloading(false);

        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `customer_details_report_${profileDetails?.name}.pdf`
            );
            link.click();
          });
        }

        if (response.status === 400) {
          setDownloading(false);
        }

        if (response.status === 403 || response.status === 401) {
          localStorage.removeItem("sso_auth_token");
          history.push("/login");
        }

        if (response.status >= 500) {
          setDownloading(false);
          setDownloadError("");
          setTimeout(() => {
            setDownloadError("");
          }, 3000);
        }
      })
      .catch((error) => {
        setDownloadError(error?.response?.message);
        setTimeout(() => {
          setDownloadError("");
        }, 3000);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "BorrowerProfile.js",
          },
        });
        setDownloading(false);
      });
  };

  return (
    <div>
      <div className="">
        {state.message && (
          <p className="alert alert-success small">{state.message}</p>
        )}
        {state.error && (
          <p className="alert alert-danger small">{state.error}</p>
        )}

        <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
          <div className="d-flex align-items-center justify-content-start">
            <h3 className="page-title d-flex align-items-center">
              Customer Profile
            </h3>
          </div>
          <div className="d-flex flex-wrap">
            {/* Below buttons commented out till business/product team decide a new pathway */}
            {/* <a
              className="btn advancly-white-btn btn-sm transition-3d-hover mr-3 mb-2"
              href="mailto:complaints@advancly.ng"
            >
              Report Customer <Flag />
            </a> */}
            {/* <button
              name="button"
              className="btn advancly-btn btn-sm transition-3d-hover mr-3 mb-2"
              type="button"
              disabled={state.loading || !profileDetails}
              onClick={() =>
                handleAccount(profileDetails.is_active ? "suspend" : "activate")
              }
            >
              {!profileDetails ? (
                <Loader />
              ) : profileDetails.is_active ? (
                "Suspend Account"
              ) : (
                "Activate Account"
              )}
              {state.loading && <Loader />}
            </button> */}
            <button
              onClick={() =>
                history.push(
                  `/${ROUTES.MAKE_LOAN_REQUEST}/${Number(
                    profileDetails.borrower_id
                  )}`
                )
              }
              // onClick={() => (
              //   <Route
              //     // path={`${match.path}/:id`}
              //     // exact
              //     render={() => <ExistingIndividualBorrowerLoanRequest />}
              //   />
              // )}
              className="btn advancly-btn  transition-3d-hover mr-3 "
              type="button"
              disabled={!profileDetails}
            >
              {!profileDetails ? <Loader /> : "Request Loan"}
            </button>
            <button
              className="btn advancly-btn  transition-3d-hover ml-1 "
              type="button"
              disabled={!profileDetails}
              onClick={handleDownloadCustomerDetailsReport}
            >
              Download Customer Report{downloading && <Loader />}
            </button>
            {/* <button
              name="button"
              className="btn advancly-btn btn-sm transition-3d-hover mr-3 mb-2"
              type="button"
              disabled={!profileDetails}
              data-toggle="modal"
              data-target="#trustScoreModal"
            >
              {!profileDetails ? <Loader /> : "Update Trust Score"}
            </button> */}
            {/* <button
              name="button"
              className="btn advancly-btn btn-sm transition-3d-hover mb-2"
              type="button"
              disabled={
                !profileDetails ||
                !loanDetails ||
                !loanDetails.overdraftLimit ||
                !loanDetails.summary ||
                !loanDetails.summary.accountBalance
              }
              data-toggle="modal"
              data-target="#overdraftModal"
            >
              {!profileDetails ? <Loader /> : "Overdraft"}
            </button> */}
          </div>
        </div>
        <div>
          <BackButtonComponent />
        </div>

        <div className="advancly-wrapper-container mt-2">
          <BorrowerProfileCard
            profileDetails={profileDetails}
            getBorrowerProfileDetails={getBorrowerProfileDetails}
          />
          <div className="advancly-sub-wrapper my-3">
            {profileDetails ? (
              <Fragment>
                {/* <!-- Transactions Section --> */}
                <div className="alignToggleTabItems">
                  {location && location.state && location.state.loanRef && (
                    <ToggleTab
                      text="Loan Details"
                      id="profile-loanDetails-tab"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                  {location && location.state && location.state.loanRef && (
                    <ToggleTab
                      text="Repayment Schedule"
                      id="profile-paidRepayment-tab"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                  <ToggleTab
                    text="All Loans"
                    id="profile-disbursements-tab"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <ToggleTab
                    text="All Repayments"
                    id="profile-repayments-tab"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <ToggleTab
                    text="Customer Wallet"
                    id="profile-wallet-tab"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {location?.state?.parent_rollover_loan_reference && (
                    <ToggleTab
                      text="Roll Over Loans"
                      id="roll-over-loans-tab"
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                    />
                  )}
                </div>
                <div>
                  <div className=" table-responsive">
                    <div className="">
                      {(() => {
                        switch (activeTab) {
                          case "profile-loanDetails-tab":
                            return (
                              <LoanDetail
                                loanId={location?.state?.loan_id}
                                loanDetails={loanDetails}
                                loanDetailsSummary={loanDetailsSummary}
                                dataForRepayment={dataForRepayment}
                                loadingMifos={loading}
                                error={error}
                                profileDetails={profileDetails}
                                loanStatus={location?.state?.status}
                                borrowerId={Number(match.params.borrower_id)}
                              />
                            );
                          case "profile-paidRepayment-tab":
                            return (
                              <ABorrowerSingleLoanRepayments
                                loadingMifos={loading}
                                paidRepayments={paidRepayments}
                                profileDetails={profileDetails}
                                error={error}
                                currency={
                                  loanDetailsSummary &&
                                  loanDetailsSummary.currency &&
                                  loanDetailsSummary.currency.code
                                }
                              />
                            );

                          case "profile-wallet-tab":
                            return (
                              <CustomerWallet profileDetails={profileDetails} />
                            );
                          case "profile-disbursements-tab":
                            return (
                              <AllABorrowerLoans
                                profileDetails={profileDetails}
                              />
                            );
                          case "roll-over-loans-tab":
                            return (
                              <RollOverLoanDetails
                                parent_rollover_loan_reference={
                                  location?.state
                                    ?.parent_rollover_loan_reference
                                }
                              />
                            );
                          case "profile-repayments-tab":
                            return <AllABorrowerRepayments />;
                          default:
                            return null;
                        }
                      })()}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="d-flex justify-content-center advancly-sub-wrapper">
                <Loader />
              </div>
            )}
          </div>
          {/* <TrustScoreModal
            getBorrowerProfileDetails={getBorrowerProfileDetails}
            state={state}
            setState={setState}
          />{" "} */}
          {loanDetails &&
            loanDetails.overdraftLimit &&
            loanDetails.summary &&
            loanDetails.summary.accountBalance && (
              <OverdraftModal
                getBorrowerProfileDetails={getBorrowerProfileDetails}
                state={state}
                setState={setState}
                loanRef={location.state.loanRef}
                loanDetails={loanDetails}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BorrowerProfile);
