import React, { useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import usePendingLoansDetails from "../../custom-hooks/usePendingLoansDetails";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import EachPendingLoanRow from "../../components/EachPendingLoanRow/EachPendingLoanRow.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import BulkLoanCommentModal from "./BulkLoanCommentModal.component";
import { errorHandler } from "../../helpers/errorHandler";
import Alert from "../../components/Alert/Alert.component";
import SingleLoanCommentModal from "./SingleLoanCommentModal.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { ReactComponent as SearchIconGrey } from "../../svg/icons/search-icon-grey.svg";
import ToolTip from "../../components/ToolTip/ToolTip";
import mixpanel from "mixpanel-browser";
interface Idata {
  id: number;
  product_name: string;
  borrower_details: string;
  amount: number;
  amount_interest: number;
  state_token: string;
  loan_tenure: number;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  submitted_on_date: string;
}
export default function PendingLoans() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [bulkLoanCommentType, setBulkLoanCommentType] = useState("");
  const [singleLoanToActionOn, setSingleLoanToActionOn] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [dataToStore, setDataToStore] = useState<any>([]);
  const [checkAll, setCheckAll] = useState(false);
  // eslint-disable-next-line
  const [checked, setChecked] = useState(false);
  const [showPendingLoansToolTip, setShowPendingLoansToolTip] = useState(false);

  const { data, status, error: loansError } = usePendingLoansDetails();

  //single checkbox
  const checkboxHandler = ({ eventTarget }: { eventTarget: any }) => {
    const loanToken = eventTarget.dataset["ref"];
    setChecked(true);
    if (eventTarget.checked === false) {
      const checkedPendingDisbursementData = dataToStore;
      const newCheckedPendingDisbursementData =
        checkedPendingDisbursementData.filter((loan: any) => {
          return loan !== loanToken;
        });

      setDataToStore([...newCheckedPendingDisbursementData]);
    } else {
      setDataToStore((dataToStore: any) => [...dataToStore, loanToken]);
    }
  };

  // Multiple Checkbox
  const checkAllPendingDisbursementData = (e: any) => {
    setCheckAll(e);
    if (checkAll) {
      const allLoans: any = document.querySelectorAll(
        ".loans_for_disbursement"
      );
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }
      setCheckAll(false);
      setDataToStore([]);
      return;
    }

    let loans = [];
    const allLoans: any = document.querySelectorAll(".loans_for_disbursement");
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loanRef = allLoans[i].dataset["ref"];
      loans.push(loanRef);
    }
    setCheckAll(true);

    setDataToStore([...dataToStore, ...loans]);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "PendingLoans.tsx",
      isLoggedIn: true,
    });
  }, []);
  useEffect(() => {
    mixpanel.track_pageview({ page: "Pending Loan Requests-Spark" });
  }, []);
  if (status === "loading") return <PageLoader />;
  return (
    <div>
      {success && <Alert message={success} type="success" />}
      {error && <Alert message={error} />}
      {loansError && (
        <p className="alert alert-danger small w-100">
          {errorHandler(loansError)}
        </p>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="page-title mb-4">Pending Loan Requests</h3>
        </div>
        <div className="d-flex">
          <div className="pl-1 pb-2">
            <button
              type="button"
              className="btn  advancly-white-btn d-flex align-items-center "
              onClick={() => setBulkLoanCommentType("decline")}
              data-toggle="modal"
              data-target="#bulkLoanCommentModal"
              disabled={Object.keys(dataToStore).length < 1 || loading}
            >
              Decline Loan
            </button>
          </div>
          <div className=" pb-2">
            <button
              type="button"
              className="btn   advancly-btn d-flex align-items-center ml-2"
              onClick={() => setBulkLoanCommentType("approve")}
              data-toggle="modal"
              data-target="#bulkLoanCommentModal"
              disabled={Object.keys(dataToStore).length < 1 || loading}
            >
              Approve Loan
            </button>
          </div>
        </div>
      </div>
      <div className="advancly-wrapper-container mt-2">
        <div className="">
          <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
            <div className="d-flex align-items-center mb-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <span className="">
                      {" "}
                      <SearchIconGrey />
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  className="mr-0 search-query form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ borderLeft: "none" }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <p className="pr-2 pt-1">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e: any) => setSize(e.target.value)}
                  value={size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                </CustomSelectDropdown>
              </div>
            </div>
          </div>
          <div
            className="advancly-sub-wrapper mt-3"
            style={{ position: "relative" }}
          >
            <div className="table-responsive">
              <table className="table-style">
                <thead className="">
                  <tr>
                    <th
                      className="th-td-style th-style  "
                      onMouseEnter={() => setShowPendingLoansToolTip(true)}
                      onMouseLeave={() => setShowPendingLoansToolTip(false)}
                    >
                      <label className="d-block" htmlFor="AllPending">
                        All
                      </label>

                      <input
                        className="d-block"
                        id="AllPending"
                        type="checkbox"
                        name="unchecked"
                        onClick={(e: any) =>
                          checkAllPendingDisbursementData({
                            eventTarget: e.target.checked,
                          })
                        }
                        checked={checkAll}
                      />
                    </th>
                    <th className="th-td-style th-style ">Reference</th>
                    <th className="th-td-style th-style ">First Name</th>
                    <th className="th-td-style th-style ">Second Name</th>
                    <th className="th-td-style th-style ">Product</th>
                    <th className="th-td-style th-style ">Amount</th>
                    <th className="th-td-style th-style ">Interest</th>
                    <th className="th-td-style th-style ">Tenure</th>
                    <th className="th-td-style th-style ">Submitted Date</th>
                    <th className="th-td-style th-style ">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {showPendingLoansToolTip && (
                    <ToolTip
                      tooltipContainerMarginLeft={"3rem"}
                      tooltipHeading={"Pending Loans"}
                      tooltipParagraph={`Speed up your loan processing! Use the 'All' button to swiftly approve or decline multiple loans at once. Check the desired loans, then click 'Approve' or 'Decline' to streamline your workflow and save time.`}
                    />
                  )}
                  {data && data?.length ? (
                    data
                      .filter(
                        (borrower: any) =>
                          borrower.state_token
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.borrower_details.first_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.borrower_details.last_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.product_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.amount
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.loan_tenure
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          borrower.amount_interest
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                      )
                      .slice(
                        (pageNumber - 1) * size,
                        size * (pageNumber - 1) + size
                      )
                      .map((data: Idata, id: number) => {
                        return (
                          <EachPendingLoanRow
                            key={id}
                            data={data}
                            checkboxHandler={checkboxHandler}
                            setSingleLoanToActionOn={setSingleLoanToActionOn}
                            loading={loading}
                            checked={checked}
                          />
                        );
                      })
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
            <Paginator
              size={size}
              page={pageNumber}
              count={data ? data.length : 0}
              changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
            />
          </div>
        </div>
      </div>
      <BulkLoanCommentModal
        loading={loading}
        setLoading={setLoading}
        setDataToStore={setDataToStore}
        setChecked={setChecked}
        setCheckAll={setCheckAll}
        dataToStore={dataToStore}
        commentType={bulkLoanCommentType}
        setSuccess={setSuccess}
        setError={setError}
      />
      <SingleLoanCommentModal
        loading={loading}
        setLoading={setLoading}
        setSuccess={setSuccess}
        setError={setError}
        singleLoanToActionOn={singleLoanToActionOn}
      />
    </div>
  );
}
