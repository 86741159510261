import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

interface IgetRecentRepayment {
  aggregatorId: number;
  currentCurrencyValue: string;
  // activeProductName: string;
}

const getRecentRepayments = async ({
  aggregatorId,
  currentCurrencyValue,
}: // activeProductName,
IgetRecentRepayment) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getRecentRepayments}?aggregator_id=${aggregatorId}&currency=${currentCurrencyValue}`
  );
  return data.data;
};

export default function useRecentRepayments({
  aggregatorId,
  currentCurrencyValue,
}: // activeProductName,
IgetRecentRepayment) {
  return useQuery(
    [{ aggregatorId, currentCurrencyValue }, "getRecentRepayments"],
    getRecentRepayments,
    {
      enabled: currentCurrencyValue ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
