import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
import AllWallets from "./AllWallets";
import WalletDisbursementTransactions from "./WalletDisbursementTransactions";

const WalletMainPage = () => {
  const [activeTab, setActiveTab] = useState("available_wallet");

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">Wallet</h3>
      </div>
      <div className="">
        <div className="">
          <div className="alignToggleTabItems  ">
            <ToggleTab
              text="Available Wallet"
              id="available_wallet"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Wallet Disbursement Transactions"
              id="wallet_disbursment_transaction"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="row table-responsive">
            <div className="leftApprovalstyles">
              {(() => {
                switch (activeTab) {
                  case "available_wallet":
                    return <AllWallets />;
                  case "wallet_disbursment_transaction":
                    return <WalletDisbursementTransactions />;

                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(WalletMainPage);
