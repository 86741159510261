import React, { useState, useEffect } from "react";

export default function CopyButton({ value }: { value: string }) {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => setCopied(false), 2000);
    return () => clearTimeout(id);
  }, [copied]);
  return (
    <button
      disabled={copied}
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true);
      }}
      type="button"
      className="btn advancly-btn btn-sm transition-3d-hover"
    >
      {copied ? "Copied!" : "Copy"}
    </button>
  );
}
