import React, { ChangeEvent, useEffect, useState } from "react";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import useBatchLoanRequestStatus from "../../custom-hooks/useBatchLoanRequestStatus";
import { errorHandler } from "../../helpers/errorHandler";
import moment from "moment";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { withRouter } from "react-router-dom";
import BackButtonComponent from "../../components/BackButton/BackButton.component";
//@ts-ignore
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import UpdateABatchLoanRecordModalComponent from "../../components/UpdateABatchLoanRecordModal/UpdateABatchLoanRecordModal.component";
import { useQueryCache } from "react-query";
import { resolveCorrectPageNumber } from "../../helpers/resolveCorrectPageNumber";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../../components/TableDataLoading/TableDataLoading.component";
import { formatMoney } from "../../helpers/formatter";
import { accurateQuantifier } from "../../components/utility";
import { appInsights } from "../../components/AppInsight/AppInsight";

const BatchLoanRequestStatus = (props: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState<any>(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDatePre, setStartDatePre] = useState("");
  const [endDatePre, setEndDatePre] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [loanStatus, setLoanStatus] = useState<string>("");
  const [loanToBeUpdated, setLoanToBeUpdated] = useState<any>();

  const { data, status, error } = useBatchLoanRequestStatus({
    Page: pageNumber,
    PageSize: size,
    BatchNumber: batchNumber,
    FromDate: startDate,
    ToDate: endDate,
    Status: loanStatus,
  });

  const queryCache = useQueryCache();
  const fetchNewData = () => {
    queryCache.invalidateQueries([
      {
        Page: pageNumber,
        PageSize: size,
        BatchNumber: batchNumber,
        FromDate: startDate,
        ToDate: endDate,
        Status: loanStatus,
      },
      "getBatchLoanRequestStatus",
    ]);
  };

  useEffect(() => {
    if (props.location && props.location.state) {
      setBatchNumber(props.location.state);
    }
    // eslint-disable-next-line
  }, []);

  const setDateItems = () => {
    if (startDatePre && endDatePre) {
      setStartDate(startDatePre);
      setEndDate(endDatePre);
    }
  };

  const formatMessage = (message: any) => {
    try {
      if (message) {
        const returnedMessage = JSON.parse(message).message;
        return returnedMessage ? returnedMessage : null;
      } else {
        return null;
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "BatchLoanRequestStatus.jsx",
        },
      });
      if (typeof message === "string") {
        return message;
      } else {
        return null;
      }
    }
  };
  const fileName = `batch-loan-upload-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`;

  useEffect(() => {
    appInsights.trackPageView({
      name: "BatchLoanRequestStatus.jsx",
      isLoggedIn: true,
    });
  }, []);

  if (error)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(error)}
      </p>
    );

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h3 className="page-title d-flex align-items-center mb-4">
          <BackButtonComponent /> Batch Loan Uploads
        </h3>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="form-group">
            <label>Start Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setStartDatePre(e.target.value)}
              value={startDatePre}
              max={moment(new Date()).format("YYYY-MM-DD")}
              placeholder="YYYY/MM/DD"
            />
          </div>
          <div className="form-group ml-2">
            <label>End Date</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) => setEndDatePre(e.target.value)}
              value={endDatePre}
              max={moment(new Date()).format("YYYY-MM-DD")}
              placeholder="YYYY/MM/DD"
            />
          </div>
          <button
            type="button"
            onClick={setDateItems}
            className="btn advancly-btn btn-sm ml-2 mt-3"
          >
            Filter
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-3">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="page-subtitle">
              {batchNumber ? `Batch Number: ${batchNumber}` : "All Uploads"}
            </h5>
            <div className="d-flex align-items-center flex-wrap">
              <div className="d-flex align-items-center mr-3">
                <p className="pr-2 pt-1">Loan Status:</p>
                <CustomSelectDropdown
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setPageNumber(1);
                    setLoanStatus(e.target?.value);
                  }}
                  value={loanStatus}
                >
                  <option value="">All</option>
                  <option value={1}>Pending</option>
                  <option value={2}>Success</option>
                  <option value={3}>Failed</option>
                </CustomSelectDropdown>
              </div>
              <div className="d-flex align-items-center mr-3">
                <p className="pr-2 pt-1">Showing:</p>
                <CustomSelectDropdown
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setPageNumber(1);
                    setSize(e.target.value);
                  }}
                  value={size}
                >
                  <option value={10}>10 Entries</option>
                  <option value={20}>20 Entries</option>
                  <option value={50}>50 Entries</option>
                  <option value={100}>100 Entries</option>
                  <option value={data && data.totalCount * 2}>All</option>
                </CustomSelectDropdown>
              </div>
              <ReactHTMLTableToExcel
                className={`btn advancly-btn btn-sm transition-3d-hover mb-2 ${
                  status === "loading" && "disabled"
                }`}
                table="table-to-xls"
                filename={fileName}
                sheet="tablexls"
                buttonText="Download as Excel"
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table" id="table-to-xls">
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>RC Number</th>
                  <th>BVN</th>
                  <th>Email</th>
                  <th>Borrower Type</th>
                  <th>Gender</th>
                  <th>Upload Date</th>
                  <th>Status</th>
                  <th>Message</th>
                  <th>Phone Number</th>
                  <th>Product</th>
                  <th>Amount</th>
                  <th>Tenure</th>
                  <th>Interest</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === "loading" ? (
                  <TableDataLoading />
                ) : data && data.data && data.data.length ? (
                  data.data.map((data: any, _id: number) => {
                    const {
                      annualInterestRate,
                      borrowerType,
                      customerBVN,
                      emailAddress,
                      firstName,
                      lastName,
                      loanAmount,
                      gender,
                      phoneNumber,
                      statusName,
                      productName,
                      rcNumber,
                      companyName,
                      tenureInDays,
                      message,
                      dateSubmitted,
                      status: loanStatus,
                      currency,
                    } = data;
                    return (
                      <tr key={_id}>
                        <td>
                          {resolveCorrectPageNumber({
                            pageNumber,
                            size,
                            id: _id,
                          })}
                        </td>
                        <td>{`${firstName} ${lastName}`}</td>
                        <td>{companyName}</td>
                        <td>{rcNumber}</td>
                        <td>{customerBVN}</td>
                        <td>{emailAddress}</td>
                        <td>{borrowerType}</td>
                        <td className="text-capitalize">{gender}</td>
                        <td className="text-capitalize">
                          {dateSubmitted &&
                            moment(dateSubmitted).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          <span className={attachClassNameToTag(statusName)}>
                            {attachClassNameToTag(statusName)}
                          </span>
                        </td>
                        <td>{formatMessage(message)}</td>
                        <td>{phoneNumber}</td>
                        <td>{productName}</td>
                        <td>{formatMoney(loanAmount, currency)}</td>
                        <td>{accurateQuantifier(tenureInDays, "day")}</td>
                        <td>{annualInterestRate ? annualInterestRate : 0}%</td>
                        <td>
                          {loanStatus === 3 && (
                            <button
                              className="btn advancly-btn btn-sm"
                              data-toggle="modal"
                              data-target="#updateABatchLoanRecordModal"
                              onClick={() => setLoanToBeUpdated(data)}
                            >
                              Update Loan
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            size={size}
            page={pageNumber}
            count={data ? data.totalCount : 0}
            changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
          />
        </div>
      </div>
      <UpdateABatchLoanRecordModalComponent
        loanToBeUpdated={loanToBeUpdated}
        fetchNewData={fetchNewData}
      />
    </div>
  );
};
export default withRouter(BatchLoanRequestStatus);
