import React, { Fragment } from "react";
import useGeneratedAggregatorKey from "../../custom-hooks/useGeneratedAggregatorKey";
import Loader from "../Loader/Loader.component";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { useAuth } from "../../Context/auth.context";
// import OtherAccountSettingsModal from "./OtherAccountSettingsModal.component";

const OtherAccountSettings = () => {
  const { aggregatorDetails } = useAuth();

  const { status, data, error } = useGeneratedAggregatorKey({
    aggregator_id: aggregatorDetails?.aggregator_id,
  });

  const allowBvnValidation = data?.allow_bvn_validation;
  const allowAccountValidation = data?.allow_bank_account_validation;
  const allowBulkLoanUpload =
    data?.allow_bulk_loan_application_via_ui === 1 ? true : false;
  const allowWidgetEdit = data?.allow_widget_edit;
  return (
    <div
      className="advancly-wrapper-container {
      "
    >
      <div
        className=" advancly-sub-wrapper {
 "
      >
        {status === "loading" ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : error ? (
          <div className="d-flex justify-content-center">
            <h4>An Error occured.</h4>
          </div>
        ) : (
          <Fragment>
            <div className="row">
              <p className="col-12">
                BVN Search Status:{" "}
                <span
                  className={attachClassNameToTag(
                    allowBvnValidation ? "active" : "inactive"
                  )}
                >
                  {attachClassNameToTag(
                    allowBvnValidation ? "active" : "inactive"
                  )}
                </span>
              </p>
              <p className="col-12">
                Account Search Status:{" "}
                <span
                  className={attachClassNameToTag(
                    allowAccountValidation ? "active" : "inactive"
                  )}
                >
                  {attachClassNameToTag(
                    allowAccountValidation ? "active" : "inactive"
                  )}
                </span>
              </p>
              <p className="col-12">
                Bulk Loan Upload:{" "}
                <span
                  className={attachClassNameToTag(
                    allowBulkLoanUpload ? "active" : "inactive"
                  )}
                >
                  {attachClassNameToTag(
                    allowBulkLoanUpload ? "active" : "inactive"
                  )}
                </span>
              </p>
              <p className="col-12">
                Widget Fields Edit:{" "}
                <span
                  className={attachClassNameToTag(
                    allowWidgetEdit ? "active" : "inactive"
                  )}
                >
                  {attachClassNameToTag(
                    allowWidgetEdit ? "active" : "inactive"
                  )}
                </span>
              </p>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3 flex-wrap">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#updateAccountAndBvnModal"
                    className="btn advancly-off-btn btn-sm mr-3 transition-3d-hover"
                    onClick={() => {
                      setType("bvn");
                      setCurrentStatus(allowBvnValidation);
                    }}
                    disabled
                  >
                    {allowBvnValidation ? "Disable" : "Enable"} BVN Search
                  </button>
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#updateAccountAndBvnModal"
                    className="btn advancly-btn btn-sm transition-3d-hover"
                    onClick={() => {
                      setType("account");
                      setCurrentStatus(allowAccountValidation);
                    }}
                    disabled
                  >
                    {allowAccountValidation ? "Disable" : "Enable"} Account
                    Search
                  </button>
                </div>
              </div>
            </div> */}
          </Fragment>
        )}
      </div>

      {/* <OtherAccountSettingsModal type={type} currentStatus={currentStatus} /> */}
    </div>
  );
};

export default OtherAccountSettings;
