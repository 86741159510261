import React, { useRef, useState } from "react";
import { ReactComponent as FileUploadedDeclined } from "../../../../img/file_uploaded_declined.svg";
import { ReactComponent as FileUploadedApproved } from "../../../../img/file_uploaded_approved.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../img/list_arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../../img/list_arrow_down.svg";
import { ReactComponent as Bin } from "../../../../svg/icons/bin.svg";
import { ReactComponent as Comments } from "../../../../img/comments_uploaded_docs.svg";

import styles from "./GetStartedUploadDocumentsNew.module.scss";
import { DocumentUploadApprovalStatus } from "./GetStartedUploadDocumentsNew";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import { getData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import LoaderColored from "../../../../components/Loader/LoaderColored";
import DeleteExistingUploadedFile from "./DeleteExistingUploadedFile";
import CommentsDocsUpload from "../../../../components/Comments/CommentsDocsUpload";
import ReUploadDocumentConfirmation from "./ReUploadDocumentConfirmation";

const ListOfUploadedDocs = ({
  aggregatorDocuments,
  documentFieldName,
  isMandatory,
  setAggregatorDocumentId,
  aggregatorDocumentId,
  setCurrentFieldDocumentId,
  currentFieldDocumentId,
}: any) => {
  const [showList, setShowList] = useState(false);
  const [downloading, setIsDownloading] = useState(false);
  const [itemValues, setItemValues] = useState<any>("");
  const [documentSelectedReuploadFile, setDocumentSelectedReuploadFile] =
    useState(null);
  const [currentUploadPath, setCurrentUploadPath] = useState<string | null>(
    null
  );
  const triggerDocsReuploadRef = useRef<any>(null);
  const triggerReUploadModal = useRef<any>(null);
  const triggerDocsReupload = (
    aggregator_document_id: number,
    document_field_id: number
  ) => {
    setAggregatorDocumentId(aggregator_document_id);
    setCurrentFieldDocumentId(document_field_id);
    if (aggregator_document_id && document_field_id) {
      triggerDocsReuploadRef.current.click();
    }
  };
  const reUploadDocChange = (event: any) => {
    setDocumentSelectedReuploadFile(event.target.files[0]);
    triggerReUploadModal.current.click();
  };

  const prepareDownloadLink = (content: string, fileName: string) => {
    let appendSource;
    if (fileName.endsWith(".pdf")) {
      appendSource = "application/pdf";
    } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
      appendSource = "application/msword";
    } else if (fileName.endsWith(".jpeg") || fileName.endsWith(".jpg")) {
      appendSource = "image/jpeg";
    } else {
      // Handle other file types if needed
      appendSource = "application/octet-stream";
    }

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (pathname: string) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${pathname}`,
        true
      );
      prepareDownloadLink(data.base64_string, pathname);
      setCurrentUploadPath(null);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "ListOfUploadedDocs.tsx",
        },
      });
      setIsDownloading(false);
    }
  };
  return (
    <div className="">
      <div className={`${styles.listofUploadedDocsContainer} mb-2`}>
        <div className={` d-flex justify-content-between `}>
          <div className={styles.ListOfDocsHeader}>
            <p>
              {documentFieldName}{" "}
              <span
                className={isMandatory ? "text-danger" : ""}
                title={isMandatory ? "required category" : "optional category"}
              >
                {isMandatory ? "*" : ""}
              </span>
            </p>
          </div>
          {showList ? (
            <div
              onClick={() => setShowList(!showList)}
              className="cursor-pointer"
            >
              <ArrowUpIcon />
            </div>
          ) : (
            <div
              onClick={() => setShowList(!showList)}
              className="cursor-pointer"
            >
              <ArrowDownIcon />
            </div>
          )}
        </div>
        {/* next mapping starts here */}
        {showList &&
          aggregatorDocuments &&
          aggregatorDocuments?.map((file: any) => {
            const { files, document_field_id } = file;
            return (
              <>
                {files?.length > 0 ? (
                  files?.map((fileDetails: any) => {
                    const {
                      file_name,
                      approval_status,
                      upload_path,
                      aggregator_document_id,
                    } = fileDetails;
                    // To get the file extension
                    const lastDotIndexFileName = file_name.lastIndexOf(".");
                    const fileNameSliced =
                      file_name.slice(0, 15) +
                      "..." +
                      file_name.slice(lastDotIndexFileName);
                    return (
                      <div key={aggregator_document_id}>
                        <div
                          className={`${styles.fileInnerdetails}  ${
                            approval_status ===
                            DocumentUploadApprovalStatus.declined
                              ? `${styles.fileInnerdetailsDeclined}`
                              : ""
                          } d-flex justify-content-between align-items-center`}
                        >
                          <div className="d-flex align-items-center">
                            {approval_status ===
                            DocumentUploadApprovalStatus.declined ? (
                              <FileUploadedDeclined />
                            ) : (
                              <FileUploadedApproved />
                            )}
                            <div className="d-flex flex-column ml-2">
                              <small className={styles.innerFileName}>
                                {fileNameSliced}
                              </small>
                              <small
                                className={styles.fileDownload}
                                onClick={() => {
                                  setCurrentUploadPath(upload_path);
                                  downloadFile(upload_path);
                                }}
                              >
                                Download{" "}
                                {downloading &&
                                  currentUploadPath === upload_path && (
                                    <LoaderColored />
                                  )}
                              </small>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start ">
                            {approval_status ===
                              DocumentUploadApprovalStatus.approved && (
                              <p className={styles.documentApproved}>
                                Approved
                              </p>
                            )}
                            {approval_status ===
                              DocumentUploadApprovalStatus.declined && (
                              <p className={styles.documentDeclined}>
                                Declined
                              </p>
                            )}
                            {approval_status ===
                              DocumentUploadApprovalStatus.pending && (
                              <p className={styles.documentPending}>
                                Submitted
                              </p>
                            )}
                            {approval_status !==
                              DocumentUploadApprovalStatus.approved && (
                              <div
                                className="ml-2 cursor-pointer"
                                title="delete"
                                data-toggle="modal"
                                data-target="#deleteUploadedDocsModal"
                                onClick={() => {
                                  setAggregatorDocumentId(
                                    aggregator_document_id
                                  );
                                  setCurrentFieldDocumentId(document_field_id);
                                }}
                              >
                                <Bin />
                              </div>
                            )}
                          </div>
                        </div>
                        {approval_status ===
                          DocumentUploadApprovalStatus.declined && (
                          <div className="d-flex justify-content-between">
                            <div
                              className={`${styles.reUpload}`}
                              onClick={() => {
                                triggerDocsReupload(
                                  aggregator_document_id,
                                  document_field_id
                                );
                              }}
                            >
                              <p>Re-upload document here</p>
                            </div>
                            <div
                              className={`${styles.comments}`}
                              data-toggle="modal"
                              data-target="#commentsDocUploadModal"
                              onClick={(e) => {
                                e.preventDefault();
                                setItemValues(fileDetails);
                              }}
                            >
                              <Comments /> <span>Comments</span>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <small>No Document has been uploaded for this category</small>
                )}
              </>
            );
          })}
      </div>
      <input
        className="d-none"
        type="file"
        onChange={reUploadDocChange}
        ref={triggerDocsReuploadRef}
      />
      <button
        className="d-none"
        data-toggle="modal"
        ref={triggerReUploadModal}
        data-target="#reUploadDocumentConfirmationModal"
      ></button>
      <DeleteExistingUploadedFile aggregatorDocumentId={aggregatorDocumentId} />
      <CommentsDocsUpload fileComments={itemValues} />
      <ReUploadDocumentConfirmation
        documentSelectedReuploadFile={documentSelectedReuploadFile}
        currentFieldDocumentId={currentFieldDocumentId}
        setAggregatorDocumentId={setAggregatorDocumentId}
        setCurrentFieldDocumentId={setCurrentFieldDocumentId}
        aggregatorDocumentId={aggregatorDocumentId}
      />
    </div>
  );
};

export default ListOfUploadedDocs;
