import moment from "moment";
import React, { Component } from "react";
import { decrypt } from "./encryptor";

const timeOut = 1000 * 60 * 5; // 5 minutes of time out after inactivity

const protectPage = (WrappedComponent) => {
  return class WithTimeOut extends Component {
    _isMounted = false;
    // For Setting Time Out
    clearTimeoutFunc = () => {
      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
      }
    };
    setTimeout = () => {
      this.logoutTimeout = setTimeout(this.logout, timeOut);
    };
    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    removeAllModals = () => {
      const modals = Array.from(document.querySelectorAll(".modal-backdrop"));
      if (modals.length) {
        modals.map((modal) => (modal.style.display = "none"));
      }
    };

    logout = () => {
      this.removeAllModals();
      if (this._isMounted && this.props.history.push) {
        this.props.history.push("/login");
        alert("Your session timed out");
      }
      sessionStorage.clear();
    };

    // Check if user token is still valid
    isTokenActive = () => {
      const expiryTime = sessionStorage.getItem("expiryTime");

      if (!expiryTime) {
        this.removeAllModals();
        sessionStorage.clear();
        return this.props.history.push("/login");
      }

      const currentTime = moment().format();
      // Token will expire if the currentTime is after expiryTime
      const hasTokenExpired = moment(currentTime).isAfter(decrypt(expiryTime));
      if (hasTokenExpired) {
        this.logout();
      }
    };

    // Cancelling subscriptions
    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount = async () => {
      this._isMounted = true;

      // Check if token is still active.
      this.isTokenActive();

      // Handling timeout when there is no event
      this.events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setTimeout(); //End of Timeout handling
    };

    componentDidUpdate(nextProps) {
      if (nextProps.location !== this.props.location) {
        // Check if token is still active whenever user changes route
        this.isTokenActive();
      }
    }

    render() {
      return <WrappedComponent />;
    }
  };
};

export default protectPage;
