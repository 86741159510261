import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

type paramProps = {
  countryISO: string;
};

const getCountryStates = async (queryKey: paramProps) => {
  const { countryISO } = queryKey;
  const { data } = await getData(
    `${apiEndpoints.COUNTRY_STATES}?iso2_code_country=${countryISO}`,
    true
  );
  return data;
};

export default function useCountryStates({ countryISO }: paramProps) {
  return useQuery([{ countryISO }, "getCountryStates"], getCountryStates, {
    refetchOnWindowFocus: false, // Refetch on window focus
    enabled: countryISO ? true : false,
  });
}
