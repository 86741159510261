import {
  ChangeEvent,
  FormEvent,
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomSelectDropdown from "../../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../../components/Paginator/Paginator.component";
import { useHistory, withRouter } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader.component";
import useDisbursementDetails from "../../../custom-hooks/useDisbursementDetails";
import { accurateQuantifier } from "../../../components/utility";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import { formatMoney } from "../../../helpers/formatter";
import NoTableDataAvailable from "../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import RollOverModal from "./AllDisbursedLoanModal/RollOverModal";
import { useAuth } from "../../../Context/auth.context";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useQueryCache } from "react-query";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../../components/AppInsight/AppInsight";
import ToolTip from "../../../components/ToolTip/ToolTip";
import CustomInputField from "../../../components/CustomHTMLElements/CustomInputField";
import LoaderColored from "../../../components/Loader/LoaderColored";
import useLoanStatus from "../../../custom-hooks/useLoanStatus";
export interface Istate {
  page: number;
  PageSize: number;
  searchValue: string;
  fromdate?: "";
  todate?: "";
}
function AllDisbursedLoansMain() {
  const [loanRef, setLoanRef] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalActionType, setModalActionType] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState("");
  const [showLoansToolTip, setShowLoansToolTip] = useState(false);
  const [count, setCount] = useState<any>(null);
  const [preState, setPreState] = useState({
    page: 1,
    PageSize: 10,
    searchValue: "",
  });
  const [state, setState] = useState<Istate>({
    page: 1,
    PageSize: 10,
    fromdate: "",
    todate: "",
    searchValue: "",
  });
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [loanReferenceNo, setLoanReferenceNo] = useState("");
  const [loanStatus, setLoanStatus] = useState("");
  const [name, setName] = useState("");

  const {
    data: loanStatusData,
    status: loanStat,
    error: loanStatusError,
  } = useLoanStatus();
  const { data, status, error } = useDisbursementDetails(preState);
  const { aggregatorDetails } = useAuth();
  let history = useHistory();
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    if (state.searchValue === "date_range") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        page: 1,
        name: "",
        loanReferenceNo: "",
        loanStatus: "",
        // fromdate: fromDate,
        // todate: toDate,
      }));

      // setLoanReferenceNo("");
      // setLoanStatus("");
      // setName("");
      // setFromDate("");
      // setToDate("");
    } else if (state.searchValue === "loan_status") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        loanStatus,
        name: "",
        fromdate: "",
        todate: "",
        loanReferenceNo: "",
      }));
    } else if (state.searchValue === "loanReferenceNo") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        loanReferenceNo,
        name: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else if (state.searchValue === "name") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        name,
        loanReferenceNo: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else if (state.searchValue === "") {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        name,
        loanReferenceNo: "",
        fromdate: "",
        todate: "",
        loanStatus: "",
      }));
    } else {
      setPreState((prevState) => ({
        ...prevState,
        ...state,
        page: 1,
      }));
    }
  };
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }
  const handleClick = (props: any) => {
    history.push({
      pathname: `/disbursements/${props.borrower_details.borrower_id}`,
      state: {
        loanRef: props.loan_ref,
        parent_rollover_loan_reference: props.parent_rollover_loan_reference,
        status: props.status,
        loan_id: props.loan_id,
      },
    });
  };
  const submitRepayment = async () => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${loanRef}`
      );
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current?.click();
      }
      setSubmitSuccess(
        "Repayment in being processed. Kindly check back in 10 minutes time to rollover the loan"
      );
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.tsx",
        },
      });
      setLoading(false);
      if (dismissRef && dismissRef.current) {
        dismissRef.current?.click();
      }
      queryCache.invalidateQueries("getDisbursementDetails");
      setSubmitError(errorHandler(error));
    }
  };
  const submitRollOverRequest = async (reqBody: { loan_tenure: string }) => {
    setLoading(true);
    try {
      await postDataWithDotNet(
        `${apiEndpoints.initiateLoanRollOver}?LoanRef=${loanRef}&PreferredLoanTenure=${reqBody.loan_tenure}`
      );
      setLoading(false);
      setModalActionType("step4");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "AllDisbursedLoansMain.tsx",
        },
      });
      setSubmitError(errorHandler(error));
      setLoading(false);
      queryCache.invalidateQueries("getDisbursementDetails");
      setModalActionType("step4");
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "AllDisbursedLoansMain.tsx",
      isLoggedIn: true,
    });
  }, []);
  if (status === "loading") return <PageLoader />;

  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {submitError && <p className="alert alert-danger small">{submitError}</p>}
      {loanStatusError && (
        <p className="alert alert-danger small">{loanStatusError as string}</p>
      )}
      {submitSuccess && (
        <p className="alert alert-success small">{submitSuccess}</p>
      )}
      {
        //@ts-ignore
        !error && status !== "loading" && (
          <div>
            <div className="advancly-wrapper-container">
              <div className="advancly-sub-wrapper">
                <div
                  className="d-flex justify-content-between align-items-center flex-wrap"
                  style={{ position: "relative" }}
                >
                  <form
                    className="d-flex flex-row flex-wrap align-items-center    custom-search-input mb-2"
                    onSubmit={filterData}
                  >
                    <div style={{ width: "150px", marginRight: "8px" }}>
                      <CustomSelectDropdown
                        name="searchValue"
                        value={state.searchValue}
                        onChange={(e: any) => handleChange(e)}
                      >
                        <option value="">All</option>
                        <option value="today">Today</option>
                        <option value="this_week">This week</option>
                        <option value="last_7_days">last 7 days</option>
                        <option value="last_30_days">last 30 days</option>
                        <option value="last_365_days">last 365 days</option>
                        <option value="date_range">Date range</option>
                        <option value="loan_status">Loan Status</option>
                        <option value="loanReferenceNo">Loan Reference</option>
                        <option value="name">Customer Name</option>
                      </CustomSelectDropdown>
                    </div>
                    {state.searchValue === "date_range" && (
                      <Fragment>
                        <div className="form-group mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-2">From </label>
                            <input
                              type="date"
                              name="fromdate"
                              className="form-control"
                              value={state.fromdate}
                              onChange={(e: any) => handleChange(e)}
                            />
                          </div>
                        </div>
                        <div className="form-group mr-3 mt-2">
                          <div className="input-group">
                            <label className="mr-2 mt-2">To </label>
                            <input
                              type="date"
                              className="form-control"
                              name="todate"
                              value={state.todate}
                              onChange={(e: any) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {state.searchValue === "loan_status" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-3">Loan Status </label>

                            <CustomSelectDropdown
                              name="loanStatus"
                              value={loanStatus}
                              onChange={(e) =>
                                setLoanStatus(
                                  (e.target as HTMLInputElement).value
                                )
                              }
                            >
                              <option value="">All</option>
                              {loanStatusData &&
                                loanStatusData?.map(
                                  ({
                                    name,
                                    id,
                                  }: {
                                    name: string;
                                    id: number;
                                  }) => {
                                    return (
                                      <option key={id} value={id}>
                                        {name.replace(/_/g, " ")}
                                      </option>
                                    );
                                  }
                                )}
                            </CustomSelectDropdown>
                            {loanStat === "loading" && <LoaderColored />}
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {state.searchValue === "loanReferenceNo" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-4">Loan Reference </label>
                            <div className="mt-3">
                              <CustomInputField
                                type="search"
                                name="loanReferenceNo"
                                value={loanReferenceNo}
                                onChange={(e: any) =>
                                  setLoanReferenceNo(e.target.value)
                                }
                                placeholder="search by reference"
                              ></CustomInputField>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {state.searchValue === "name" && (
                      <Fragment>
                        <div className="form-group pl-2  mr-3">
                          <div className="input-group mt-2">
                            <label className="mr-2 mt-4">Customer Name </label>
                            <div className="mt-3">
                              <CustomInputField
                                type="search"
                                name="name"
                                value={name}
                                onChange={(e: any) => setName(e.target.value)}
                                placeholder="search by customer name"
                              ></CustomInputField>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div className="mb-2">
                      <button
                        type="submit"
                        className="btn advancly-btn "
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                  <div
                    className="d-flex align-items-center"
                    onMouseEnter={() => setShowLoansToolTip(true)}
                    onMouseLeave={() => setShowLoansToolTip(false)}
                  >
                    <p className="pr-2 pt-1">Showing:</p>
                    <CustomSelectDropdown
                      onChange={(e) =>
                        //@ts-ignore
                        setPreState({ ...state, PageSize: e.target?.value })
                      }
                      value={preState.PageSize}
                      //@ts-ignore

                      changeCurrentPage={(page) =>
                        setPreState({ ...state, page })
                      }
                    >
                      <option value={10}>10 Entries</option>
                      <option value={20}>20 Entries</option>
                      <option value={50}>50 Entries</option>
                      <option value={100}>100 Entries</option>
                    </CustomSelectDropdown>

                    {showLoansToolTip && (
                      <ToolTip
                        tooltipContainerRight={"1rem"}
                        tooltipContainerTop={"5rem"}
                        tooltipHeading={"Entries"}
                        tooltipParagraph={`Maximise your overview: Select the number of entries to view multiple loans on a single page and effortlessly compare your options`}
                      />
                    )}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table-style">
                    <thead className="">
                      <tr>
                        <th className="th-td-style th-style ">Reference</th>
                        <th className="th-td-style th-style ">Borrower</th>
                        <th className="th-td-style th-style ">Product</th>
                        <th className="th-td-style th-style ">Amount</th>
                        <th className="th-td-style th-style ">Tenure</th>
                        <th className="th-td-style th-style ">Interest</th>
                        <th className="th-td-style th-style ">Outstanding</th>
                        <th className="th-td-style th-style ">Borrower Type</th>
                        <th className="th-td-style th-style ">Date Created</th>
                        <th className="th-td-style th-style ">Loan Status</th>
                        <th className="th-td-style th-style ">Roll Over</th>
                        <th className="th-td-style th-style ">
                          Roll Over Status
                        </th>
                        <th className="th-td-style th-style ">Roll Over Ref</th>
                        <th className="th-td-style th-style ">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.data?.length ? (
                        data?.data?.map((data: any, id: number) => {
                          const {
                            borrower_details: {
                              first_name,
                              last_name,
                              borrower_type_value,
                            },
                            loan_ref,
                            pub_date,
                            loan_amount,
                            product_name,
                            loan_tenure,
                            loan_outstanding,
                            loan_interest_amount,
                            status,
                            currency,
                            is_rollover,
                            rollover_loan_reference,
                            can_rollover,
                          } = data;
                          return (
                            <tr key={id}>
                              <td className="th-td-style ">{loan_ref}</td>
                              <td
                                style={{ minWidth: "180px" }}
                                className="text-capitalize th-td-style "
                              >
                                {first_name && first_name.toLowerCase()}{" "}
                                {last_name && last_name.toLowerCase()}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "150px" }}
                              >
                                {product_name}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_amount, currency)}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "150px" }}
                              >
                                {accurateQuantifier(loan_tenure, "day")}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_interest_amount, currency)}
                              </td>
                              <td className="th-td-style ">
                                {formatMoney(loan_outstanding, currency)}
                              </td>
                              <td className="th-td-style ">
                                {borrower_type_value}
                              </td>
                              <td
                                className="th-td-style "
                                style={{ minWidth: "120px" }}
                              >
                                {pub_date.substring(0, 10)}
                              </td>
                              <td className="th-td-style ">
                                <span className={attachClassNameToTag(status)}>
                                  {attachClassNameToTag(status)}
                                </span>
                              </td>

                              {can_rollover === true ? (
                                <td
                                  className="color-mid-blue cursor-pointer th-td-style "
                                  onClick={() => {
                                    setLoanRef(loan_ref);
                                    setModalActionType("step1");
                                  }}
                                  style={{ minWidth: "120px" }}
                                  data-toggle="modal"
                                  data-target="#rollOverModal"
                                >
                                  Apply
                                </td>
                              ) : (
                                <td className="text-secondary th-td-style">
                                  Not available
                                </td>
                              )}
                              <td className="th-td-style ">
                                {is_rollover ? "True" : "False"}
                              </td>
                              <td className="th-td-style ">
                                {rollover_loan_reference || "N/A"}
                              </td>
                              <td
                                className="color-mid-blue cursor-pointer th-td-style"
                                onClick={() => handleClick(data)}
                                style={{ minWidth: "120px" }}
                              >
                                View Details
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && (
                  <Paginator
                    size={preState.PageSize}
                    page={preState.page}
                    count={count}
                    changeCurrentPage={(page) =>
                      setPreState({ ...state, page })
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )
      }

      <RollOverModal
        modalActionType={modalActionType}
        setModalActionType={setModalActionType}
        loanRef={loanRef}
        aggregatorDetails={aggregatorDetails}
        submitRepayment={submitRepayment}
        submitRollOverRequest={submitRollOverRequest}
        loading={loading}
        submitError={submitError}
        dismissRef={dismissRef}
      />
    </div>
  );
}
export default withRouter(AllDisbursedLoansMain);
