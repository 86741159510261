import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
type TapplicationID = {
  id: number;
};
const getLoanApplicationById = async ({ id }: TapplicationID) => {
  const data = await getData(`${apiEndpoints.LOAN_APPLICATIONS}/${id}`);

  return data?.data;
};

export default function useLoanApplicationById({ id }: TapplicationID) {
  return useQuery(
    [
      {
        id,
      },
      "getLoanApplicationById",
    ],
    getLoanApplicationById,
    {
      enabled: id ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
