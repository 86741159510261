import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import { IMultiplesignatoryModalStep } from "./MultipleSignatoryLandingPage";
const schema = yup.object().shape({
  signatoryName1: yup.string().required("Please Select a Name"),
  signatoryName2: yup.string().required("Please Select a Name"),
  signatoryName3: yup.string(),
});
type TUser = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};
const MultipleSignatoryModalStep1 = ({
  setMultipleSignatoryName,
  setMultipleSignatory,
  multipleSignatory,
  setSignatoryType,
  step,
}: IMultiplesignatoryModalStep) => {
  const signatoriesHandler = (data: {
    signatoryName1: string;
    signatoryName2: string;
    signatoryName3: string;
  }) => {
    let signatoriesBody;
    const { signatoryName1, signatoryName2, signatoryName3 } = data;

    if (signatoryName1 && signatoryName2 && signatoryName3) {
      signatoriesBody = [
        { email: signatoryName1, levelNo: 1 },
        { email: signatoryName2, levelNo: 2 },
        { email: signatoryName3, levelNo: 3 },
      ];
      setMultipleSignatoryName(signatoriesBody);
      setMultipleSignatory(multipleSignatory + step);
    } else if (signatoryName1 && signatoryName2) {
      signatoriesBody = [
        { email: signatoryName1, levelNo: 1 },
        { email: signatoryName2, levelNo: 2 },
      ];
      setMultipleSignatoryName(signatoriesBody);
      setMultipleSignatory(multipleSignatory + step);
    } else {
      return;
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: users, status, error } = useAggregatorSignatoryUsers();

  const watchMultiSignatoryNameChange = watch("signatoryName1");
  const watchMultiSignatoryNameChange2 = watch("signatoryName2");

  return (
    <div>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Multi-signatories Approval</h4>
          <button type="button" className="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div className="modal-body">
          <small
            className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 "
            onClick={() => {
              setSignatoryType("");
            }}
          >
            &#8592; Go back
          </small>
          <p className="pt-2">
            Select 2-3 of the following users as a signatories with their
            approval level
          </p>
          <form onSubmit={handleSubmit(signatoriesHandler)}>
            <div className="row">
              <div className="col-12">
                {status === "loading" && (
                  <small className="text-primary">Loading...</small>
                )}
                {error && (
                  <small className="text-dangeralert alert-danger small text-center">
                    {errorHandler(error)}
                  </small>
                )}
                {errors && (
                  <small className="text-danger">
                    {errors.signatoryName1?.message}
                  </small>
                )}
                <CustomSelectDropdown
                  name="signatoryName1"
                  ref={register}
                  label="Signatory One"
                >
                  <option value="">Select Signatory for Level 1</option>
                  {users?.map((user: TUser) => {
                    const { id, firstName, lastName, email } = user;
                    return (
                      <option
                        key={id}
                        value={email}
                      >{`${firstName} ${lastName}`}</option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>

              <div className="col-12">
                {errors && (
                  <small className="text-danger">
                    {errors.signatoryName2?.message}{" "}
                  </small>
                )}
                <CustomSelectDropdown
                  name="signatoryName2"
                  ref={register}
                  label="Signatory Two"
                >
                  <option value="">Select Signatory for Level 2</option>
                  {users?.map((user: TUser) => {
                    const { id, firstName, lastName, email } = user;
                    return (
                      <option
                        key={id}
                        value={email}
                      >{`${firstName} ${lastName}`}</option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>

              <div className="col-12">
                <CustomSelectDropdown
                  name="signatoryName3"
                  ref={register}
                  label="Signatory Three"
                >
                  <option value="">Select Signatory for Level 3</option>
                  {users?.map((user: TUser) => {
                    const { id, firstName, lastName, email } = user;
                    return (
                      <option
                        key={id}
                        value={email}
                      >{`${firstName} ${lastName}`}</option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>
            </div>
            <button
              className="btn advancly-btn btn-sm w-100 mt-3"
              disabled={
                !watchMultiSignatoryNameChange ||
                !watchMultiSignatoryNameChange2
              }
            >
              Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MultipleSignatoryModalStep1;
