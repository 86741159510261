import React, { Dispatch, LegacyRef, SetStateAction, useState } from "react";
import MultipleSignatoryModalStep1 from "./MultipleSignatoryModalStep1";
import MultipleSignatoryModalStep2 from "./MultipleSignatoryModalStep2";
import MultipleSignatoryModalStep3 from "./MultipleSignatoryModalStep3";
export type MultipleSignatoryModalStepBody = {
  email: string;
  levelNo: number;
};
export interface IMultiplesignatoryModalStep {
  setMultipleSignatory: Dispatch<SetStateAction<number>>;
  step: number;
  multipleSignatory: number;
  setSignatoryType: Dispatch<SetStateAction<string>>;
  setMultipleSignatoryName: Dispatch<
    SetStateAction<string | MultipleSignatoryModalStepBody[]>
  >;
}

const MultipleSignatoryLandingPage = ({
  setSignatoryType,
  dismissRef,
}: {
  setSignatoryType: Dispatch<SetStateAction<string>>;
  dismissRef: LegacyRef<HTMLButtonElement> | undefined;
}) => {
  const [multipleSignatoryName, setMultipleSignatoryName] = useState<
    string | MultipleSignatoryModalStepBody[]
  >("");
  const [agreementTick, setAgreementTick] = useState<string | number>("");
  const [multipleSignatory, setMultipleSignatory] = useState(1);
  const step = 1;
  return (
    <div>
      {(() => {
        switch (multipleSignatory) {
          case 1:
            return (
              <MultipleSignatoryModalStep1
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                setMultipleSignatoryName={setMultipleSignatoryName}
                setSignatoryType={setSignatoryType}
              />
            );

          case 2:
            return (
              <MultipleSignatoryModalStep2
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                setAgreementTick={setAgreementTick}
              />
            );
          case 3:
            return (
              <MultipleSignatoryModalStep3
                multipleSignatory={multipleSignatory}
                step={step}
                setMultipleSignatory={setMultipleSignatory}
                multipleSignatoryName={multipleSignatoryName}
                agreementTick={agreementTick}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default MultipleSignatoryLandingPage;
