import axios from "axios";
import { appInsights } from "../components/AppInsight/AppInsight";
import CONFIG from "../components/config";
import { decrypt } from "../helpers/encryptor";
import { environmentTypes } from "../helpers/environmentTypes";

export const token = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return null;
  }
  return decrypt(token);
};

const getBaseUrl = () => {
  const aggregatorDetails = JSON.parse(
      decrypt(sessionStorage.getItem("_aggDt"))
  );

  if (!aggregatorDetails) {
    return CONFIG.BASE_URL;
  }

  const envKey = `${aggregatorDetails?.rep_first_name}${aggregatorDetails?.rep_last_name}env`;

  if (!envKey) {
    return CONFIG.BASE_URL;
  }

  const currentEnvironment = localStorage.getItem(envKey);
  if (
      currentEnvironment &&
      decrypt(currentEnvironment) === environmentTypes.production
  ) {
    // Return Production URL
    return CONFIG.BASE_URL2;
  } else {
    // Return Sandbox URL
    return CONFIG.SANDBOX_BASE_URL;
  }
};

const axiosInstance = axios.create();
const axiosInstanceProdOnly = axios.create();
const resetPasswordFinalStepAxiosInstance = axios.create();
const resetPasswordFinalStepAxiosInstanceProdOnly = axios.create();

let savedHeaders = {}


axiosInstance.interceptors.request.use(
    function (config) {
      config.headers = {
        ...config.headers,
        Authorization: `Token ${token()}`,
        "content-security-policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      };
      config.baseURL = getBaseUrl();
      // you can also do other modification in config
      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      if (error.response && error.response.status === 500) {
        return Promise.reject(new Error("Internal Server Error"));
      }
      return Promise.reject(error);
    }
);

resetPasswordFinalStepAxiosInstance.interceptors.request.use(
    function (config) {
      const savedHeaders = JSON.parse(localStorage.getItem("savedHeaders"));
      config.headers = {
        ...config.headers,
        ...savedHeaders,
        Authorization: `Token ${token()}`,
        "content-security-policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      };
      config.baseURL = getBaseUrl();
      // you can also do other modification in config
      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });
      if (error.response && error.response.status === 500) {
        return Promise.reject(new Error("Internal Server Error"));
      }
      return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
      
      savedHeaders = {
        ssoarraffinity: response.headers["ssoarraffinity"],
        ssoarraffinitysamesite: response.headers["ssoarraffinitysamesite"]
      };

      localStorage.setItem('savedHeaders', JSON.stringify(savedHeaders));

      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
);

axiosInstanceProdOnly.interceptors.request.use(
    function (config) {
      config.headers = {
        ...config.headers,
        "content-security-policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
        Authorization: `Token ${token()}`,
      };
      config.baseURL = CONFIG.BASE_URL;
      // you can also do other modification in config
      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });

      return Promise.reject(error);
    }
);

resetPasswordFinalStepAxiosInstanceProdOnly.interceptors.request.use(
    function (config) {
      const savedHeaders = JSON.parse(localStorage.getItem("savedHeaders"));
      config.headers = {
        ...config.headers,
        ...savedHeaders,
        "content-security-policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
        Authorization: `Token ${token()}`,
      };
      config.baseURL = CONFIG.BASE_URL;
      // you can also do other modification in config
      return config;
    },
    function (error) {
      appInsights.trackException({ exception: error });

      return Promise.reject(error);
    }
);

export const resetPasswordFinalStepPostData = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await resetPasswordFinalStepAxiosInstanceProdOnly({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await resetPasswordFinalStepAxiosInstance({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const getData = async (url, prodOnly) => {
  try {
    if (prodOnly && prodOnly === true) {
      const data = await axiosInstanceProdOnly({
        method: "GET",
        url,
      });
      return data.data;
    } else {
      const data = await axiosInstance({
        method: "GET",
        url,
      });
      return data.data;
    }
  } catch (error) {
    return error;
  }
};

export const getDataWithoutToken = async (url, prodOnly) => {
  try {
    const data = await axios({
      method: "GET",
      url: `${
          prodOnly && prodOnly === true ? CONFIG.BASE_URL : getBaseUrl()
      }${url}`,
      headers: {
        "Content-Type": "application/json",
        "content-security-policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      },
    });
    return data.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.location.href = "/login";
    } else {
      return error;
    }
  }
};

export const postData = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  }
};
export const postDataMultiform = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const post = (url, reqBody) => {
  return axiosInstance({
    method: "POST",
    url: url,
    data: reqBody,
  });
};

export const postDataWithoutToken = async (url, reqBody, prodOnly) => {
  const data = await axios({
    method: "POST",
    url: `${
        prodOnly && prodOnly === true ? CONFIG.BASE_URL : getBaseUrl()
    }${url}`,
    data: reqBody,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data.data;
};

export const patchData = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "PATCH",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "PATCH",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const putData = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "PUT",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "PUT",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const deleteData = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "DELETE",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "DELETE",
      url,
      data: reqBody,
    });
    return data.data;
  }
};
