import React from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
// import { ReactComponent as DeleteIcon } from "../../../img/DeleteIcon2.svg";
import { ReactComponent as EditIcon } from "../../../img/EditIcon.svg";
import { formatMoney } from "../../../helpers/formatter";
import { StatusTag } from "../../../helpers/attachClassNameToTag";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";

const LoanInformationExistingList = ({
  setEditLoanInformation,
  setProposedLoanProductId,
  setEditLoanDetails,
  existingLoanInformationData,
  existingLoanInformationStatus,
  existingLoanInformationError,
}: any) => {
  // checking if loan should be read only
  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;

  return (
    <div>
      {existingLoanInformationStatus === "loading" ? (
        <PageLoader />
      ) : (
        existingLoanInformationData?.loan_application_details?.map(
          (loanInfoList: any) => {
            const {
              proposed_amount,
              currency,
              approval_status,
              loan_application_id,
              proposed_loan_product_detail,
              proposed_loan_product_detail_id,
            } = loanInfoList;
            return (
              <div className="my-4" key={loan_application_id}>
                {existingLoanInformationError && (
                  <p className="alert alert-danger small">
                    {existingLoanInformationError}
                  </p>
                )}

                <div className="card-border-wrapper-medium d-flex justify-content-between align-items-center">
                  <div className="mt-4">
                    <h5 className="color-dark-purple">{`${proposed_loan_product_detail}`}</h5>
                    <p className="bg-light-grey">
                      {proposed_amount && currency
                        ? formatMoney(proposed_amount, currency)
                        : `status unavailable`}
                    </p>
                    <p
                      className={`${StatusTag(approval_status)} text-center `}
                      style={{ maxWidth: "40%" }}
                    >
                      {approval_status ? approval_status : "Unavailable"}
                    </p>
                  </div>
                  {!LoanApplicationEditOrWrite && (
                    <div>
                      <span
                        title="Edit Loan information List"
                        className="cursor-pointer  transition-3d-hover "
                        onClick={() => {
                          setProposedLoanProductId(
                            proposed_loan_product_detail_id
                          );
                          setEditLoanInformation(true);
                          setEditLoanDetails(loanInfoList);
                        }}
                      >
                        <EditIcon className={`transition-3d-hover  `} />{" "}
                      </span>
                      {/* <span
                      title="Delete Loan information List"
                      className="cursor-pointer  transition-3d-hover "
                      data-toggle="modal"
                      data-target="#confirmationDeleteModal"
                    >
                      {" "}
                      <DeleteIcon className={`transition-3d-hover  `} />{" "}
                    </span> */}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )
      )}
    </div>
  );
};

export default LoanInformationExistingList;
