import React, { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../../components/CustomHTMLElements/CustomTextArea";
import { queryCache } from "react-query";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    .required("Please enter a comment")
    .min(5, "Comment must have at least 5 characters"),
});
const WalletDisbursmentApprovalComment = ({
  setCommentDetails,
  setApprovalSteps,
  setError,
}: {
  setCommentDetails: Dispatch<SetStateAction<string>>;
  setApprovalSteps: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string>>;
}) => {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = ({ comment }: { comment: string }) => {
    setCommentDetails(comment);
    setApprovalSteps("approvalpin");
  };
  const refreshModal = () => {
    queryCache.invalidateQueries("getPendingDisbursement");
    reset();
  };
  const refreshPinModal = () => {
    setError("");
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursmentApprovalComment.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <div className="modal-header border-bottom-0">
        <h6 className="text-center font-weight-bold text-capitalize">
          Approve Disbursement{" "}
        </h6>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="form-group">
            <CustomTextArea
              maxLength={128}
              ref={register}
              placeholder="Add a comment"
              label="Add Comment"
              name="comment"
              errors={errors.comment}
            />
            <div className="d-flex">
              <button
                type="button"
                className="btn advancly-white-btn btn-sm transition-3d-hover w-50 mr-2"
                data-dismiss="modal"
                onClick={refreshModal}
              >
                Cancel
              </button>
              <button
                className="btn advancly-btn btn-sm transition-3d-hover w-50 ml-2"
                type="submit"
                onClick={refreshPinModal}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default WalletDisbursmentApprovalComment;
