import { useRef, useState } from "react";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
import styles from "../LoanApplication.module.scss";
import stylesFeedbackLoanApp from "./FeedBackLoanApplicationExisting.module.scss";
import { ReactComponent as Folder } from "../../../svg/icons/folder.svg";
import { ReactComponent as Bin } from "../../../svg/icons/bin.svg";
import FeedbackLoanApplicationExisting from "./FeedbackLoanApplicationExisting";
import { useForm } from "react-hook-form";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData, putData } from "../../../apis/apiMethods";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { IstepLoanApp } from "../LoanApplicationMain";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { useAuth } from "../../../Context/auth.context";
import LoaderColored from "../../Loader/LoaderColored";
import { useQueryCache } from "react-query";
import Loader from "../../Loader/Loader.component";
import { useHistory } from "react-router-dom";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
const FeedBackLoanApplication = ({ scrollToTop }: IstepLoanApp) => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState<string>();
  const [feedbackSuccess, setFeedbackSuccess] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
  const [sendToRMSuccess, setSendToRMSuccess] = useState("");
  const [sendToRMError, setsendToRMError] = useState("");
  const [isLoadingsendToRM, setIsLoadingsendToRM] = useState(false);
  const [displayCommentBox, setDisplayCommentBox] = useState<any>({});
  const [feedBackId, setFeedBackId] = useState<number | null>(null);
  const selectedFilesViaRef = useRef<any>();
  const isFileSelectedViaRef = useRef(false);
  const queryCache = useQueryCache();

  const toggleDisplayAddComments = (id: number) => {
    setDisplayCommentBox((prev: any) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const handleFileChange = async (event: React.ChangeEvent | any) => {
    event.preventDefault(); // Prevent the default form submission
    const file = event.target.files[0];

    selectedFilesViaRef.current = file || "";
    // setSelectedFiles(file);
    // if (selectedFiles) {
    //   setIsFileSelected(true);
    // }
    if (selectedFilesViaRef.current) {
      isFileSelectedViaRef.current = true;
      setIsFileSelected(true);
    }
  };
  let history = useHistory();
  const handleAddButtonClick = (event: any) => {
    event.preventDefault();
    // Trigger the file input click event when the "Add" button is clicked
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };
  const { currentLoanApplicationId } = useAuth();
  const {
    data: existingLoanInformationData,
    status: existingLoanInformationStatus,
  } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const { handleSubmit } = useForm();
  const submitFeedback = async () => {
    if (!selectedFilesViaRef.current) {
      setFeedbackError("Please select a file");
      setTimeout(() => {
        setFeedbackError("");
      }, 3000);
    }
    // if (!selectedFiles) {
    //   setFeedbackError("Please select a file");
    //   setTimeout(() => {
    //     setFeedbackError("");
    //   }, 3000);
    // }
    const acceptedBase64 = selectedFilesViaRef.current
      ? //@ts-ignore
        await convertToBase64(selectedFilesViaRef.current)
      : null;

    const feedbackReq = {
      loan_application_feedback_id: feedBackId,
      comment: feedbackComment,
      base64_file_string: selectedFilesViaRef ? acceptedBase64 : "",
      filename_with_extension: selectedFilesViaRef?.current?.name,
    };
    try {
      setIsLoadingFeedback(true);
      setFeedbackError("");
      if (feedbackComment || selectedFilesViaRef.current) {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATION_FEEDBACK_COMMENT,
          feedbackReq,
          true
        );
        setIsLoadingFeedback(false);
        setFeedbackSuccess(res?.message);

        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        setFeedbackComment("");
        selectedFilesViaRef.current = "";
        setTimeout(() => {
          setFeedbackSuccess("");
        }, 4000);
        if (scrollToTop) {
          scrollToTop();
        }
      }
      setDisplayCommentBox({});
      setFeedBackId(null);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "FeedBackLoanApplication.tsx",
        },
      });
      setIsLoadingFeedback(false);
      setFeedbackSuccess("");
      setFeedbackError(errorHandler(error));

      setTimeout(() => {
        setFeedbackError("");
      }, 5000);
    }
  };

  const handleSendRM = async () => {
    const handleRMReq = {
      loan_application_id: currentLoanApplicationId,
    };
    try {
      setIsLoadingsendToRM(true);
      setsendToRMError("");
      {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATION_SEND_TO_RM,
          handleRMReq,
          true
        );
        setIsLoadingsendToRM(false);
        setSendToRMSuccess(res?.message);
        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          history.push(`loan-application`);
          setSendToRMSuccess("");
        }, 3000);
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "FeedBackLoanApplication.tsx",
        },
      });
      setIsLoadingsendToRM(false);
      setSendToRMSuccess("");
      setsendToRMError(errorHandler(error));

      setTimeout(() => {
        setsendToRMError("");
      }, 5000);
    }
  };
  return (
    <div
      className={`${stylesFeedbackLoanApp.center_container_feedbackLoan}  mt-4`}
    >
      {feedbackSuccess && (
        <p className="alert alert-success small">{feedbackSuccess}</p>
      )}
      {sendToRMSuccess && (
        <p className="alert alert-success small">{sendToRMSuccess}</p>
      )}
      {feedbackError && (
        <p className="alert alert-danger small">{feedbackError}</p>
      )}
      {sendToRMError && (
        <p className="alert alert-danger small">{sendToRMError}</p>
      )}
      <div>
        <FeedbackLoanApplicationExisting
          feedBackId={feedBackId}
          setFeedBackId={setFeedBackId}
          toggleDisplayAddComments={toggleDisplayAddComments}
          displayCommentBox={displayCommentBox}
          existingLoanInformationData={existingLoanInformationData}
          existingLoanInformationStatus={existingLoanInformationStatus}
        />
      </div>
      {feedBackId && displayCommentBox[feedBackId] && (
        <form onSubmit={handleSubmit(submitFeedback)}>
          <div>
            <div className="row">
              <div className="col-12 mt-2">
                <div>
                  <div>
                    <CustomTextArea
                      rows={4}
                      label={`Add Customer Feedback Comment`}
                      value={feedbackComment}
                      placeholder={`Enter feedback comment`}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFeedbackComment(e.target.value);
                      }}
                    ></CustomTextArea>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    accept="application/pdf, application/msword, image/*,.xlsx, .xls,.csv"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />

                  {isFileSelected && isFileSelectedViaRef?.current && (
                    <>
                      <div className="pt-4">
                        <label htmlFor="" className="text-muted">
                          Feedback File
                        </label>

                        <div className={styles.uploadedDocCard}>
                          <div className={styles.left}>
                            {<Folder />}
                            <span>
                              {selectedFilesViaRef?.current?.name
                                ? selectedFilesViaRef?.current?.name
                                : "file type"}
                            </span>
                          </div>

                          <div
                            onClick={() => {
                              // setSelectedFiles(null);
                              setIsFileSelected(false);
                              selectedFilesViaRef.current = null;
                              isFileSelectedViaRef.current = false;
                            }}
                            className={styles.right}
                          >
                            <Bin />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <button
                    className="btn advancly-white-btn btn-sm transition-3d-hover mt-2"
                    onClick={handleAddButtonClick}
                  >
                    {!isFileSelected && !isFileSelectedViaRef.current
                      ? "Click to Add File to feedback comment(optional)"
                      : "Change Feedback comment file"}
                  </button>
                </div>

                <button
                  type="submit"
                  className="btn advancly-white-btn btn-sm transition-3d-hover mt-2"
                  disabled={isLoadingFeedback}
                >
                  Add Comment {isLoadingFeedback && <LoaderColored />}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
      {Number(existingLoanInformationData?.status_id) ===
        loanApplicationStatus.returnedToCustomer && (
        <button className="w-100 advancly-btn mt-3" onClick={handleSendRM}>
          Submit To Admin {isLoadingsendToRM && <Loader />}
        </button>
      )}
    </div>
  );
};

export default FeedBackLoanApplication;
