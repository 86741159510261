import React from "react";

export default function LoaderColored() {
  return (
    <div
      className="spinner-border color-mid-blue  spinner-border-sm ml-2"
      role="status"
    >
      <span className="sr-only">Loading</span>
    </div>
  );
}
