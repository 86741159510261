import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getProductTypeRemaining = async () => {
  const data = await getDataWithDotNet(apiEndpoints.PRODUCT_TYPE_REMAINING);
  return data.data;
};

export default function useProductTypeRemaining() {
  return useQuery(["getProductTypeRemaining"], getProductTypeRemaining, {
    refetchOnWindowFocus: false,
  });
}
