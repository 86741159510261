import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { apiEndpoints } from "../apis/apiEndpoints";
import { postDataWithDotNet } from "../apis/dotNetApiMethods";
import { errorHandler } from "../helpers/errorHandler";
import BackButtonComponent from "./BackButton/BackButton.component";
import MakeSingleRepaymentModal from "../Pages/Repayment/RepaymentModal/MakeSingleRepaymentModal";
import { useAuth } from "../Context/auth.context";
import Loader from "./Loader/Loader.component";
import { useLocation } from "react-router-dom";

export default function RepayLoans(props: any) {
  const location = useLocation();
  const [state, setState] = useState({
    dataToStore: [],
    //@ts-ignore
    totalAmount: location?.data?.loanDetailsSummary?.totalOutstanding || 0,
    repaymentError: false,
    repaymentSuccess: false,
    repaymentLoading: false,
    reference: "",
    deficitBalance: "",
  });

  const [modalActionType, setModalActionType] = useState("");
  const { aggregatorDetails } = useAuth();
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (!Object.keys(props.data).length) {
      props.history.goBack();
    }
  }, [props]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    setState({
      ...state,
      totalAmount: Number(value),
      repaymentError: false,
      repaymentSuccess: false,
      repaymentLoading: false,
      reference: "",
      deficitBalance: "",
    });
  };
  const submitRepayment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });
    let loan = [];
    loan.push({
      loan_Id: props?.data?.loan_id,
      amount: Number(state.totalAmount),
    });
    //@ts-ignore

    setState({ ...state, dataToStore: loan });
    try {
      const result = await postDataWithDotNet(apiEndpoints.autoRepayment, loan);

      if (result?.data && result?.data?.isPaid === 0) {
        setState({
          ...state,
          repaymentSuccess: false,
          repaymentError: false,
          repaymentLoading: false,
          reference: result?.data?.reference,
          deficitBalance: result?.data?.deficitBalance,
        });
        setModalActionType("step2");
      } else if (result?.data && result?.data?.isPaid === 1) {
        setState({
          ...state,
          repaymentSuccess: result.message,
          repaymentError: false,
          repaymentLoading: false,
          dataToStore: [],
          totalAmount: 0,
        });
        if (dismissRef && dismissRef.current) {
          dismissRef.current.click();
        }
      }
    } catch (error) {
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
    }
  };
  const finalSubmit = async () => {
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });

    try {
      const result = await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${state.reference}`
      );
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: result.message,
        repaymentError: false,
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
    } catch (error) {
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
    }
  };

  return (
    <div className="container my-3">
      <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
        <div className="d-flex align-items-center justify-content-start">
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent />
          </h3>
        </div>
      </div>
      <div className="text-center mb-7">
        <p className="h4 text-dark">Repay Loan</p>
        <p className="small">you can pay back your Outstanding loans</p>
      </div>
      <div className="row mt-5 justify-content-center">
        <div className="col-12 col-lg-6">
          <div className="card card-frame">
            <div className="card-body px-5 py-4">
              {
                //@ts-ignore

                state?.message && (
                  <p className="alert alert-success small">
                    {
                      //@ts-ignore
                      state?.message
                    }
                  </p>
                )
              }
              {state.repaymentSuccess && (
                <p className="alert alert-success small">
                  {state?.repaymentSuccess}
                </p>
              )}
              {
                //@ts-ignore

                state?.error && (
                  <p className="alert alert-danger small">
                    {
                      //@ts-ignore
                      state?.error
                    }
                  </p>
                )
              }
              {state.repaymentError && (
                <p className="alert alert-danger small">
                  {state.repaymentError}
                </p>
              )}
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="font-weight-medium">Phone Number</label>
                      <input
                        name="phone number"
                        placeholder="Enter your phone number"
                        className="form-control"
                        // onChange={handlePhoneChange}
                        value={
                          props.data.borrower_details
                            ? props.data.borrower_details.personal_phone
                            : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="font-weight-medium">
                        Loan Reference
                      </label>
                      <input
                        name="loan_ref"
                        className="form-control"
                        value={props.data ? props.data.loan_ref : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="extra-info">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div>Borrower Name</div>
                    <div style={{ color: "#141414" }}>
                      {props.data.borrower_details
                        ? props.data.borrower_details.first_name
                        : ""}
                      {props.data.borrower_details
                        ? " " + props.data.borrower_details.last_name
                        : ""}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="font-weight-medium">Repayment Amount</label>
                  <input
                    type="number"
                    name="Amount"
                    defaultValue={state.totalAmount}
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                    placeholder="amount you wish to pay"
                  />
                </div>
              </form>
              <button
                className="btn advancly-btn btn-block mb-2"
                style={{ cursor: "pointer" }}
                data-toggle="modal"
                data-target="#MakeSingleRepaymentModal"
                onClick={() => {
                  setModalActionType("step1");
                }}
                disabled={state.repaymentLoading || !state.totalAmount}
              >
                Proceed {state.repaymentLoading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>

      <MakeSingleRepaymentModal
        modalActionType={modalActionType}
        state={state}
        aggregatorDetails={aggregatorDetails}
        submitRepayment={submitRepayment}
        finalSubmit={finalSubmit}
        dismissRef={dismissRef}
        repaymentLoading={state.repaymentLoading}
      />
    </div>
  );
}
