const loanApplicationStatus = {
  pendingSubmission: 1,
  relationshipManager: 2,
  creditReview: 3,
  returnedToCustomer: 4,
  returnedToRelationshipManager: 5,
  returnedToCreditAnalyst: 6,
  creditApprover: 7,
  declinedBycreditAnalyst: 8,
  approvedByCreditAnalyst: 9,
  declinedByCreditApprover: 10,
  approvedByCreditApprover: 11,
  sentToMCC: 12,
  declinedByMCC: 13,
  approvedByMCC: 14,
  offerLetterwithCustomer: 15,
  offerLetterDeclinedByCustomer: 16,
  offerLetterAcceptedByCustomer: 17,
  offerLetterExecutedByLegal: 18,
};

export default loanApplicationStatus;
