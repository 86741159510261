import { useState } from "react";
import styles from "./TermsOfUse.module.scss";
import TermsOfUseDecription from "./TermsOfUseDecription";

const Terms = () => {
  const [activeItem, setActiveItem] = useState("");
  const handleActiveClick = (refName: string) => {
    setActiveItem(refName);
  };
  return (
    <div className={styles.alignTermsPage}>
      <h3 className="page-title d-flex align-items-center">
        Terms and Conditions
      </h3>
      <div className={styles.termsUseSubContainer}>
        {/* Start of List items */}
        <div className={styles.termsUseContentList}>
          <ol>
            <li>
              <a
                href="#aboutAdvancly"
                className={
                  activeItem === "aboutAdvancly" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("aboutAdvancly")}
              >
                About Advancly
              </a>
            </li>
            <li>
              <a
                href="#terms"
                className={activeItem === "terms" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("terms")}
              >
                Terms
              </a>
            </li>
            <li>
              <a
                href="#aboutus"
                className={activeItem === "aboutus" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("aboutus")}
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#theAgreement"
                className={
                  activeItem === "theAgreement" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("theAgreement")}
              >
                The Agreement
              </a>
            </li>
            <li>
              <a
                href="#registration"
                className={
                  activeItem === "registration" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("registration")}
              >
                Registration
              </a>
            </li>
            <li>
              <a
                href="#userRepresentation"
                className={
                  activeItem === "userRepresentation" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("userRepresentation")}
              >
                User Representation
              </a>
            </li>
            <li>
              <a
                href="#ageRestriction"
                className={
                  activeItem === "ageRestriction" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("ageRestriction")}
              >
                Age Restriction{" "}
              </a>
            </li>
            <li>
              <a
                href="#identityAuthentication"
                className={
                  activeItem === "identityAuthentication"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("identityAuthentication")}
              >
                Identity Authentication
              </a>
            </li>
            <li>
              <a
                href="#assignment"
                className={
                  activeItem === "assignment" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("assignment")}
              >
                Assignment{" "}
              </a>
            </li>
            <li>
              <a
                href="#generalDisclaimer"
                className={
                  activeItem === "generalDisclaimer" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("generalDisclaimer")}
              >
                General Disclaimer
              </a>
            </li>
            <li>
              <a
                href="#communicationsAndNotices"
                className={
                  activeItem === "communicationsAndNotices"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("communicationsAndNotices")}
              >
                Communications and Notices to you
              </a>
            </li>
            <li>
              <a
                href="#consentElectronicTransactions"
                className={
                  activeItem === "consentElectronicTransactions"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() =>
                  handleActiveClick("consentElectronicTransactions")
                }
              >
                Consent to Electronic Transactions and Disclosures
              </a>
            </li>
            <li>
              <a
                href="#taxes"
                className={activeItem === "taxes" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("taxes")}
              >
                Taxes
              </a>
            </li>
            <li>
              <a
                href="#suspensionTerminationAccess"
                className={
                  activeItem === "suspensionTerminationAccess"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("suspensionTerminationAccess")}
              >
                Suspension or Termination of Access
              </a>
            </li>
            <li>
              <a
                href="#ownershipSiteContents"
                className={
                  activeItem === "ownershipSiteContents"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("ownershipSiteContents")}
              >
                Ownership of Site Contents{" "}
              </a>
            </li>
            <li>
              <a
                href="#copyrightsandTrademarks"
                className={
                  activeItem === "copyrightsandTrademarks"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("copyrightsandTrademarks")}
              >
                Copyright and Trademarks
              </a>
            </li>
            <li>
              <a
                href="#limitationsOfDamages"
                className={
                  activeItem === "limitationsOfDamages"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("limitationsOfDamages")}
              >
                Limitation of Damages
              </a>
            </li>
            <li>
              <a
                href="#disclaimer"
                className={
                  activeItem === "disclaimer" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("disclaimer")}
              >
                Disclaimer{" "}
              </a>
            </li>
            <li>
              <a
                href="#disputeResolution"
                className={
                  activeItem === "disputeResolution" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("disputeResolution")}
              >
                Dispute Resolution
              </a>
            </li>
            <li>
              <a
                href="#applicableLaw"
                className={
                  activeItem === "applicableLaw" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("applicableLaw")}
              >
                Applicable Law
              </a>
            </li>
            <li>
              <a
                href="#waiverandSeverability"
                className={
                  activeItem === "waiverandSeverability"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("waiverandSeverability")}
              >
                Waiver and Severability
              </a>
            </li>
            <li>
              <a
                href="#contactUs"
                className={activeItem === "contactUs" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("contactUs")}
              >
                Contact Us
              </a>
            </li>
            <li>
              <a
                href="#modifications"
                className={
                  activeItem === "modifications" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("modifications")}
              >
                Modifications
              </a>
            </li>
          </ol>
        </div>
        <TermsOfUseDecription />
      </div>
    </div>
  );
};

export default Terms;
