import { FC, Fragment } from "react";
import LoaderColored from "../Loader/LoaderColored";
import styles from "./DashboardCard.module.scss";

interface IDashboardCard {
  value: number | string;
  text: string;
  status: string;
  children: any;
  activeDefaultColor?: Boolean | string;
}

const DashboardCard: FC<IDashboardCard> = ({
  value,
  text,
  status,
  children,
}) => {
  return (
    <div
      className={` ${styles.DashboardCard_container} 
      `}
    >
      <div className="">
        {status === "loading" ? (
          <div className="d-flex justify-content-center align-items-center m-h-100">
            <LoaderColored />
          </div>
        ) : (
          <Fragment>
            <div className={`d-flex ${styles.DashboardCard_details}`}>
              <div> {children}</div>
              <div
                className={`${styles.DashboardCard_value} 

                
                ml-2`}
              >
                <p className={styles.label}>{text}</p>
                <p className={styles.value}>{value ? value : 0}</p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
