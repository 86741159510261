import React from "react";
import styles from "./WalletTermsUse.module.scss";
const WalletTermsUse = () => {
  return (
    <div className={styles.alignTermsPage}>
      <h3 className="page-title mb-4">Terms of Use</h3>
      <div className="mt-3">
        {/* <p>
          By using the wallet signatory feature, you agree to be bound by and to
          comply with the following Terms and Conditions.{" "}
        </p> */}
        <p>
          In Consideration of the Aggregator utilizing Wallet signatory feature,
          Aggregator hereby declare and covenant as follows;
        </p>
        <ol type="1" className="pl-3">
          <li>
            <div>
              The aggregator is fully aware that based on the preselected money
              transfer method chosen, the authorised signatories selected by
              Aggregator shall have access to transfer money from the
              Aggregator’s wallet into any chosen bank account provided by the
              authorised signatories.
            </div>
          </li>
          <li>
            <div>
              The aggregator is fully aware of the risk of loss of money, fraud
              and/or other unauthorised interventions by third parties which are
              inherent if the authorised signatories transfer money from the
              Aggregator wallet to a wrong or any bank account.
            </div>
          </li>
          <li>
            <div>
              In the case of any fraudulent or unauthorised transfer of money
              from the aggregator wallet by the authorised signatories,
              Aggregator fully indemnifies Advancly against any loss of money
              incurred via such transfers as Advancly shall not be liable.
            </div>
          </li>
          <li>
            <div>
              The aggregator is fully aware and consents that such affected
              transactions as referred to and described shall be irreversible.
            </div>
          </li>
          <li>
            <div>
              In the event of loss of monies by the aggregator or its wallet
              authorised signatories, the aggregator undertakes to repay all
              monies disbursed as loans within the agreed repayment date(s).
            </div>
          </li>
          <li>
            <div>
              The Aggregator and its authorised signatories covenant that it has
              taken steps to ensure the security of its account password,
              transaction PIN, secret question, OTP code, email addresses and
              electronic devices, and hereby authorise the ADVANCLY to give
              effect to transfer instructions emanating from the authorised
              signatories
            </div>
          </li>
          <li>
            <div>
              The aggregator indemnifies ADVANCLY, its officers, staff and
              affiliates and keeps ADVANCLY indemnified against all litigations,
              actions and proceedings for which ADVANCLY may be liable or losses
              which may be suffered by ADVANCLY or claims which may be incurred
              or made against ADVANCLY as a result of or pursuant to ADVANCLY
              consenting to the aggregator's request and for carrying out any
              instruction(s) received from aggregator and/or its authorised
              signatories to the wallet.
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default WalletTermsUse;
