import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useAllCustomerWallets from "../../custom-hooks/useAllCustomerWallets";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../PageLoader/PageLoader.component";
import Paginator from "../Paginator/Paginator.component";
interface IprofileDetails {
  customer_id: number;
}
const CustomerWallet = ({
  match,
  profileDetails,
}: {
  match: any;
  profileDetails: IprofileDetails;
}) => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    consumerType: "Customer",
    targetId: profileDetails.customer_id,
  });

  const [count, setCount] = useState<any>(null);
  const { data, status, error } = useAllCustomerWallets(preState);
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="">
            {status === "loading" ? (
              <div className="">
                <PageLoader />
              </div>
            ) : (
              <div className="">
                <div className="table-responsive">
                  <table className="table-style">
                    <thead className="">
                      <tr>
                        <th className="th-td-style th-style ">S/N</th>
                        <th className="th-td-style th-style ">Account Name</th>
                        <th className="th-td-style th-style ">
                          Account Number
                        </th>
                        <th className="th-td-style th-style ">Bank Name</th>
                        <th className="th-td-style th-style ">Currency</th>
                        <th className="th-td-style th-style ">
                          Is Wallet Default
                        </th>

                        <th className="th-td-style th-style ">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.data ? (
                        data?.data?.map(
                          (
                            request: {
                              accountNumber: string;
                              bankName: string;
                              currency: string;
                              walletId: number;
                              accountName: string;
                              isDefault: boolean;
                            },
                            _id: number
                          ) => {
                            const {
                              accountNumber,
                              bankName,
                              currency,
                              walletId,
                              accountName,
                              isDefault,
                            } = request;
                            return (
                              <tr key={_id}>
                                <td className="th-td-style ">{_id + 1}</td>
                                {/* <td>{walletId}</td> */}
                                <td className="th-td-style ">{accountName}</td>
                                <td className="th-td-style ">
                                  {accountNumber}
                                </td>
                                <td className="th-td-style ">{bankName}</td>
                                <td className="th-td-style ">{currency}</td>
                                <td className="th-td-style ">
                                  {isDefault ? "True" : "False"}
                                </td>
                                {/* <td>{walletProvider}</td> */}

                                <td className="th-td-style ">
                                  <Link
                                    to={{
                                      pathname: `/wallet/customer/${walletId}`,
                                      state: {
                                        data: request,
                                        consumerType: preState.consumerType,
                                      },
                                    }}
                                    className="color-blue"
                                  >
                                    View Details
                                  </Link>
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
//@ts-ignore
export default withRouter(CustomerWallet);
