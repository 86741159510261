import React from "react";
import { formatMifosDate } from "../../helpers/formatter";
interface IeachRepayment {
  period: string;
  daysInPeriod: string;
  dueDate: Date;
  principalDue: number;
  obligationsMetOnDate: Date;
  principalLoanBalanceOutstanding: number;
  interestDue: number;
  // feeChargesDue,
  // penaltyChargesDue,
  totalDueForPeriod: number;
  totalPaidForPeriod: number;
  totalPaidInAdvanceForPeriod: number;
  totalPaidLateForPeriod: number;
  totalOutstandingForPeriod: number;
}
export default function EachPaidRepayment({
  data,
  currency,
}: {
  data: IeachRepayment;
  currency: string;
}) {
  const {
    period,
    daysInPeriod,
    dueDate,
    principalDue,
    obligationsMetOnDate,
    principalLoanBalanceOutstanding,
    interestDue,
    // feeChargesDue,
    // penaltyChargesDue,
    totalDueForPeriod,
    totalPaidForPeriod,
    totalPaidInAdvanceForPeriod,
    totalPaidLateForPeriod,
    totalOutstandingForPeriod,
  } = data;
  return (
    <tr>
      <td>{period}</td>
      <td>{daysInPeriod}</td>
      <td style={{ width: "20%" }}>{formatMifosDate(dueDate)}</td>
      <td style={{ width: "20%" }}>{formatMifosDate(obligationsMetOnDate)}</td>
      <td>{currency}</td>
      <td>{principalDue}</td>
      <td>{principalLoanBalanceOutstanding}</td>
      <td>{interestDue}</td>
      {/* <td>{feeChargesDue}</td> */}
      {/* <td>{penaltyChargesDue}</td> */}
      <td>{totalDueForPeriod}</td>
      <td>{totalPaidForPeriod}</td>
      <td>{totalPaidInAdvanceForPeriod}</td>
      <td>{totalPaidLateForPeriod}</td>
      <td>{totalOutstandingForPeriod}</td>
    </tr>
  );
}
