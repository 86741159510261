import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IuseDisbursementDetails {
  searchValue?: string;
  page: number;
  PageSize: number;
  fromdate?: string;
  todate?: string;
  loanStatus?: string;
  loanReferenceNo?: string;
  name?: string;
}
const getDisbursementDetails = async ({
  searchValue,
  page,
  PageSize,
  fromdate,
  todate,
  loanReferenceNo,
  loanStatus,
  name,
}: IuseDisbursementDetails) => {
  let queryParams = `?page=${page}&PageSize=${PageSize}`;
  if (fromdate) {
    queryParams = `${queryParams}&fromdate=${fromdate}`;
  }
  if (todate) {
    queryParams = `${queryParams}&todate=${todate}`;
  }

  if (searchValue) {
    if (
      searchValue === "date_range" ||
      searchValue === "loan_status" ||
      searchValue === "loanReferenceNo" ||
      searchValue === "name"
    ) {
      queryParams = `${queryParams}`;
    } else {
      queryParams = `${queryParams}&searchvalue=${searchValue}`;
    }
  }
  if (loanStatus) {
    queryParams = `${queryParams}&loanStatus=${loanStatus}`;
  }
  if (name) {
    queryParams = `${queryParams}&name=${String(name.toLowerCase())}`;
  }
  if (loanReferenceNo) {
    queryParams = `${queryParams}&loanReferenceNo=${loanReferenceNo}`;
  }

  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllDisbursement}${queryParams}`
  );
  return data;
};

export default function useDisbursementDetails({
  searchValue,
  page,
  PageSize,
  fromdate,
  todate,

  loanReferenceNo,
  loanStatus,
  name,
}: IuseDisbursementDetails) {
  return useQuery(
    [
      {
        searchValue,
        page,
        PageSize,
        fromdate,
        todate,
        loanStatus,
        loanReferenceNo,
        name,
      },
      "getDisbursementDetails",
    ],
    getDisbursementDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
