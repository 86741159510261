import React from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
interface ILoanOfferDocumentViewer {
  isLoadingDocument: boolean;
  closeDocumentModal: () => void;
  documentData: { fileName: string; base64Url: string };
}
const LoanViewDocument = ({
  isLoadingDocument,
  closeDocumentModal,
  documentData,
}: ILoanOfferDocumentViewer) => {
  return (
    <div
      className="modal"
      id="loanViewModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="loanViewModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-lg" role="document">
        <div
          className="modal-content cursor-pointer"
          style={{ height: "100dvh", width: "55dvw" }}
        >
          <button
            type="button"
            className={`d-flex justify-content-end close p-3`}
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeDocumentModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div className="modal-body cursor-pointer">
            {isLoadingDocument ? (
              <PageLoader />
            ) : (
              <object
                style={{
                  objectFit: "contain",
                }}
                aria-labelledby={documentData.fileName}
                width="100%"
                height="100%"
                data={`data:application/pdf;base64,${documentData?.base64Url}`}
                type="application/pdf"
              ></object>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanViewDocument;
