import styles from "./ErrorHandler.module.scss";

const ErrorHandler = ({ errors }: { errors: any }) => {
  return (
    <div className="exclamation">
      {errors && (
        <>
          {(() => {
            switch (errors) {
              case errors.type === "required":
                return (
                  <span className={styles.errorText}>
                    {errors.message ? errors.message : "This field is required"}
                  </span>
                );
              case errors.type === "maxLength" || errors.type === "max":
                return (
                  <span className={styles.errorText}>
                    {errors.message
                      ? errors.message
                      : "Your input exceeded the maximum length"}
                  </span>
                );
              case errors.type === "minLength" || errors.type === "min":
                return (
                  <span className={styles.errorText}>
                    {errors.message
                      ? errors.message
                      : "Your input is not up to the minimum length"}
                  </span>
                );
              case errors.type === "matches":
                return (
                  <span className={styles.errorText}>{errors.message}</span>
                );
              case errors.type === "typeError":
                return (
                  <span className={styles.errorText}>
                    Invalid value entered
                  </span>
                );
              default:
                return (
                  <span className={styles.errorText}>
                    {errors.message
                      ? errors.message
                      : "The value entered into this field is invalid"}
                  </span>
                );
            }
          })()}
        </>
      )}
    </div>
  );
};

export default ErrorHandler;
