import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import useGeneratedAggregatorKey from "../../custom-hooks/useGeneratedAggregatorKey";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import EditButton from "../../svg/icons/edit-button.svg";
import { ReactComponent as DummyUser } from "../../svg/icons/user-fill.svg";
import styles from "./BusinessInfo.module.scss";
import { convertToBase64 } from "../../helpers/convertToBase64";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useAuth } from "../../Context/auth.context";
//@ts-ignore
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryCodes from "../../custom-hooks/useCountryCodes";
import { appInsights } from "../AppInsight/AppInsight";
import { useForm } from "react-hook-form";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";

const BusinessInfo = () => {
  const { aggregatorDetails } = useAuth();
  const [error, setError] = useState<boolean | string>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [imgBase64, setImgBase64] = useState<string | unknown>("");

  const photoRef = useRef<HTMLInputElement | null>(null);
  const { register, setValue } = useForm();
  const {
    status,
    data,
    error: publicKeyError,
  } = useGeneratedAggregatorKey({
    aggregator_id: aggregatorDetails?.aggregator_id,
  });
  const fileSelected = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImgBase64(await convertToBase64(e.target.files[0]));
    }
  };

  useEffect(() => {
    if (aggregatorDetails?.biz_name) {
      setValue("bname", aggregatorDetails?.biz_name);
    }
    if (aggregatorDetails?.biz_phone) {
      setValue("bphone", aggregatorDetails?.biz_phone);
    }
    if (aggregatorDetails?.biz_email) {
      setValue("bemail", aggregatorDetails?.biz_email);
    }
    if (aggregatorDetails?.biz_address) {
      setValue("address", aggregatorDetails?.biz_address);
    }
    if (aggregatorDetails?.country_name) {
      setValue("countryCode", aggregatorDetails?.country_name);
    }
    if (aggregatorDetails?.short_name) {
      setValue("shortName", aggregatorDetails?.short_name);
    }
    if (data?.public_key) {
      setTimeout(() => {
        setValue("key", data?.public_key);
      }, 3000);
    }
  }, [aggregatorDetails, data, setValue]);

  const handleFileUpload = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setMessage(null);

    const formElements = e.target as HTMLFormElement;
    const fd = new FormData(formElements);
    fd.append("aggregator_id", aggregatorDetails?.aggregator_id);

    try {
      const res = await postDataWithDotNet(
        apiEndpoints.updateAggregatorUserPhoto,
        fd,
        true
      );
      setMessage(res.message);
      setLoading(false);
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "BusinessInfo.component.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const { data: countries } = useCountryCodes();

  const resolveCountryInfo = countries?.find(
    ({ country_code }: { country_code: string }) =>
      country_code === aggregatorDetails?.country_code
  );

  if (publicKeyError && !error) {
    setError(errorHandler(publicKeyError));
  }
  return (
    <div className="advancly-wrapper-container">
      <div className="advancly-sub-wrapper">
        {message && <p className="alert alert-success small">{message}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        <div className={`${styles.alignBusinessInfo} `}>
          <form onSubmit={handleFileUpload}>
            <input
              type="file"
              onChange={fileSelected}
              id="profilePhoto"
              ref={photoRef}
              accept="image/*"
              className="d-none"
              name="aggregator_photo"
            />
            <div className="row">
              <div className={` ${styles.profilePhoto} col-12 col-md-6 pt-1 `}>
                {aggregatorDetails?.aggregator_photo_url ? (
                  <div
                    className={`d-flex justify-content-start align-items-center`}
                  >
                    <img
                      src={aggregatorDetails?.aggregator_photo_url}
                      alt="Logo"
                      onClick={() => photoRef.current?.click()}
                      className={styles.profileImage}
                    />
                    <div className="pl-1">
                      <h5 className={`${styles.usernameHeading}`}>
                        {aggregatorDetails?.biz_name}
                      </h5>
                      <p className={`${styles.usernameHandle}`}>
                        @{aggregatorDetails?.short_name}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`d-flex justify-content-start align-items-center`}
                  >
                    <DummyUser className={styles.profileImage} />
                  </div>
                )}
                <img src={EditButton} className={styles.editButton} alt="" />
              </div>
              <div className="col-12 col-md-6 pt-1">
                <CustomInputField
                  ref={register}
                  type="text"
                  name="bname"
                  label="Business name"
                  readOnly
                />
              </div>
              <div className="col-12 col-md-2 pt-1">
                <CustomSelectDropdown
                  name="countrycode"
                  ref={register}
                  label="Dial Code"
                  disabled
                >
                  {aggregatorDetails?.country_code && (
                    <option value="">
                      {getUnicodeFlagIcon(aggregatorDetails?.country_code)}{" "}
                      {resolveCountryInfo?.dial_code}
                    </option>
                  )}
                </CustomSelectDropdown>
              </div>
              <div className="col-12 col-md-4 pt-1">
                <CustomInputField
                  ref={register}
                  label="Business Phone"
                  name="bphone"
                  type="number"
                  readOnly
                ></CustomInputField>
              </div>

              <div className="col-12 col-md-6 pt-1">
                <CustomInputField
                  ref={register}
                  label="Business Email"
                  name="bemail"
                  type="email"
                  readOnly
                  validateEmailInput
                />
              </div>
              <div className="col-12 col-md-6 pt-1">
                <CustomInputField
                  ref={register}
                  label="Business Address"
                  name="address"
                  type="text"
                  readOnly
                />
              </div>

              <div className="col-12 col-md-6 pt-1">
                <CustomInputField
                  ref={register}
                  label="Country"
                  name="countryCode"
                  type="text"
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6 pt-1">
                <CustomInputField
                  ref={register}
                  label="Short Name"
                  name="shortName"
                  type="text"
                  readOnly
                />
              </div>
              <div className="col-12 col-md-6  pt-1">
                <CustomInputField
                  ref={register}
                  label="Public Key"
                  name="key"
                  type="text"
                  readOnly
                  extraLabel={status === "loading" && "(Loading public key...)"}
                />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-start mt-3 flex-wrap">
                  <button
                    type="button"
                    disabled={loading || !imgBase64}
                    className="btn advancly-nobg-btn mr-4  transition-3d-hover"
                    onClick={() => setImgBase64("")}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loading || !imgBase64}
                    className="btn advancly-btn transition-3d-hover"
                  >
                    Save Changes {loading && <Loader />}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
