import React, { useState } from "react";
import ToggleTab from "../../../../../components/ToggleTab/ToggleTab.component";
import style from "../../../../../components/Comments/comments.module.scss";

const CommentsBoardOfDirectorDocsModal = ({ fileComments }: any) => {
  const [activeTab, setActiveTab] = useState("comments");

  return (
    <div
      id="commentsBoardOfDirectorsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="commentsBoardOfDirectorsLabel"
      aria-hidden="true"
      className="modal fade "
    >
      {" "}
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <div className="w-100">
              <div className="alignToggleTabItems centralize ">
                <ToggleTab
                  text="comments"
                  id="comments"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </div>
          <div className="modal-body mb-0 mt-0">
            {(() => {
              switch (activeTab) {
                case "comments":
                  return (
                    <div className={style.commentsContainer}>
                      <div className={style.commentsSection}>
                        {fileComments}
                      </div>
                    </div>
                  );

                default:
                  return;
              }
            })()}
          </div>
          <button
            type="button"
            className=" advancly-btn p-2 mb-2 w-70 m-1"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Close
          </button>
        </div>
      </div>
      {/* end of modal dialog */}
    </div>
  );
};

export default CommentsBoardOfDirectorDocsModal;
