import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

type TPendingDealSlipParam = {
  Page: number;
  PageSize: number;
  Type: string;
};

const getPendingDealSlipList = async ({
  Page,
  PageSize,
  Type,
}: TPendingDealSlipParam) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}`;
  if (Type) {
    queryParams = `${queryParams}&Type=${Type}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.PENDING_DEAL_SLIP_LIST}${queryParams && queryParams}`
  );
  return data;
};

export default function usePendingDealSlipList({
  Page,
  PageSize,
  Type,
}: TPendingDealSlipParam) {
  return useQuery(
    [{ Page, PageSize, Type }, "getPendingDealSlipList"],
    getPendingDealSlipList,
    {
      refetchOnWindowFocus: false,
    }
  );
}
