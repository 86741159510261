import React from "react";

const LoanRequestDisbursement = () => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div>
          <h3 className="page-title ">Disburse Loans</h3>
        </div>
      </div>
    </div>
  );
};

export default LoanRequestDisbursement;
