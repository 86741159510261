import documentationCode from "../../helpers/documentationCode";
import CopyButton from "../../components/CopyButton/CopyButton.component";
import ContactForm from "../../components/ContactForm/ContactForm.component";
import styles from "./ApiAndWidget.module.scss";

const ApiAndWidget = () => {
  return (
    <div className="container">
      <h3 className="page-title">Widget</h3>
      <p>
        Here is a sample code that calls the advancly borrower’s widget. You can
        copy and paste this code to any page you want the widget to be used.
      </p>
      <div className="card p-3">
        <div className={styles.widgetWrapper}>
          <div className="d-flex justify-content-between mb-3 flex-wrap align-items-center">
            <h5 className="page-subtitle">Advancly Borrower Widget</h5>
            <div>
              <button
                type="button"
                className="btn advancly-off-btn btn-sm mr-3 transition-3d-hover"
                data-toggle="modal"
                data-target="#contactForm"
              >
                Contact Support
              </button>
              <CopyButton value={documentationCode} />
            </div>
          </div>
          <pre className="doc-page">{documentationCode}</pre>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="contactForm"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="contactFormLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className={`modal-content ${styles.modalContent}`}>
            <div className="modal-body">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiAndWidget;
