import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import styles from "../LoanApplication.module.scss";
import { loanRequestSchema } from "../../../helpers/loanRequestSchema";
import { useForm } from "react-hook-form";
import Loader from "../../Loader/Loader.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData, putData } from "../../../apis/apiMethods";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import LoanInformationExistingList from "./LoanInformationExistingList";
import useLoanProductDetails from "../../../custom-hooks/useLoanProductDetail";
import LoaderColored from "../../Loader/LoaderColored";
import { IstepLoanApp } from "../LoanApplicationMain";
import { handleFormatAmountNoCurrency } from "../../../helpers/formatterNoDecimal";
import { useAuth } from "../../../Context/auth.context";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
import { decrypt, encrypt } from "../../../helpers/encryptor";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";
import { formatMoney } from "../../../helpers/formatter";
const schema = yup.object().shape({
  loanAmount: loanRequestSchema.loanAmount,
  interestRate: loanRequestSchema.interestRate,
  loanProduct: loanRequestSchema.productId,
  loanTenure: loanRequestSchema.loanTenure,
  sourceOfRepayment: loanRequestSchema.sourceOfRepayment,
});
interface IreqBody {
  loanAmount: string;
  currency: string;
  loanProduct: string;
  loanTenure: string;
  interestRate: string;
  sourceOfRepayment: string;
}

const LoanInformation = ({ handleNext, scrollToTop }: IstepLoanApp) => {
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);

  const [AddMoreInformationLoading, setAddMoreInformationLoading] =
    useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [editLoanInformation, setEditLoanInformation] = useState(false);
  const [loanTenureMax, setLoanTenureMax] = useState(null);
  const [editLoanDetails, setEditLoanDetails] = useState<any>();
  const [proposedLoanProductId, setProposedLoanProductId] = useState<
    number | null
  >(null);

  const [amountValue, setAmountValue] = useState("");
  const { register, handleSubmit, errors, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    currentLoanApplicationId,
    editLoanApplicationGlobal,
    setEditLoanApplicationGlobal,
    setCurrentLoanApplicationId,
  } = useAuth();

  const {
    data: loanProductDetailsData,
    status: loanProductDetailsStatus,
    error: loanProductDetailsErrors,
  } = useLoanProductDetails();

  const {
    data: existingLoanInformationData,
    status: existingLoanInformationStatus,
    error: existingLoanInformationError,
  } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const productHandlerId = (e: any) => {
    setProposedLoanProductId(null);

    setProductId(e.target.value);
  };

  const loanProductFindFees = loanProductDetailsData?.find((product: any) => {
    if (proposedLoanProductId) {
      return Number(product?.id) === Number(proposedLoanProductId);
    } else {
      return Number(product?.id) === Number(productId);
    }
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;
  useEffect(() => {
    if (productId) {
      const product = loanProductDetailsData?.find(
        (eachProduct: { id: number }) =>
          Number(eachProduct.id) === Number(productId) && eachProduct
      );
      setValue("loanTenure", product?.maximum_tenor);
      setLoanTenureMax(product?.maximum_tenor);
      setValue("interestRate", product?.maximum_interest_rate);
    }
  }, [productId, loanProductDetailsData, setValue]);
  useEffect(() => {
    const storedCurrentId = decrypt(sessionStorage.getItem("currentLoanAppId"));
    const storedEditLoanApplicationGlobal = decrypt(
      sessionStorage.getItem("globalEditLoanApplication")
    );
    if (storedCurrentId) {
      setCurrentLoanApplicationId(storedCurrentId);
    }
    if (storedEditLoanApplicationGlobal) {
      setEditLoanApplicationGlobal(storedEditLoanApplicationGlobal);
    }
  }, [setCurrentLoanApplicationId, setEditLoanApplicationGlobal]);

  useEffect(() => {
    if (editLoanDetails) {
      setValue("loanAmount", editLoanDetails?.proposed_amount);
      setValue("loanTenure", editLoanDetails?.proposed_tenor);
      setValue("interestRate", editLoanDetails?.proposed_interest_rate);
      setValue("sourceOfRepayment", editLoanDetails?.repayment_source);

      setTimeout(() => {
        setValue(
          "loanProduct",
          editLoanDetails?.proposed_loan_product_detail_id
        );
      }, 2000);
    }
  }, [editLoanDetails, setValue]);

  const resetAmountValue = () => {
    setAmountValue("");
  };
  const onAddLoanInformationHandler = async (data: IreqBody) => {
    const {
      loanAmount,
      loanProduct,
      loanTenure,
      sourceOfRepayment,
      interestRate,
    } = data;
    const ReqBody = {
      loan_application_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),

          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
          repayment_source: sourceOfRepayment,
        },
      ],
    };
    const EditReqBody = {
      id: currentLoanApplicationId,

      loan_application_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),

          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
          repayment_source: sourceOfRepayment,
          id: editLoanDetails?.id,
        },
      ],
    };
    try {
      setAddMoreInformationLoading(true);
      setError(null);
      if (editLoanInformation && editLoanApplicationGlobal) {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATIONS,
          EditReqBody,
          true
        );
        setAddMoreInformationLoading(false);
        setSuccess(res?.message);

        setEditLoanInformation(false);

        if (scrollToTop) {
          scrollToTop();
        }

        setTimeout(() => {
          setSuccess(null);
        }, 2000);
      } else {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setAddMoreInformationLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);
        sessionStorage.setItem(
          "currentLoanAppId",
          encrypt(res?.data?.loan_application_id)
        );
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setSuccess(null);
          reset();
          resetAmountValue();
        }, 2000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "LoanInformation.tsx",
        },
      });

      setAddMoreInformationLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const onContinueLoanAmountHandler = async (data: IreqBody) => {
    const {
      loanAmount,
      loanProduct,
      loanTenure,
      sourceOfRepayment,
      interestRate,
    } = data;
    const ReqBody = {
      loan_application_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),
          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
          repayment_source: sourceOfRepayment,
        },
      ],
    };

    const EditReqBody = {
      id: currentLoanApplicationId,

      loan_application_details: [
        {
          proposed_loan_product_detail_id: Number(loanProduct),
          proposed_amount: +handleFormatAmountNoCurrency(String(loanAmount)),

          proposed_interest_rate: Number(interestRate),
          proposed_tenor: Number(loanTenure),
          repayment_source: sourceOfRepayment,
          id: editLoanDetails?.id,
        },
      ],
    };
    try {
      setLoading(true);
      setError(null);
      if (editLoanInformation && editLoanApplicationGlobal) {
        const res = await putData(
          apiEndpoints.LOAN_APPLICATIONS,
          EditReqBody,
          true
        );
        setLoading(false);
        setSuccess(res?.message);

        if (scrollToTop) {
          scrollToTop();
        }
        if (handleNext) {
          setTimeout(() => {
            handleNext();
          }, 2000);
        }
        setEditLoanInformation(false);
        setTimeout(() => {
          reset();
          setSuccess(null);
        }, 2000);
      } else {
        const res = await postData(
          apiEndpoints.LOAN_APPLICATIONS,
          ReqBody,
          true
        );
        setLoading(false);
        setSuccess(res?.message);
        setCurrentLoanApplicationId(res?.data?.loan_application_id);

        sessionStorage.setItem(
          "currentLoanAppId",
          encrypt(res?.data?.loan_application_id)
        );

        if (scrollToTop) {
          scrollToTop();
        }
        if (handleNext) {
          setTimeout(() => {
            handleNext();
          }, 2000);
        }
        setTimeout(() => {
          setSuccess(null);
          reset();
        }, 2000);
      }
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "LoanInformation.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  return (
    <div className={`${styles.center_container} `}>
      <div className="py-3">
        <h2 className={`${styles.loanInformationHeading}`}>Loan Information</h2>
        <p className={`${styles.InfoParagraph}`}>
          Update the information stated below
        </p>
      </div>
      <div>
        {loanProductDetailsErrors && (
          <p className="alert alert-danger small">
            {errorHandler(loanProductDetailsErrors)}
          </p>
        )}

        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
      </div>
      <LoanInformationExistingList
        setEditLoanInformation={setEditLoanInformation}
        setEditLoanDetails={setEditLoanDetails}
        setProposedLoanProductId={setProposedLoanProductId}
        existingLoanInformationData={existingLoanInformationData}
        existingLoanInformationStatus={existingLoanInformationStatus}
        existingLoanInformationError={existingLoanInformationError}
      />

      {!LoanApplicationEditOrWrite && (
        <form>
          <div className="row">
            <div className="col-12">
              <CustomInputField
                type="number"
                ref={register}
                name="loanAmount"
                value={amountValue}
                placeholder="E.g 240,000,000,000"
                label="Amount"
                errors={errors.loanAmount}
                enableSeparator
                onChange={(values: any) => {
                  const { formattedValue } = values;
                  setAmountValue(formattedValue);
                }}
              ></CustomInputField>
            </div>
            <div className="col-12">
              {loanProductFindFees?.fees?.map((feeProperties: any) => {
                const { id, fee, value, fee_calculation_type } = feeProperties;
                const feeName = fee;
                const feeValue =
                  fee_calculation_type === "Value"
                    ? formatMoney(value, loanProductFindFees?.currency)
                    : `${value}%`;

                return (
                  <span
                    className="text-danger text-uppercase "
                    key={id}
                  >{` ${feeName} : ${feeValue},`}</span>
                );
              })}
              <CustomSelectDropdown
                ref={register}
                name="loanProduct"
                label="Loan Product"
                errors={errors.loanProduct}
                onChange={productHandlerId}
              >
                <option value="">Select Product</option>
                {loanProductDetailsData &&
                  loanProductDetailsData?.length &&
                  loanProductDetailsData?.map(
                    ({ id, name }: { id: number; name: string }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    )
                  )}
              </CustomSelectDropdown>
              {loanProductDetailsStatus === "loading" && (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              )}
            </div>
            <div className="col-12">
              <CustomInputField
                type="number"
                maxLength={3}
                min={10}
                max={loanTenureMax}
                ref={register}
                name="loanTenure"
                placeholder="E.g 230"
                label="Tenor"
                extraLabel="(in days)"
                showRequiredIcon={true}
                errors={errors.loanTenure}
              />
            </div>
            <div className="col-12 ">
              <CustomInputField
                type="number"
                maxLength={5}
                step="any"
                ref={register}
                label=" Interest Rate"
                name="interestRate"
                errors={errors.interestRate}
                extraLabel="(in %)"
                showRequiredIcon={true}
                readOnly={productId || editLoanDetails ? true : false}
              />
            </div>
            <div className="col-12">
              <CustomTextArea
                ref={register}
                name="sourceOfRepayment"
                label="Source of Repayment"
                errors={errors.sourceOfRepayment}
                showRequiredIcon={true}
              />
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="fa fa-plus  advancly-text-btn my-3"
                onClick={handleSubmit(onAddLoanInformationHandler)}
                disabled={AddMoreInformationLoading}
              >
                <span className={` pl-1`}>
                  Add another Loan Information{" "}
                  {AddMoreInformationLoading && <LoaderColored />}
                </span>
              </button>
            </div>
            <div className={`col-12`}>
              <button
                type="submit"
                className="advancly-btn  transition-3d-hover m-2"
                onClick={handleSubmit(onContinueLoanAmountHandler)}
                disabled={loading}
              >
                Save and Continue
                {loading && <Loader />}
              </button>
            </div>
          </div>
        </form>
      )}
      {LoanApplicationEditOrWrite && (
        <button
          className="btn advancly-white-btn transition-3d-hover m-2 "
          type="button"
          onClick={handleNext}
        >
          Next
        </button>
      )}
    </div>
  );
};

export default LoanInformation;
