import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import useAllABorrowerLoans from "../../custom-hooks/useAllABorrowerLoans";
import EachLoanRow from "../EachLoanRow/EachLoanRow.component";
import Loader from "../Loader/Loader.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import { ReactComponent as SearchIconGrey } from "../../svg/icons/search-icon-grey.svg";
const AllABorrowerLoans = ({
  profileDetails,
  match,
}: {
  profileDetails: any;
  match: any;
}) => {
  const {
    data: loans,
    status,
    error,
  } = useAllABorrowerLoans({
    id: match.params.borrower_id,
  });

  if (status === "loading")
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;
  return (
    <Fragment>
      {/* <!-- Search --> */}
      <div className="js-focus-state input-group input-group-sm my-3 w-50">
        <div className="input-group-prepend">
          <span className="input-group-text" id="searchDisbursementTable">
            <span className="">
              {" "}
              <SearchIconGrey />
            </span>
          </span>
        </div>
        <input
          id="disbursementTableSearch"
          type="email"
          className="form-control"
          placeholder="Search transactions"
          aria-label="Search activities"
          aria-describedby="searchDisbursementTable"
          style={{ borderLeft: "none" }}
        />
      </div>
      {/* <!-- End Search --> */}
      {/* <!-- Disbursements Table --> */}
      <div className="table-responsive">
        <table className="table-style">
          <thead className="">
            <tr>
              <th className="th-td-style th-style ">Reference</th>
              <th className="th-td-style th-style ">Borrower</th>
              <th className="th-td-style th-style ">Amount</th>
              <th className="th-td-style th-style ">Tenure</th>
              <th className="th-td-style th-style ">Interest</th>
              <th className="th-td-style th-style ">Outstanding</th>
              <th className="th-td-style th-style ">Due Date</th>
              <th className="th-td-style th-style ">Status</th>
            </tr>
          </thead>
          <tbody>
            {loans?.length > 0 ? (
              loans?.map((data: unknown, index: number) => {
                return (
                  <EachLoanRow
                    data={data}
                    key={index}
                    profileDetails={profileDetails}
                  />
                );
              })
            ) : (
              <NoTableDataAvailable />
            )}
          </tbody>
        </table>
      </div>
      {/* <!-- End Disbursements Table --> */}
    </Fragment>
  );
};
//@ts-ignore
export default withRouter(AllABorrowerLoans);
