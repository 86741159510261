import React, { Fragment } from "react";
import EachPaidRepayment from "../EachPaidRepayment/EachPaidRepayment.component";
import Loader from "../Loader/Loader.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";

export default function ABorrowerSingleLoanRepayments({
  paidRepayments,
  currency,
  loadingMifos,
  error,
}: {
  paidRepayments: any;
  currency: string;
  loadingMifos: boolean;
  error: string;
}) {
  if (loadingMifos)
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  if (error)
    return <h5 className="alert alert-danger small">An error occured</h5>;
  return (
    <Fragment>
      <div className="table-responsive my-4">
        <table className="table mb-5">
          <thead className="bg-lighter-grey">
            <tr>
              <th>#</th>
              <th>Days</th>
              <th>Date</th>
              <th>Paid Date</th>
              <th>Currency</th>
              <th>Principal Due</th>
              <th>Balance of Loan</th>
              <th>Interest</th>
              {/* <th>Fees</th> */}
              {/* <th>Penalties</th> */}
              <th>Due</th>
              <th>Paid</th>
              <th>In Advance</th>
              <th>Late</th>
              <th>Outstanding</th>
            </tr>
          </thead>
          <tbody className="font-size-1">
            {paidRepayments && paidRepayments.length > 0 ? (
              paidRepayments?.map((data: any, index: number) => {
                return (
                  <EachPaidRepayment
                    data={data}
                    key={index}
                    currency={currency}
                  />
                );
              })
            ) : (
              <NoTableDataAvailable />
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
}
