// const masterurl = "https://advancly-api-master.test.vggdev.com/api/v1";
// const master2url = "https://advancly-api-master2.test.vggdev.com/api/v1";
// const master3url = "https://advancly-api-master3.test.vggdev.com/api/v1";
//  const sprint1url=  "https://advancly-api-sprint.test.vggdev.com/api/v1"
// const sprint2url = "https://advancly-api-sprint2.test.vggdev.com/api/v1";
// const sprint3url = "https://advancly-api-sprint3.test.vggdev.com/api/v1";
const master3stagingUrl =
  "https://advancly-api-master3.staging.vggdev.com/api/v1";
// const masterstagingUrl =
//   "https://advancly-api-master.staging.vggdev.com/api/v1";
// const stagingUrl = "https://advancly-aggregator.staging.vggdev.com/api/v1";

// const productionUrl = "https://api.advancly.com/api/v1"

const baseUrl =
  process.env.NODE_ENV === "development"
    ? master3stagingUrl
    : window.env.baseUrl2;

const sandboxBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://advancly-api-sprint-sandbox.test.vggdev.com/api/v1"
    : window.env.sandboxBaseUrl;

const APPINSIGHTS_KEY_SPRINT1 = "f61e284d-e994-43fe-a260-57fa5ebf3ceb";
const APPINSIGHTS_KEY_SPRINT2 = "3ad0b7b4-b995-4d57-961a-34a65fac24e3";
const APPINSIGHTS_KEY_SPRINT3 = "defbe183-0456-4597-b0d6-bf61bd7932e6";
const APPINSIGHTS_KEY_MASTER = "a843a9ae-838c-48b4-bffa-127b64518eeb";
const APPINSIGHTS_KEY_MASTER2 = "54c4a25d-df7c-4ca1-b652-eb77c0e1a4e3";
const APPINSIGHTS_KEY_STAGING = "1d1cf431-5f26-444f-a86e-8fe63964320c";
const APPINSIGHTS_KEY_PRODUCTION = "880dff61-8441-4cdd-8a59-87ccb415f404";

export const getAppInsightKey = () => {
  switch (window.env.baseUrl) {
    //Sprint 1
    case "https://advancly-api-sprint.test.vggdev.com/api/v1":
      return APPINSIGHTS_KEY_SPRINT1;
    //Sprint 2
    case "https://advancly-api-sprint2.test.vggdev.com/api/v1":
      return APPINSIGHTS_KEY_SPRINT2;
    // Sprint 3
    case "https://advancly-api-sprint3.test.vggdev.com/api/v1":
      return APPINSIGHTS_KEY_SPRINT3;
    // Master
    case "https://advancly-api-master.test.vggdev.com/api/v1":
      return APPINSIGHTS_KEY_MASTER;
    // Staging
    case "https://advancly-api-master.staging.vggdev.com/api/v1":
      return APPINSIGHTS_KEY_STAGING;
    //Prod
    case "https://api.advancly.com/api/v1":
      return APPINSIGHTS_KEY_PRODUCTION;
    // Using App Insight Master 2 for local development bcos 'window.env.baseUrl' returns undefined on local
    default:
      return APPINSIGHTS_KEY_MASTER2;
  }
};
const CONFIG = {
  BASE_URL: baseUrl,
  BASE_URL2: baseUrl,
  SANDBOX_BASE_URL: sandboxBaseUrl,

  APPINSIGHTS_KEY: getAppInsightKey(),
  BORROWER_HOST_NAME:
    process.env.NODE_ENV === "development"
      ? "advancly-borrower.test.vggdev.com"
      : window.env.borrowerHostName,
  ENCRYPTION_KEY:
    process.env.NODE_ENV === "development"
      ? "this_is_meant_to_be_a_very_secretive_P@55w0rd01"
      : window.env.encryptionKey,

  MIXPANEL_PROJECT_TOKEN:
    process.env.NODE_ENV === "development"
      ? "this_yryee_ssdfff_dgfgggg" // this is a dummy string
      : "this_yryee_ssdfff_dgfgggg",

  // window.env.mixpanelProjectToken,
};

export default CONFIG;
