import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
type IFunderPeople = {
  funderId: number;
  Type?: number | null;
};
const getShareholders = async ({ funderId, Type }: IFunderPeople) => {
  const data = await getData(
    `${apiEndpoints.SHAREHOLDER_ONBOARDING}?type=${Type}&funder_id=${funderId}`,
    true
  );
  return data?.data;
};

export default function useGetShareholders({ funderId, Type }: IFunderPeople) {
  return useQuery([{ funderId, Type }, "getShareholders"], getShareholders, {
    enabled: funderId || Type ? true : false,
    refetchOnWindowFocus: false,
  });
}
