import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useQueryCache } from "react-query";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { getData, putData } from "../../../apis/apiMethods";
import { useAuth } from "../../../Context/auth.context";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";
import PageLoader from "../../PageLoader/PageLoader.component";
import { ISignatoryName } from "./ChangeSingleSignatoryComponentPage";
import OtpInput from "react-otp-input";

interface IChangesignatoryModal3 {
  changeSingleSignatoryName: string | ISignatoryName[];
  agreementTick: string;
  setChangeSingleSignatory: Dispatch<SetStateAction<number>>;
  step: number;
  changeSingleSignatory: number;
  dismissRef: any;
}
const ChangeSingleSignatoryModal3 = ({
  changeSingleSignatoryName,
  agreementTick,
  setChangeSingleSignatory,
  step,
  changeSingleSignatory,
  dismissRef,
}: IChangesignatoryModal3) => {
  // const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otp, setOtp] = useState("");

  const [success, setSuccess] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>("");
  const { aggregatorDetails } = useAuth();
  const queryCache = useQueryCache();

  // const handleChange = (element: any, index: number) => {
  //   if (isNaN(element.value)) return false;
  //   setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

  //   //Focus next input
  //   if (element.nextSibling) {
  //     element.nextSibling.focus();
  //   }
  // };
  // const otpFinal = otp.join("").toString();

  const submitHandler = async () => {
    setLoading(true);
    setError(null);
    const reqBody = {
      transaction_signatories: changeSingleSignatoryName,
      otp: otp,
      accepted_terms_and_conditions: agreementTick,
    };
    try {
      const res = await putData(apiEndpoints.CHANGE_SIGNATORIES, reqBody);
      setLoading(false);
      setSuccess(true);
      setSuccessMessage(res.message);

      // Refetch Aggregator signatories Data
      queryCache.invalidateQueries("getAggregatorSignatories");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ChangeSingleSignatoryModal3.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const resetOTP = async (data: unknown) => {
    setLoading(true);
    setError(null);

    try {
      await getData(apiEndpoints.GENERATE_OTP);
      setLoading(false);
      return data;
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ChangeSingleSignatoryModal3.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  return (
    <div className="modal-content">
      <Fragment>
        {/* {error && <Alert message={error} />} */}
        {error && <p className="alert alert-danger small row">{error}</p>}
        {success ? (
          <div className="d-flex align-items-center justify-content-between flex-column">
            <i className="ri-checkbox-circle-fill text-success ri-5x"></i>
            <p className="mt-5 font-weight-bold">{successMessage}</p>
            <div className="col-12 pb-3">
              <button
                type="button"
                className="btn btn-sm advancly-btn  w-100 "
                data-dismiss="modal"
                onClick={() => {
                  setChangeSingleSignatory(2);
                }}
              >
                Done
              </button>
            </div>
          </div>
        ) : (
          <Fragment>
            {loading ? (
              <div className="p-5">
                <PageLoader />
              </div>
            ) : (
              <Fragment>
                <div className="modal-header">
                  <h4 className="modal-title">OTP</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    ref={dismissRef}
                    onClick={() => {
                      setChangeSingleSignatory(2);
                    }}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <small
                    className="color-mid-blue text-right  row cursor-pointer font-weight-bold pl-2 "
                    onClick={() => {
                      setChangeSingleSignatory(changeSingleSignatory - step);
                    }}
                  >
                    &#8592; Go back
                  </small>
                  <p className="pt-2">
                    Enter the OTP that was sent to your email
                    <br />
                    <span className="color-mid-blue">
                      {aggregatorDetails?.rep_personal_email}
                    </span>
                  </p>
                  <div className="row pb-4 pt-4 mr-3">
                    {/* {otp.map((data, index) => {
                      return (
                        <input
                          className="otp-field  col ml-4 pt-2 pb-2 pl-2 pr-2 text-center"
                          type="text"
                          name="otp"
                          key={index}
                          maxLength={1}
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      );
                    })} */}
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <div className="text-right">
                    <small
                      className="color-mid-blue cursor-pointer font-weight-bold "
                      onClick={resetOTP}
                    >
                      Send OTP again?
                    </small>
                    <small
                      className="text-secondary cursor-pointer font-weight-bold pl-2"
                      // onClick={(e) => setOtp([...otp.map((v) => "")])}
                    >
                      Clear
                    </small>
                  </div>
                  <button
                    type="button"
                    className="btn advancly-btn btn-sm w-100 mt-3"
                    onClick={submitHandler}
                  >
                    Continue
                  </button>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default ChangeSingleSignatoryModal3;
