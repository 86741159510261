import Loader from "../Loader/Loader.component";

export default function TableDataLoading() {
  return (
    <tr>
      <td colSpan={100} className="text-center">
        <Loader />
      </td>
    </tr>
  );
}
