import React, { Fragment, useEffect, useState } from "react";
import WalletDisbursmentDeclineComment from "./WalletDisbursmentDeclineComment";
import usePendingDisbursment from "../../custom-hooks/usePendingDisbursment";
import { errorHandler } from "../../helpers/errorHandler";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../../components/TableDataLoading/TableDataLoading.component";
import WalletDisbursementApprovalSwitch from "./WalletDisbursementApprovalSwitch";
import { appInsights } from "../../components/AppInsight/AppInsight";
export interface IDisbursement {
  recipient_account_name: string;
  amount: number;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  maker_checker_status: string;
  maker_checker_reference: string;
  pub_date: string;
}
const WalletDisbursement = () => {
  // const [searchValue, setSearchValue] = useState("");

  const [pendingUpdate, setPendingUpdate] = useState<IDisbursement | any>();
  const {
    data: pendingDisbursments,
    error: pendingError,
    status: pendingStatus,
  } = usePendingDisbursment();
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursement.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (pendingError)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(pendingError)}
      </p>
    );
  return (
    <Fragment>
      {/* <div className="">
        <form>
          <div className="d-flex flex-wrap align-items-center justify-content-start  custom-search-input mb-2">
            <div className="input-group mb-2" style={{ maxWidth: "200px" }}>
              <input
                type="text"
                className="search-query form-control"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span className="fa fa-search"></span>
            </div>
            <div className="form-group mr-3">
              <div className="input-group mt-2">
                <label className="mr-2 mt-2">From </label>
                <input
                  type="date"
                  name="fromDate"
                  className="form-control"
                  // value={dueLoansState.fromDate}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group mr-3 mt-2">
              <div className="input-group">
                <label className="mr-2 mt-2">To </label>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  // value={dueLoansState.toDate}
                  // onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="">
              <button
                type="submit"
                className="btn btn-primary btn-sm mb-2"
                value="submit"
                //   disabled={status === "loading"}
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </div> */}

      <div className="advancly-wrapper-container">
        <div className="advancly-sub-wrapper">
          <div className="table-responsive">
            <table className="table-style">
              <thead className="">
                <tr className="">
                  <th className="th-td-style th-style ">Recipient</th>
                  <th className="th-td-style th-style ">Date</th>
                  <th className="th-td-style th-style ">Amount</th>
                  <th className="th-td-style th-style ">Status</th>
                  <th className="th-td-style th-style ">Reference</th>
                  <th className="th-td-style th-style " colSpan={2}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {pendingStatus === "loading" ? (
                  <TableDataLoading />
                ) : pendingDisbursments && pendingDisbursments?.length ? (
                  <Fragment>
                    {pendingDisbursments?.map(
                      (disbursement: IDisbursement, _id: number) => {
                        const {
                          recipient_account_name,
                          amount,
                          currency,
                          maker_checker_status,
                          maker_checker_reference,
                          pub_date,
                        } = disbursement;
                        return (
                          <tr key={_id}>
                            <td className="th-td-style ">
                              {recipient_account_name &&
                              recipient_account_name.length > 0
                                ? recipient_account_name
                                : "Not Found"}
                            </td>
                            <td className="th-td-style ">
                              {moment(pub_date).format("DD-MM-YYYY")}
                            </td>
                            <td className="th-td-style ">
                              {formatMoney(amount, currency)}{" "}
                            </td>
                            <td
                              className={` th-td-style 
                               ${
                                 maker_checker_status === "Pending"
                                   ? "text-warning"
                                   : "text-dark"
                               }  `}
                            >
                              {maker_checker_status}
                            </td>
                            <td className="th-td-style ">
                              {maker_checker_reference}
                            </td>

                            <td
                              className="text-success cursor-pointer th-td-style"
                              data-toggle="modal"
                              data-target="#approvalDisbursmentModal"
                              onClick={() => setPendingUpdate(disbursement)}
                            >
                              Accept
                            </td>
                            <td
                              className="text-danger cursor-pointer th-td-style"
                              data-toggle="modal"
                              data-target="#declineDisbursmentModal"
                              onClick={() => setPendingUpdate(disbursement)}
                            >
                              {" "}
                              Decline
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </Fragment>
                ) : (
                  <NoTableDataAvailable text="There is no Pending Disbursment" />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <WalletDisbursementApprovalSwitch pendingUpdate={pendingUpdate} />

        <WalletDisbursmentDeclineComment pendingUpdate={pendingUpdate} />
      </div>
    </Fragment>
  );
};

export default WalletDisbursement;
