import React, { ChangeEventHandler, useState } from "react";
import { enforceMaxLength } from "../../helpers/enforceMaxLength";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import { NumericFormat } from "react-number-format";
import { ReactComponent as CheckMark } from "../../img/checkmark.svg";
import styles from "./CustomFieldStyles.module.scss";
interface ICustomInputFieldProps {
  type: string;
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  maxLength?: undefined | number;
  max?: number | string | any;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  readOnly?: boolean | any;
  onChange?: ChangeEventHandler | any;
  min?: number | string;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  step?: string | number;
  onClickActionButton?: () => void;
  extraLabel?: string | any;
  required?: boolean;
  disabled?: boolean;
  enableSeparator?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  children?: React.ReactNode;
  className?: string;
  disableAutoComplete?: boolean;
  style?: React.CSSProperties;
  customInputStyle?: React.CSSProperties | any;
  validateEmailInput?: boolean;
  extras?: any;
  labelTextNewLine?: string;
}
const CustomInputField = React.forwardRef(
  (
    {
      type,
      name,
      placeholder,
      label,
      errors,
      maxLength,
      max,
      defaultValue,
      minLength,
      onChange,
      readOnly,
      extraLabel,
      min,
      showRequiredIcon,
      step,
      hasActionButton,
      required,
      disableAutoComplete,
      disabled,
      children,
      className,
      value,
      enableSeparator = false,
      style,
      customInputStyle,
      validateEmailInput,
      extras,
      labelTextNewLine,
    }: ICustomInputFieldProps,
    ref: any
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className={`mb-4 ${className}`} style={style}>
        <div className="d-flex align-items-center">
          {label && <label className={`form-label text_label`}>{label}</label>}
          {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
          {extraLabel && extraLabel.length && (
            <label className="form-label ml-2">
              <small className="crimson">{extraLabel}</small>
            </label>
          )}
        </div>
        {labelTextNewLine && (
          <div>
            <small className="text-danger p-0 my-0">{labelTextNewLine}</small>
          </div>
        )}
        <div className="input-group ">
          {children && (
            <div className="input-group-prepend">
              <span className="input-group-text">{children}</span>
            </div>
          )}

          {enableSeparator ? (
            <NumericFormat
              allowNegative={false}
              thousandSeparator={true}
              allowLeadingZeros
              value={value}
              defaultValue={defaultValue}
              className={`form-control  ${
                hasActionButton && "border-right-0"
              } ${children && "border-left-0 "} `}
              style={{
                borderRadius: hasActionButton ? "10px 0px 0px 10px" : "10px ",
              }}
              name={name}
              max={max}
              min={min}
              placeholder={placeholder}
              getInputRef={ref}
              onKeyPress={enforceMaxLength}
              maxLength={maxLength}
              minLength={minLength}
              onValueChange={onChange}
              readOnly={readOnly}
              step={step}
              required={required}
              autoComplete={disableAutoComplete ? "new-password" : ""}
              disabled={disabled}
            />
          ) : (
            <input
              className={`form-control  ${
                (hasActionButton || validateEmailInput || extras) &&
                "border-right-0"
              } ${children && "border-left-0 "} ${styles.customInputStyles}`}
              style={{
                border: "0.5px solid  #D1CFD6",

                borderRadius:
                  hasActionButton || validateEmailInput || extras
                    ? "10px 0px 0px 10px"
                    : "10px ",
                ...customInputStyle,
              }}
              type={
                showPassword
                  ? showPassword === true
                    ? "text"
                    : "password"
                  : type
              }
              name={name}
              max={max}
              min={min}
              placeholder={placeholder}
              ref={ref}
              onKeyPress={enforceMaxLength}
              maxLength={maxLength}
              minLength={minLength}
              defaultValue={defaultValue}
              onChange={onChange}
              readOnly={readOnly}
              step={step}
              required={required}
              autoComplete={disableAutoComplete ? "new-password" : ""}
              disabled={disabled}
              value={value}
            />
          )}

          {hasActionButton && (
            <div
              className="input-group-append  cursor-pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              <span
                className="input-group-text text-capitalize "
                style={{
                  border: "0.5px solid  #D1CFD6",

                  borderRadius: "0px 10px 10px 0px",
                  backgroundColor: readOnly ? "#f8f8f8" : "#fff",
                }}
              >
                {showPassword ? (
                  <i className="fa fa-eye"></i>
                ) : (
                  <i className="fa fa-eye-slash"></i>
                )}
              </span>
            </div>
          )}
          {validateEmailInput && type === "email" && (
            <div className="input-group-append  cursor-pointer">
              <span
                className={`input-group-text text-capitalize  `}
                style={{
                  border: "0.5px solid  #D1CFD6",
                  borderRadius: "0px 10px 10px 0px",
                  backgroundColor: readOnly ? "#f8f8f8" : "#fff",
                  color: "#8b8699",
                }}
              >
                <CheckMark />
              </span>
            </div>
          )}
          {extras && (
            <div className="input-group-append  cursor-pointer">
              <span
                className={`input-group-text text-capitalize  `}
                style={{
                  border: "0.5px solid  #D1CFD6",

                  borderRadius: "0px 10px 10px 0px",
                  backgroundColor: readOnly ? "#f8f8f8" : "#fff",

                  color: "#8b8699",
                }}
              >
                {extras}
              </span>
            </div>
          )}
        </div>
        <ErrorHandler errors={errors} />
      </div>
    );
  }
);

export default CustomInputField;
