import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { Link, useLocation, withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { useAuth } from "../../Context/auth.context";
import useAllInvestments from "../../custom-hooks/useAllInvestments";
import { formatMoney } from "../../helpers/formatter";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { decrypt } from "../../helpers/encryptor";
import CONFIG from "../../components/config";
import Loader from "../../components/Loader/Loader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";

interface IpendingDealSlip {
  funder_name: string;
  principal_amount: number;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  tenure: string;
  monthly_interest_repayment: number;
  effective_date: string;
  maturity_date: string;
  outstanding_interest_amount: number;
  funder_id: number;
}
interface Istate {
  pageNumber: number;
  size: number;
  aggregatorId?: number;
  startDate?: string;
  endDate?: string;
  searchValue?: string;
  currency?: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
}
function AllInvestorsMain() {
  const { aggregatorDetails } = useAuth();
  const [preState, setPreState] = useState<any>({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });

  const [state, setState] = useState<Istate>({
    pageNumber: 1,
    size: 20,
    aggregatorId: aggregatorDetails?.aggregator_id,
    startDate: "",
    endDate: "",
    searchValue: "",
  });
  const [count, setCount] = useState<any>(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const { data, status, error } = useAllInvestments(preState);
  const location = useLocation();
  console.log("currentlocation", location);

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    const getToken = sessionStorage.getItem("token");
    const token = decrypt(getToken);
    let queryParams = `?AggregatorId=${state.aggregatorId}`;
    if (state.startDate && state.endDate) {
      queryParams = `${queryParams}&FromDate=${state.startDate}&ToDate=${state.endDate}`;
    }
    if (state.searchValue) {
      queryParams = `${queryParams}&FunderName=${state.searchValue}`;
    }
    setPreState(state);
    fetch(CONFIG.BASE_URL + `/account/download_investments${queryParams}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: "Token " + token,
      },
      // body: JSON.stringify(data)
    })
      .then((response) => {
        setDownloadLoading(false);
        if (response.status === 200 || response.status === 201) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `${
                state.startDate && state.endDate
                  ? `Transactions from ${state.startDate} to ${state.endDate}.csv`
                  : `Transactions.csv`
              }`
            );
            link.click();
          });
        }

        if (response.status === 403 || response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }

        if (response.status >= 500) {
          // this.setState({ error: "Something went wrong. Please check your internet connection and try again" });
        }
      })
      .catch(() => {
        appInsights.trackException({
          //@ts-ignore
          exception: error,
          properties: {
            fileName: "AllInvestments.tsx",
          },
        });
        // this.setState({ loading: false, error: "Something went wrong. Please check your internet connection and try again" });
        setDownloadLoading(false);
      });
  };
  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };
  useEffect(() => {
    appInsights.trackPageView({ name: "AllInvestments.tsx", isLoggedIn: true });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div className="">
          {location?.pathname === "/investments" && (
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h3 className="page-title ">Investments</h3>
              <button
                type="button"
                className="btn btn-sm advancly-white-btn m-0"
                onClick={handleDownload}
                disabled={status === "loading" || downloadLoading}
              >
                <i className="fas fa-download mr-1" />
                Download {downloadLoading && <Loader />}
              </button>
            </div>
          )}
          <div className="advancly-wrapper-container">
            {status === "loading" ? (
              <div className=" p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="  p-3">
                <div className="  py-3 advancly-sub-wrapper">
                  <form
                    className="d-flex flex-row justify-content-start align-items-center flex-wrap "
                    onSubmit={filterData}
                  >
                    <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px" }}
                    >
                      <CustomInputField
                        type="search"
                        placeholder="Name..."
                        value={state.searchValue}
                        name="searchValue"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                      />
                    </div>
                    <div style={{ width: "120px" }} className="mr-3 mb-3">
                      <CustomSelectDropdown
                        onChange={(e: any) => handleChange(e)}
                        name="currency"
                        value={state.currency}
                        className=" "
                      >
                        <option value="">Currency</option>
                        <option value="NGN">Naira</option>
                        <option value="USD">USD</option>
                      </CustomSelectDropdown>
                    </div>
                    <div className=" mr-3">
                      <div className="input-group ">
                        <label className="mr-2 mt-3">From </label>
                        <CustomInputField
                          type="date"
                          name="startDate"
                          value={state.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="mr-3 ">
                      <div className="input-group">
                        <label className="mr-2  mt-3">To </label>
                        <CustomInputField
                          type="date"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn advancly-btn  mb-4"
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="advancly-sub-wrapper mt-3">
                  <div className="table-responsive">
                    <table className="table-style">
                      <thead className="">
                        <tr>
                          <th className="th-td-style th-style ">Investor</th>
                          <th className="th-td-style th-style ">
                            Amount Invested
                          </th>
                          <th className="th-td-style th-style ">Tenor</th>
                          <th className="th-td-style th-style ">
                            Monthly Interest
                          </th>
                          <th className="th-td-style th-style ">
                            Date Invested
                          </th>
                          <th className="th-td-style th-style ">
                            Maturity Date
                          </th>
                          <th className="th-td-style th-style ">Amount Due</th>
                          <th className="th-td-style th-style ">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.length ? (
                          data.data.map(
                            (request: IpendingDealSlip, _id: number) => {
                              const {
                                funder_name,
                                principal_amount,
                                currency,
                                tenure,
                                monthly_interest_repayment,
                                effective_date,
                                maturity_date,
                                outstanding_interest_amount,
                                funder_id,
                                // investment_status,
                              } = request;
                              return (
                                <tr key={_id}>
                                  <td className="th-td-style ">
                                    {funder_name}
                                  </td>
                                  <td className="th-td-style ">
                                    {formatMoney(principal_amount, currency)}
                                  </td>
                                  <td className="th-td-style ">{tenure}</td>
                                  <td className="th-td-style ">
                                    {formatMoney(
                                      monthly_interest_repayment,
                                      currency
                                    )}
                                  </td>
                                  {/* <td>
                                <span
                                  className={attachClassNameToTag(
                                    investment_status
                                  )}
                                >
                                  {attachClassNameToTag(investment_status)}
                                </span>
                              </td> */}
                                  <td className="th-td-style ">
                                    {effective_date
                                      ? effective_date.substring(0, 10)
                                      : "N/A"}
                                  </td>
                                  <td className="th-td-style ">
                                    {maturity_date
                                      ? maturity_date.substring(0, 10)
                                      : "N/A"}
                                  </td>
                                  <td className="th-td-style ">
                                    {formatMoney(
                                      outstanding_interest_amount,
                                      currency
                                    )}
                                  </td>

                                  <td className="dropright  th-td-style ">
                                    <span
                                      className=""
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <MoreIconHorizontal />
                                    </span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <button className="btn btn-sm advancly-nobg-btn">
                                        <Link
                                          to={{
                                            pathname: `/investment-details/${funder_id}`,
                                            //@ts-ignore
                                            data: request,
                                          }}
                                          className="color-blue"
                                        >
                                          View Details
                                        </Link>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...state, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(AllInvestorsMain);
