import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IbatchLoanRequestStatus {
  Page: number;
  PageSize: number;
  BatchNumber?: number | string;
  FromDate?: string;
  ToDate?: string;
  Status?: string;
}
const getBatchLoanRequestStatus = async ({
  Page,
  PageSize,
  BatchNumber,
  FromDate,
  ToDate,
  Status,
}: IbatchLoanRequestStatus) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}`;
  if (BatchNumber) {
    queryParams = `${queryParams}&BatchNumber=${BatchNumber}`;
  }
  if (FromDate && ToDate) {
    queryParams = `${queryParams}&FromDate=${FromDate}&ToDate=${ToDate}`;
  }
  if (Status) {
    queryParams = `${queryParams}&Status=${Status}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.getBatchLoanRequestStatus}${queryParams}`
  );
  return data;
};

export default function useBatchLoanRequestStatus({
  Page,
  PageSize,
  BatchNumber,
  FromDate,
  ToDate,
  Status,
}: IbatchLoanRequestStatus) {
  return useQuery(
    [
      { Page, PageSize, BatchNumber, FromDate, ToDate, Status },
      "getBatchLoanRequestStatus",
    ],
    getBatchLoanRequestStatus,
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1 * 60 * 1000, // Refetch after every one minute
    }
  );
}
