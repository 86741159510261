import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getLoanStatus = async () => {
  const data = await getData(`${apiEndpoints.LOAN_STATUS}`);
  return data?.data;
};

export default function useLoanStatus() {
  return useQuery(["getLoanStatus"], getLoanStatus, {
    refetchOnWindowFocus: false,
  });
}
