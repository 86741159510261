import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAllEligibilityQuestions = async () => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllEligibilityQuestions}`,
    true
  );
  return data.data;
};

export default function useAllEligibilityQuestions() {
  return useQuery(["getAllEligibilityQuestions"], getAllEligibilityQuestions, {
    refetchOnWindowFocus: false,
  });
}
