import React, { Fragment, useRef } from "react";
import { withRouter } from "react-router-dom";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import styles from "./MultipleLoanRequestSuccessModal.module.scss";

const MultipleLoanRequestSuccessModal = (props) => {
  const dismissRef = useRef();
  const { success, redirectToLoanStatusPage, fileUploadError, setSuccess } =
    props;

  const redirectToStatusPage = () => {
    dismissRef.current.click();
    setTimeout(() => {
      redirectToLoanStatusPage();
    }, 50);
  };

  return (
    <div
      className="modal fade"
      id="multipleLoanSuccessModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="multipleLoanTitleSuccessModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h6
              className="text-center font-weight-bold"
              id="exampleModalLongTitle"
            >
              Loan Request Success
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
              onClick={() => setSuccess(null)}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <h5 className="text-center text-success my-5 text-uppercase font-weight-bold">
              {success}
            </h5>
            <p>
              <span className={styles.retryText} onClick={redirectToStatusPage}>
                Click here
              </span>{" "}
              to view the status of your upload
            </p>

            {fileUploadError && (
              <Fragment>
                <p className="text-left text-danger">
                  However, some rows contain errors, see preview below.
                </p>
                <div className="table-responsive">
                  <table className="table table-white table-hover">
                    <thead>
                      <tr>
                        <th>Error(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fileUploadError.length ? (
                        <Fragment>
                          {fileUploadError.map((error, _id) => {
                            return (
                              <tr key={_id}>
                                <td>{error}</td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ) : (
                        <NoTableDataAvailable text="No Error Information available" />
                      )}
                    </tbody>
                  </table>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(MultipleLoanRequestSuccessModal);
