import React, { useState } from "react";
import { ReactComponent as FileUploadIconColored } from "../../../img/file_upload_colored.svg";
import { ReactComponent as ArrowUpIcon } from "../../..//img/arrow_up_icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../..//img/arrow_down_icon.svg";
import { ReactComponent as DeleteIcon } from "../../..//img/DeleteIcon3.svg";
import styles from "../LoanApplication.module.scss";
import { StatusTag } from "../../../helpers/attachClassNameToTag";
import { useAuth } from "../../../Context/auth.context";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";

const ShowFinancialInformationList = ({
  setFinDocAppId,
  setFinId,
  setloanApplicationDocTypeId,
  financeData,
}: any) => {
  const [showList, setShowList] = useState(false);
  const { financial_type, documents } = financeData;
  const { currentLoanApplicationId } = useAuth();
  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;

  return (
    <div className={`${styles.financialDocumentListContainer} mb-2`}>
      <div
        className={`${styles.financialListHeader} d-flex justify-content-between `}
      >
        <div className={styles.financialListMainHeader}>
          <p>{financial_type}</p>
        </div>
        {showList ? (
          <div onClick={() => setShowList(!showList)}>
            <ArrowUpIcon />
          </div>
        ) : (
          <div onClick={() => setShowList(!showList)}>
            <ArrowDownIcon />
          </div>
        )}
      </div>
      {/* next mapping starts here */}
      {showList &&
        documents?.map((file: any) => {
          const {
            id,
            file_name,
            approval_status,
            loan_application_id,
            loan_application_document_type_id,
          } = file;
          return (
            <div
              key={id}
              className={`${styles.financialListHeaderMiniContainer} d-flex justify-content-between align-items-start`}
            >
              <div className="d-flex align-items-start">
                <div>
                  <FileUploadIconColored />
                </div>
                <div className={styles.docdescriptionContainer}>
                  <span className={styles.documentName} title={file_name}>
                    {file_name ? file_name.slice(0, 15) : "Name Unavailable "}
                  </span>
                  <span
                    className={`${StatusTag(approval_status)} ${styles.stat}
            `}
                  >
                    {approval_status}
                  </span>
                </div>
              </div>
              {!LoanApplicationEditOrWrite && (
                <div
                  title="Delete"
                  data-toggle="modal"
                  data-target="#confirmationDeleteFinancialModal"
                  className="transition-3d-hover cursor-pointer crimson "
                  onClick={() => {
                    setFinDocAppId(loan_application_id);
                    setFinId(id);
                    setloanApplicationDocTypeId(
                      loan_application_document_type_id
                    );
                  }}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default ShowFinancialInformationList;
