import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getSpecificCurrencies = async () => {
  const { data } = await getData(
    apiEndpoints.SPECIFIC_CURRENCIES_BASED_ON_AGGREGATOR,
    true
  );
  return data;
};

const useSpecificCurrencies = () => {
  return useQuery(["getSpecificCurrencies"], getSpecificCurrencies, {
    refetchOnWindowFocus: false,
  });
};
export default useSpecificCurrencies;
