import React, { useEffect, useState } from "react";
import { ReactComponent as Plus } from "../../../../../img/plus_add.svg";
import { ReactComponent as DeleteIcon } from "../../../../../img/DeleteIcon2.svg";
import { ReactComponent as EditIcon } from "../../../../../img/EditIcon.svg";
import BoardOfDirectorsForm from "./BoardOfDirectorsForm";
import { IstepFunctions } from "../GetStartedBusinesInfoMain";
import styles from "../../../GetStarted.module.scss";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { useAuth } from "../../../../../Context/auth.context";
import useGetOnboardingManagement from "../../../../../custom-hooks/useGetOnboardingManagement";
import { errorHandler } from "../../../../../helpers/errorHandler";
import PageLoader from "../../../../../components/PageLoader/PageLoader.component";
import ConfirmationDeleteModal from "../ConfirmationDeleteModal";

const GetStartedBoardOfDirectorsInfo = ({
  handlePrev,
  handleNext,
  scrollToTop,
}: IstepFunctions) => {
  const [isAddMoreDirectors, setIsAddMoreDirectors] = useState(false);
  const [isDirectorsComplete, setIsDirectorsComplete] = useState(false);
  const [boardOfDirectorId, setBoardOfDirectorId] = useState<number | null>(
    null
  );
  const [editAndPopulateDirectorsForm, setEditAndPopulateDirectorsForm] =
    useState<{} | null>(null);
  const [loading, setLoading] = useState(false);
  const [addAnotherLoading, setAddAnotherLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [success, setSuccess] = useState<string | null>(null);
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedBoardOfDirectorsInfo.tsx",
      isLoggedIn: true,
    });
  }, []);

  const { aggregatorDetails } = useAuth();
  const {
    data: boardofDirectorsData,
    isLoading: boardofDirectorsLoading,
    error: boardofDirectorsErrors,
  } = useGetOnboardingManagement({
    funderId: aggregatorDetails?.customer_id,
    funderPeopleType: 4,
  });

  return (
    <section className={`${styles.center_Container}`}>
      <div style={{ width: "11.375rem" }}>
        {" "}
        <small
          className="color-mid-blue  cursor-pointer font-weight-bold  "
          onClick={handlePrev}
        >
          &#8592; Go back
        </small>
      </div>
      <div className={styles.center_form}>
        {success && <p className="alert alert-success small">{success}</p>}

        {error && <p className="alert alert-danger small">{error}</p>}
        {boardofDirectorsErrors && (
          <p className="alert alert-danger small">
            {errorHandler(boardofDirectorsErrors)}
          </p>
        )}
        <div>
          <h3 className="color-blue-hover-none">Board of Director</h3>
          <p className="bg-light-grey">Add your board of director(s) </p>
        </div>
        {boardofDirectorsLoading ? (
          <div className="py-2">
            <PageLoader />
          </div>
        ) : (
          boardofDirectorsData?.length >= 1 &&
          boardofDirectorsData?.map((directorData: any) => {
            const {
              role,
              id,
              first_name,
              last_name,
              government_id_approval_status,
              proof_of_address_approval_status,
            } = directorData;
            return (
              <div className="my-4" key={id}>
                <div className="card-border-wrapper d-flex justify-content-between align-items-center">
                  <div className="mt-4">
                    <h5 className="color-dark-purple">{`${first_name} ${last_name}`}</h5>
                    <p className="bg-light-grey">
                      {role ? role : `Unavailable`}
                    </p>
                    {government_id_approval_status === 1 ||
                    proof_of_address_approval_status === 1 ? (
                      <small className=" p-2 pending_status">
                        {" "}
                        Document Pending Approval
                      </small>
                    ) : government_id_approval_status === 2 &&
                      proof_of_address_approval_status === 2 ? (
                      <small className=" p-2 approved_status">
                        Documents Approved
                      </small>
                    ) : (
                      (government_id_approval_status === 3 ||
                        proof_of_address_approval_status === 3) && (
                        <small className="  p-2 declined_status ">
                          Document Declined
                        </small>
                      )
                    )}
                  </div>
                  <div>
                    <span
                      title="Edit Director"
                      className={`cursor-pointer  `}
                      onClick={() => {
                        setIsAddMoreDirectors(true);
                        setEditAndPopulateDirectorsForm(directorData);
                      }}
                    >
                      <EditIcon className={`transition-3d-hover  `} />{" "}
                    </span>
                    <span
                      title="Delete Director"
                      className="cursor-pointer  transition-3d-hover "
                      data-toggle="modal"
                      data-target="#confirmationDeleteModal"
                      onClick={() => {
                        setBoardOfDirectorId(id);
                      }}
                    >
                      {" "}
                      <DeleteIcon className={`transition-3d-hover  `} />{" "}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {isAddMoreDirectors && <div className="horizontal-dash-line"></div>}
        {isAddMoreDirectors && (
          <BoardOfDirectorsForm
            isAddMoreDirectors={isAddMoreDirectors}
            setIsAddMoreDirectors={setIsAddMoreDirectors}
            isDirectorsComplete={isDirectorsComplete}
            setIsDirectorsComplete={setIsDirectorsComplete}
            handleNext={handleNext}
            loading={loading}
            setLoading={setLoading}
            addAnotherLoading={addAnotherLoading}
            setAddAnotherLoading={setAddAnotherLoading}
            setError={setError}
            setSuccess={setSuccess}
            editAndPopulateDirectorsForm={editAndPopulateDirectorsForm}
            setEditAndPopulateDirectorsForm={setEditAndPopulateDirectorsForm}
            scrollToTop={scrollToTop}
          />
        )}
        {(boardofDirectorsData?.length === 0 || !boardofDirectorsData) &&
          !isAddMoreDirectors && (
            <div className="mb-4" onClick={() => setIsAddMoreDirectors(true)}>
              <button className="advancly-purple-btn ">
                <Plus />
                <span className="p-2"> Add Board of Director</span>
              </button>
            </div>
          )}
        <div className="d-flex justify-content-between">
          {boardofDirectorsData?.length >= 1 && !isAddMoreDirectors && (
            <div className="mb-4" onClick={() => setIsAddMoreDirectors(true)}>
              <button className="advancly-purple-btn ">
                <Plus />
                <span className="p-2"> Add Another Director</span>
              </button>
            </div>
          )}
          {boardofDirectorsData?.length >= 1 && !isAddMoreDirectors && (
            <div className="mb-4" onClick={handleNext}>
              <button className="advancly-purple-btn ">Next</button>
            </div>
          )}
        </div>
      </div>
      <ConfirmationDeleteModal
        currentId={boardOfDirectorId}
        setCurrentId={setBoardOfDirectorId}
      />
    </section>
  );
};

export default GetStartedBoardOfDirectorsInfo;
