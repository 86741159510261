import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IgetGenerateKey {
  apiKeyType: string;
}
const getGenerateApiKeys = async ({ apiKeyType }: IgetGenerateKey) => {
  const data = await getData(
    `${apiEndpoints.GENERATE_AGGREGATOR_API_KEYS}?apiKeyType=${apiKeyType}`
  );
  return data;
};

export default function useGenerateApiKeys({ apiKeyType }: IgetGenerateKey) {
  return useQuery([{ apiKeyType }, "getGenerateApiKeys"], getGenerateApiKeys, {
    refetchOnWindowFocus: false,
    enabled: apiKeyType ? true : false, // disable this query from automatically running
  });
}
