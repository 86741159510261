import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorProductDetails = async () => {
  const data = await getDataWithDotNet(apiEndpoints.AGGREGATOR_PRODUCT_DETAILS);
  return data.data;
};

export default function useAggregatorProductDetails() {
  return useQuery("getAggregatorProductDetails", getAggregatorProductDetails, {
    refetchOnWindowFocus: false,
  });
}
