import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { appInsights } from "../../components/AppInsight/AppInsight";
import BackButtonComponent from "../../components/BackButton/BackButton.component";
import Loader from "../../components/Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { formatMoney } from "../../helpers/formatter";
import ROUTES from "../../helpers/ROUTES";

import styles from "./PreviewLoanOffer.module.scss";

type TLocationState = {
  reqBody: {
    loan_amount: number;
    loan_tenure: number;
    annual_interest_rate: number;
    first_name: string;
  };
};

const PreviewLoanOffer = () => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const history = useHistory();

  const loanOfferPreview = location.state as TLocationState;

  const postLoanRequest = async () => {
    setLoading(true);
    try {
      const data = await postDataWithDotNet(
        apiEndpoints.loanApplicationDirect,
        loanOfferPreview?.reqBody
      );
      setLoading(false);
      if (data.status === true) {
        setSuccess(data.message);
        setTimeout(() => {
          setSuccess(null);
          history.push(`/disbursements`);
        }, 5000);
      } else {
        setError(data.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "PreviewLoanOffer.tsx",
        },
      });
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
    setLoading(false);
  };

  const loanPayback = (interest: number, amount: number) => {
    const interestAmount = (interest / 100) * amount;
    const paybackAmount = interestAmount + amount;

    return formatMoney(Number(paybackAmount.toFixed(2)), "NGN");
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "PreviewLoanOffer.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className={styles.LoanOfferContainer}>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Loan Offer
        </h3>
        <div>
          <span
            className={styles.DeclineAction}
            onClick={() => history.push(`/${ROUTES?.MAKE_LOAN_REQUEST}`)}
          >
            Decline
          </span>
          <button
            type="button"
            className="btn advancly-btn btn-sm transition-3d-hover"
            onClick={postLoanRequest}
          >
            Accept
            {isLoading && <Loader />}
          </button>
        </div>
      </div>
      <div className={`card mb-5 ${styles.Card}`}>
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        <div className={styles.CardContent}>
          <h3 className={`page-title text-center`}>Your Loan Offer</h3>
          <p>
            Based on the selected loan product, you are eligible for this offer.
          </p>
          <div className={styles.OfferTable}>
            <div className={styles.Header}>
              <h2>{loanOfferPreview?.reqBody?.first_name}</h2>
            </div>
            <div className={styles.Body}>
              <div className={`row`}>
                <div className={`col-5`}>
                  <span className={styles.cellHeader}>Loan Amount</span>
                  <p className={styles.cellContent}>
                    {formatMoney(loanOfferPreview?.reqBody?.loan_amount, "NGN")}
                  </p>
                </div>
                <div className={`col-7`}>
                  <span className={styles.cellHeader}>Loan Tenure</span>
                  <p className={styles.cellContent}>
                    {loanOfferPreview?.reqBody?.loan_tenure} Day(s)
                  </p>
                </div>
              </div>

              <div className={`row`}>
                <div className={`col-5`}>
                  <span className={styles.cellHeader}>Loan Payback</span>
                  <p className={styles.cellContent}>
                    {loanPayback(
                      loanOfferPreview?.reqBody?.annual_interest_rate,
                      loanOfferPreview?.reqBody?.loan_amount
                    )}
                  </p>
                </div>
                <div className={`col-7`}>
                  <span className={styles.cellHeader}>Loan Interest</span>
                  <p className={styles.cellContent}>
                    {loanOfferPreview?.reqBody?.annual_interest_rate}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewLoanOffer;
