import { Dispatch, FC, Fragment, SetStateAction } from "react";

interface IToggleTab {
  text: string;
  id: string;
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

const ToggleTab: FC<IToggleTab> = ({ text, id, activeTab, setActiveTab }) => {
  return (
    <Fragment>
      <div
        className={`tabItem ${activeTab === id && "activeTab"}`}
        onClick={() => setActiveTab(id)}
      >
        {text}
      </div>
    </Fragment>
  );
};

export default ToggleTab;
