import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface ILoanOverview {
  currentCurrencyValue: string;
}

const getLoanOverview = async ({ currentCurrencyValue }: ILoanOverview) => {
  const data = await getData(
    `${apiEndpoints.LOAN_SIDEMENU_OVERVIEW_SUMMARY}?currency=${currentCurrencyValue}`
  );
  return data?.data;
};

export default function useLoanOverview({
  currentCurrencyValue,
}: ILoanOverview) {
  return useQuery(
    [{ currentCurrencyValue }, "getLoanOverview"],
    getLoanOverview,
    {
      refetchOnWindowFocus: false,
    }
  );
}
