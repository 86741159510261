import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IeachInvestorInvestmentSummary {
  id: number;
  walletAccountId: string;
  investmentProductId: number;
}
const getEachInvestorInvestmentSummary = async ({
  id,
  walletAccountId,
  investmentProductId,
}: IeachInvestorInvestmentSummary) => {
  if (investmentProductId && walletAccountId && id) {
    const queryParams = `?funderId=${Number(id)}&investmentProductId=${Number(
      investmentProductId
    )}&walletAccountId=${Number(walletAccountId)}`;

    const data = await getDataWithDotNet(
      `${apiEndpoints.EACH_INVESTOR_INVESTMENT_SUMMARY}${queryParams}`
    );
    return data.data;
  }
};
export default function useEachInvestorInvestmentSummary({
  id,
  walletAccountId,
  investmentProductId,
}: IeachInvestorInvestmentSummary) {
  return useQuery(
    [
      { id, walletAccountId, investmentProductId },
      "getEachInvestorInvestmentSummary",
    ],
    getEachInvestorInvestmentSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
}
