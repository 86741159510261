import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type TaggregatorProducts = {
  country_code?: string;
};
const getAggregatorProducts = async ({ country_code }: TaggregatorProducts) => {
  if (
    country_code === "" ||
    country_code === null ||
    country_code === undefined
  ) {
    const data = await getDataWithDotNet(apiEndpoints.getAggregatorProducts);
    return data.data;
  } else {
    const data = await getDataWithDotNet(
      `${apiEndpoints.getAggregatorProducts}?country_code=${country_code}`
    );
    return data.data;
  }
};

export default function useAggregatorProducts({
  country_code,
}: TaggregatorProducts) {
  return useQuery(
    [{ country_code }, "getAggregatorProducts"],
    getAggregatorProducts,
    {
      refetchOnWindowFocus: false,
    }
  );
}
