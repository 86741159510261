import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getInvestorInvestmentSummary = async (id: number) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.INVESTORS_INVESTMENT_SUMMARY}?funderId=${Number(id)}`
  );
  return data.data;
};

export default function useInvestorInvestmentSummary(id: number) {
  return useQuery(
    [id, "getInvestorInvestmentSummary"],
    getInvestorInvestmentSummary,
    {
      refetchOnWindowFocus: false,
    }
  );
}
