import { Fragment } from "react";
import { DownloadOfferLetterDocument } from "../../../components/UploadedDocumentCards/UploadedDocumentCard.component";
import CustomTextArea from "../../../components/CustomHTMLElements/CustomTextArea";
import styles from "../GetStarted.module.scss";
export default function GetStartedViewHistory({
  data,
}: {
  data: {
    adminUploadDate: string;
    adminFileName: string;
    adminUploadPath: string;
    documentName: string;
    aggregatorUploadDate: string;
    aggregatorFileName: string;
    aggregator_upload_path: string;
    adminComment: string;
    aggregatorComment: string;
  };
}) {
  return (
    <Fragment>
      <div className="card w-100 mb-4">
        <div className="card-body p-4">
          <p className="text-justify color-card-border-color font-weight-bold">
            Date: {data?.adminUploadDate?.substring(0, 10)}
          </p>
          <p className="text-justify color-card-border-color">
            Thank you for applying to Advancly! We have attached an offer letter
            and relevant documents for your perusal.
          </p>
          <p className="text-justify color-card-border-color">
            Kindly fill out and upload the documents as applicable.
          </p>
          <div className="row">
            <p className="col-6 font-weight-bold">
              Received Documents{" "}
              <span className={styles.dateText}>
                {data?.adminUploadDate?.substring(0, 10)}
              </span>
            </p>
            <p className="col-6 font-weight-bold">
              My Uploads
              <span className={styles.dateText}>
                {data?.aggregatorUploadDate?.substring(0, 10)}
              </span>
            </p>
          </div>

          <div className="row">
            <div className="col-6">
              <DownloadOfferLetterDocument
                name={data?.adminFileName}
                pathname={data?.adminUploadPath}
                documentName={data?.documentName}
              />
            </div>
            <div className="col-6">
              <DownloadOfferLetterDocument
                name={data?.aggregatorFileName}
                pathname={data?.aggregator_upload_path}
                documentName={data?.documentName}
              />
            </div>
          </div>
          <form>
            <div className="row mt-3">
              <div className="col-12">
                <CustomTextArea
                  label={`Admin's comment on`}
                  otherLabelClassName={styles.dateText}
                  otherLabel={data?.adminComment}
                  readOnly
                  defaultValue={data ? data?.adminComment : ""}
                />
              </div>
              <div className="col-12">
                <CustomTextArea
                  label={`Aggregator's comment on`}
                  otherLabelClassName={styles.dateText}
                  otherLabel={data?.aggregatorUploadDate}
                  readOnly
                  defaultValue={data ? data?.aggregatorComment : ""}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
