import { Dispatch, SetStateAction, useState } from "react";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import AddNewUserModalContent from "../../AddNewUserModal/AddNewUserModalContent";
import ChangeSingleSignatoryModal1 from "./ChangeSingleSignatoryModal1";
import ChangeSingleSignatoryModal2 from "./ChangeSingleSignatoryModal2";
import ChangeSingleSignatoryModal3 from "./ChangeSingleSignatoryModal3";

export type ISignatoryName = {
  email: string;
  levelNo: number;
};
export type TSignatories = {
  email: string;
};
export interface IChangeSingleSignatory {
  usersData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }[];
  usersStatus: string;
  usersError: any;
  changeSingleSignatory: number;
  setChangeSingleSignatory: Dispatch<SetStateAction<number>>;
  setChangeSinglesSignatoryName: Dispatch<
    SetStateAction<string | ISignatoryName[]>
  >;
  step: number;
  setChangeAddNewUserModal: Dispatch<SetStateAction<boolean>>;
  signatories: TSignatories[];
  signatoriesStatus: string;
  dismissRef: any;
}
const ChangeSingleSignatoryComponentPage = ({
  dismissRef,
  signatories,
}: Pick<IChangeSingleSignatory, "signatories" | "dismissRef">) => {
  const [changeSingleSignatory, setChangeSingleSignatory] = useState(2);
  const [changeSingleSignatoryName, setChangeSinglesSignatoryName] = useState<
    string | ISignatoryName[]
  >("");

  const [agreementTick, setAgreementTick] = useState<string>("");
  const [changeAddNewUserModal, setChangeAddNewUserModal] = useState(false);
  const step = 1;
  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useAggregatorSignatoryUsers();
  return (
    <div>
      {(() => {
        switch (changeSingleSignatory) {
          case 1:
            return (
              <AddNewUserModalContent
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                changeAddNewUserModal={changeAddNewUserModal}
                step={step}
              />
            );
          case 2:
            return (
              <ChangeSingleSignatoryModal1
                usersData={usersData}
                usersStatus={usersStatus}
                usersError={usersError}
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                setChangeSinglesSignatoryName={setChangeSinglesSignatoryName}
                setChangeAddNewUserModal={setChangeAddNewUserModal}
                step={step}
                signatories={signatories}
              />
            );

          case 3:
            return (
              <ChangeSingleSignatoryModal2
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                setAgreementTick={setAgreementTick}
                step={step}
              />
            );
          case 4:
            return (
              <ChangeSingleSignatoryModal3
                changeSingleSignatory={changeSingleSignatory}
                setChangeSingleSignatory={setChangeSingleSignatory}
                changeSingleSignatoryName={changeSingleSignatoryName}
                agreementTick={agreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default ChangeSingleSignatoryComponentPage;
