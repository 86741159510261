import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const doughnutOptions = {
  maintainAspectRatio: false,

  plugins: {
    legend: {
      display: true,
      position: "bottom",

      labels: {
        color: "#200865",
        usePointStyle: true,
        // boxWidth: 3,
      },
    },
  },
};
export default function DoughnutChart({
  graphData: { labels, datasets },
}: any) {
  const data = {
    // labels: labels.slice(0, 3),
    // datasets: datasets.slice(0, 3),
    labels: labels,
    datasets: datasets,
  };

  return (
    <div>
      <Doughnut
        data={data}
        width={100}
        height={300}
        // options={barChartOptions}
        options={doughnutOptions as any}
      />
    </div>
  );
}
