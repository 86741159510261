export const isActiveAggregator = (eligibilityData: { statusCode: number }) =>
  !eligibilityData || !eligibilityData.statusCode
    ? false
    : Number(eligibilityData.statusCode) === 9
    ? true
    : false;
//  For  aggregator who  wants to add new product after being activated checking eligibility data
export const isSubsequentAggregatorOnboarded = (eligibilityData: {
  subsequentStatusCode: number;
}) =>
  (eligibilityData.subsequentStatusCode >= 1 &&
    eligibilityData.subsequentStatusCode <= 8) ||
  eligibilityData.subsequentStatusCode === 23
    ? false
    : Number(eligibilityData.subsequentStatusCode) === 9
    ? true
    : true;
