import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

type currencyType = {
  currentCurrencyValue: string;
  // activeProductName: string;
};

const getTotalOutstandingLoans = async ({
  currentCurrencyValue,
}: // activeProductName,
currencyType) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.TOTAL_OUTSTANDING_LOANS}?currency=${currentCurrencyValue}`
  );
  return data.data;
};

const useTotalOutstandingLoans = ({ currentCurrencyValue }: currencyType) => {
  return useQuery(
    [{ currentCurrencyValue }, "getTotalOutstanding"],
    getTotalOutstandingLoans,
    {
      enabled: currentCurrencyValue ? true : false,
      retry: 4,
    }
  );
};

export default useTotalOutstandingLoans;
