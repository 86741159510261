import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IgetOfferLetterSla {
  customerId: number;
  loanApplicationId: number;
  isCurrent?: boolean;
}
const getOfferLetterAndSla = async ({
  customerId,
  loanApplicationId,
  isCurrent,
}: IgetOfferLetterSla) => {
  const data = await getData(
    `${apiEndpoints.offerLetterAndSla}?customer_id=${customerId}&loan_application_id=${loanApplicationId}&is_current=${isCurrent}
    `
  );
  return data.data;
};

export default function useOfferLetterAndSla({
  customerId,
  loanApplicationId,
  isCurrent,
}: IgetOfferLetterSla) {
  return useQuery(
    [{ customerId, loanApplicationId, isCurrent }, "getOfferLetterAndSla"],
    getOfferLetterAndSla,
    {
      enabled: customerId && loanApplicationId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
