import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IdueRepaymentDetails {
  Page: number;
  PageSize: number;
  currency?: string;
  aggregatorId: number;
}
const getDueRepaymentDetails = async ({
  Page,
  PageSize,
  currency,
  aggregatorId,
}: IdueRepaymentDetails) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}&AggregatorId=${aggregatorId}`;
  if (currency) {
    queryParams = `${queryParams}&Currency=${currency}`;
  }
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllDueRepayments}${queryParams && queryParams}`
  );
  return data;
};

export default function useDueRepaymentDetails({
  Page,
  PageSize,
  currency,
  aggregatorId,
}: IdueRepaymentDetails) {
  return useQuery(
    [{ Page, PageSize, currency, aggregatorId }, "getDueRepaymentDetails"],
    getDueRepaymentDetails,
    {
      enabled: aggregatorId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
