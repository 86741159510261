import React from "react";
import styles from "./GetStartedCard.module.scss";

export default function GetStartedCard({
  title,
  text,
  icon,
  button,
}: {
  title: string;
  text: string;
  icon: any;
  button: React.RefAttributes<HTMLAnchorElement>;
}) {
  return (
    <div className={styles.getStartedCard}>
      {icon}
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text}</div>
      {button}
    </div>
  );
}
