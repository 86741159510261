import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

interface IinvestmentOverview {
  aggregatorId: number;
  filter: number;
}

const getInvestorGraph = async ({
  aggregatorId,
  filter,
}: IinvestmentOverview) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.AGGREGATOR_INVESTORS_GRAPH}?aggregatorId=${aggregatorId}&filter=${filter}`
  );
  return data;
};

export default function useInvestorGraph({
  aggregatorId,
  filter,
}: IinvestmentOverview) {
  return useQuery(
    [{ aggregatorId, filter }, "getInvestorGraph"],
    getInvestorGraph,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
