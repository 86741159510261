import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithoutToken } from "../apis/apiMethods";

const getBanksList = async () => {
  const data = await getDataWithoutToken(apiEndpoints.getBanksList);
  return data.data;
};

export default function useBanksList() {
  return useQuery(["getBanksList"], getBanksList, {
    refetchOnWindowFocus: false,
  });
}
