import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getDueLoansCount = async () => {
  const data = await getDataWithDotNet(apiEndpoints.getDueLoansCount);
  return data.data;
};

export default function useDueLoansCount() {
  return useQuery(["getDueLoansCount"], getDueLoansCount, {
    refetchOnWindowFocus: false,
  });
}
