import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { postDataWithDotNet } from "../apis/dotNetApiMethods";
interface IsearchHistory {
  Page: number;
  PageSize: number;
  start_date?: string;
  end_date?: string;
  kyc_type_id?: number;
  searchValue?: string;
  aggregator_id?: number;
}
const getSearchHistory = async ({
  Page,
  PageSize,
  start_date,
  end_date,
  kyc_type_id,
  searchValue,
  aggregator_id,
}: IsearchHistory) => {
  let queryParams = `?Page=${Page}&PageSize=${PageSize}&aggregator_id=${aggregator_id}`;

  if (kyc_type_id) {
    queryParams = `${queryParams}&kyc_type_id=${Number(kyc_type_id)}`;
  }

  if (end_date && start_date) {
    queryParams = `${queryParams}&start_date=${start_date}&end_date=${end_date}`;
  }
  if (searchValue && Number(kyc_type_id) === 1) {
    queryParams = `${queryParams}&Bvn=${searchValue}`;
  }
  if (searchValue && Number(kyc_type_id) === 2) {
    queryParams = `${queryParams}&AccountNumber=${searchValue}`;
  }

  const data = await postDataWithDotNet(
    `${apiEndpoints.getSearchHistory}${queryParams}`,
    {},
    true
  );
  return data;
};

export default function useSearchHistory({
  Page,
  PageSize,
  start_date,
  end_date,
  kyc_type_id,
  aggregator_id,
  searchValue,
}: IsearchHistory) {
  return useQuery(
    [
      {
        Page,
        PageSize,
        start_date,
        end_date,
        kyc_type_id,
        aggregator_id,
        searchValue,
      },
      "getSearchHistory",
    ],
    getSearchHistory
  );
}
