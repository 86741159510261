export const resolveCorrectPageNumber = ({
  pageNumber,
  size,
  id,
}: {
  pageNumber: number;
  size: number;
  id: number;
}) => {
  return (pageNumber - 1) * size + (id + 1);
};
