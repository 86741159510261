import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getAggregatorAccounts = async () => {
  const data = await getDataWithDotNet(
    apiEndpoints.getAggregatorAccounts,
    true
  );
  return data.data;
};

export default function useAggregatorAccounts() {
  return useQuery(["getAggregatorAccounts"], getAggregatorAccounts, {
    refetchOnWindowFocus: false,
  });
}
