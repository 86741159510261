import React, { Fragment, useEffect } from "react";
import styles from "./SignUp.module.scss";

import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { yupValidators } from "../../helpers/yupValidators";
import advanclyLogo from "../../img/advancly_logo.svg";
import sparkImageOnboard from "../../img/sparkOverview.svg";
import mixpanel from "mixpanel-browser";

const SignUpFour = ({
  setStep,
  error,
  setError,
  reqBodyData,
  setReqBodyData,
  state,
  personalEmail,
}: any) => {
  const { data: otpResponseData } = state;
  const schema = yup.object().shape({
    //@ts-ignore
    password: !otpResponseData?.isExistingUser && yupValidators.newPassword,
  });
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = ({ password }: { password: string }) => {
    // setError(false);
    // setLoading(true);
    const reqBody = { ...reqBodyData, password };
    setReqBodyData(reqBody);
    setStep(5);
    // try {
    //   const reqBody = {
    //     aggregator_type,
    //     first_name,
    //     last_name,
    //     password,
    //     country_code,
    //     biz_name,
    //     personal_email: personalEmail,
    //     personal_phone,
    //     sector_id: Number(businessSector),
    //     dial_code: dialCode,
    //     IsLenderTrue,
    //   };
    //   const response = await postDataWithoutToken(
    //     apiEndpoints.signUpUrl,
    //     reqBody,

    //     true // Boolean here means that this endpoint should call production URL only
    //   );
    //   // This sets everything in the sessionStorage / localStorage
    //   setUserInfo(response, true); // true signifies that user is on sso by default

    //   const { aggregatorResult, eligibilityResult } = await setLoginInfo();
    //   setAggregatorDetails(aggregatorResult);
    //   setEligibilityData(eligibilityResult);
    //   setLoading(false);
    //   history.push(ROUTES.GET_STARTED);
    // } catch (error) {
    //   setLoading(false);
    //   setError(errorHandler(error));
    // }
  };

  useEffect(() => {
    mixpanel.track_pageview({ page: "sign up-Enter password -spark" });
  }, []);
  return (
    <main role="main" className={`bg-white ${styles.signUpLayout}`}>
      <div className={styles.left}>
        <Link className={styles.sparkLogoLink} to="/login">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>
        <div className={`${styles.onboardingBackButton} mt-3`}>
          {" "}
          <small
            className="color-mid-blue  cursor-pointer font-weight-bold  "
            onClick={() => {
              setStep(3);
              setError(false);
            }}
          >
            &#8592; Go back
          </small>
        </div>
        <Fragment>
          {/* Form section  */}
          <form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
            <h3 className=" font-weight-bold mb-1 color-blue-hover-none">
              Secure your account{" "}
            </h3>
            <p className="font-weight-normal mb-4 color-dark-purple">
              Enter a secure password for your account{" "}
            </p>
            {error && <p className="alert alert-danger small mb-4">{error}</p>}
            <div className="row">
              <div className="col-12">
                <CustomInputField
                  type="password"
                  maxLength={128}
                  ref={register}
                  label={
                    otpResponseData?.isExistingUser
                      ? `Please enter existing password for '${personalEmail}' `
                      : "Create a Password"
                  }
                  placeholder="**********"
                  name="password"
                  errors={errors.password}
                  hasActionButton={true}
                  disableAutoComplete={true}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
              <div></div>
              <button
                type="submit"
                value="submit"
                className="btn advancly-btn btn-sm transition-3d-hover"
              >
                Proceed
              </button>
            </div>
          </form>
        </Fragment>
      </div>
      <div className={styles.right}>
        <div className={styles.content}>
          <img
            className={styles.illustrator}
            src={sparkImageOnboard}
            alt="Login Illustration"
          />
          <h3 className={styles.imgHeading}>
            Discover endless business possibilities with Spark
          </h3>
          <p className={styles.imgParagraph}>
            Register now for fast, tailored credit. <br /> Start your financial
            journey today!
          </p>
          <div className={styles.highlightContainer}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SignUpFour;
