import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IinvestmentOverview {
  currentCurrencyValue: string;
}
const getInvestmentOverviewMenu = async ({
  currentCurrencyValue,
}: IinvestmentOverview) => {
  const data = await getData(
    `${apiEndpoints.INVESTMENT_SIDEMENU_OVERVIEW_SUMMARY}?currency=${
      currentCurrencyValue ? currentCurrencyValue : ""
    }`
  );
  return data?.data;
};

const useInvestmentsOverviewMenu = ({
  currentCurrencyValue,
}: IinvestmentOverview) => {
  return useQuery(
    [{ currentCurrencyValue }, "getInvestmentOverviewMenu"],
    getInvestmentOverviewMenu,
    {
      enabled: currentCurrencyValue ? true : false,
      retry: 4,
    }
  );
};

export default useInvestmentsOverviewMenu;
