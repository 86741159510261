import React, { useEffect } from "react";
import useAggregatorAccounts from "../../custom-hooks/useAggregatorAccounts";
import useBanksList from "../../custom-hooks/useBanksList";
import Loader from "../Loader/Loader.component";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";

const BusinessBankInfo = () => {
  const { register, setValue } = useForm();

  const { data: banks, status: banksStatus } = useBanksList();
  const {
    data: aggregatorAccounts,
    status: aggregatorAccountsStatus,
    error: aggregatorAccountsError,
  } = useAggregatorAccounts();
  const hasAccount = aggregatorAccounts ? aggregatorAccounts.length > 0 : false;
  const bankAccount = hasAccount ? aggregatorAccounts[0] : null;

  useEffect(() => {
    if (hasAccount) {
      setValue("bankAccountNum", bankAccount?.bank_account_num);
    }
    if (hasAccount) {
      setValue("bankAccountName", bankAccount?.bank_account_name);
    }
  }, [setValue, hasAccount, bankAccount]);

  return (
    <div className="advancly-wrapper-container">
      <div className="advancly-sub-wrapper">
        {aggregatorAccountsStatus === "loading" || banksStatus === "loading" ? (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        ) : aggregatorAccountsError ? (
          <h5 className="text-danger text-center font-weight-bold">
            An error occured
          </h5>
        ) : (
          <form className="">
            <div className="row">
              <div className=" col-md-5 col-12">
                <div>
                  <div className="">
                    <CustomSelectDropdown
                      label="                    Bank name
                    "
                      className=""
                      name="bankName"
                      ref={register}
                      disabled
                    >
                      <option>{bankAccount?.bank_name}</option>

                      {banks && banks?.length
                        ? banks.map(
                            (
                              data: {
                                code: string;
                                name: string;
                              },
                              index: string | number
                            ) => {
                              return (
                                <option value={data?.code} key={index}>
                                  {data?.name}
                                </option>
                              );
                            }
                          )
                        : null}
                    </CustomSelectDropdown>
                    {/* } */}
                  </div>
                </div>
              </div>
              <div className=" col-md-5 col-12">
                <CustomInputField
                  ref={register}
                  label="Business Account Number
                      "
                  type="number"
                  name="bankAccountNum"
                  placeholder="Enter business bank account number"
                  readOnly
                />
              </div>
              <div className=" col-md-5 col-12">
                <CustomInputField
                  type="text"
                  label="Business Account Name  "
                  ref={register}
                  name="bankAccountName"
                  readOnly
                />
              </div>
              <div className=" col-12">
                <div className="d-flex justify-content-start mt-3 flex-wrap">
                  <button
                    type="button"
                    disabled
                    className="btn advancly-nobg-btn  mr-4 transition-3d-hover"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled
                    className="btn advancly-btn  transition-3d-hover"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default BusinessBankInfo;
