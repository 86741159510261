import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getPendingDisbursement = async () => {
  const { data } = await getData(apiEndpoints.PENDING_DISBURSEMENT);
  return data;
};
export default function usePendingDisbursment() {
  return useQuery("getPendingDisbursement", getPendingDisbursement, {
    refetchOnWindowFocus: false,
  });
}
