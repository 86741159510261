import React from "react";
import { Bar } from "react-chartjs-2";
import { barChartSingleOptions } from "../../../helpers/graphOptions";

export default function BarChartSingle({
  graphData: { labels, modifiedData },
  isDisbursement,
}: any) {
  const data = {
    labels: labels,
    datasets: [
      {
        data: modifiedData,
        backgroundColor: `${isDisbursement ? "#FF5E2E" : "#200865"}`,
        borderColor: `${isDisbursement ? "#FF5E2E" : "#200865"}`,
        categoryPercentage: 0.18,
        borderRadius: 40,
      },
    ],
  };
  return (
    <div>
      <Bar
        data={data}
        width={100}
        height={300}
        options={barChartSingleOptions as any}
      />
    </div>
  );
}
