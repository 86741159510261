import React, { Fragment, useRef, useState } from "react";
import Alert from "../../../components/Alert/Alert.component";

interface IWalletDetails {
  aggregatorDetails: IaggregatorDetails;
}
interface IaggregatorDetails {
  biz_name: string;
  account_number: string;
  bank_name: string;
}
const WalletDetailsModal = ({ aggregatorDetails }: IWalletDetails) => {
  const [copied, setCopied] = useState("");
  const dismissRef = useRef<HTMLButtonElement>(null);
  const copyText = (text: string) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };
  return (
    <div
      className="modal fade"
      id="walletDetailsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="walletDetailsModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              x
            </button>
          </div>
          <div className="modal-body">
            <Fragment>
              {copied && <Alert message={copied} type="success" />}
              <h5 className="page-subtitle mb-3">Fund Your Account</h5>
              <p>
                Kindly copy the account number and transfer the amount you want
                to repay to this account.
              </p>
              <div className="bg-lighter-grey p-3">
                <div className="mb-4">
                  <div className="form-label mb-2">
                    <small>Account Name</small>
                  </div>
                  <h6 className="page-subtitle">
                    {aggregatorDetails?.biz_name}
                  </h6>
                </div>
                <div className="mb-4">
                  <div className="form-label mb-2">
                    <small>Account Number</small>
                  </div>
                  <h6 className="page-subtitle">
                    {aggregatorDetails?.account_number}
                    <span
                      className="font-weight-bold cursor-pointer ml-4"
                      onClick={() =>
                        copyText(aggregatorDetails?.account_number)
                      }
                    >
                      <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                      {/* <i class="fas fa-copy"></i> */}
                    </span>
                  </h6>
                </div>
                <div className="mb-4">
                  <div className="form-label mb-2">
                    <small>Bank Name</small>
                  </div>
                  <h6 className="page-subtitle">
                    {aggregatorDetails?.bank_name}
                  </h6>
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WalletDetailsModal;
