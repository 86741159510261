import React, { useEffect } from "react";
import { useAuth } from "../../Context/auth.context";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
//@ts-ignore
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryCodes from "../../custom-hooks/useCountryCodes";
import { useForm } from "react-hook-form";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";

const PersonalInfo = () => {
  const { aggregatorDetails } = useAuth();
  const { register, setValue } = useForm();

  const { data: countries } = useCountryCodes();

  const resolveCountryInfo = countries?.find(
    ({ country_code }: { country_code: string }) =>
      country_code === aggregatorDetails?.country_code
  );

  useEffect(() => {
    if (aggregatorDetails?.rep_first_name) {
      setValue("firstname", aggregatorDetails?.rep_first_name);
    }
    if (aggregatorDetails?.rep_last_name) {
      setValue("lastname", aggregatorDetails?.rep_last_name);
    }
    if (aggregatorDetails?.rep_personal_email) {
      setValue("email", aggregatorDetails?.rep_personal_email);
    }
    if (aggregatorDetails?.rep_personal_phone) {
      setValue("phonenumber", aggregatorDetails?.rep_personal_phone);
    }
  }, [aggregatorDetails, setValue]);

  return (
    <div className="advancly-wrapper-container">
      <div className="advancly-sub-wrapper">
        <form className="js-validate mt-3">
          <div className="row">
            <div className="col-12 col-md-5">
              <CustomInputField
                ref={register}
                name="firstname"
                type="text"
                label="First name"
                readOnly
              />
            </div>
            <div className="col-12 col-md-5">
              <CustomInputField
                ref={register}
                name="lastname"
                type="text"
                label="Last name"
                readOnly
              />
            </div>

            <div className="col-12 col-md-5">
              <CustomInputField
                name="email"
                ref={register}
                type="email"
                label="Email"
                readOnly
              />
            </div>
            <div className="col-12 col-md-2">
              <CustomSelectDropdown
                name="countrycode"
                ref={register}
                label="Dial Code"
                disabled
              >
                {aggregatorDetails?.country_code && (
                  <option value="">
                    {getUnicodeFlagIcon(aggregatorDetails?.country_code)}{" "}
                    {resolveCountryInfo?.dial_code}
                  </option>
                )}
              </CustomSelectDropdown>
            </div>
            <div className="col-12 col-md-3">
              <CustomInputField
                name="phonenumber"
                ref={register}
                type="number"
                label=" Phone Number"
                readOnly
              ></CustomInputField>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-start mt-3 flex-wrap">
                <button
                  type="button"
                  disabled
                  className="btn advancly-nobg-btn  mr-4 transition-3d-hover"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled
                  className="btn advancly-btn  transition-3d-hover"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfo;
