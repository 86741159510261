import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithoutToken } from "../apis/apiMethods";
interface IAggregatorStates {
  country_code: string;
  aggregator_id: number;
}
const getAggregatorStates = async ({
  country_code,
  aggregator_id,
}: IAggregatorStates) => {
  const { data } = await getDataWithoutToken(
    `${apiEndpoints.getAggregatorStates}?country_code=${country_code}&aggregator_id=${aggregator_id}`
  );
  return data;
};

export default function useAggregatorStates({
  country_code,
  aggregator_id,
}: IAggregatorStates) {
  return useQuery(
    [{ country_code, aggregator_id }, "getAggregatorStates"],
    getAggregatorStates,
    {
      refetchOnWindowFocus: false,
    }
  );
}
