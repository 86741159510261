import { useRef, useState } from "react";
import RichTextBox from "../../RichTextBox/RichTextBox";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { useAuth } from "../../../Context/auth.context";
import { putData } from "../../../apis/apiMethods";
import { useForm } from "react-hook-form";
import Loader from "../../Loader/Loader.component";
import { useHistory } from "react-router-dom";
interface IDeclineLoanOffer {
  offerLetterId: number | null;
}
const DeclineLoanOfferModal = ({ offerLetterId }: IDeclineLoanOffer) => {
  const [loadingDeclineOffer, setLoadingDeclineOffer] = useState(false);
  const [declineError, setDeclineError] = useState<string | null>(null);
  const [declinedRichTextBox, setDeclinedRichTextBox] = useState<any>();
  const [rejectSuccessMsg, setRejectSucessMsg] = useState<string | null>(null);
  const { currentLoanApplicationId } = useAuth();
  const closeDeclineModalRef = useRef<HTMLButtonElement | null>(null);
  const handleChange = (data: string) => {
    setDeclinedRichTextBox(data);
  };
  const history = useHistory();
  const { handleSubmit } = useForm({});
  const handleDeclineOfferLetter = async () => {
    setLoadingDeclineOffer(true);
    const reqBody = {
      loan_application_id: currentLoanApplicationId,
      id: offerLetterId,
      comment: declinedRichTextBox,
    };

    try {
      const res = await putData(apiEndpoints.REJECT_OFFER_LETTER, reqBody);
      setRejectSucessMsg(res.message);
      setLoadingDeclineOffer(false);
      if (closeDeclineModalRef && closeDeclineModalRef.current) {
        setTimeout(() => {
          closeDeclineModalRef?.current?.click();
          history.push(`loan-application`);

          setRejectSucessMsg(null);
        }, 2000);
      }
    } catch (error) {
      setLoadingDeclineOffer(false);
      setDeclineError(errorHandler(error));
      setTimeout(() => {
        setDeclineError(null);

        if (closeDeclineModalRef && closeDeclineModalRef.current) {
          closeDeclineModalRef?.current?.click();
        }
      }, 3000);
    }
  };
  return (
    <div
      className="modal"
      id="DeclineOfferLetterModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="loanOfferLetterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog  modal-lg" role="document">
        <div
          className="modal-content cursor-pointer"
          style={{ height: "100dvh", width: "55dvw" }}
        >
          <button
            type="button"
            ref={closeDeclineModalRef}
            className={`d-flex justify-content-end close p-3`}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setDeclinedRichTextBox(null);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div className="modal-body cursor-pointer">
            <>
              {rejectSuccessMsg && (
                <p className="alert alert-success  text-center">
                  {rejectSuccessMsg}
                </p>
              )}
              {declineError && (
                <p className="alert alert-danger  text-center">
                  {declineError}
                </p>
              )}
              <h4 className="color-light-blue" style={{ fontSize: "1.5rem" }}>
                {" "}
                State reason for declining the offer letter
              </h4>
              <form onSubmit={handleSubmit(handleDeclineOfferLetter)}>
                <RichTextBox onChange={handleChange} defaultValue={""} />
                <button
                  className="btn advancly-btn"
                  disabled={loadingDeclineOffer}
                >
                  send {loadingDeclineOffer && <Loader />}
                </button>
              </form>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeclineLoanOfferModal;
