import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type QueryParams = {
  aggregatorID: number;
  questionDomainCategory: number;
};
const getLoanApplicationQuestions = async ({
  aggregatorID,
  questionDomainCategory,
}: QueryParams) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.LOAN_APPLICATION_QUESTIONS}?aggregatorId=${aggregatorID}&domain=${questionDomainCategory}`
  );
  return data.data;
};

export default function useLoanApplicationQuestions({
  aggregatorID,
  questionDomainCategory,
}: QueryParams) {
  return useQuery(
    [{ aggregatorID, questionDomainCategory }, "getLoanApplicationQuestions"],
    getLoanApplicationQuestions,
    {
      enabled: aggregatorID && questionDomainCategory ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
