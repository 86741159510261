import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import styles from "../SelectSignatoryType/SelectSignatoryType.module.scss";

const SelectSignatoryType = ({
  setSignatoryType,
}: {
  setSignatoryType: Dispatch<SetStateAction<string>>;
}) => {
  const [type, setType] = useState("");
  const signatoryTypeHandler = () => {
    if (type === "single") {
      setSignatoryType("single");
    } else {
      setSignatoryType("multi");
    }
  };

  return (
    <div className="modal-content">
      <Fragment>
        <div className="modal-header border-bottom-0">
          <h5 className="page-subtitle">Select Signatory Type</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* /body dtarts here */}
        <div className="modal-body">
          <div
            className={`${styles.modalCard} ${
              type === "single" && styles.active
            }`}
            onClick={() => setType("single")}
          >
            <div className={styles.radioButton}></div>
            <div>
              <h6 className="font-weight-bold">Single signatory approval</h6>
              <p>
                Only the selected signatory can give approval for funds
                transfer.
              </p>
            </div>
          </div>

          <div
            className={`${styles.modalCard} ${
              type === "multi" && styles.active
            }`}
            onClick={() => setType("multi")}
          >
            <div className={styles.radioButton}></div>
            <div>
              <h6 className="font-weight-bold">Multi-signatories Approval</h6>
              <p>Multiple signatories can give approval for funds transfer.</p>
            </div>
          </div>
          <button
            className="btn advancly-btn btn-sm w-100 mt-3"
            onClick={signatoryTypeHandler}
            disabled={!type}
          >
            Continue
          </button>
        </div>
        {/* ends here */}
      </Fragment>
    </div>
  );
};

export default SelectSignatoryType;
