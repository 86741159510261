import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IuseTransactions {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  aggregatorId?: number;
  referenceNumber?: number;
}
const getTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  aggregatorId,
  referenceNumber,
}: IuseTransactions) => {
  let queryParams = `?Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&FromDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&ToDate=${endDate}`;
  }
  if (aggregatorId) {
    queryParams = `${queryParams}&AggregatorId=${aggregatorId}`;
  }
  if (referenceNumber) {
    queryParams = `${queryParams}&ReferenceNumber=${referenceNumber}`;
  }
  const data = await getData(
    `${apiEndpoints.getAllTransactions}${queryParams}`
  );

  return data;
};

export default function useTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  aggregatorId,
  referenceNumber,
}: IuseTransactions) {
  return useQuery(
    [
      {
        pageNumber,
        size,
        startDate,
        endDate,
        aggregatorId,
        referenceNumber,
      },
      "getTransactions",
    ],
    getTransactions,
    {
      enabled: aggregatorId ? true : false,
      refetchOnWindowFocus: false,
    }
  );
}
