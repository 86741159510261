import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import ROUTES from "../../helpers/ROUTES";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter your email address")
      .min(5, "Email must have at least 5 characters"),
});

const StepOne = ({
                   updateStateToken,
                 }: {
  updateStateToken: (token: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }: { email: string }) => {
    setError("");
    setLoading(true);

    const reqBody = {
      reset_step: "first_step",
      email,
    };

    try {
      const response = await postData(
          apiEndpoints.resetPasswordUrl,
          reqBody,
      );

      setLoading(false);
      updateStateToken(response.state_token);
      history.push(ROUTES.RESET_PASSWORD_VERIFY);
    } catch (error) {

      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "StepOne.component.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
      <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-7">
          <h3 className="h3 color-blue font-weight-bold mb-3 text-capitalize">
            Forgot Password?
          </h3>
          <p className="color-card-border-color">
            Enter your email address to reset password
          </p>
        </div>
        {error && <p className="alert alert-danger small mb-4">{error}</p>}

        <CustomInputField
            type="email"
            maxLength={128}
            ref={register}
            name="email"
            placeholder="morty@ricknmorty.com"
            label="Email address"
            errors={errors.email}
        />
        <div className="d-flex justify-content-between align-items-center">
          <Link className="small color-mid-blue" to="/login">
            Back to Log In
          </Link>
          <button
              type="submit"
              value="submit"
              className="btn advancly-btn btn-sm transition-3d-hover"
              disabled={loading}
          >
            Continue
            {loading && <LoaderSignUp />}
          </button>
        </div>
      </form>
  );
};

export default StepOne;
