import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useQueryCache } from "react-query";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { deleteData } from "../../../../apis/apiMethods";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import PageLoader from "../../../../components/PageLoader/PageLoader.component";
import { useAuth } from "../../../../Context/auth.context";
import { errorHandler } from "../../../../helpers/errorHandler";
import styles from "../../GetStarted.module.scss";
interface IcurrentId {
  currentId: number | null;
  setCurrentId: Dispatch<SetStateAction<number | null>>;
}
const ConfirmationDeleteModal = ({ currentId, setCurrentId }: IcurrentId) => {
  const [confirmationDeleteLoading, setConfirmationDeleteLoading] =
    useState<boolean>(false);
  const [confirmationSuccessDelete, setConfirmationSuccessDelete] =
    useState<boolean>(false);

  const [confirmationSuccessDeleteMsg, setConfirmationSuccessDeleteMsg] =
    useState<string | null>(null);

  const [confirmationDeleteError, setConfirmationDeleteError] =
    useState<{} | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const queryCache = useQueryCache();
  const { aggregatorDetails } = useAuth();

  const DeleteExistingDirectorOrManager = async () => {
    try {
      setConfirmationDeleteLoading(true);
      setConfirmationSuccessDelete(false);
      const res = await deleteData(
        `${apiEndpoints.FUNDER_PEOPLE}/${currentId} `,
        {},
        true
      );
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(res?.message);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, funderPeopleType: 4 },
        "getOnboardingManagement",
      ]);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.customer_id, funderPeopleType: 5 },
        "getOnboardingManagement",
      ]);
      setTimeout(() => {
        setCurrentId(null);
        setConfirmationSuccessDelete(false);
        setConfirmationSuccessDeleteMsg(null);

        closeModalRef?.current?.click();
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "ConfirmationDelete.tsx",
        },
      });
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(null);
      setConfirmationSuccessDelete(false);
      setConfirmationDeleteError(errorHandler(error));
      setTimeout(() => {
        closeModalRef?.current?.click();
        setConfirmationDeleteError(null);
      }, 5000);
    }
  };
  return (
    <div
      className="modal fade"
      id="confirmationDeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmationDeleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {confirmationDeleteError && (
            <p className="alert alert-danger small text-center">
              {confirmationDeleteError}
            </p>
          )}
          {(confirmationSuccessDeleteMsg || confirmationSuccessDelete) && (
            <p className="alert alert-success small text-center">
              {" "}
              {confirmationSuccessDeleteMsg}
            </p>
          )}
          <div className="modal-body" style={{ color: "#150849" }}>
            {confirmationDeleteLoading ? (
              <PageLoader />
            ) : (
              <>
                <button
                  type="button"
                  className={`d-flex justify-content-end close`}
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div>
                  <p className="text-center">
                    Are you sure you want to delete?
                  </p>
                  <div className={`d-flex justify-content-around  `}>
                    <small
                      className="text-center cursor-pointer transition-3d-hover"
                      onClick={DeleteExistingDirectorOrManager}
                    >
                      Yes
                    </small>
                    <small
                      className={`${styles.deleteAcion}  text-center cursor-pointer transition-3d-hover `}
                      data-dismiss="modal"
                    >
                      No
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteModal;
