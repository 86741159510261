import { useState } from "react";
import useWebHookList from "../../../custom-hooks/useWebHookList";
import { errorHandler } from "../../../helpers/errorHandler";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../PageLoader/PageLoader.component";
import DeleteWebHookModal from "./DeleteWebHookModal";
import EditWebHookModal from "./EditWebHookModal";

const WebHookList = () => {
  const [idData, setIdData] = useState<number | null>(null);
  const {
    data: webHookListData,
    status: webHookListStatus,
    error: webHookListError,
  } = useWebHookList();
  return (
    <div
      className="advancly-wrapper-container mt-4
      "
    >
      <div className="">
        {webHookListError && (
          <small className="text-dangeralert alert-danger small text-center">
            {errorHandler(webHookListError)}
          </small>
        )}
        <div className=" p-3">
          <div className="table-responsive">
            <table className={` table-style`}>
              <thead className="">
                <tr>
                  <th className="th-td-style th-style ">Url</th>
                  <th className="th-td-style th-style ">Event Type</th>
                  <th className="th-td-style th-style " colSpan={3}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {webHookListStatus === "loading" ? (
                  <div className="d-flex justify-content-center align-items-center ">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <PageLoader />
                  </div>
                ) : webHookListData ? (
                  <>
                    {webHookListData?.map(
                      ({
                        webhookUrl,
                        webhookEvent,
                        id,
                      }: {
                        webhookUrl: string;
                        webhookEvent: { name: string };
                        id: number;
                      }) => {
                        return (
                          <tr key={id}>
                            <td className="th-td-style ">{webhookUrl}</td>
                            <td className="th-td-style ">
                              {webhookEvent?.name}
                            </td>
                            {/* Right now business has decided history should be display */}

                            {/* <td
                          className="color-mid-blue cursor-pointer "
                          data-toggle="modal"
                          data-target="#webHookHistoryModal"
                          onClick={() => setIdData(id)}
                          style={{ textDecoration: "underline" }}
                        >
                          History{" "}
                        </td> */}
                            <td
                              className="color-mid-blue cursor-pointer "
                              data-toggle="modal"
                              data-target="#editWebHookModal"
                              onClick={() => setIdData(id)}
                              style={{
                                textDecoration: "underline",
                                paddingLeft: "0.62rem",
                              }}
                            >
                              Edit
                            </td>
                            <td
                              className="color-mid-blue cursor-pointer "
                              onClick={() => setIdData(id)}
                              data-toggle="modal"
                              data-target="#deleteWebHookModal"
                              style={{
                                textDecoration: "underline",
                                paddingRight: "0.62rem",
                              }}
                            >
                              Delete
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                ) : (
                  <NoTableDataAvailable />
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* <HistoryWebHookModal webhookEventId={idData} /> */}
        <DeleteWebHookModal idData={idData} />
        <EditWebHookModal idData={idData} />
      </div>
    </div>
  );
};

export default WebHookList;
