import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { useAuth } from "../../../Context/auth.context";
import { errorHandler } from "../../../helpers/errorHandler";
import { formatMoney } from "../../../helpers/formatter";
import { appInsights } from "../../AppInsight/AppInsight";
import Loader from "../../Loader/Loader.component";
import styles from "./LoanOfferModal.module.scss";

const LoanOfferModalIndividualRequest = ({
  scrollToTop,
  loanTenureInDays,
  loanCurrency,
  totalPrincipal,
  totalRepayment,
  totalInterestCharged,
  success,
  error,
  loading,
  setLoading,
  setSucess,
  setError,
  requestBody,
  setRequestBody,
  reset,
  productName,
  setBorrowerSearchId,
  resetAmountValue,
}: any) => {
  const { setBorrowerSearchDetailsIndividual } = useAuth();

  const history = useHistory();
  const closeLoanOfferIndividual = useRef<any>();
  const loanApprovedHandler = async () => {
    //Then if loan offer is accepted by aggregator  the loan form request is submitted

    setLoading(true);
    setError(false);
    setSucess(false);
    try {
      const data = await postDataWithDotNet(
        apiEndpoints.loanApplicationDirect,
        requestBody
      );
      setLoading(false);

      if (data.status === true) {
        setSucess(data.message);
        reset();
        resetAmountValue();
        setTimeout(() => {
          setSucess(false);
          closeLoanOfferIndividual.current.click();
        }, 5000);
        scrollToTop();
        setRequestBody(null);
        setBorrowerSearchId(null);
        setBorrowerSearchDetailsIndividual();
      } else {
        setError(data.message);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setRequestBody(null);
      }
    } catch (error: any) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "LoanOfferModalIndividualRequest.jsx" },
      });
      setLoading(false);
      if (error.response.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        setError(errorHandler(error));
        setTimeout(() => {
          setError(null);
          closeLoanOfferIndividual.current.click();
        }, 5000);
        setRequestBody(null);

        scrollToTop();
      }
    }
  };
  const loanDeclinedHandler = () => {
    setRequestBody(null);
    // setBorrowerSearchId(null);
    closeLoanOfferIndividual.current.click();

    scrollToTop();
  };
  return (
    <div
      className="modal fade  "
      id="loanOfferModalIndividual"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="loanOfferModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-scrollable pt-5 "
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={closeLoanOfferIndividual}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div
            className={` modal-body    d-flex flex-column align-items-center justify-content-center`}
          >
            {success && (
              <p className="alert alert-success small mt-1">{success}</p>
            )}
            {error && <p className="alert alert-danger small mt-1">{error}</p>}
            <h3 className="text">Your loan offer</h3>
            <p>
              Based on the selected loan product, you <br />
              are eligible for this offer.
            </p>
            <div className={`${styles.loanProductCard} `}>
              <h4 className="pt-1 pl-1">{productName}</h4>
              <hr />
              <div className="d-flex p-2 justify-content-between">
                <div className="">
                  <h6>Loan Amount</h6>
                  <p>{formatMoney(totalPrincipal, loanCurrency)}</p>
                </div>
                <div>
                  <h6>Loan Tenure</h6>
                  <p>{loanTenureInDays} Days</p>
                </div>
              </div>
              <div className="d-flex p-2 justify-content-between">
                <div>
                  <h6>Loan Repayment</h6>
                  <p>{formatMoney(totalRepayment, loanCurrency)}</p>
                </div>
                <div>
                  <h6>Loan Interest Amount</h6>
                  <p>{formatMoney(totalInterestCharged, loanCurrency)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex justify-content-start ml-5 pt-2 pb-3">
            <button
              type="button"
              className="btn advancly-btn"
              onClick={loanApprovedHandler}
            >
              Approve
              {loading && <Loader />}
            </button>
            <button
              type="button"
              className="btn advancly-nobg-btn color-mid-blue"
              onClick={loanDeclinedHandler}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanOfferModalIndividualRequest;
