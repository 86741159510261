import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithoutToken } from "../apis/apiMethods";
interface IbankListCountry {
  country_code: string;
}
const getBanksListByCountry = async ({ country_code }: IbankListCountry) => {
  const { data } = await getDataWithoutToken(
    `${apiEndpoints.getBanksListByCountry}?country_code=${country_code}`
  );
  return data;
};

export default function useCountryBanksList({
  country_code,
}: IbankListCountry) {
  return useQuery(
    [{ country_code }, "getBanksListByCountry"],
    getBanksListByCountry,
    {
      // enabled: country_code?.length > 1 ? true : false

      refetchOnWindowFocus: false,
      // retry: 2,
    }
  );
}
