import { useEffect } from "react";
import { useAuth } from "../Context/auth.context";
declare global {
  interface Window {
    __AGGREGATOR_DETAILS__?: BusinessInfo;
  }
}
type ProductType = {
  id: number;
  name: string;
  product: string;
  type: string;
  product_type: string;
};

type BusinessInfo = {
  Business_registration_number: string;
  account_number: string;
  aggregator_id: number;
  aggregator_photo_url: string;
  aggregator_type: string;
  aggregator_type_id: number;
  allow_bulk_loan_application_via_ui: number;
  allow_loan_approval_notification: boolean;
  bank_name: string;
  biz_address: string;
  biz_category: string;
  biz_email: string;
  biz_name: string;
  biz_phone: string;
  biz_phone_dialcode: string | null;
  business_description: string;
  country_code: string;
  country_name: string;
  has_set_pin: boolean;
  has_set_security_question: boolean;
  has_setup_disbursement_signatory: boolean;
  is_active: boolean;
  is_lender: boolean;
  lending_experience_in_years: number;
  maximum_running_loan: number;
  modified_date: string;
  product_types: ProductType[];
  pub_date: string;
  rep_first_name: string;
  rep_last_name: string;
  rep_personal_email: string;
  rep_personal_phone: string;
  rep_phone_dialcode: string | null;
  sector_id: number;
  send_email_to_funders: boolean;
  short_name: string;
  transfer_charge: number;
  wallet_id: string;
  aggregator_domain: string;
};
export default function ShareAggregatorDetails() {
  const { aggregatorDetails } = useAuth();
  useEffect(() => {
    if (aggregatorDetails) {
      (window as any).__AGGREGATOR_DETAILS__ =
        aggregatorDetails as BusinessInfo;
    }
  }, [aggregatorDetails]);
  return null;
}
