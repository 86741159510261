import React, { Fragment } from "react";
import { formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import styles from "./ScoreCard.module.scss";
import { ReactComponent as ProgressIcon } from "../../svg/icons/progress-icon-top-colored.svg";

export default function ScoreCard({
  title,
  value,
  formatValue = 0,
  loading,
}: {
  title: string;
  value: number | string;
  formatValue?: number;
  loading: boolean;
}) {
  return (
    <div className={styles.scoreCard}>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <Fragment>
          <ProgressIcon />
          <div className={styles.subScoreContainer}>
            <div className={styles.title}>{title}</div>

            <div className={styles.value}>
              {value ? value : !formatValue ? "0%" : formatMoney(0)}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
