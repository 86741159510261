import React, { useRef, useState } from "react";
import Loader from "../../Loader/Loader.component";
import styles from "./PreDisbursement.module.scss";
import { ReactComponent as DeleteIcon } from "../../../img/DeleteIcon.svg";

import { ReactComponent as CloseModal } from "../../../svg/icons/modal-close.svg";
import { deleteData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";

interface Ipredisbursement {
  refetchConditions: any;
  loanApplicationConditionId: number;
  currentConditionDocumentDetails: {
    conditionDocumentId: number;
    conditionDocumentFileName: string;
  };
}
const PreDisbursementDeleteDocumentModal = ({
  refetchConditions,
  loanApplicationConditionId,
  currentConditionDocumentDetails,
}: Ipredisbursement) => {
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const hideModal = () => {
    dismissRef.current?.click();
  };
  const DeleteCurrentPreDisbursmentDocument = async () => {
    try {
      setLoading(true);
      const res = await deleteData(
        `${apiEndpoints.DELETE_PREDISBURSEMENT_DOCUMENT}?loan_application_condition_id=${loanApplicationConditionId}&loan_application_condition_document_id=${currentConditionDocumentDetails.conditionDocumentId} `,
        {},
        true
      );

      setSuccess(res?.message);

      refetchConditions();
      setTimeout(() => {
        dismissRef?.current?.click();
      }, 5000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "PreDisbursementDeleteDocumentModal.tsx",
        },
      });

      setError(errorHandler(error));
      setTimeout(() => {
        hideModal();
        setError(null);
      }, 5000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className="modal fade"
      id="predisbursementDeleteDocumentModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="predisbursementDeleteDocumentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close "
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              {" "}
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div
              className={`d-flex justify-content-center ${styles.advanclyIconDialog}`}
            >
              <DeleteIcon />
            </div>
            <h5
              className={`page-subtitle text-center w-100 ${styles.confirmationHeader}`}
            >
              Are you sure you want to Delete this Document?
            </h5>
            <p
              className={` ${styles.predisbursementFileNameText} text-center p-3 `}
            >
              {currentConditionDocumentDetails?.conditionDocumentFileName}
            </p>
            <div className="">
              <button
                className={`btn btn-sm advancly-white-btn transition-3d-hover w-100 ${styles.updateUserConfirmationCancelButton}`}
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm advancly-btn transition-3d-hover w-100"
                disabled={loading}
                onClick={DeleteCurrentPreDisbursmentDocument}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreDisbursementDeleteDocumentModal;
