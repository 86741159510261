// this Formatter is strictly for cases where decimal is not required
import moment from "moment";

const formatter = new Intl.NumberFormat("en-NG", {
  style: "currency",
  currency: "NGN",
  minimumFractionDigits: 2,
});

export default formatter;

export const formatMifosDate = (mifosDate: Date) => {
  if (mifosDate) {
    return moment(new Date(mifosDate)).format("DD MMMM,YYYY");
  } else {
    return null;
  }
};

// export const formatMoney = (amount, currency) => {
//   // Check if there is a value passed. Convert value passed to number
//   const amountIncoming = amount ? Number(amount) : 0;

//   // Check the type of value passed. If type is "number", convert to two decimal places. Else, return 0.
//   const amountToFormat =
//     typeof amountIncoming === "number" ? amountIncoming : 0;

//   // Check if currency is specified. If specified, use the specified currency. Else, use Naira
//   return accounting.formatMoney(amountToFormat, {
//     symbol: currency ? `${currency} ` : "₦ ", // I intentionally added space
//     precision: 2, // Precision is same as toFixed(2)
//   });
// };

export const formatMoneyNoDecimal = (
  amount: number,
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh"
) => {
  if (!currency) {
    return Number(amount)?.toFixed(2);
  }
  const format =
    currency === "NGN" || "#"
      ? "en-NG"
      : currency === "USD" || "$"
      ? "en-US"
      : currency === "KES" || "KSh"
      ? "en-KE"
      : "";
  const newCurrency =
    currency === "#"
      ? "NGN"
      : currency === "$"
      ? "USD"
      : currency === "KSh"
      ? "KES"
      : currency;
  return new Intl.NumberFormat(format, {
    style: "currency",
    currency: newCurrency,
    minimumFractionDigits: 0,
  }).format(amount);
};

export const handleFormatAmountNoCurrency = (amount: any) => {
  return Number(amount.replace(/,/g, ""));
};
