import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
// import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getPendingLoansDetails = async () => {
  const data = await getDataWithDotNet(apiEndpoints.getAllPendingLoans);
  return data.data;
};

export default function usePendingLoansDetails() {
  return useQuery("getPendingLoansDetails", getPendingLoansDetails, {
    refetchOnWindowFocus: false,
  });
}
