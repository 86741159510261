import React from "react";
import styles from "./BorrowerProfileCard.module.scss";
import { getLast4 } from "../utility";
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { withRouter } from "react-router-dom";
import ScoreCard from "../ScoreCard/ScoreCard.component";
import Loader from "../Loader/Loader.component";
import TableDataLoading from "../TableDataLoading/TableDataLoading.component";
import { formatMoney } from "../../helpers/formatter";
interface IBorrowerProfileCard {
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  total_loan_repaid: number;
  total_loan_collected: number;
  borrower_type_value: string;
  city: string;
  state: string;
  residence_address: string;
  date_of_birth: string;
  my_biz_rc_num: string;
  first_name: string;
  last_name: string;
  photo_url: string;
  bvn_number: string;
  borrower_phone: string;
  gender: string;
  my_biz_name: string;
}
const BorrowerProfileCard = (props: {
  profileDetails: IBorrowerProfileCard;
}) => {
  const {
    currency,
    total_loan_repaid,
    total_loan_collected,
    // trust_score,
    borrower_type_value,
    city,
    state,
    residence_address,
    date_of_birth,
    my_biz_rc_num,
    first_name,
    last_name,
    bvn_number,
    borrower_phone,
    gender,
    my_biz_name,
  } = props?.profileDetails;
  return (
    <div className="">
      {/* <!-- Profile Card --> */}
      <div className=" advancly-sub-wrapper">
        <div className={styles.alignBusinessInfo}>
          <div className={styles.alignNameSection}>
            {props?.profileDetails ? (
              <div
                className={`d-flex justify-content-start align-items-center ${styles.profilePhoto}`}
              >
                <img
                  // src={aggregatorDetails?.aggregator_photo_url}
                  alt="Logo"
                  // onClick={() => photoRef.current?.click()}
                  className={styles.profileImage}
                />
                <div className="pl-1">
                  <h5 className={`${styles.usernameHeading}`}>
                    {`${first_name} ${last_name}`}
                  </h5>
                  <p className={`${styles.usernameHandle}`}>@{my_biz_name}</p>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <Loader />
              </div>
            )}
          </div>

          <ScoreCard
            title="Total Amount Repaid"
            value={formatMoney(total_loan_repaid, currency)}
            loading={!props?.profileDetails}
          />
          <ScoreCard
            title="Total Amount Disbursed"
            value={formatMoney(total_loan_collected, currency)}
            loading={!props?.profileDetails}
          />
          {/* <ScoreCard
            title="Trust Score"
            value={`${trust_score ? trust_score : 0}/10`}
            loading={!props?.profileDetails}
            formatValue={false}
          /> */}
        </div>
      </div>
      <div className="advancly-sub-wrapper mt-3">
        <h3 className={`${styles.ProfileCardMiniTitle}`}>
          Customer's Information
        </h3>
        <div className="table-responsive mt-1">
          <table className="table-style">
            <thead>
              <tr>
                <th className="th-td-style th-style ">BVN</th>
                <th className="th-td-style th-style ">Phone Number</th>
                <th className="th-td-style th-style ">Sex</th>
                <th className="th-td-style th-style ">Borrower Type</th>
                <th className="th-td-style th-style ">Business Name</th>
                {borrower_type_value === "Corporate" ? (
                  <th>RC Number</th>
                ) : null}

                <th className="th-td-style th-style ">Date of Birth</th>
                <th className="th-td-style th-style ">Street Name</th>
                <th className="th-td-style th-style ">City</th>
                <th className="th-td-style th-style ">State</th>
              </tr>
            </thead>
            <tbody>
              {props?.profileDetails ? (
                <tr>
                  <td className="th-td-style ">{`*******${getLast4(
                    bvn_number
                  )}`}</td>
                  <td className="th-td-style ">{borrower_phone}</td>
                  <td className="th-td-style ">
                    {gender ? capitalizeFirstLetter(gender) : ""}
                  </td>
                  <td className="th-td-style ">
                    {borrower_type_value ? borrower_type_value : "N/A"}
                  </td>
                  <td className="th-td-style ">
                    {my_biz_name ? my_biz_name : "Not Found"}
                  </td>

                  {borrower_type_value === "Corporate" ? (
                    <td className="th-td-style ">
                      {my_biz_rc_num ? my_biz_rc_num : "N/A"}
                    </td>
                  ) : null}
                  <td className="th-td-style ">
                    {date_of_birth ? date_of_birth.substring(0, 10) : ""}
                  </td>
                  <td className="th-td-style ">
                    {residence_address
                      ? capitalizeFirstLetter(residence_address)
                      : ""}
                  </td>
                  <td className="th-td-style ">
                    {city ? capitalizeFirstLetter(city) : ""}
                  </td>
                  <td className="th-td-style ">
                    {state ? capitalizeFirstLetter(state) : ""}
                  </td>
                </tr>
              ) : (
                <TableDataLoading />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
//@ts-ignore
export default withRouter(BorrowerProfileCard);
