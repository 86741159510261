import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Paginator from "../../components/Paginator/Paginator.component";
import { Link, withRouter } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import { useAuth } from "../../Context/auth.context";
import useAllInvestorsDetails from "../../custom-hooks/useAllInvestorsDetails";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";

interface IAllInvestorsMain {
  email: string;
  funder_id: number;
  first_name: string;
  last_name: string;
  funder_type: string;
  pub_date: string;
  gender: string;
  country: string;
  biz_name: string;
}

function AllInvestorsMain() {
  const { aggregatorDetails } = useAuth();
  const [preState, setPreState] = useState<any>({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    searchBy: 3,
    aggregatorId: aggregatorDetails?.aggregator_id,
    startDate: "",
    endDate: "",
    searchValue: "",
  });
  const [count, setCount] = useState<any>(null);

  const { data, status, error } = useAllInvestorsDetails(preState);

  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCount(null);
    setPreState({ ...state, pageNumber: 1 });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "AllInvestorMain.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <h3 className="page-title mb-4">Investors</h3>
            </div>
            <div className="d-flex flex-wrap align-items-center custom-search-input mb-2"></div>
          </div>
          <div className="advancly-wrapper-container">
            {status === "loading" ? (
              <div className=" p-3">
                <PageLoader />
              </div>
            ) : (
              <div className=" ">
                <div className="  advancly-sub-wrapper ">
                  <form
                    className="d-flex flex-row justify-content-start align-items-center"
                    onSubmit={filterData}
                  >
                    <div
                      className="input-group mr-3 mb-3"
                      style={{ maxWidth: "200px" }}
                    >
                      <CustomInputField
                        type="search"
                        placeholder="Name..."
                        value={state.searchValue}
                        name="searchValue"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleChange(e)
                        }
                      />
                    </div>

                    <div className="form-group mr-3">
                      <div className="input-group ">
                        <label className="mr-2 mt-2">From </label>
                        <CustomInputField
                          type="date"
                          name="startDate"
                          value={state.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group mr-3">
                      <div className="input-group">
                        <label className="mr-2 mt-2">To </label>
                        <CustomInputField
                          type="date"
                          name="endDate"
                          value={state.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        type="submit"
                        className="btn advancly-btn mb-4"
                        value="submit"
                        //@ts-ignore
                        disabled={status === "loading"}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className=" advancly-sub-wrapper mt-3">
                  <div className="table-responsive ">
                    <table className="table-style">
                      <thead className="">
                        <tr>
                          <th className="th-td-style th-style ">S/N</th>
                          <th className="th-td-style th-style ">
                            Business Name
                          </th>
                          <th className="th-td-style th-style ">Name</th>
                          <th className="th-td-style th-style ">
                            Investor Type
                          </th>
                          <th className="th-td-style th-style ">
                            Contact Email
                          </th>
                          <th className="th-td-style th-style ">Gender</th>
                          <th className="th-td-style th-style ">Country</th>
                          <th className="th-td-style th-style ">
                            Created Date
                          </th>
                          <th className="th-td-style th-style ">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data?.length ? (
                          data?.data?.map(
                            (request: IAllInvestorsMain, _id: number) => {
                              const {
                                email,
                                funder_id,
                                first_name,
                                last_name,
                                funder_type,
                                pub_date,
                                gender,
                                country,
                                biz_name,
                              } = request;
                              return (
                                <tr key={_id}>
                                  <td className="th-td-style ">
                                    {(preState.pageNumber - 1) * preState.size +
                                      (_id + 1)}
                                  </td>
                                  <td className="th-td-style ">
                                    {biz_name || "N/A"}
                                  </td>
                                  <td className="th-td-style ">
                                    {first_name} {last_name}
                                  </td>
                                  <td className="th-td-style ">
                                    {funder_type}
                                  </td>
                                  <td className="th-td-style ">{email}</td>
                                  <td className="th-td-style ">{gender}</td>
                                  <td className="th-td-style ">{country}</td>
                                  <td className="th-td-style ">
                                    {pub_date?.substring(0, 10)}
                                  </td>

                                  <td className="dropright  th-td-style ">
                                    <span
                                      className=""
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <MoreIconHorizontal />
                                    </span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <button className="btn btn-sm advancly-nobg-btn">
                                        <Link
                                          to={`/investor/${funder_id}`}
                                          className="color-blue"
                                        >
                                          View Details
                                        </Link>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...state, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(AllInvestorsMain);
