import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
interface IAllCustomerWallets {
  pageNumber: number;
  size: number;
  consumerType?: string;
  targetId?: number;
}
const getAllCustomerWallets = async ({
  pageNumber,
  size,
  consumerType,
  targetId,
}: IAllCustomerWallets) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (targetId) {
    queryParams = `${queryParams}&TargetId=${targetId}`;
  }
  const data = await getData(`${apiEndpoints.GET_ALL_WALLET}?${queryParams}`);

  return data;
};

export default function useAllCustomerWallets({
  pageNumber,
  size,
  consumerType,
  targetId,
}: IAllCustomerWallets) {
  return useQuery(
    [{ pageNumber, size, consumerType, targetId }, "getAllCustomerWallet"],
    getAllCustomerWallets,
    { enabled: consumerType && targetId ? true : false }
  );
}
