import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface ILoanApplicationConditions {
  loanApplicationId: number;
}
const getLoanApplicationConditions = async ({
  loanApplicationId,
}: ILoanApplicationConditions) => {
  const data = await getData(
    `${apiEndpoints.LOAN_APPLICATION_CONDITIONS}?loanApplicationId=${loanApplicationId}&loanConditionClass=external&loanConditionType=precedent`
  );
  return data.data;
};

export default function useLoanApplicationConditions({
  loanApplicationId,
}: ILoanApplicationConditions) {
  return useQuery(
    [{ loanApplicationId }, "getLoanApplicationConditions"],
    getLoanApplicationConditions,
    {
      refetchOnWindowFocus: false,
    }
  );
}
