import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IAggregatorSignatories {
  roleType: [string];
}
const getAggregatorSignatories = async () => {
  const data = await getDataWithDotNet(apiEndpoints.GET_AGGREGATOR_SIGNATORIES);
  return data.data;
};

export default function useAggregatorSignatories({
  roleType,
}: IAggregatorSignatories) {
  return useQuery("getAggregatorSignatories", getAggregatorSignatories, {
    refetchOnWindowFocus: false,
    enabled: roleType?.includes("aggregator") ? true : false,
  });
}
