import React, { useRef } from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
import { useAuth } from "../../../Context/auth.context";
import { appInsights } from "../../AppInsight/AppInsight";
import { putData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { useHistory } from "react-router-dom";
import { errorHandler } from "../../../helpers/errorHandler";

const ConfirmationSubmitLoanApplicationModal = ({
  setLoading,
  setSuccess,
  setError,
  setSelectedFiles,

  reset,
  resetAmountValue,
  scrollToTop,
  loading,
}: any) => {
  const history = useHistory();
  const closeModalRef = useRef<HTMLButtonElement | null>(null);

  const {
    currentLoanApplicationId,
    setCurrentLoanApplicationId,
    setEditLoanApplicationGlobal,
  } = useAuth();
  const confirmationLoanApplication = async () => {
    try {
      setLoading(true);
      setError(null);
      const res = await putData(
        apiEndpoints.LOAN_APPLICATION_SUBMIT,
        { loan_application_id: currentLoanApplicationId },
        true
      );
      setLoading(false);
      setSuccess(res?.message);

      setTimeout(() => {
        closeModalRef?.current?.click();
        setCurrentLoanApplicationId();
        sessionStorage.removeItem("currentLoanAppId");
        setSuccess(null);
        setSelectedFiles([]);
        setEditLoanApplicationGlobal(false);
        reset();

        resetAmountValue();
        history.push(`loan-application`);
      }, 2000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "collateral/ConfirmationSubmitLoanApplicationModal.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      closeModalRef?.current?.click();
      setError(errorHandler(err));
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };
  return (
    <div
      className="modal fade"
      id="confirmationOnSubmitLoanApplication"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmationOnSubmitLoanApplicationLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog ">
        <div className="modal-content">
          <div className="modal-body " style={{ color: "#150849" }}>
            {loading ? (
              <PageLoader />
            ) : (
              <>
                <button
                  type="button"
                  className={`d-flex justify-content-end close`}
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="pt-4">
                  <p className="text-center">
                    Are you sure you want to submit this application for
                    processing?
                  </p>

                  <div className={`d-flex justify-content-around `}>
                    <small
                      className="text-center cursor-pointer transition-3d-hover  advancly-btn  "
                      onClick={confirmationLoanApplication}
                    >
                      Yes
                    </small>
                    <small
                      className={`
                 text-center cursor-pointer  transition-3d-hover  advancly-btn `}
                      data-dismiss="modal"
                    >
                      No
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationSubmitLoanApplicationModal;
