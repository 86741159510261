import React, { Dispatch, SetStateAction, useState } from "react";
import SelectWalletModal from "./SelectWalletModal";
import SendMoneyModalComponent from "./SendMoneyModalComponent";
import SendMoneyNaration from "./SendMoneyNaration";
import SendMoneyTransactionPin from "./SendMoneyTransactionPin";
export interface IsendMoney {
  sendMoneySteps: number;
  setSendMoneySteps: Dispatch<SetStateAction<number>>;
  step: number;
  setAllWalletsState: Dispatch<SetStateAction<any>>;
  setWalletProviderType: Dispatch<SetStateAction<string>>;
  setWalletAccountNum: Dispatch<SetStateAction<string>>;
}
type TRecipientDetails = {
  amount: string;
  bankCode: string;
  accountNumber: string;
};
export interface ISendMoneyModalComponent
  extends Pick<IsendMoney, "step" | "sendMoneySteps" | "setSendMoneySteps"> {
  setRecipientDetails: Dispatch<SetStateAction<TRecipientDetails>>;
  walletAccountNum: string;
  allwalletsState: any;
}
export interface IsendMoneyNaration
  extends Pick<IsendMoney, "sendMoneySteps" | "setSendMoneySteps" | "step"> {
  setNarration: Dispatch<SetStateAction<string>>;
}

export interface IsendMoneyTransactionPin
  extends Pick<IsendMoney, "sendMoneySteps" | "setSendMoneySteps" | "step"> {
  walletAccountNum: string;
  recipientDetails: TRecipientDetails;
  walletProviderType: string;
  narration: string;
}

const SendMoneyModal = () => {
  const [sendMoneySteps, setSendMoneySteps] = useState<number>(1);
  const [allwalletsState, setAllWalletsState] = useState("");
  const step = 1;
  const [walletAccountNum, setWalletAccountNum] = useState<string>("");
  const [recipientDetails, setRecipientDetails] = useState<TRecipientDetails>({
    amount: "",
    bankCode: "",
    accountNumber: "",
  });
  const [walletProviderType, setWalletProviderType] = useState<string>("");
  const [narration, setNarration] = useState("");
  return (
    <div id="SendMoneyModal" className="modal fade" role="dialog" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered">
        {(() => {
          switch (sendMoneySteps) {
            case 1:
              return (
                <SelectWalletModal
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setWalletAccountNum={setWalletAccountNum}
                  setAllWalletsState={setAllWalletsState}
                  setWalletProviderType={setWalletProviderType}
                />
              );
            case 2:
              return (
                <SendMoneyModalComponent
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setRecipientDetails={setRecipientDetails}
                  walletAccountNum={walletAccountNum}
                  allwalletsState={allwalletsState}
                />
              );
            case 3:
              return (
                <SendMoneyNaration
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  setNarration={setNarration}
                />
              );

            case 4:
              return (
                <SendMoneyTransactionPin
                  sendMoneySteps={sendMoneySteps}
                  setSendMoneySteps={setSendMoneySteps}
                  step={step}
                  walletAccountNum={walletAccountNum}
                  recipientDetails={recipientDetails}
                  walletProviderType={walletProviderType}
                  narration={narration}
                />
              );

            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default SendMoneyModal;
