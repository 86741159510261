import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { appInsights } from "../../components/AppInsight/AppInsight";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import Paginator from "../../components/Paginator/Paginator.component";
import useWalletDisbursementTransactions from "../../custom-hooks/useWalletDisbursementTransactions";
import { formatMoney } from "../../helpers/formatter";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import styles from "./Wallet.module.scss";
interface Iitem {
  id: number;
  account_name: string;
  account_number: string;
  bank_name: string;
  amount: number;
  transaction_date: string;
  status: string;
  maker_checker_reference: string;
  narration: string;
}
const WalletDisbursementTransactions = () => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
  });
  const [state, setState] = useState({
    pageNumber: 1,
    size: 20,
    startDate: "",
    endDate: "",
    searchStatus: "",
  });
  const [count, setCount] = useState<any>(null);
  const {
    data,
    status: walletTransactStatus,
    error,
  } = useWalletDisbursementTransactions(preState);
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  const filterData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPreState({ ...state });
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursementTransactions.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="advancly-wrapper-container">
            {walletTransactStatus === "loading" ? (
              <div className="advancly-sub-wrapper p-3">
                <PageLoader />
              </div>
            ) : (
              <div className=" ">
                <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
                  <form
                    className="d-flex flex-row flex-wrap justify-content-start align-items-center  "
                    onSubmit={filterData}
                  >
                    <div className=" mr-3 mb-3 ">
                      <div className="input-group  d-flex justify-content-start align-items-center ">
                        <label
                          className={`mr-2  ${styles.walletLabelTextFilter}`}
                        >
                          Filter By Status:
                        </label>
                        <CustomSelectDropdown
                          className=""
                          value={state.searchStatus}
                          name="searchStatus"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        >
                          <option value="">All</option>
                          <option value={0}>Pending</option>
                          <option value={1}>Approved</option>
                          <option value={2}>Declined</option>
                        </CustomSelectDropdown>
                      </div>
                    </div>
                    {/* <div
                      className="input-group mr-3"
                      style={{ maxWidth: "200px", marginTop: "10px" }}
                    >
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Check By Status"
                        value={state.searchStatus}
                        name="searchStatus"
                        onChange={(e) => handleChange(e)}
                      />
                    </div> */}

                    <div className=" mr-3">
                      <div className="input-group  d-flex justify-content-start align-items-center">
                        <label
                          className={`mr-2  ${styles.walletLabelTextFilter}`}
                        >
                          From:{" "}
                        </label>
                        <CustomInputField
                          type="date"
                          name="startDate"
                          className=""
                          value={state.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className=" mr-3  ">
                      <div className="input-group d-flex justify-content-start align-items-center">
                        <label
                          className={`mr-2 ${styles.walletLabelTextFilter}`}
                        >
                          To:{" "}
                        </label>

                        <CustomInputField
                          type="date"
                          name="endDate"
                          className=""
                          value={state.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleChange(e)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <button
                        type="submit"
                        className="btn advancly-btn  "
                        value="submit"
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className=" advancly-sub-wrapper mt-4">
                  <div className="table-responsive ">
                    <table className="table-style">
                      <thead className="">
                        <tr>
                          <th className="th-td-style th-style ">S/N</th>
                          <th className="th-td-style th-style ">
                            Recipient Name
                          </th>
                          <th className="th-td-style th-style ">
                            Account Number
                          </th>
                          <th className="th-td-style th-style ">Bank Name</th>
                          <th className="th-td-style th-style ">Amount</th>
                          <th className="th-td-style th-style ">
                            Transaction Date
                          </th>
                          <th className="th-td-style th-style ">Status</th>
                          {/* <th>Reference</th> */}
                          <th className="th-td-style th-style ">Narration</th>
                          <th className="th-td-style th-style ">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.data ? (
                          data?.data.map((item: Iitem, _id: number) => {
                            const {
                              id,
                              account_name,
                              account_number,
                              bank_name,
                              amount,
                              transaction_date,
                              status,
                              maker_checker_reference,
                              narration,
                            } = item;
                            return (
                              <tr key={id}>
                                <td className="th-td-style ">{_id + 1}</td>
                                <td className="th-td-style ">
                                  {account_name
                                    ? account_name
                                    : "Not Available"}
                                </td>
                                <td className="th-td-style ">
                                  {account_number}
                                </td>
                                <td className="th-td-style ">{bank_name}</td>
                                <td className="th-td-style ">
                                  {formatMoney(amount, "#")}
                                </td>
                                <td className="th-td-style ">
                                  {transaction_date
                                    ? transaction_date.substring(0, 10)
                                    : "N/A"}
                                </td>
                                <td className="th-td-style ">{status}</td>
                                {/* <td>{maker_checker_reference}</td> */}
                                <td className="th-td-style ">{narration}</td>

                                <td className="dropright  th-td-style ">
                                  <span
                                    className=""
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <MoreIconHorizontal />
                                  </span>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <button className="btn btn-sm advancly-nobg-btn">
                                      <Link
                                        to={{
                                          pathname: `/wallettransactionlog/${maker_checker_reference}`,
                                          state: {
                                            data: item,
                                          },
                                        }}
                                        className="color-blue"
                                      >
                                        View Details
                                      </Link>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoTableDataAvailable />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletDisbursementTransactions;
