import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import PageLoader from "../PageLoader/PageLoader.component";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import { useQueryCache } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";
import { IsendMoneyTransactionPin } from "./SendMoneyModal";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";

const schema = yup.object({
  pin: yup
    .string()
    .required("Please enter your pin")
    .min(4, "Pin must have at least 4 characters"),
});
const SendMoneyTransactionPin = ({
  sendMoneySteps,
  setSendMoneySteps,
  step,
  walletAccountNum,
  recipientDetails,
  walletProviderType,
  narration,
}: IsendMoneyTransactionPin) => {
  const [success, setSuccess] = useState<string | boolean>("");
  const [loading, setLoading] = useState<boolean | string>("");
  const [error, setError] = useState<boolean | string | null>("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();

  const onSubmitHandler = async (data: { pin: string }) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    const { pin } = data;
    const { amount, bankCode, accountNumber } = recipientDetails;
    const reqBody = {
      sender_account_number: walletAccountNum,
      recipient_account_number: accountNumber,
      recipient_bank_code: bankCode,
      amount: Number(amount),
      pin: pin,
      wallet_provider: walletProviderType,
      narration: narration,
    };
    try {
      const res = await postData(apiEndpoints.INITIATE_DISBURSEMENT, reqBody);
      reset();
      // setSuccess(res?.data?.data?.message);
      setSuccess(res?.data?.result?.message);
      setLoading(false);
      queryCache.invalidateQueries("getPendingDisbursement");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "SendMoneyTransactionPin.jsx" },
      });
      setSuccess(false);
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <Fragment>
      {success ? (
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex align-items-center justify-content-between flex-column">
              <i className="ri-checkbox-circle-fill text-success ri-5x"></i>
              <h5 className="mt-5 font-weight-bold">{success}</h5>
              <button
                type="button"
                className="btn btn-sm advancly-btn mt-5 w-100 "
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setSendMoneySteps(1);
                }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-content">
          {error && <p className="alert alert-danger small row">{error}</p>}
          <div className="modal-header">
            <h4 className="modal-title">Transaction PIN</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => {
                setSendMoneySteps(1);
              }}
            >
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            <Fragment>
              {loading ? (
                <PageLoader />
              ) : (
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="row">
                    <small
                      className="color-mid-blue text-right   cursor-pointer font-weight-bold pl-2 pb-2"
                      onClick={() => {
                        setSendMoneySteps(sendMoneySteps - step);
                      }}
                    >
                      &#8592; Go back
                    </small>
                  </div>
                  <div className="row">
                    <p className="col-12">Enter transaction PIN to proceed</p>
                    <div className="col-12">
                      <CustomInputField
                        type="password"
                        name="pin"
                        ref={register}
                        label="Transaction PIN"
                        maxLength={4}
                        errors={errors.pin}
                      />
                    </div>
                    <div className="col-12 pb-4 pt-4">
                      <button className="btn btn-sm advancly-btn  w-100">
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SendMoneyTransactionPin;
