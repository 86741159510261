import React from "react";
import styles from "./PrivacyPolicy.module.scss";
const PrivacyPolicyDescription = () => {
  return (
    <div className={styles.privacyDescription}>
      <dl>
        <dt id="introduction">Introduction</dt>
        <dd>
          This Privacy Policy (“Policy”) governs your use of the Advancly
          Digital Platform which includes its website
          “https://www.advancly.com”, the associated domains thereof,
          applications and other technological solutions(“the Platform”)
          developed by Venture Garden Nigeria Limited (“VGN/We/Our”),. The
          Platform enables efficient data collection of programme data and issue
          reporting from the field. If you choose to use the Platform, then you
          agree to the collection and use of your personal information in
          accordance with this Policy. The personal information collected will
          be used to improve your experience during and after using the
          Platform. We will not use or share your personal information with
          anyone except as provided under this Policy.
        </dd>
        <dt id="informationcollection">Information Collection and Use</dt>
        <dd>
          {" "}
          By using the Platform, you are consenting to our processing of your
          information as set forth in this Policy as may be amended by us from
          time to time. For a better experience, while using the Platform, we
          require users of the Platform to provide us with certain personally
          identifiable information, including but not limited to name, email
          address, Global Positioning System (“GPS”) location, phone number,
          password, PIN. The information that we request will be retained by us
          and used as described in this Policy. We collect information about how
          you use the Platform, such as the types of content that you view or
          engage with, the features you use, the actions you take, the time,
          frequency and duration of your activities. For example, we log when
          you're using and have last used the Platform. The Platform utilizes
          third party services that may collect information used to identify
          you. You agree that we may use the information provided to contact you
          with any important information, required notices and marketing
          promotions.
        </dd>
        <dt id="deviceinformation">Device information</dt>
        <dd>
          {" "}
          As described below, we collect information from and about the mobile
          devices, phones, connected TVs and other web-connected devices you use
          that integrate with our Products, and we combine this information
          across different devices that you use. Information that we obtain from
          these devices include
          <ul>
            <li>
              <b>Device attributes:</b> Information such as the operating
              system, hardware and software versions, battery level, signal
              strength, available storage space, etc.
            </li>
            <li>
              <b>Device signals:</b> Bluetooth signals, information about nearby
              Wi-Fi access points, beacons and mobile phone masts.
            </li>
            <li>
              <b>Data from device settings:</b> Information that you allow us to
              receive through device settings that you turn on, such as access
              to your GPS location, camera or photos.
            </li>
            <li>
              <b>Network and connections:</b> Information such as the name of
              your mobile operator or Internet Service Provider (“ISP”),
              language, time zone, mobile phone number, IP address, connection
              speed.
            </li>
          </ul>
        </dd>
        <dt id="logData">Log Data</dt>
        <dd>
          Please note that whenever you use the Platform, and you experience any
          error during the course of use, we collect data and information
          relating to such errors (“Log Data”), through third party products and
          services. This Log Data may include GPS, phone model type, e.t.c.
        </dd>
        <dt id="Cookies">Cookies</dt>
        <dd>
          {" "}
          Cookies are files with a small amount of data that are commonly used
          as anonymous unique identifiers for each user, and the information
          obtained are stored on your device's internal memory. This Platform
          does not use these “cookies” explicitly. However, the Platform may use
          third party code and libraries that use “cookies” to collect
          information and improve its services. You have the option to either
          accept or refuse these cookies and you shall be expressly informed
          before a cookie is sent to your device. If you choose to refuse our
          cookies, you may not be able to use some functionalities provided by
          the Platform.
        </dd>
        <dt id="automatedcollectedinformation">
          {" "}
          Automatically Collected Information / User Provided Information
        </dt>
        <dd>
          You agree that &#123;VGN&#125; may disclose information provided by
          you, or automatically collected by the Platform, in the manner listed
          below:
          <ul>
            <li>
              as required by law, such as to comply with a subpoena, or similar
              legal process;
            </li>
            <li>
              when &#123;VGN&#125; believes in good faith that disclosure is
              necessary to protect its rights, protect your safety or the safety
              of others, investigate fraud, or respond to a government request;
            </li>
            <li>
              with Our trusted services providers who work on Our behalf, do not
              have an independent use of the information We disclose to them,
              and have agreed to adhere to the rules set forth in this privacy
              statement.
            </li>
            <li>
              You agree that &#123;VGN&#125; may retain User Provided data for
              as long as you use the Platform and for a reasonable time
              thereafter. We will retain automatically collected information for
              up to twenty-four (24) months and thereafter may store it in
              aggregate. If you’d like us to delete data that you have provided
              via the Platform, please contact us at {""}
              <a
                className="color-mid-blue"
                rel="noreferrer"
                href="mailto:support@advancly.com"
                target="_blank"
              >
                support@advancly.com
              </a>
              , and we will respond in a reasonable time. Please note that some
              or all of your data may be required in order for the Platform to
              function properly.
            </li>
          </ul>
        </dd>
        <dt id="security">Security</dt>
        <dd>
          {" "}
          We value your trust in providing us with your Personal Information in
          connection with your use of the Platform and shall endeavor to use
          commercially acceptable means of protecting it. However, we do not
          guarantee its absolute security.
        </dd>
        <dt id="linktowebsite">Links to Other Websites</dt>
        <dd>
          {" "}
          Please note that this Platform may contain links to other websites. If
          you click on a third-party link, you will be directed to that website.
          Note that these third party websites are not operated by us.
          Therefore, we strongly advise you to review the privacy policy of
          these websites. We have no control and assume no responsibility for
          the content, privacy policies, or practices of any third-party sites
          or services.
        </dd>
        <dt id="childrensprivacy">Children’s Privacy</dt>
        <dd>
          {" "}
          We do not use the Platform to knowingly solicit data from children or
          individuals under the age of thirteen (13). Where a parent or guardian
          discovers that a child or individual under the age of thirteen (13)
          has provided us with his/her personal information without their
          consent, he or she should contact us at{" "}
          <a
            className="color-mid-blue"
            href="mailto:support@advancly.com"
            target="_blank"
            rel="noreferrer"
          >
            support@advancly.com
          </a>{" "}
          and we shall immediately delete the user’s account from our servers.
        </dd>
        <dt id="newowner">New owner</dt>
        <dd>
          {" "}
          If the ownership or control of all or part of this Platform changes,
          we may transfer your information to the new owner.
        </dd>
        <dt id="changetopolicy">Changes to This Policy</dt>
        <dd>
          {" "}
          We may update this Policy from time to time without any prior notice
          to you or consent. Thus, you are advised to consult this Platform
          periodically for any changes. We will notify you of any changes by
          posting the revised Policy on this Platform. Please note that changes
          shall be effective immediately after they are updated on this
          Platform.
        </dd>
        <dt id="contactusprivacypolicy">Contact Us</dt>
        <dd>
          {" "}
          If you have any questions or suggestions about our Policy, do not
          hesitate to contact us via{" "}
          <a
            className="color-mid-blue"
            href="mailto:support@advancly.com"
            target="_blank"
            rel="noreferrer"
          >
            support@advancly.com
          </a>
        </dd>
      </dl>
    </div>
  );
};

export default PrivacyPolicyDescription;
