import React from "react";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { accurateQuantifier } from "../utility";

export default function EachLoanRow({
  data: loans,
  profileDetails,
}: {
  data: any;
  profileDetails: {
    last_name: string;
    first_name: string;
  };
}) {
  const {
    loan_ref,
    loan_amount,
    loan_interest_amount,
    loan_amount_repay,
    loan_due_date,
    loan_status,
    loan_repayment_balance,
    loan_tenure,
    currency,
  } = loans;
  const { last_name, first_name } = profileDetails;
  return (
    <tr>
      <td className="text-uppercase th-td-style ">{loan_ref}</td>
      <td className="text-capitalize th-td-style">
        {first_name && first_name.toLowerCase()}{" "}
        {last_name && last_name.toLowerCase()}
      </td>
      <td className="th-td-style ">{formatMoney(loan_amount, currency)}</td>
      <td className="th-td-style ">{accurateQuantifier(loan_tenure, "day")}</td>
      <td className="th-td-style ">
        {formatMoney(loan_interest_amount, currency)}
      </td>
      <td className="th-td-style ">
        {formatMoney(
          Number(loan_amount_repay) - Number(loan_repayment_balance),
          currency
        )}
      </td>
      <td className="th-td-style ">
        {moment(loan_due_date).format("DD-MM-YYYY")}
      </td>
      <td className="th-td-style ">
        <span className={attachClassNameToTag(loan_status)}>{loan_status}</span>
      </td>
    </tr>
  );
}
