import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getBusinessSectorsLive = async () => {
  const data = await getDataWithDotNet(apiEndpoints.businessSectors, true);
  return data.data;
};

export default function useBusinessSectorsLive() {
  return useQuery(["getBusinessSectors"], getBusinessSectorsLive, {
    refetchOnWindowFocus: false,
  });
}
