import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useAggregatorProducts from "../custom-hooks/useAggregatorProducts";
import { errorHandler } from "../helpers/errorHandler";
import { formatMoney } from "../helpers/formatter";
import CustomSelectDropdown from "./CustomHTMLElements/CustomSelectDropdown";
import PageLoader from "./PageLoader/PageLoader.component";
import Paginator from "./Paginator/Paginator.component";
import { accurateQuantifier } from "./utility";
import NoTableDataAvailable from "./NoTableDataAvailable/NoTableDataAvailable.component";
import { ReactComponent as SearchIconGrey } from "../svg/icons/search-icon-grey.svg";
import mixpanel from "mixpanel-browser";

interface Iproducts {
  id: string;
  product_name: string;
  product_code: string;
  productCategoryName: string;
  maximum_amount: number;
  maximum_tenor: string;
  interest_rate: number;
  repayment_type: string;
  loan_calculation_method_value: string;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
}
const Products = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState<number>(20);
  const [searchValue, setSearchValue] = useState("");

  const { data, error, status } = useAggregatorProducts({ country_code: "" });
  useEffect(() => {
    mixpanel.track_pageview({ page: "Loan Products-Spark" });
  }, []);
  if (status === "loading") return <PageLoader />;
  return (
    <div>
      <div className="d-flex  align-items-center">
        <div>
          <h3 className="page-title mb-4">Loan Product</h3>
        </div>
      </div>
      <div className="advancly-wrapper-container">
        <div className="">
          <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
            <div className="d-flex align-items-center mb-2">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text ">
                    <span className="">
                      <SearchIconGrey />
                    </span>
                  </span>
                </div>
                <input
                  type="text"
                  className="mr-0 search-query form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  style={{ borderLeft: "none" }}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <p className="pr-2 pt-1">Showing:</p>
              <CustomSelectDropdown
                onChange={(e: any) => setSize(e.target?.value)}
                value={size}
              >
                <option value={10}>10 Entries</option>
                <option value={20}>20 Entries</option>
                <option value={50}>50 Entries</option>
                <option value={100}>100 Entries</option>
              </CustomSelectDropdown>
            </div>
          </div>
          <div className="advancly-sub-wrapper mt-3">
            <div className="table-responsive">
              {error && (
                <p className="alert alert-danger small">
                  {errorHandler(error)}
                </p>
              )}
              <table className="table-style">
                <thead className="">
                  <tr>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "100px" }}>Product Id</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "150px" }}>Product Name</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "120px" }}>Product Code</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "150px" }}>Product Category</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div>Max Amount</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "120px" }}>Max Tenure</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "150px" }}>Interest Rate(%)</div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "200px" }}>
                        Repayment Frequency
                      </div>
                    </th>
                    <th className="th-td-style th-style ">
                      <div style={{ minWidth: "120px" }}>Schedule Type</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length ? (
                    data
                      .filter(
                        (product: Iproducts) =>
                          product.id
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.product_code
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.product_name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.productCategoryName
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.maximum_amount
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.maximum_tenor
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.interest_rate
                            .toString()
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.repayment_type
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                          product.loan_calculation_method_value
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                      )
                      .slice(
                        (pageNumber - 1) * size,
                        size * (pageNumber - 1) + size
                      )
                      .map((data: Iproducts) => {
                        const {
                          id,
                          product_name,
                          product_code,
                          productCategoryName,
                          maximum_amount,
                          maximum_tenor,
                          interest_rate,
                          repayment_type,
                          loan_calculation_method_value,
                          currency,
                        } = data;
                        return (
                          <tr key={id}>
                            <td className="th-td-style ">{id}</td>
                            <td className="th-td-style ">{product_name}</td>
                            <td className="th-td-style ">{product_code}</td>
                            <td className="th-td-style ">
                              {productCategoryName}
                            </td>
                            <td className="th-td-style ">
                              {formatMoney(maximum_amount, currency)}
                            </td>
                            <td className="th-td-style ">
                              {accurateQuantifier(maximum_tenor, "day")}
                            </td>
                            <td className="th-td-style ">{interest_rate}</td>
                            <td className="th-td-style ">{repayment_type}</td>
                            <td className="th-td-style ">
                              {loan_calculation_method_value}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
            <Paginator
              size={size}
              page={pageNumber}
              count={data ? data.length : 0}
              changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Products);
