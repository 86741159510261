import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getSecurityQuestion = async () => {
  const { data } = await getData(apiEndpoints.GET_SAVED_SECURITY_QUESTION);
  return data;
};
export default function useSecurityQuestion() {
  return useQuery("getSecurityQuestion", getSecurityQuestion, {
    refetchOnWindowFocus: false,
  });
}
