import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

interface IgetWalletTransactions {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  consumerType?: string;
  accountNumber?: string | number;
}

const getWalletTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: IgetWalletTransactions) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (accountNumber) {
    queryParams = `${queryParams}&AccountNumber=${accountNumber}`;
  }
  const data = await getData(
    `${apiEndpoints.getAllWalletTransactions}?${queryParams}`
  );

  return data?.data;
};

export default function useWalletTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: IgetWalletTransactions) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, accountNumber },
      "getAllWalletWalletTransactions",
    ],
    getWalletTransactions
  );
}
