import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type currencyType = {
  currentCurrencyValue: string;
  // activeProductName: string;
};
const getTransactionsOverview = async ({
  currentCurrencyValue,
}: // activeProductName,
currencyType) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.transactionsOverview}?currency=${currentCurrencyValue}
    `
  );
  return data.data;
};

export default function useTransactionsOverview({
  currentCurrencyValue,
}: // activeProductName,
currencyType) {
  return useQuery(
    [{ currentCurrencyValue }, "getTransactionsOverview"],
    getTransactionsOverview,
    {
      enabled: currentCurrencyValue ? true : false,
      retry: 4,
    }
  );
}
