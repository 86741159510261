import React, { Dispatch, SetStateAction, useState } from "react";
import useAggregatorSignatoryUsers from "../../../custom-hooks/useAggregatorSignatoryUsers";
import ChangeMultipleSignatoryModal1 from "./ChangeMultipleSignatoryModal1";
import ChangeMultipleSignatoryModal2 from "./ChangeMultipleSignatoryModal2";
import ChangeMultipleSignatoryModal3 from "./ChangeMultipleSignatoryModal3";
import {
  ISignatoryName,
  TSignatories,
} from "../ChangeSingleSingnatorySteps/ChangeSingleSignatoryComponentPage";
export interface IChangeMultipleSignatory {
  usersData: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }[];
  usersStatus: string;
  usersError: unknown;
  changeMultipleSignatory: number;
  setChangeMultipleSignatory: Dispatch<SetStateAction<number>>;
  setChangeMultipleSignatoryName: Dispatch<
    SetStateAction<string | ISignatoryName[]>
  >;
  changeMultipleSignatoryName: string | ISignatoryName[];
  step: number;
  setChangeAddNewUserModal: Dispatch<SetStateAction<boolean>>;
  signatories: TSignatories[];
  signatoriesStatus: string;
  dismissRef: any;
}
const ChangeMultipleSignatoryComponentPage = ({
  dismissRef,
  signatories,
  signatoriesStatus,
}: Pick<
  IChangeMultipleSignatory,
  "signatories" | "dismissRef" | "signatoriesStatus"
>) => {
  const [changeMultipleSignatory, setChangeMultipleSignatory] = useState(1);
  const [changeMultipleSignatoryName, setChangeMultipleSignatoryName] =
    useState<string | ISignatoryName[]>("");

  const [agreementTick, setAgreementTick] = useState<string>("");
  const step = 1;
  const {
    data: usersData,
    status: usersStatus,
    error: usersError,
  } = useAggregatorSignatoryUsers();
  return (
    <div>
      {(() => {
        switch (changeMultipleSignatory) {
          case 1:
            return (
              <ChangeMultipleSignatoryModal1
                usersData={usersData}
                usersStatus={usersStatus}
                usersError={usersError}
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                setChangeMultipleSignatoryName={setChangeMultipleSignatoryName}
                step={step}
                signatories={signatories}
                signatoriesStatus={signatoriesStatus}
              />
            );

          case 2:
            return (
              <ChangeMultipleSignatoryModal2
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                setAgreementTick={setAgreementTick}
                step={step}
              />
            );
          case 3:
            return (
              <ChangeMultipleSignatoryModal3
                changeMultipleSignatory={changeMultipleSignatory}
                setChangeMultipleSignatory={setChangeMultipleSignatory}
                changeMultipleSignatoryName={changeMultipleSignatoryName}
                agreementTick={agreementTick}
                step={step}
                dismissRef={dismissRef}
              />
            );

          default:
            return "";
        }
      })()}
    </div>
  );
};

export default ChangeMultipleSignatoryComponentPage;
