import React, { Fragment, useEffect, useRef, useState } from "react";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
import DueLoans from "./DueLoans";
import RepaymentList from "./RepaymentList";
import MakeRepaymentModal from "./RepaymentModal/MakeRepaymentModal";
import Alert from "../../components/Alert/Alert.component";
import { useAuth } from "../../Context/auth.context";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import useDueRepaymentDetails from "../../custom-hooks/useDueRepaymentDetails";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../components/AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";

function MainRepayment() {
  // const [searchString, setSearchString] = useState("");
  const [activeTab, setActiveTab] = useState("due-repayment");
  // const [searchValue, setSearchValue] = useState("");
  const [modalActionType, setModalActionType] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [size, setSize] = useState(20);
  const [checkAll, setCheckAll] = useState(false);

  const [state, setState] = useState<any>({
    dataToStore: [],
    totalAmount: 0,
    repaymentError: false,
    repaymentSuccess: false,
    repaymentLoading: false,
    reference: "",
    deficitBalance: "",
  });

  const [currency, setCurrency] = useState<
    "NGN" | "USD" | "KES" | "#" | "$" | "Ksh"
  >("#");
  const [dueLoansPreState, setDueLoansPreState] = useState<any>({
    pageNumber: 1,
    size: 20,
  });
  const [count, setCount] = useState<any>(null);
  const [dueLoansState, setDueLoansState] = useState({
    pageNumber: 1,
    size: 20,
    fromDate: "",
    toDate: "",
    loanReference: "",
  });
  const { aggregatorDetails } = useAuth();

  const { data, status, error } = useDueRepaymentDetails({
    Page: pageNumber,
    PageSize: size,
    currency,
    aggregatorId: aggregatorDetails?.aggregator_id,
  });

  // const { data: walletDetails, status: walletDetailsStatus } =
  //   useWalletDetails();
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  const location = useLocation();
  useEffect(() => {
    //@ts-ignore

    if (location?.state?.dueLoans === true) {
      setActiveTab("due-repayment");
    }
  }, [location]);

  // const resetDataToStore = (e: any) => {
  //   setState({
  //     ...state,
  //     dataToStore: [],
  //     totalAmount: 0,
  //   });
  //   setCheckAll(false);
  // };
  const submitRepayment = async (e: any) => {
    e.preventDefault();
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });

    try {
      const result = await postDataWithDotNet(
        apiEndpoints.autoRepayment,
        state.dataToStore
      );
      if (result?.data && result?.data?.isPaid === 0) {
        setState({
          ...state,
          repaymentSuccess: false,
          repaymentError: false,
          repaymentLoading: false,
        });
        setModalActionType("step2");
        setState({
          ...state,
          reference: result?.data?.reference,
          deficitBalance: result?.data?.deficitBalance,
        });
      } else {
        setState({
          ...state,
          repaymentSuccess: result.message,
          repaymentError: false,
          repaymentLoading: false,
          dataToStore: [],
          totalAmount: 0,
        });
        if (dismissRef && dismissRef.current) {
          dismissRef.current.click();
        }
        queryCache.invalidateQueries([
          { Page: pageNumber, PageSize: size },
          "getDueRepaymentDetails",
        ]);
        queryCache.invalidateQueries(["getWalletDetails"]);
        setCheckAll(false);
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "MainRepayment.jsx",
        },
      });
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    }
    console.log("checking the state", state);
  };
  const finalSubmit = async () => {
    setState({
      ...state,
      repaymentSuccess: false,
      repaymentError: false,
      repaymentLoading: true,
    });

    try {
      const result = await postDataWithDotNet(
        `${apiEndpoints.finalAutoRepayment}?reference=${state.reference}`
      );

      mixpanel.track("Auto Repay loan", {
        "total repayment amount": `${state?.dataToStore?.amount}`,
        "loan  reference": `${state?.reference}`,
        "deficit balance": `${state?.deficitBalance}`,
        Product: `Spark`,
      });
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: result.message,
        repaymentError: false,
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "MainRepayment.tsx",
        },
      });
      if (dismissRef && dismissRef.current) {
        dismissRef.current.click();
      }
      setState({
        ...state,
        repaymentSuccess: false,
        repaymentError: errorHandler(error),
        repaymentLoading: false,
        dataToStore: [],
        totalAmount: 0,
      });
      queryCache.invalidateQueries([
        { Page: pageNumber, PageSize: size },
        "getDueRepaymentDetails",
      ]);
      queryCache.invalidateQueries(["getWalletDetails"]);
      setCheckAll(false);
    }
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "MainRepayment.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {state.repaymentError && <Alert message={state.repaymentError} />}
      {state.repaymentSuccess && (
        <Alert message={state.repaymentSuccess} type="success" />
      )}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h3 className="page-title">Repayments</h3>
        </div>

        {activeTab === "due-repayment" && (
          <>
            <button
              type="submit"
              className="btn advancly-btn btn-sm mt-2"
              style={{ cursor: "pointer" }}
              data-toggle="modal"
              data-target="#MakeRepaymentModal"
              onClick={() => {
                setModalActionType("step1");
              }}
              disabled={state.dataToStore?.length === 0}
            >
              Repay Loan
            </button>
          </>
        )}
      </div>
      <div className="">
        <div>
          <Fragment>
            <div>
              {/* <!-- Transactions Section --> */}
              <div className="alignToggleTabItems">
                <ToggleTab
                  text="Due Repayments"
                  id="due-repayment"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                <ToggleTab
                  text="Paid Repayments"
                  id="repayment-list"
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
                {/* Temprarily to be commented out  */}
                {/* <ToggleTab
                text="Closed Loans"
                id="auto-repayment"
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              /> */}
              </div>
              <div className="advancly-wrapper-container">
                <div className="">
                  <div className="">
                    {(() => {
                      switch (activeTab) {
                        case "due-repayment":
                          return (
                            <DueLoans
                              state={state}
                              setState={setState}
                              data={data}
                              pageNumber={pageNumber}
                              size={size}
                              error={error}
                              setPageNumber={setPageNumber}
                              setSize={setSize}
                              status={status}
                              checkAll={checkAll}
                              setCheckAll={setCheckAll}
                              searchCurrency={currency}
                              setCurrency={setCurrency}
                            />
                          );
                        case "repayment-list":
                          return (
                            <RepaymentList
                              dueLoansPreState={dueLoansPreState}
                              setDueLoansPreState={setDueLoansPreState}
                              dueLoansState={dueLoansState}
                              setDueLoansState={setDueLoansState}
                              count={count}
                              setCount={setCount}
                            />
                          );

                        // case "auto-repayment":
                        //   return (
                        //     <AutoRepaymentLoans
                        //       searchString={searchString}
                        //       setSearchString={setSearchString}
                        //       searchValue={searchValue}
                        //       setSearchValue={setSearchValue}
                        //     />
                        //   );

                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
              <MakeRepaymentModal
                modalActionType={modalActionType}
                state={state}
                aggregatorDetails={aggregatorDetails}
                submitRepayment={submitRepayment}
                finalSubmit={finalSubmit}
                dismissRef={dismissRef}
              />
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default MainRepayment;
