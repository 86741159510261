import { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { getDataWithDotNet } from "../../apis/dotNetApiMethods";
import BackButtonComponent from "../../components/BackButton/BackButton.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { TSlipDetails } from "./type";

import styles from "./DealSlipStatus.module.scss";
import { appInsights } from "../../components/AppInsight/AppInsight";

type TLocationState = {
  loanId: number;
};

const DealSlipStatus = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isDealSlipReady, setIsDealSlipReady] = useState(false);
  const [slipMetaData, setSlipMetaData] = useState<TSlipDetails>();
  const [message, setMessage] = useState("");

  const history = useHistory();

  const location = useLocation();

  const { loanId } = location?.state as TLocationState;

  useEffect(() => {
    const getPendingDealSlip = async () => {
      setIsFetching(true);
      try {
        const response = await getDataWithDotNet(
          `${apiEndpoints.PENDING_DEAL_SLIP}${loanId}`
        );
        if (response?.status === true) {
          if (response?.data?.admin_uploaded_slip_filename) {
            setSlipMetaData({
              admin_uploaded_slip_filename:
                response?.data?.admin_uploaded_slip_filename,
              admin_uploaded_slip_path:
                response?.data?.admin_uploaded_slip_path,
              loan_id: response?.data?.loan_id,
              deal_slip_id: response?.data?.id,
              from: "dealSlipStatus",
              deal_slip_comments: response?.data?.comments,
            });
            setIsDealSlipReady(true);
          } else {
            setIsDealSlipReady(false);
          }
        } else {
          setMessage(response.response?.data?.message);

          setIsDealSlipReady(false);
        }
      } catch (error: any) {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "DealSlipStatus.tsx",
          },
        });
      }
      setIsFetching(false);
    };
    getPendingDealSlip();
  }, [loanId]);
  useEffect(() => {
    appInsights.trackPageView({
      name: "DealSlipStatus.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className={styles.dealSlipStatusContainer}>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent /> Deal Slip
        </h3>
      </div>
      <div className={`card mb-5 ${styles.Card}`}>
        {isFetching && <PageLoader />}
        {!isFetching && (
          <div className={styles.CardContent}>
            {isDealSlipReady ? (
              <>
                <h3 className={`page-title text-center`}>
                  Your Deal Slip is Ready
                </h3>
                <p>
                  Thank you for accepting our offer. Kindly upload a signed copy
                  of the deal slip
                </p>
              </>
            ) : (
              <>
                <h3 className={`page-title text-center`}>
                  Deal Slip Not Ready!!
                </h3>
                <p>{message}</p>
              </>
            )}

            <div className={styles.ActionButtons}>
              {isDealSlipReady && (
                <button
                  name="button"
                  className="btn advancly-btn btn-lgtransition-3d-hover w-100"
                  type="button"
                  onClick={() =>
                    history.push({
                      pathname: `/deal-slip-status/${loanId}/download`,
                      state: { slipMetaData },
                    })
                  }
                >
                  Proceed
                </button>
              )}

              {/* <button
               name="button"
               className={`btn advancly-btn btn-lgtransition-3d-hover w-100 ${styles.PreviewButton}`}
               type="button"
             >
               Preview
             </button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DealSlipStatus;
