import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

interface IdashBoardGraph {
  aggregatorId?: number;
  filter?: number;
}
const getDashboardBorrowersGraph = async ({
  aggregatorId,
  filter,
}: IdashBoardGraph) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.dashboardBorrowersGraph}?aggregatorId=${aggregatorId}&filter=${filter}`
  );
  return data;
};

export default function useDashboardBorrowerGraph({
  aggregatorId,
  filter,
}: IdashBoardGraph) {
  return useQuery(
    [{ aggregatorId, filter }, "getDashboardBorrowersGraph"],
    getDashboardBorrowersGraph,
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
}
