import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWebHookList = async () => {
  const data = await getData(apiEndpoints.WEB_HOOK_LIST);
  return data?.data;
};

export default function useWebHookList() {
  return useQuery(["getWebHookList"], getWebHookList, {
    refetchOnWindowFocus: false,
  });
}
