import React from "react";
import { Route, withRouter } from "react-router-dom";
import BorrowerProfile from "../../components/BorrowerProfile";
import BorrowersMain from "./BorrowersMain";

function Borrowers(props: any) {
  let match = props.match;

  return (
    <div>
      <Route exact path={match.path} render={() => <BorrowersMain />} />
      <Route
        path={match.path + "/:borrower_id"}
        render={() => <BorrowerProfile />}
      />
    </div>
  );
}
export default withRouter(Borrowers);
