import React, { useRef, useState } from "react";
import MultipleSignatoryLandingPage from "./MultipleSignatoryModal/MultipleSignatoryLandingPage";
import SelectSignatoryType from "./SelectSignatoryType/SelectSignatoryType";
import SingleSignatoryLandingPage from "./SingleSignatoryModal/SingleSignatoryLandingPage";

const SetSignatoryLandingPage = () => {
  const [signatoryType, setSignatoryType] = useState("");
  const dismissRef = useRef<HTMLButtonElement>(null);

  return (
    <div className="modal" id="SetSignatoryLandingPage">
      <div className="modal-dialog">
        {(() => {
          switch (signatoryType) {
            case "":
              return (
                <SelectSignatoryType setSignatoryType={setSignatoryType} />
              );
            case "single":
              return (
                <SingleSignatoryLandingPage
                  setSignatoryType={setSignatoryType}
                  dismissRef={dismissRef}
                />
              );

            case "multi":
              return (
                <MultipleSignatoryLandingPage
                  setSignatoryType={setSignatoryType}
                  dismissRef={dismissRef}
                />
              );

            default:
              return (
                <p text-danger>Something is wrong , please try again later.</p>
              );
          }
        })()}
      </div>
    </div>
  );
};

export default SetSignatoryLandingPage;
