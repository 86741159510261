import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithoutToken } from "../apis/apiMethods";

const getCountryCodesLive = async () => {
  const data = await getDataWithoutToken(apiEndpoints.countryCodesUrl, true);
  return data.data;
};

export default function useCountryCodesLive() {
  return useQuery(["getCountryCodes"], getCountryCodesLive);
}
