import moment from "moment";
import { encrypt } from "./encryptor";
import { environmentTypes } from "./environmentTypes";

export const setUserInfo = (res: any, onSSOBydefault?: boolean) => {
  //set token to sessionStorage
  sessionStorage.setItem("token", encrypt(res.token || res.sso_auth_token));
  // Set the SSO registration status of the user.
  // SSO is true if user is registered on SSO and vice versa
  if (onSSOBydefault) {
    // By default, user will be on SSO after sign up
    //@ts-ignore
    sessionStorage.setItem("on_sso", true);
  } else {
    // Check whether or not user is on SSo after login
    sessionStorage.setItem("on_sso", JSON.stringify(!res.forceChangePassword));
  }

  // Set User role type
  sessionStorage.setItem("roleTypes", encrypt(JSON.stringify(res.roles)));
  // Set User Email
  sessionStorage.setItem("email", encrypt(JSON.stringify(res.email)));

  // set SSOUserId
  sessionStorage.setItem("ssoUserId", encrypt(res.ssoUserId));

  // Set Expiry Date
  // Add certain seconds to the current time
  const currentTime = moment().format();
  const expiryTime = moment(currentTime)
    .add(Number(res.expiresIn), "s")
    .format();
  sessionStorage.setItem("expiryTime", encrypt(expiryTime));

  // Set User Environment based on what's persisted on the backend
  const envKey = `${res?.first_name}${res?.last_name}env`;
  const isLive =
    res?.environment === environmentTypes.production ? true : false;
  localStorage.setItem(
    envKey,
    isLive
      ? encrypt(environmentTypes.production)
      : encrypt(environmentTypes.sandbox)
  );
};
