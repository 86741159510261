import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IuseCustomerWallet {
  pageNumber: number;
  size: number;
  startDate?: string;
  endDate?: string;
  consumerType?: string;
  accountNumber?: string;
}
const getWalletCustomerTransactions = async ({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: IuseCustomerWallet) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&StartDate=${startDate}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&EndDate=${endDate}`;
  }
  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (accountNumber) {
    queryParams = `${queryParams}&AccountNumber=${accountNumber}`;
  }
  const data = await getData(
    `${apiEndpoints.getAllWalletTransactions}?${queryParams}`
  );

  return data?.data;
};

export default function useCustomerWalletTransactions({
  pageNumber,
  size,
  startDate,
  endDate,
  consumerType,
  accountNumber,
}: IuseCustomerWallet) {
  return useQuery(
    [
      { pageNumber, size, startDate, endDate, consumerType, accountNumber },
      "getWalletCustomerTransactions",
    ],
    getWalletCustomerTransactions
  );
}
