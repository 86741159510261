import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IgetRecentInvestment {
  currentCurrencyValue: string;
  activeProductName: string;
}

const getRecentInvestments = async ({
  currentCurrencyValue,
  activeProductName,
}: IgetRecentInvestment) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.AGGREGATOR_RECENT_INVESTMENTS}?currency=${currentCurrencyValue}&product=${activeProductName}`
  );
  return data?.data;
};

export default function useRecentInvestments({
  currentCurrencyValue,
  activeProductName,
}: IgetRecentInvestment) {
  return useQuery(
    [{ currentCurrencyValue, activeProductName }, "getRecentInvestments"],
    getRecentInvestments,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
