import { useState } from "react";
import ToggleTab from "../../../components/ToggleTab/ToggleTab.component";
import BackButtonComponent from "../../../components/BackButton/BackButton.component";
import InvestorInfo from "./InvestorInfo";
import InvestorsInvestment from "./InvestorsInvestment";
import { useRouteMatch } from "react-router-dom";
import Loader from "../../../components/Loader/Loader.component";
import useAllInvestorsDetails from "../../../custom-hooks/useAllInvestorsDetails";

export default function InvestorTabs() {
  const [activeTab, setActiveTab] = useState("investor-information");
  const match = useRouteMatch();
  //@ts-ignore
  const { id } = match?.params;
  const {
    data: investorData,
    status,
    error,
  } = useAllInvestorsDetails({
    pageNumber: 1,
    size: 20,
    FunderId: Number(id),
  });
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />{" "}
          {status === "loading" ? (
            <Loader />
          ) : !error && investorData ? (
            investorData?.data[0]?.first_name +
            " " +
            investorData?.data[0]?.last_name
          ) : (
            ""
          )}
        </h3>
      </div>
      <div className="">
        <div className=" p-3">
          <div className="alignToggleTabItems mb-3">
            <ToggleTab
              text="Investor Details"
              id="investor-information"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Investments"
              id="investment-information"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>

          <div>
            {(() => {
              switch (activeTab) {
                case "investor-information":
                  return (
                    <InvestorInfo
                      investorData={investorData?.data[0]}
                      status={status}
                      error={error}
                    />
                  );

                case "investment-information":
                  return <InvestorsInvestment id={Number(id)} />;

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
}
