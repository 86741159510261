import React, { useState } from "react";
import { ReactComponent as CopyIcon } from "../../../svg/icons/copy.svg";
import { ReactComponent as PlusIcon } from "../../../svg/icons/plus-icon.svg";
import { queryCache } from "react-query";
import useAggregatorApiKeys from "../../../custom-hooks/useAggregatorApiKeys";
import useGenerateApiKeys from "../../../custom-hooks/useGenerateApiKeys";
import { decrypt } from "../../../helpers/encryptor";
import LoaderColored from "../../Loader/LoaderColored";
import DeveloperAPIModal from "./DeveloperAPIModal";
import DeveloperWidgetModal from "./DeveloperWidgetModal";
const DeveloperAPIKeys = () => {
  const roleType = JSON.parse(decrypt(sessionStorage.getItem("roleTypes")));
  const [apiKeyTypeValue, setApiKeyTypeValue] = useState<string>("");
  //for apikeyValue 1 means generate to api keys , 2 means generate widget API keys
  const { data: aggrKeys, status } = useAggregatorApiKeys();
  const {
    data: generateKeys,
    refetch,
    status: generateStatus,
  } = useGenerateApiKeys({
    apiKeyType: apiKeyTypeValue,
  });

  const [copied, setCopied] = useState("");
  const copyText = (text: string) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };
  const generateApiKeyHandler = () => {
    setApiKeyTypeValue("1");
    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      queryCache.invalidateQueries("getAggregatorApiKeys");
    }, 5000);
  };
  const generateWidgetApiKeyHandler = () => {
    setApiKeyTypeValue("2");
    setTimeout(() => {
      refetch();
    }, 1000);
    setTimeout(() => {
      queryCache.invalidateQueries("getAggregatorApiKeys");
    }, 5000);
  };

  return (
    <div
      className="advancly-wrapper-container 
    "
    >
      <div className="advancly-sub-wrapper">
        <div className="">
          {copied && (
            <span
              className="d-flex justify-content-center text-success

            "
            >
              copied !!!
            </span>
          )}

          {status === "loading" ? (
            <div className="d-flex justify-content-center align-items-center m-h-100">
              <LoaderColored />
            </div>
          ) : (
            <div className="js-validate mt-3">
              <div className="">
                <div className="generate-api-key-row  row">
                  <div className="col-12 col-md-4">
                    <p className="advancly-indigo-blue-text">
                      Client ID : {aggrKeys?.client_id}
                    </p>
                  </div>
                  <div className="">
                    <div
                      className="btn advancly-nobg-btn btn-sm transition-3d-hover  "
                      onClick={() => copyText(aggrKeys?.client_id)}
                    >
                      Copy <CopyIcon />
                    </div>
                    {roleType && roleType.includes("aggregator") && (
                      <button
                        type="button"
                        className="btn advancly-btn btn-sm transition-3d-hover ml-2 "
                        data-toggle="modal"
                        data-target="#devloperModal"
                        onClick={generateApiKeyHandler}
                      >
                        <PlusIcon /> Generate API Key
                      </button>
                    )}
                  </div>
                </div>

                {/* widget API key */}
                <div className="generate-api-key-row  row pt-3">
                  <div className="col-12 col-md-4">
                    <p className="advancly-indigo-blue-text">
                      {" "}
                      Widget Public Key : {aggrKeys?.widget_public_key}
                    </p>
                  </div>
                  <div className="">
                    <span
                      className="btn advancly-nobg-btn btn-sm transition-3d-hover "
                      onClick={() => copyText(aggrKeys?.widget_public_key)}
                    >
                      Copy <CopyIcon />
                    </span>
                    {roleType && roleType.includes("aggregator") && (
                      <button
                        type="button"
                        className="btn advancly-btn btn-sm transition-3d-hover ml-2 "
                        data-toggle="modal"
                        data-target="#devloperWidgetApiModal"
                        onClick={generateWidgetApiKeyHandler}
                      >
                        <PlusIcon />
                        Generate Widget API Key
                      </button>
                    )}
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12">
                    <div className="">
                      <div className="d-flex justify-content-start mt-3 flex-wrap">
                        <a
                          href="https://docs.advancly.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="color-mid-blue"
                        >
                          API Documentation
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <DeveloperAPIModal
          generateStatus={generateStatus}
          generateKeys={generateKeys}
          copyText={copyText}
          copied={copied}
        />
        <DeveloperWidgetModal
          generateStatus={generateStatus}
          generateKeys={generateKeys}
          copyText={copyText}
          copied={copied}
        />
      </div>
    </div>
  );
};

export default DeveloperAPIKeys;
