import React, { useRef, useState } from "react";
import PageLoader from "../../PageLoader/PageLoader.component";
import { ReactComponent as UploadMedium } from "../../../svg/icons/upload-medium.svg";
import { ReactComponent as UploadSmall } from "../../../svg/icons/upload-small.svg";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import { ReactComponent as Bin } from "../../../svg/icons/bin.svg";

import styles from "./PreDisbursement.module.scss";
import PredisbursementConfirmationUploadModal from "./PredisbursementConfirmationUploadModal";
import useLoanApplicationConditions from "../../../custom-hooks/useLoanApplicationConditions";
import { useAuth } from "../../../Context/auth.context";
import LoanViewDocument from "../LoanOfferLetter/LoanViewDocument";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../AppInsight/AppInsight";
import PreDisbursementDeleteDocumentModal from "./PreDisbursementDeleteDocumentModal";
interface Icondition {
  id: number;
  status: string;
  statusId: number;
  condition: string;
  DocumentsUpload: any;
  loanApplicationConditionDocuments: any;
}
enum conditionsStatus {
  Pending = 1,
  Done = 2,
  Deferred = 3,
}
const PreDisbursement = () => {
  const [documentData, setDocumentData] = useState<{
    fileName: string;
    base64Url: string;
  }>({
    fileName: "",
    base64Url: "",
  });
  const [error, setError] = useState<any>(null);
  const [currentSelectedFile, setCurrentSelectedFile] = useState<any>(null);
  const [
    currentLoanApplicationConditionId,
    setCurrentLoanApplicationnConditionId,
  ] = useState<any>(null);
  const [currentConditionDocumentDetails, setCurrentConditionDocumentDetails] =
    useState<any>(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);

  const { currentLoanApplicationId } = useAuth();
  const selectConditionFile = useRef<any>();
  const triggerUploadConfirmationRef = useRef<any>();
  const deleteDocumentConfirmationRef = useRef<any>();
  const triggerFileUpload = (loanApplicationConditionId: number) => {
    setCurrentLoanApplicationnConditionId(loanApplicationConditionId);
    selectConditionFile.current.click();
  };

  const triggerDeleteConditionDocsModal = (
    conditionDocumentId: number,
    conditionDocumentFileName: string,
    loanApplicationConditionId: number
  ) => {
    const conditionDocumentDetails = {
      conditionDocumentId,
      conditionDocumentFileName,
    };
    setCurrentConditionDocumentDetails(conditionDocumentDetails);
    setCurrentLoanApplicationnConditionId(loanApplicationConditionId);

    deleteDocumentConfirmationRef?.current?.click();
  };
  const closeDocumentModal = () => {
    setDocumentData({
      fileName: "",
      base64Url: "",
    });
    setIsLoadingDocument(false);
  };
  const viewDocument = async (documentUrl: string) => {
    setIsLoadingDocument(true);

    try {
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${documentUrl}`
      );

      setDocumentData({
        fileName: data?.file_name,
        base64Url: data?.base64_string,
      });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "predisbursement.tsx",
        },
      });
    }
    setIsLoadingDocument(false);
  };
  const handleSelectedFileDocument = (event: any) => {
    const selectedFile = event.target.files[0];
    const getFileSizeMB = selectedFile.size / (1024 * 1024);

    setCurrentSelectedFile(selectedFile);

    if (!event.target.files[0]) return;
    setError(null);
    const fileName = event.target.files[0].name;

    const isValidFile = fileName.endsWith(".pdf") || fileName.endsWith(".PDF");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    if (getFileSizeMB > 25) {
      return setError("File Size exceeds 25MB");
    }
    if (event.target.files[0]) {
      triggerUploadConfirmationRef?.current?.click();
    }
  };
  const {
    data: LoanApplicationConditionsData,
    status: LoanApplicationConditionsStatus,
    error: LoanApplicationConditionsError,
    refetch: refetchConditions,
  } = useLoanApplicationConditions({
    loanApplicationId: currentLoanApplicationId,
  });

  return (
    <div className={styles.predisbursmentMainContainer}>
      {error && <p className="alert alert-danger small">{error}</p>}
      {LoanApplicationConditionsError && (
        <p className="alert alert-danger small">
          {LoanApplicationConditionsError}
        </p>
      )}

      <h5 className={styles.predisbursmentMainHeader}>
        You can upload multiple documents for each conditions in PDFs
      </h5>

      <div className="advancly-wrapper-container">
        <div className="advancly-sub-wrapper">
          {LoanApplicationConditionsStatus === "loading" ? (
            <div className=" ">
              <PageLoader />
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table-style">
                <thead className="">
                  <tr>
                    <th className="th-td-style th-style ">Conditions</th>
                    <th className="th-td-style th-style ">Status</th>
                    <th className="th-td-style th-style ">Documents upload</th>
                    <th className="th-td-style th-style ">Upload File(s)</th>
                    {/* <th>Comments</th> */}
                  </tr>
                </thead>
                <tbody>
                  {LoanApplicationConditionsData &&
                  LoanApplicationConditionsData.length > 0 ? (
                    LoanApplicationConditionsData?.map(
                      (conditionTableDetails: Icondition) => {
                        const {
                          id,
                          condition,
                          status,
                          statusId,
                          loanApplicationConditionDocuments,
                        } = conditionTableDetails;
                        const currentStatusIdDefered =
                          statusId === conditionsStatus.Deferred;
                        const currentStatusIdDone =
                          statusId === conditionsStatus.Done;
                        return (
                          <tr key={id}>
                            <td className="th-td-style ">{condition}</td>
                            <td
                              className={`th-td-style 
                          `}
                            >
                              <p
                                className={`$ ${
                                  status === "Approved" || status === "Done"
                                    ? styles.predisbursementApprovedStatus
                                    : status === "Deferred"
                                    ? styles.predisbursementDeclinedStatus
                                    : status === "Pending"
                                    ? styles.predisbursementPendingStatus
                                    : ""
                                }`}
                              >
                                {status}
                              </p>
                            </td>
                            <td className="th-td-style ">
                              {loanApplicationConditionDocuments.length > 0 ? (
                                loanApplicationConditionDocuments?.map(
                                  ({
                                    document_file_name,
                                    document_url,
                                    id: docId,
                                  }: any) => {
                                    return (
                                      <ul
                                        key={docId}
                                        className={styles.predisbursementList}
                                      >
                                        <li>
                                          <UploadSmall /> {document_file_name}{" "}
                                          <div>
                                            <span
                                              className="font-weight-bold text-underline color-blue cursor-pointer"
                                              data-toggle="modal"
                                              data-target="#loanViewModal"
                                              onClick={() =>
                                                viewDocument(document_url)
                                              }
                                            >
                                              View
                                            </span>
                                            {!currentStatusIdDefered &&
                                              !currentStatusIdDone && (
                                                <span
                                                  className="ml-2"
                                                  role="button"
                                                  aria-roledescription="delete button"
                                                  title="Delete"
                                                  onClick={() =>
                                                    triggerDeleteConditionDocsModal(
                                                      docId,
                                                      document_file_name,
                                                      id
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  <Bin />
                                                </span>
                                              )}
                                          </div>
                                        </li>
                                      </ul>
                                    );
                                  }
                                )
                              ) : (
                                <ul className={styles.predisbursementList}>
                                  <li></li>
                                </ul>
                              )}
                            </td>
                            {!currentStatusIdDone && (
                              <td
                                className={`th-td-style ${styles.prebursementFileUpload} `}
                                onClick={() => {
                                  triggerFileUpload(id);
                                }}
                              >
                                <UploadMedium /> <small> Upload file(s)</small>
                              </td>
                            )}
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <NoTableDataAvailable />
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <form>
        <input
          type="file"
          className="d-none"
          accept="application/pdf"
          ref={selectConditionFile}
          onChange={handleSelectedFileDocument}
        />
      </form>
      <button
        className="d-none"
        ref={triggerUploadConfirmationRef}
        data-toggle="modal"
        data-target="#predisbursementConfirmationUploadModal"
      ></button>
      <button
        className="d-none"
        ref={deleteDocumentConfirmationRef}
        data-toggle="modal"
        data-target="#predisbursementDeleteDocumentModal"
      ></button>
      <PredisbursementConfirmationUploadModal
        currentSelectedFile={currentSelectedFile}
        loanApplicationConditionId={currentLoanApplicationConditionId}
        setCurrentSelectedFile={setCurrentSelectedFile}
        refetchConditions={refetchConditions}
      />
      <PreDisbursementDeleteDocumentModal
        loanApplicationConditionId={currentLoanApplicationConditionId}
        currentConditionDocumentDetails={currentConditionDocumentDetails}
        refetchConditions={refetchConditions}
      />
      <LoanViewDocument
        closeDocumentModal={closeDocumentModal}
        isLoadingDocument={isLoadingDocument}
        documentData={documentData}
      />
    </div>
  );
};

export default PreDisbursement;
