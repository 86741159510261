import React from "react";
import { Route, withRouter } from "react-router-dom";
import InvestorTabs from "./InvestorTabs/InvestorTabs";
import EachInvestorInvestment from "./InvestorTabs/EachInvestorInvestment/EachInvestorInvestment";

const EachInvestor = (props: any) => {
  let match = props.match;
  return (
    <div>
      <Route exact path={match.path} render={() => <InvestorTabs />} />
      <Route
        path={match.path + "/investment"}
        render={() => <EachInvestorInvestment />}
      />
    </div>
  );
};
export default withRouter(EachInvestor);
