import { useEffect, useState, Fragment } from "react";
import styles from "./Alert.module.scss";

interface IAlertProps {
  message: string;
  type?: "success" | "danger" | "info";
}

const Alert: React.FC<IAlertProps> = ({ message, type }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const hideAlert = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(hideAlert);
    };
  }, [message]);

  const close = () => {
    setShow(false);
  };
  return (
    <Fragment>
      {show ? (
        <div className={styles.mainAlert}>
          <div
            className={`${styles.alert} ${
              type === "success" && styles.success
            } ${type === "info" && styles.info}`}
          >
            <div className={styles.message}>{message}</div>
            <div className={styles.close} onClick={close}>
              <span aria-hidden="true">X</span>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
export default Alert;
