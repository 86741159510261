import { Dispatch, SetStateAction, useState } from "react";
import NoTableDataAvailable from "../../NoTableDataAvailable/NoTableDataAvailable.component";
import styles from "./FeedBackLoanApplicationExisting.module.scss";
import { ReactComponent as ArrowUpIcon } from "../../..//img/arrow_up_icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../..//img/arrow_down_icon.svg";
import PageLoader from "../../PageLoader/PageLoader.component";
import FeedBackDocumentViewer from "./FeedBackDocumentViewer/FeedBackDocumentViewer";
import LoaderColored from "../../Loader/LoaderColored";
import { getData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { appInsights } from "../../AppInsight/AppInsight";
interface Icomments {
  id: number;
  comment: string;
  document_url?: string;
  document_file_name?: string;
  user: string;
  user_type: string;
  date_created: string;
  user_type_id: number;
}

interface Ifeedback {
  id: number;
  type: string;
  feedback: string;
  feedback_status: string;
  comments: Icomments[];
}
interface IExistingFeedBack {
  displayCommentBox: any;
  feedBackId: any;
  existingLoanInformationData: {
    feedbacks: Ifeedback[];
  };
  existingLoanInformationStatus: string;
  setFeedBackId: Dispatch<SetStateAction<number | null>>;
  toggleDisplayAddComments: (id: number) => void;
}

const FeedbackLoanApplicationExisting = ({
  displayCommentBox,
  feedBackId,
  setFeedBackId,
  toggleDisplayAddComments,
  existingLoanInformationData,
  existingLoanInformationStatus,
}: IExistingFeedBack) => {
  const [feedbackCommentData, setFeedbackCommentData] = useState<any>();
  const [showList, setShowList] = useState<any>({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [documentData, setDocumentData] = useState<{
    fileName: string;
    base64Url: string;
  }>({
    fileName: "",
    base64Url: "",
  });

  const closeDocumentModal = () => {
    setDocumentData({
      fileName: "",
      base64Url: "",
    });
    setIsLoadingDocument(false);
  };

  const toggleComments = (id: number) => {
    setShowList((prev: any) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const viewDocument = async (documentUrl: string) => {
    setIsLoadingDocument(true);

    try {
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${documentUrl}`
      );

      setDocumentData({
        fileName: data?.file_name,
        base64Url: data?.base64_string,
      });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FeedBackDocumentViewer.tsx",
        },
      });
    }
    setIsLoadingDocument(false);
  };

  const prepareDownloadLink = (content: string, fileName: string) => {
    const appendSource = fileName.endsWith(".pdf")
      ? "application/pdf"
      : "application/msword";

    const linkSource = `data:${appendSource};base64,${content}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setIsDownloading(false);
  };

  const downloadFile = async (pathname: string) => {
    try {
      setIsDownloading(true);
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${pathname}`,
        true
      );
      setIsLoadingDocument(false);

      prepareDownloadLink(data?.base64_string, data?.file_name);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "FeedBackDocumentViewer.tsx",
        },
      });
      setIsDownloading(false);
    }
  };

  return (
    <div>
      <div className="table-responsive">
        {existingLoanInformationStatus === "loading" ? (
          <PageLoader />
        ) : (
          <table className="table">
            <thead className="bg-lighter-grey">
              <tr>
                <th style={{ fontSize: "0.75rem" }}>Feedback Type</th>
                <th style={{ fontSize: "0.75rem" }}>Feedback Status</th>
                <th style={{ fontSize: "0.75rem" }}>Feed Message</th>
                <th style={{ fontSize: "0.75rem" }}>Feedback Comments</th>
                <th style={{ fontSize: "0.75rem" }}>Add Comments</th>
              </tr>
            </thead>

            <tbody>
              {existingLoanInformationData?.feedbacks?.length ? (
                existingLoanInformationData?.feedbacks?.map(
                  (feedbackdata: Ifeedback) => {
                    const { id, type, feedback, feedback_status, comments } =
                      feedbackdata;
                    return (
                      <tr key={id}>
                        <td style={{ fontSize: "0.75rem" }}>{type}</td>
                        <td style={{ fontSize: "0.75rem" }}>
                          {feedback_status}
                        </td>
                        <td style={{ fontSize: "0.75rem" }}>{feedback}</td>

                        <td
                          style={{ fontSize: "0.70rem" }}
                          className="color-blue cursor-pointer"
                          onClick={() => {
                            setFeedbackCommentData(comments);
                            setFeedBackId(id);

                            toggleComments(id);
                          }}
                        >
                          {showList[id] ? (
                            <span>
                              Hide Comments <ArrowUpIcon />
                            </span>
                          ) : (
                            <span>
                              Show Comments <ArrowDownIcon />
                            </span>
                          )}
                        </td>
                        <td
                          style={{ fontSize: "0.70rem" }}
                          className="color-blue cursor-pointer"
                          onClick={() => {
                            setFeedBackId(id);
                            toggleDisplayAddComments(id);
                          }}
                        >
                          <span>
                            Add New Comments{" "}
                            {displayCommentBox[id] ? (
                              <ArrowUpIcon />
                            ) : (
                              <ArrowDownIcon />
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <NoTableDataAvailable />
              )}
            </tbody>
          </table>
        )}

        {showList[feedBackId] && (
          <div className="">
            {feedbackCommentData?.length > 0 &&
              feedbackCommentData?.map?.((commentInfo: any) => {
                const {
                  id,
                  comment,
                  document_url,
                  document_file_name,
                  user,
                  user_type,
                  user_type_id,
                  date_created,
                } = commentInfo;
                // user_type_id === 1 is customer

                const originalDateTime = date_created;
                const formattedDateTime = new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: false,
                }).format(new Date(originalDateTime));
                return (
                  <div key={id} className={`${styles.feedbackCommentData}  `}>
                    <div
                      className={
                        user_type_id === 1
                          ? `${styles.rightCommentsUser} `
                          : `${styles.leftCommentsAdmin} `
                      }
                    >
                      <div>
                        <p className={`${styles.commentdetails}`}>{comment}</p>

                        <div>
                          <div>
                            <small className="bg-light-grey">
                              {document_file_name}
                            </small>
                          </div>
                          <div className="py-2">
                            {document_file_name?.endsWith("pdf") && (
                              <button
                                className="btn btn-sm advancly-btn  cursor-pointer"
                                data-toggle="modal"
                                data-target="#feedbackDocumentModal"
                                onClick={() => viewDocument(document_url)}
                              >
                                Open Document
                              </button>
                            )}
                            {document_url && (
                              <button
                                className="btn btn-sm advancly-white-btn ml-2 "
                                onClick={() => {
                                  downloadFile(document_url);
                                }}
                              >
                                Download Document{" "}
                                {isDownloading && <LoaderColored />}
                              </button>
                            )}
                          </div>
                        </div>
                        <p className={`${styles.nameOfUserComments}`}>{user}</p>
                        <p className={`${styles.nameOfUserType}`}>
                          {user_type}
                        </p>
                        <small className={`${styles.time}`}>
                          {formattedDateTime}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <FeedBackDocumentViewer
        closeDocumentModal={closeDocumentModal}
        isLoadingDocument={isLoadingDocument}
        documentData={documentData}
      />
    </div>
  );
};

export default FeedbackLoanApplicationExisting;
