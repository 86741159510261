import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getABorrowerProfileDetails = async ({ id }: { id: number }) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.aggregatorDetails}${id}`
  );
  return data.data;
};

export default function useABorrowerProfileDetails({ id }: { id: number }) {
  return useQuery(
    [{ id }, "getABorrowerProfileDetails"],
    getABorrowerProfileDetails,
    {
      refetchOnWindowFocus: false,
    }
  );
}
