import React from "react";

const ErrorListComponent = ({ data, setError }) => {
  return (
    <div
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      {data?.map((item, index) => (
        <div key={index}>
          <p>{item.name}</p>

          <ol>
            {item.errors.map((error, errorIndex) => (
              <li key={errorIndex}>{error}</li>
            ))}
          </ol>
        </div>
      ))}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          setError(null);
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default ErrorListComponent;
