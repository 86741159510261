import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IAllBorrowerRepayments {
  id: number;
}
const getAllABorrowerRepayments = async ({ id }: IAllBorrowerRepayments) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllABorrowerRepayments}/${id}`
  );
  return data.data;
};

export default function useAllABorrowerRepayments({
  id,
}: IAllBorrowerRepayments) {
  return useQuery(
    [{ id }, "getAllABorrowerRepayments"],
    getAllABorrowerRepayments,
    {
      refetchOnWindowFocus: false,
    }
  );
}
