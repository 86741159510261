import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { apiEndpoints } from "../../../../../apis/apiEndpoints";
import { useAuth } from "../../../../../Context/auth.context";
import { useQueryCache } from "react-query";
import { deleteData } from "../../../../../apis/apiMethods";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { errorHandler } from "../../../../../helpers/errorHandler";
import PageLoader from "../../../../../components/PageLoader/PageLoader.component";
import styles from "../../../GetStarted.module.scss";
import { ReactComponent as InfoIcon } from "../../../../../img/info_icon.svg";

interface IcurrentId {
  currentId: number | null;
  setCurrentId: Dispatch<SetStateAction<number | null>>;
  currentParentShareholderId?: any;
  setCurrentParentShareholderId?: any;
}

const ConfirmationDeleteShareholderModal = ({
  currentId,
  setCurrentId,
  currentParentShareholderId,
  setCurrentParentShareholderId,
}: IcurrentId) => {
  const [confirmationDeleteLoading, setConfirmationDeleteLoading] =
    useState<boolean>(false);
  const [confirmationSuccessDelete, setConfirmationSuccessDelete] =
    useState<boolean>(false);

  const [confirmationSuccessDeleteMsg, setConfirmationSuccessDeleteMsg] =
    useState<string | null>(null);

  const [confirmationDeleteError, setConfirmationDeleteError] =
    useState<{} | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const queryCache = useQueryCache();
  const { aggregatorDetails } = useAuth();

  const DeleteExistingDirectorOrManager = async () => {
    try {
      setConfirmationDeleteLoading(true);
      setConfirmationSuccessDelete(false);
      const res = await deleteData(
        `${apiEndpoints.SHAREHOLDER_ONBOARDING}/${currentId} `,
        {},
        true
      );
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(res?.message);
      queryCache.invalidateQueries([
        { funderId: aggregatorDetails?.aggregator_id, Type: null },
        "getShareholders",
      ]);
      setCurrentParentShareholderId(null);
      setTimeout(() => {
        setConfirmationSuccessDelete(false);
        setConfirmationSuccessDeleteMsg(null);

        setCurrentId(null);
        closeModalRef?.current?.click();
      }, 2000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "ConfirmationDeleteShareholder.tsx",
        },
      });
      setConfirmationDeleteLoading(false);
      setConfirmationSuccessDeleteMsg(null);
      setConfirmationSuccessDelete(false);
      setConfirmationDeleteError(errorHandler(error));
      setTimeout(() => {
        closeModalRef?.current?.click();
        setConfirmationDeleteError(null);
      }, 5000);
    }
  };
  return (
    <div
      className="modal fade"
      id="confirmationDeleteShareholderModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmationDeleteShareholderModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {confirmationDeleteError && (
            <p className="alert alert-danger small text-center">
              {confirmationDeleteError}
            </p>
          )}
          {(confirmationSuccessDeleteMsg || confirmationSuccessDelete) && (
            <p className="alert alert-success small text-center">
              {" "}
              {confirmationSuccessDeleteMsg}
            </p>
          )}
          <div className="modal-body" style={{ color: "#150849" }}>
            {confirmationDeleteLoading ? (
              <PageLoader />
            ) : (
              <>
                <button
                  type="button"
                  className={`d-flex justify-content-end close`}
                  data-dismiss="modal"
                  aria-label="Close"
                  ref={closeModalRef}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div>
                  <p className="text-center">
                    Are you sure you want to delete?
                  </p>

                  {currentParentShareholderId?.parent_shareholder_id === null &&
                    currentParentShareholderId.shareholder_type ===
                      "Corporate" && (
                      <div
                        className={`d-flex justify-content-center  align-items-center p-3 ${styles.onboarding_info_card}`}
                      >
                        <InfoIcon />
                        <span className=" pl-2">
                          {" "}
                          This is a parent shareholder, deleting the parent
                          shareholder means deleting all child shareholder on
                          this parent
                        </span>
                      </div>
                    )}
                  <div className={`d-flex justify-content-around  `}>
                    <small
                      className="text-center cursor-pointer transition-3d-hover "
                      onClick={DeleteExistingDirectorOrManager}
                    >
                      Yes
                    </small>
                    <small
                      className={`${styles.deleteAcion}  text-center cursor-pointer  transition-3d-hover`}
                      data-dismiss="modal"
                    >
                      No
                    </small>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDeleteShareholderModal;
