import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";
import { apiEndpoints } from "../apis/apiEndpoints";
interface IallWallet {
  pageNumber: number;
  size: number;
  consumerType?: string;
  targetId?: number;
}
const getAllWallet = async ({
  pageNumber,
  size,
  consumerType,
  targetId,
}: IallWallet) => {
  let queryParams = `Page=${pageNumber}&PageSize=${size}`;

  if (consumerType) {
    queryParams = `${queryParams}&ConsumerType=${consumerType}`;
  }
  if (targetId) {
    queryParams = `${queryParams}&TargetId=${targetId}`;
  }
  const data = await getData(`${apiEndpoints.GET_ALL_WALLET}?${queryParams}`);

  return data;
};

export default function useAllWallet({
  pageNumber,
  size,
  consumerType,
  targetId,
}: IallWallet) {
  return useQuery(
    [{ pageNumber, size, consumerType, targetId }, "getAllWallet"],
    getAllWallet,
    { enabled: consumerType && targetId ? true : false }
  );
}
