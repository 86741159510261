import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
type TapplicationList = {
  page: number;
  PageSize: number;
  searchValue?: string;
};
const getLoanApplicationTableList = async ({
  page,
  PageSize,
  searchValue,
}: TapplicationList) => {
  let queryParams = `?Page=${page}&PageSize=${PageSize}`;

  if (searchValue) {
    queryParams = `${queryParams}&search_value=${searchValue}`;
  }

  const data = await getData(`${apiEndpoints.LOAN_APPLICATIONS}${queryParams}`);

  return data;
};

export default function useLoanApplicationTableList({
  page,
  PageSize,
  searchValue,
}: TapplicationList) {
  return useQuery(
    [
      {
        page,
        PageSize,
        searchValue,
      },
      "getLoanApplicationTableList",
    ],
    getLoanApplicationTableList,
    { refetchOnWindowFocus: false }
  );
}
