import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getAggregatorApiKeys = async () => {
  const data = await getData(apiEndpoints.AGGREGATOR_API_KEYS);
  return data?.data;
};

export default function useAggregatorApiKeys() {
  return useQuery(["getAggregatorApiKeys"], getAggregatorApiKeys);
}
