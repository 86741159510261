import React, { ChangeEvent, Fragment, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import useAllWallet from "../../custom-hooks/useAllWallet";
import useAggregatorDetails from "../../custom-hooks/useAggregatorDetails";
import PageLoader from "../PageLoader/PageLoader.component";
import useWalletInformation from "../../custom-hooks/useWalletInformation";
import { formatMoney } from "../../helpers/formatter";
import Loader from "../Loader/Loader.component";
import { IsendMoney } from "./SendMoneyModal";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";

const schema = yup.object({
  walletName: yup.string().required("Please Select a Wallet"),
});
const SelectWalletModal = ({
  sendMoneySteps,
  setSendMoneySteps,
  step,
  setWalletAccountNum,
  setAllWalletsState,
  setWalletProviderType,
}: IsendMoney) => {
  const [walletAccountNumber, setWalletAccountNumber] = useState("");
  const {
    watch,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: aggregatorDetails,
    status: aggregatorStatus,
    error: aggregatorError,
  } = useAggregatorDetails();
  const {
    data: allWallets,
    status: walletStatus,
    error: walletError,
  } = useAllWallet({
    pageNumber: 1,
    size: 1000000000,
    consumerType: "Aggregator",
    targetId: aggregatorDetails?.aggregator_id,
  });

  const walletWatch = watch("walletName");
  const getWalletProvider = allWallets?.data?.find(
    (walletInfo: { accountNumber: string }) => {
      return walletAccountNumber === walletInfo.accountNumber;
    }
  )?.walletProviderId;
  const {
    data: walletInformation,
    status: walletInfoStatus,
    error: walletInfoError,
  } = useWalletInformation({
    accountNumber: walletAccountNumber,
    consumerType: "Aggregator",
    walletProviderId: getWalletProvider,
  });
  const onSubmitHandler = ({ walletName }: { walletName: string }) => {
    setWalletAccountNum(walletName);
    setAllWalletsState(allWallets);
    setWalletProviderType(getWalletProvider);

    setSendMoneySteps(sendMoneySteps + step);
  };
  return (
    <Fragment>
      {aggregatorError && (
        <p className="alert alert-danger small">{aggregatorError as string}</p>
      )}

      {walletError && (
        <p className="alert alert-danger small">
          {walletError as React.ReactNode}
        </p>
      )}
      {walletInfoError && (
        <p className="alert alert-danger small">
          {walletInfoError as React.ReactNode}
        </p>
      )}
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Select Wallet</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              reset();
              setSendMoneySteps(1);
            }}
          >
            <CloseModal />
          </button>
        </div>
        <div className="modal-body">
          {walletStatus === "loading" || aggregatorStatus === "loading" ? (
            <div>
              {" "}
              <PageLoader />
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="row">
                <div className="col-12">
                  <CustomSelectDropdown
                    label="Wallet"
                    name="walletName"
                    ref={register}
                    errors={errors.walletName}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setWalletAccountNumber(e.target.value)
                    }
                  >
                    <option value="">Select Wallet</option>
                    {allWallets?.data?.map(
                      (wallet: {
                        id: number;
                        accountName: string;
                        accountNumber: string | number;
                        currency: string;
                        bankName: string;
                      }) => (
                        <option value={wallet.accountNumber} key={wallet.id}>
                          {`${wallet.accountName}  ${wallet.currency} Acct No. ${wallet.accountNumber}  ${wallet.bankName}`}{" "}
                        </option>
                      )
                    )}
                  </CustomSelectDropdown>
                </div>

                <h6 className="col">Available Balance</h6>
                <h6 className="col text-right  color-mid-blue font-weight-bold">
                  {walletInfoStatus === "loading" ? (
                    <Loader />
                  ) : (
                    formatMoney(
                      walletInformation?.data
                        ? walletInformation?.data?.availableBalance
                        : 0,
                      "#"
                    )
                  )}
                </h6>
                <div className="col-12 pb-4 pt-4">
                  <button
                    className="btn btn-sm advancly-btn  w-100"
                    disabled={!walletWatch}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SelectWalletModal;
