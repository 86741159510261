import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "../../components/Loader/Loader.component";
import CustomTextArea from "../../components/CustomHTMLElements/CustomTextArea";
import { useQueryCache } from "react-query";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  comment: yup
    .string()
    .trim()
    .required("Please enter a comment")
    .min(5, "Comment must have at least 5 characters"),
});

const BulkLoanCommentModal = ({
  loading,
  setLoading,
  setDataToStore,
  setChecked,
  setCheckAll,
  dataToStore,
  commentType,
  setSuccess,
  setError,
}: any) => {
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  //@ts-ignore
  const dataToActionOn: any = [...new Set(dataToStore)];

  // Bulk Loan approval
  const onSubmit = async ({ comment }: { comment: string }) => {
    const result = dataToActionOn.toString();

    setLoading(true);
    setError(null);
    setSuccess(null);

    const url =
      commentType === "approve"
        ? "/eco/aggregator_bulk_loan_approval"
        : commentType === "decline"
        ? "/eco/aggregator_bulk_loan_rejection"
        : null;

    const reqBody = {
      state_tokens: result,
      aggregator_comment: comment,
    };
    try {
      const res = await postDataWithDotNet(url, reqBody);
      setDataToStore([]);
      setChecked(false);
      setCheckAll(false);
      setLoading(false);
      reset();
      window.scrollTo(0, 0);
      setSuccess(res.message);
      setError(null);
      // Refetch Pending Loans Data
      queryCache.invalidateQueries("getPendingLoansDetails");
      dismissRef.current?.click();
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "BulkLoanCommentModal.component.jsx",
        },
      });
      reset();
      window.scrollTo(0, 0);
      setError(errorHandler(err));
      setLoading(false);
      setDataToStore([]);
      setChecked(false);
      setCheckAll(false);
      dismissRef.current?.click();
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "BulkLoanCommentModal.component.jsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div
      className="modal fade"
      id="bulkLoanCommentModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="bulkLoanCommentModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h6 className="text-center font-weight-bold text-capitalize">
              {commentType} {dataToActionOn.length > 1 ? "Loans" : "Loan"}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <CustomTextArea
                  maxLength={128}
                  ref={register}
                  placeholder="Add a comment"
                  label="Add Comment"
                  name="comment"
                  errors={errors.comment}
                />
                <div className="">
                  <button
                    type="button"
                    className="btn advancly-white-btn  transition-3d-hover w-100"
                    data-dismiss="modal"
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn advancly-btn  transition-3d-hover w-100 mt-3"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BulkLoanCommentModal;
