import advanclyLogo from "../../../../img/advancly_logo.svg";
import { useEffect, useState } from "react";
import GetStartedBasicInfo from "./GetStartedBasicBusinessInfo/GetStartedBasicInfo";
import { Link } from "react-router-dom";
import styles from "../../GetStarted.module.scss";
import GetStartedShareHolder from "./GetStartedShareHolders/GetStartedShareHolder";
import GetStartedBoardOfDirectorsInfo from "./GetStartedBoardOfDirectors/GetStartedBoardOfDirectorsInfo";
import GetStartedSeniorManagementInfo from "./GetStartedSeniorManagement/GetStartedSeniorManagementInfo";
import { useAuth } from "../../../../Context/auth.context";
export interface IstepFunctions {
  handlePrev: () => void;
  handleNext?: () => void;
  scrollToTop?: () => void;
}

export default function GetStartedBusinesInfoMain() {
  const { eligibilityData } = useAuth();
  const scrollToTop = () => {
    //@ts-ignore
    document.getElementById("topOfPage").scrollIntoView({
      behavior: "smooth",
    });
  };
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (eligibilityData?.lastUpdatedOnboardingDetailCode === 1) {
      setStep(0);
    } else if (eligibilityData?.lastUpdatedOnboardingDetailCode === 2) {
      setStep(1);
    } else if (eligibilityData?.lastUpdatedOnboardingDetailCode === 3) {
      setStep(2);
    } else if (eligibilityData?.lastUpdatedOnboardingDetailCode === 4) {
      setStep(3);
    } else {
      setStep(0);
    }
  }, [eligibilityData]);

  const title = {
    1: "Business Information",
    2: "Shareholders",
    3: "Board of Directors",
    4: "Senior Management",
  };
  //progress bar styling

  const interval = 100 / Object.keys(title).length - 4;

  const progress = ((step + 1) * interval).toFixed(2);
  const clickOnProgressBarHandler = (stepClicked: string) => {
    if (stepClicked === "Business Information") {
      setStep(0);
    }
    if (stepClicked === "Shareholders") {
      setStep(1);
    }
    if (stepClicked === "Board of Directors") {
      setStep(2);
    }
    if (stepClicked === "Senior Management") {
      setStep(3);
    }
  };

  const steps = Object.values(title).map((stepParams, i) => {
    return (
      <div
        key={i}
        className={`cursor-pointer ${styles.barmarker}`}
        onClick={() => {
          clickOnProgressBarHandler(stepParams);
        }}
      >
        {stepParams}
      </div>
    );
  });

  // Renders with every input event
  // Will fix next => for  optimization
  const handlePrev = () => setStep((prev) => prev - 1);
  const handleNext = () => setStep((next) => next + 1);

  return (
    <div id="topOfPage">
      <div className={`${styles.logo_progressbar_container}`}>
        <div>
          <Link to="/">
            <img src={advanclyLogo} alt="Login logo" />
          </Link>
        </div>
        <div className={styles.progressContainer}>
          <progress
            className={styles.progress}
            max="100"
            value={progress}
          ></progress>
          <div className={styles.barmarkerContainer}>{steps}</div>
        </div>
      </div>
      {(() => {
        switch (step) {
          case 0:
            return (
              <GetStartedBasicInfo
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 1:
            return (
              <GetStartedShareHolder
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );

          case 2:
            return (
              <GetStartedBoardOfDirectorsInfo
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );

          case 3:
            return (
              <GetStartedSeniorManagementInfo
                handlePrev={handlePrev}
                scrollToTop={scrollToTop}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}
