import React, { useRef, useState } from "react";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { putDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { useQueryCache } from "react-query";
import styles from "../LoanApplication.module.scss";
import { ReactComponent as AdvanclyIcon } from "../../../svg/icons/advancly-3d-icon-1.svg";
import { ReactComponent as CloseModal } from "../../../svg/icons/modal-close.svg";
import { useAuth } from "../../../Context/auth.context";
import Loader from "../../Loader/Loader.component";
import { convertToBase64 } from "../../../helpers/convertToBase64";
const ConfirmationModalReUploadOfferLetter = ({
  selectedFileReUpload,
  reUploadDocumentTypeId,
  offerLetterApplication,
  reUploadOfferLetterDetailsId,
}: any) => {
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { aggregatorDetails, currentLoanApplicationId } = useAuth();

  const updateUserStatus = async () => {
    //@ts-ignore
    const fileBase64 = await convertToBase64(selectedFileReUpload);
    setLoading(true);
    setError(null);
    const reqData = {
      loan_application_id: currentLoanApplicationId,
      customer_id: aggregatorDetails?.customer_id,
      offer_letter_details: [
        {
          document_type_id: reUploadDocumentTypeId,
          base64_file_string: fileBase64,
          filename_with_extension: (selectedFileReUpload as any)?.name,
          id: reUploadOfferLetterDetailsId,
        },
      ],
      id: offerLetterApplication?.id,
      comment: "string",
    };
    try {
      const res = await putDataWithDotNet(
        `${apiEndpoints.REUPLOAD_OFFER_LETTER}`,
        reqData
      );
      if (res.status === true) {
        setSuccess(`Document has been successfully reuploaded`);
        queryCache.invalidateQueries([
          {
            customerId: aggregatorDetails?.customer_id,
            loanApplicationId: currentLoanApplicationId,
          },
          "getOfferLetterAndSla",
        ]);
        setTimeout(() => {
          dismissRef.current?.click();
          setSuccess(null);
        }, 3000);
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "ConfirmationModalReUploadOfferLetter.tsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const hideModal = () => {
    dismissRef.current?.click();
  };
  return (
    <div
      className="modal fade"
      id="confirmationModalReUploadOfferLetter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="confirmationModalReUploadOfferLetterlLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close "
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              {" "}
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div
              className={`d-flex justify-content-center ${styles.advanclyIconDialog}`}
            >
              <AdvanclyIcon />
            </div>
            <h5
              className={`page-subtitle text-center w-100 ${styles.confirmationHeader}`}
              id="updateUserStatusModalLabel"
            >
              Are you sure you want to reupload file ?
            </h5>
            <p className={`text-muted ${styles.paragraphConfirmationReupload}`}>
              {(selectedFileReUpload as any)?.name}
            </p>
            <div className="">
              <button
                className={`btn btn-sm advancly-white-btn transition-3d-hover w-100 ${styles.updateUserConfirmationCancelButton}`}
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm advancly-btn transition-3d-hover mt-2 w-100"
                disabled={loading}
                onClick={updateUserStatus}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModalReUploadOfferLetter;
