import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useAggregatorUsers from "../../custom-hooks/useAggregatorUsers";
import AddNewUserModal from "../AddNewUserModal/AddNewUserModal.component";
import styles from "./UsersManagement.module.scss";
import { errorHandler } from "../../helpers/errorHandler";
import UpdateUserConfirmationDialog from "../UpdateUserConfirmationDialog/UpdateUserConfirmationDialog.component";
import NoTableDataAvailable from "../NoTableDataAvailable/NoTableDataAvailable.component";
import TableDataLoading from "../TableDataLoading/TableDataLoading.component";
import EditUserRoleModal from "../EditUserRoleModal/EditUserRoleModal";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";
import { ReactComponent as PlusIcon } from "../../svg/icons/plus-icon.svg";
import mixpanel from "mixpanel-browser";

export interface IUser {
  email: string;
  lastName: string;
  firstName: string;
  isActive: string;
  id: string;
  userId: string;
  phone: string;
  roles: string[];
}

const UsersManagement = () => {
  const { data: users, status, error } = useAggregatorUsers();
  const [userToUpdate, setUserToUpdate] = useState<IUser>();
  useEffect(() => {
    mixpanel.track_pageview({ page: "Users Management-Spark" });
  }, []);
  if (error)
    return (
      <p className="alert alert-danger small text-center">
        {errorHandler(error)}
      </p>
    );

  return (
    <div>
      <div
        className={`d-flex justify-content-between ${styles.UserManagementHeader}`}
      >
        <h3 className="page-title ">Users Management</h3>

        <button
          className="btn btn-sm advancly-btn"
          data-toggle="modal"
          data-target="#addNewUserModal"
        >
          <PlusIcon /> Add New User
        </button>
      </div>
      <div className="advancly-wrapper-container">
        <div className="advancly-sub-wrapper">
          <div>
            <div>
              <div className="table-responsive">
                <table className="table-style">
                  <thead className="">
                    <tr>
                      <th className="th-td-style th-style ">Name</th>
                      <th className="th-td-style th-style ">Email Address</th>
                      <th className="th-td-style th-style ">Phone Number</th>
                      <th className="th-td-style th-style "> Assigned Role</th>
                      <th className="th-td-style th-style ">Status</th>
                      <th className="th-td-style th-style "> Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {status === "loading" ? (
                      <TableDataLoading />
                    ) : users && users.length ? (
                      <Fragment>
                        {users.map((user: IUser) => {
                          const {
                            email,
                            lastName,
                            firstName,
                            isActive,
                            id,
                            phone,
                            roles,
                          } = user;

                          return (
                            <tr key={id}>
                              <td className="th-td-style ">
                                {firstName} {lastName}
                              </td>
                              <td className="th-td-style ">{email}</td>
                              <td className="th-td-style ">{phone}</td>
                              <td className="th-td-style ">
                                {" "}
                                {roles.map((role, id) => {
                                  return (
                                    <div
                                      className={styles.assignedRole}
                                      key={id}
                                    >{`${role},`}</div>
                                  );
                                })}
                              </td>
                              <td className="th-td-style ">
                                {isActive ? "Active" : "Inactive"}
                              </td>
                              <td className="dropright  th-td-style ">
                                <span
                                  className=""
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <MoreIconHorizontal />
                                </span>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    className="btn btn-sm advancly-nobg-btn"
                                    data-toggle="modal"
                                    data-target="#updateUserStatusModal"
                                    onClick={() => setUserToUpdate(user)}
                                  >
                                    {isActive ? "Disable User" : "Enable User"}
                                  </button>
                                  <button
                                    className="btn btn-sm advancly-nobg-btn"
                                    data-toggle="modal"
                                    data-target="#editUserRoleModal"
                                    onClick={() => setUserToUpdate(user)}
                                  >
                                    {" "}
                                    Edit User
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <NoTableDataAvailable text="There are currently no users" />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewUserModal />
      <EditUserRoleModal userToUpdate={userToUpdate} />
      <UpdateUserConfirmationDialog userToUpdate={userToUpdate} />
    </div>
  );
};

export default withRouter(UsersManagement);
