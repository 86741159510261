import {
  GRAPH_SCALE,
  //  BAR_CHART_SCALE
} from "./graphOptions";

export const formatGraphData = (data: any[]) => {
  let labels: string[] = [];
  let modifiedData: number[] = [];
  if (data && data.length) {
    data.map((data) => labels.push(data.valueName)).slice(0, 3);
    data
      .filter((data) => modifiedData.push(Number(data.amount) / GRAPH_SCALE))
      .slice(0, 3);
  }
  return {
    labels,
    modifiedData,
  };
};

export const formatBorrowerGraphData = (data: any[]) => {
  let labels: any = [];

  let datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[];
    cutout: string;
    radius: string;
  }[] = [
    {
      data: [],
      backgroundColor: ["#FF5E2E ", "#200865"],
      borderColor: ["#FF5E2E ", "#200865"],
      cutout: "90%",
      radius: "70%",
    },
    // {
    //   data: [],
    //   backgroundColor: ["#FF5E2E ", "#200865"],
    //   borderColor: ["#FF5E2E ", "#200865"],
    // },
  ];
  // if (data && data.length) {
  //   console.log(data);
  //   data.map((data: any) => labels.push([data.valueName])).slice(0, 3);

  //   data.slice(0, 3).map((newData: any, i) => {
  //     console.log(newData);
  //     datasets[0].data.push(
  //       Number(newData.statusCount.totalActive / BAR_CHART_SCALE)
  //     );
  //     datasets[1].data.push(
  //       Number(newData.statusCount.totalInActive / BAR_CHART_SCALE)
  //     );
  //     return null;
  //   });
  // }
  const activeBorrowerCount = data
    .map((dataCheck: any) => {
      return dataCheck.statusCount.totalActive;
    })
    .reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0);
  const inActiveBorrowerscount = data
    .map((dataCheck: any) => {
      return dataCheck.statusCount.totalInActive;
    })
    .reduce((accumulator: any, value: any) => {
      return accumulator + value;
    }, 0);
  datasets[0].data.push(activeBorrowerCount, inActiveBorrowerscount);
  labels.push(
    `${activeBorrowerCount} Active`,
    `${inActiveBorrowerscount} Inactive`
  );
  return {
    labels,
    datasets,
  };
};
export const formatInvestorsGraphData = (data: any[]) => {
  let labels: any = [];

  let datasets: {
    data: any[];
    backgroundColor: string[];
    borderColor: string[];
    cutout: string;
    radius: string;
  }[] = [
    {
      data: [],
      backgroundColor: ["#FF5E2E ", "#200865"],
      borderColor: ["#FF5E2E ", "#200865"],
      cutout: "90%",
      radius: "70%",
    },
  ];

  const activeBorrowerCount = data.map((dataCheck: any) => {
    return dataCheck.statusCount.totalActive;
  });

  const inActiveBorrowerscount = data.map((dataCheck: any) => {
    return dataCheck.statusCount.totalInActive;
  });

  datasets[0].data.push(activeBorrowerCount, inActiveBorrowerscount);
  labels.push(
    `${activeBorrowerCount} Active`,
    `${inActiveBorrowerscount} Inactive`
  );
  return {
    labels,
    datasets,
  };
};
