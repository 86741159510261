import { Fragment, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import moment from "moment";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import Paginator from "../../components/Paginator/Paginator.component";
import usePendingDealSlipList from "../../custom-hooks/usePendingDealSlips";
import { attachClassNameToTag } from "../../helpers/attachClassNameToTag";
import { formatMoney } from "../../helpers/formatter";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";
import ToolTip from "../../components/ToolTip/ToolTip";

interface IpendingDealSlips {
  loan_ref: string;
  datetime_created: string;
  status: string;
  loan_id: number;
  biz_name: string;
  loan_amount: number;
  first_name: string;
  last_name: string;
}
function PendingDealSlips() {
  const [Type] = useState("Aggregator");
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(20);
  const [showPendingDealSlipToolTip, setPendingDealToolTip] = useState(false);

  const history = useHistory();

  const { data, status, error } = usePendingDealSlipList({
    Page: Page,
    PageSize: PageSize,
    Type: Type,
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "PendingDealSlip.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <h3 className="page-title">Pending Deal Slips</h3>
        </div>
      </div>
      <div className="advancly-wrapper-container">
        <div className="px-3 pt-4">
          <Fragment>
            <div className="">
              <div className="">
                <div>
                  {error && (
                    <p className="alert alert-danger small">
                      {error as string}
                    </p>
                  )}
                  {status === "loading" ? (
                    <div className="d-flex justify-content-center p-5">
                      <PageLoader />
                    </div>
                  ) : (
                    !error && (
                      <div className="">
                        <div
                          className="advancly-sub-wrapper "
                          style={{ position: "relative" }}
                        >
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="page-subtitle"></div>
                            <div
                              className="d-flex align-items-center"
                              onMouseEnter={() => setPendingDealToolTip(true)}
                              onMouseLeave={() => setPendingDealToolTip(false)}
                            >
                              <p className="pr-2">Showing:</p>
                              <CustomSelectDropdown
                                onChange={(e: any) =>
                                  setPageSize(e.target.value)
                                }
                                value={PageSize}
                              >
                                <option value={10}>10 Entries</option>
                                <option value={20}>20 Entries</option>
                                <option value={50}>50 Entries</option>
                                <option value={100}>100 Entries</option>
                              </CustomSelectDropdown>

                              {showPendingDealSlipToolTip && (
                                <ToolTip
                                  tooltipContainerRight={"1rem"}
                                  tooltipContainerTop={"6rem"}
                                  tooltipHeading={"Entries"}
                                  tooltipParagraph={`Maximise your overview: Select the number of entries to view multiple loans on a single page and effortlessly compare your options`}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="advancly-sub-wrapper mt-3 ">
                          <div className="table-responsive">
                            <table className="table-style">
                              <thead className="">
                                <tr>
                                  <th className="th-td-style th-style cursor-pointer ">
                                    Reference
                                  </th>
                                  <th className="th-td-style th-style ">
                                    Borrower Name
                                  </th>
                                  <th className="th-td-style th-style ">
                                    Business Name
                                  </th>
                                  <th className="th-td-style th-style ">
                                    Amount
                                  </th>
                                  <th className="th-td-style th-style ">
                                    Date
                                  </th>
                                  <th className="th-td-style th-style ">
                                    Status
                                  </th>
                                  <th className="th-td-style th-style  ">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data && data.data?.data?.length ? (
                                  data.data?.data?.map(
                                    (data: IpendingDealSlips, id: number) => {
                                      const {
                                        loan_ref,
                                        datetime_created,
                                        status,
                                        loan_id,
                                        biz_name,
                                        loan_amount,
                                        first_name,
                                        last_name,
                                      } = data;
                                      return (
                                        <tr key={id}>
                                          <td className="th-td-style ">
                                            {loan_ref}
                                          </td>
                                          <td className="th-td-style ">{`${
                                            first_name ? first_name : ""
                                          } ${last_name ? last_name : ""}`}</td>
                                          <td className="th-td-style ">
                                            {biz_name}
                                          </td>
                                          <td className="th-td-style ">
                                            {formatMoney(loan_amount, "NGN")}
                                          </td>
                                          <td
                                            className="th-td-style "
                                            style={{ minWidth: "120px" }}
                                          >
                                            {moment(datetime_created).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </td>

                                          <td
                                            className="th-td-style "
                                            style={{ minWidth: "120px" }}
                                          >
                                            <span
                                              className={attachClassNameToTag(
                                                status
                                              )}
                                            >
                                              {status}
                                            </span>
                                          </td>
                                          <td className="dropright  th-td-style cursor-pointer ">
                                            <span
                                              className=""
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <MoreIconHorizontal />
                                            </span>
                                            <div
                                              className="dropdown-menu"
                                              aria-labelledby="dropdownMenuButton"
                                            >
                                              <button
                                                className="btn btn-sm advancly-nobg-btn"
                                                onClick={() =>
                                                  history.push({
                                                    pathname: `/deal-slip-status/${loan_id}/download`,
                                                    state: {
                                                      slipMetaData: {
                                                        admin_uploaded_slip_filename:
                                                          //@ts-ignore
                                                          data?.admin_uploaded_slip_filename,
                                                        admin_uploaded_slip_path:
                                                          //@ts-ignore
                                                          data?.admin_uploaded_slip_path,
                                                        loan_id: data?.loan_id,
                                                        //@ts-ignore

                                                        deal_slip_id: data?.id,
                                                        from: "pendingDealSlips",
                                                        loan_ref:
                                                          data?.loan_ref,
                                                        deal_slip_comments:
                                                          //@ts-ignore

                                                          data?.comments,
                                                      },
                                                    },
                                                  })
                                                }
                                              >
                                                {" "}
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <NoTableDataAvailable />
                                )}
                              </tbody>
                            </table>
                          </div>
                          <Paginator
                            size={PageSize}
                            page={Page}
                            count={data?.data?.totalCount}
                            changeCurrentPage={(pageNumber) =>
                              setPage(pageNumber)
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
}
export default PendingDealSlips;
