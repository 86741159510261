import React, { Fragment, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import { yupValidators } from "../../helpers/yupValidators";
import { useAuth } from "../../Context/auth.context";
import useCountryBanksList from "../../custom-hooks/useCountryBanksList";
import { errorHandler } from "../../helpers/errorHandler";
import PageLoader from "../PageLoader/PageLoader.component";
import useValidateBankAccount from "../../custom-hooks/useValidateBankAccount";
import { ISendMoneyModalComponent } from "./SendMoneyModal";
import LoaderColored from "../Loader/LoaderColored";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";

const schema = yup.object().shape({
  amount: yupValidators.amount,
  bankCode: yupValidators.bankCode,
  accountNumber: yupValidators.accountNumber,
});
const SendMoneyModalComponent = ({
  sendMoneySteps,
  setSendMoneySteps,
  step,
  setRecipientDetails,
  walletAccountNum,
  allwalletsState,
}: ISendMoneyModalComponent) => {
  const { aggregatorDetails } = useAuth();
  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useCountryBanksList({ country_code: aggregatorDetails?.country_code });
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmitHandler = (data: {
    amount: string;
    bankCode: string;
    accountNumber: string;
  }) => {
    const { amount, bankCode, accountNumber } = data;
    setRecipientDetails({
      amount,
      bankCode,
      accountNumber,
    });
    setSendMoneySteps(sendMoneySteps + step);
  };
  const accountNumber = watch("accountNumber");
  const bankCodeValue = watch("bankCode");
  const bankCode = bankListData?.find((bank: { code: string }) => {
    return bank?.code === bankCodeValue;
  })?.code;
  useEffect(() => {
    setValue("accountNumber", "");
    // eslint-disable-next-line
  }, [bankCodeValue]);
  const {
    data: accountName,
    isLoading: isAccountNameLoading,
    isError: isAccountNameError,
    error: accountNameError,
  } = useValidateBankAccount({
    bankCode,
    accountNumber,
  });
  const getWalletCurrency = allwalletsState?.data.find((wallet: any) => {
    return wallet.accountNumber === walletAccountNum;
  }).currency;
  return (
    <Fragment>
      <div className="modal-content">
        {bankListError && (
          <p className="alert alert-danger small">
            {errorHandler(bankListError)}
          </p>
        )}
        {isAccountNameError && (
          <p className="alert alert-danger mb-4 small ">
            {errorHandler(accountNameError)}
          </p>
        )}
        <div className="modal-header">
          <h4 className="modal-title">Send Money</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              setSendMoneySteps(1);
            }}
          >
            <CloseModal />
          </button>
        </div>
        <div className="modal-body">
          {bankStatus === "loading" ? (
            <PageLoader />
          ) : (
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="row">
                <small
                  className="color-mid-blue text-right   cursor-pointer font-weight-bold pl-2 pb-2"
                  onClick={() => {
                    setSendMoneySteps(sendMoneySteps - step);
                  }}
                >
                  &#8592; Go back
                </small>
              </div>
              <div className="row">
                <div className="col-12">
                  <CustomInputField
                    type="number"
                    name="amount"
                    ref={register}
                    label="Amount"
                    errors={errors.amount}
                    showRequiredIcon={true}
                  >
                    <select>
                      <option>{getWalletCurrency}</option>
                    </select>
                  </CustomInputField>
                </div>
                <div className="col-12">
                  <CustomSelectDropdown
                    name="bankCode"
                    ref={register}
                    label="Bank"
                    showRequiredIcon={true}
                    errors={errors.bankCode}
                  >
                    <option value="">Select Receiver Bank</option>
                    {bankListData &&
                      bankListData.length &&
                      bankListData.map(
                        (bank: { code: string; name: string }) => (
                          <option key={bank.code} value={bank.code}>
                            {bank.name}
                          </option>
                        )
                      )}
                  </CustomSelectDropdown>
                </div>

                <div className="col-12">
                  <CustomInputField
                    type="number"
                    maxLength={20}
                    name="accountNumber"
                    placeholder="Enter Receiver Account Number"
                    ref={register}
                    label="Account Number"
                    errors={errors.accountNumber}
                    showRequiredIcon={true}
                  />
                </div>
                <p className="col-12 color-mid-blue text-right">
                  {isAccountNameLoading ? <LoaderColored /> : accountName}{" "}
                </p>
                <div className="col-12 pb-4 pt-4">
                  <button
                    className="btn btn-sm advancly-btn  w-100"
                    disabled={!accountName}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SendMoneyModalComponent;
