import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getCollateralList = async () => {
  const { data } = await getData(
    `${apiEndpoints.COLLATERAL_LIST_DOCUMENTS}`,
    true
  );
  return data;
};

export default function useCollateralList() {
  return useQuery(["getListOfFinancialDocument"], getCollateralList, {
    refetchOnWindowFocus: false,
  });
}
