import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getProductType = async () => {
  const data = await getDataWithDotNet(apiEndpoints.PRODUCT_TYPE);
  return data.data;
};

export default function useProductType() {
  return useQuery(["getProductType"], getProductType, {
    refetchOnWindowFocus: false,
  });
}
