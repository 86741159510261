import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getLoanProductDetails = async () => {
  const data = await getData(apiEndpoints.getLoanProductDetails);
  return data.data;
};

export default function useLoanProductDetails() {
  return useQuery(["getLoanProductDetails"], getLoanProductDetails, {
    refetchOnWindowFocus: false,
  });
}
