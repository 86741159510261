import moment from "moment";
import { useHistory } from "react-router-dom";
import { formatMoney } from "../../helpers/formatter";
import { accurateQuantifier } from "../utility";
import { ReactComponent as MoreIconHorizontal } from "../../svg/icons/more-icon-2.svg";

const EachPendingLoanRow = ({
  data,
  checkboxHandler,
  checked,
  setSingleLoanToActionOn,
  loading,
}: any) => {
  const {
    id,
    product_name,
    borrower_details,
    amount,
    amount_interest,
    state_token,
    loan_tenure,
    currency,
    submitted_on_date,
  } = data;
  let history = useHistory();

  const handleClick = (props: { borrower_id: number }) => {
    history.push(`/borrowers/${props.borrower_id}`);
  };
  return (
    <tr>
      <td className="th-td-style ">
        <input
          type="checkbox"
          data-id={id}
          data-ref={state_token}
          value={checked}
          onClick={(e) =>
            checkboxHandler({
              eventTarget: e.target,
            })
          }
          className="loans_for_disbursement"
        />
      </td>
      <td className="th-td-style ">{state_token}</td>
      <td className={"text-capitalize th-td-style "}>
        {borrower_details ? borrower_details.first_name.toLowerCase() : ""}
      </td>
      <td className={"text-capitalize th-td-style"}>
        {borrower_details ? borrower_details.last_name.toLowerCase() : ""}
      </td>
      <td className="th-td-style ">{data ? product_name : "None"}</td>
      <td className="th-td-style ">
        {data ? formatMoney(amount, currency) : 0}
      </td>
      <td className="th-td-style ">
        {data ? formatMoney(amount_interest, currency) : "None"}
      </td>
      <td className="th-td-style ">
        {data ? accurateQuantifier(loan_tenure, "day") : "None"}
      </td>
      <td className="th-td-style ">
        {moment(submitted_on_date).format("DD-MM-YYYY")}
      </td>

      <td className="dropright  th-td-style text-center  ">
        <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <MoreIconHorizontal />
        </span>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button
            className="btn btn-sm advancly-nobg-btn"
            name="Accept"
            onClick={() =>
              setSingleLoanToActionOn({ commentType: "approve", state_token })
            }
            data-toggle="modal"
            data-target="#singleLoanCommentModal"
            disabled={loading}
          >
            Approve Loan
          </button>
          <button
            className="btn btn-sm advancly-nobg-btn"
            name="Reject"
            onClick={() =>
              setSingleLoanToActionOn({ commentType: "decline", state_token })
            }
            data-toggle="modal"
            data-target="#singleLoanCommentModal"
            disabled={loading}
          >
            Decline Loan
          </button>
          <button
            className="btn btn-sm advancly-nobg-btn"
            name="View Profile"
            onClick={() => handleClick(borrower_details)}
          >
            View Profile
          </button>
        </div>
      </td>
    </tr>
  );
};
export default EachPendingLoanRow;
