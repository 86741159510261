export const currency = (value: string) => {
  return Number(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getLast4 = (str: string) => {
  return str ? str.substring(str.length - 4, str.length) : "";
};

export const accurateQuantifier = (value: string, quantifier: string) => {
  const accurateQuantifier = `${value} ${quantifier}`;
  if (parseInt(value) === 1) {
    return accurateQuantifier;
  } else {
    return accurateQuantifier + "s";
  }
};
