import React, { Fragment, useRef, useState } from "react";
import styles from "../LoanApplication.module.scss";
import localStyles from "./LoanApplicationOfferLetter.module.scss";
import {
  DeleteUploadedDocument,
  DownloadOfferLetterDocument,
} from "../../UploadedDocumentCards/UploadedDocumentCard.component";
import { ReactComponent as Upload } from "../../../svg/icons/fileUpload.svg";
import Loader from "../../Loader/Loader.component";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { getData, putData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { useAuth } from "../../../Context/auth.context";
import useOfferLetterAndSla from "../../../custom-hooks/useOfferLetterAndSla";
import PageLoader from "../../PageLoader/PageLoader.component";
import { appInsights } from "../../AppInsight/AppInsight";
import DeclineLoanOfferModal from "./DeclineLoanOfferModal";
import { useQueryCache } from "react-query";
import ConfirmationModalReUploadOfferLetter from "./ConfirmationModalReUploadOfferLetter";
import LoanViewDocument from "./LoanViewDocument";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
import { useHistory } from "react-router-dom";
enum EnumofferLetterStatus {
  CustomerOfferLetterNotSent = 1,
  CustomerOfferLetterAcceptance = 2,
  CustomerOfferLetterDeclined = 3,
  AdvanclyOfferLetterAcceptance = 4,
  AdvanclyOfferLetterApproved = 5,
}
const LoanApplicationOfferLetter = ({ scrollToTop }: any) => {
  const [acceptError, setAcceptError] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileReUpload, setSelectedFileReUpload] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [loadingAcceptOffer, setLoadingAcceptOffer] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  //  const [loadingAcceptOffer,setLoadingAcceptOffer]=useState(false)

  const [acceptSuccessMsg, setAcceptSucessMsg] = useState<string | null>(null);
  const [offerLetterSuccessMsg, setOfferLetterSucessMsg] = useState<
    string | null
  >(null);
  const [documentTypeId, setDocumentTypeId] = useState<number | null>(null);
  const [reUploadDocumentTypeId, setReUploadDocumentTypeId] = useState<
    number | null
  >(null);
  const [offerLetterDetailsId, setOfferLetterDetailsId] = useState<
    number | null
  >(null);
  const [reUploadOfferLetterDetailsId, setReUploadOfferLetterDetailsId] =
    useState<number | null>(null);
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const queryCache = useQueryCache();
  const history = useHistory();

  const [documentData, setDocumentData] = useState<{
    fileName: string;
    base64Url: string;
  }>({
    fileName: "",
    base64Url: "",
  });
  const fileInputRef = useRef<any>(null);
  const fileInputRefReupload = useRef<any>(null);
  const triggerConfirmationReUploadModal = useRef<any>(null);
  const triggerFileUpload = (document_type_id: number, id: number) => {
    setDocumentTypeId(document_type_id);
    setOfferLetterDetailsId(id);
    (fileInputRef as any).current.click();
  };
  const triggerFileReUpload = (document_type_id: number, id: number) => {
    setReUploadDocumentTypeId(document_type_id);
    setReUploadOfferLetterDetailsId(id);
    (fileInputRefReupload as any).current.click();
  };

  const { aggregatorDetails, currentLoanApplicationId } = useAuth();

  const closeDocumentModal = () => {
    setDocumentData({
      fileName: "",
      base64Url: "",
    });
    setIsLoadingDocument(false);
  };
  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (!event.target.files[0]) return;
    setError(null);
    const fileName = event.target.files[0].name;

    const isValidFile = fileName.endsWith(".pdf") || fileName.endsWith(".PDF");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
  };
  const handleFileSelectReUpload = (event: any) => {
    const file = event?.target?.files[0];
    setSelectedFileReUpload(file);

    if (!event.target.files[0]) return;
    setError(null);
    const fileName = event.target.files[0].name;

    const isValidFile = fileName.endsWith(".pdf" || "PDF");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    if (event.target.files[0]) {
      triggerConfirmationReUploadModal.current.click();
    }
  };

  const handleAcceptOfferLetter = async () => {
    const reqBody = {
      loan_application_id: currentLoanApplicationId,
      id: offerLetterApplication?.id,
      customer_id: aggregatorDetails?.customer_id,
    };
    setLoadingAcceptOffer(true);
    try {
      const res = await putData(apiEndpoints.ACCEPT_OFFER_LETTER, reqBody);
      setAcceptSucessMsg(res?.message);
      setLoadingAcceptOffer(false);

      setTimeout(() => {
        setAcceptSucessMsg(null);
        history.push(`loan-application`);
      }, 2000);
    } catch (error) {
      setLoadingAcceptOffer(false);
      setAcceptError(errorHandler(error));
      setTimeout(() => {
        setAcceptError(null);
      }, 3000);
    }
  };
  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const {
    data: offerLetterApplication,
    isLoading,
    error: offerLetterError,
  } = useOfferLetterAndSla({
    customerId: aggregatorDetails?.customer_id,
    loanApplicationId: currentLoanApplicationId,
    isCurrent: existingLoanInformationData?.status_id === 16 ? false : true,
  });
  const viewDocument = async (documentUrl: string) => {
    setIsLoadingDocument(true);

    try {
      const data = await getData(
        `${apiEndpoints.DOWNLOAD_AGGREGATOR_UPLOADED_FILE_BASE64}?file_path=${documentUrl}`
      );

      setDocumentData({
        fileName: data?.file_name,
        base64Url: data?.base64_string,
      });
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "LoanApplicationOfferLetter.tsx",
        },
      });
    }
    setIsLoadingDocument(false);
  };

  const handleDelete = () => {
    setSelectedFile(null);
    if (fileInputRef?.current) {
      //@ts-ignore
      fileInputRef.current.value = null;
    }
  };

  const handleUploadOfferLetter = async () => {
    //@ts-ignore
    const fileBase64 = await convertToBase64(selectedFile);
    const reqData = {
      loan_application_id: currentLoanApplicationId,
      customer_id: aggregatorDetails?.customer_id,
      offer_letter_details: [
        {
          document_type_id: documentTypeId,
          base64_file_string: fileBase64,
          filename_with_extension: (selectedFile as any)?.name,
          id: offerLetterDetailsId,
        },
      ],
      id: offerLetterApplication?.id,
      comment: "string",
    };

    setUploadLoading(true);
    try {
      const res = await putData(apiEndpoints.UPLOAD_OFFER_LETTER, reqData);
      setUploadLoading(false);
      setOfferLetterSucessMsg(res?.message);
      setSelectedFile(null);
      queryCache.invalidateQueries([
        {
          customerId: aggregatorDetails?.customer_id,
          loanApplicationId: currentLoanApplicationId,
        },
        "getOfferLetterAndSla",
      ]);
      //   setDocumentTypeId(null);
      //   setOfferLetterDetailsId(null);
      if (fileInputRef.current) {
        //@ts-ignore
        fileInputRef.current.value = null;
      }
      setTimeout(() => {
        setOfferLetterSucessMsg(null);
      }, 3000);
    } catch (error) {
      setUploadLoading(false);

      setUploadError(errorHandler(error));

      setTimeout(() => {
        setUploadError(null);
      }, 3000);
    }
  };

  const offerLetterStatus =
    offerLetterApplication?.status_id ===
      EnumofferLetterStatus.CustomerOfferLetterNotSent ||
    offerLetterApplication?.status_id ===
      EnumofferLetterStatus.CustomerOfferLetterAcceptance;
  return (
    <>
      <div className={`${localStyles.errorContainerMessage} py-2`}>
        {acceptError && (
          <p className="alert alert-danger text-center">{acceptError}</p>
        )}
        {error && <p className="alert alert-danger  text-center">{error}</p>}

        {uploadError && (
          <p className="alert alert-danger  text-center">{uploadError}</p>
        )}
        {offerLetterError && (
          <p className="alert alert-danger  text-center">{offerLetterError}</p>
        )}
        {acceptSuccessMsg && (
          <p className="alert alert-success  text-center">{acceptSuccessMsg}</p>
        )}
        {offerLetterSuccessMsg && (
          <p className="alert alert-success  text-center">
            {offerLetterSuccessMsg}
          </p>
        )}
      </div>

      {isLoading ? (
        <PageLoader />
      ) : (
        <div
        // className={`
        // ${styles.center_container}
        // `}
        >
          <div className="py-3">
            <div
              className={`d-flex  flex-column  ${localStyles.loanOfferHeadingContainer}`}
            >
              <h2 className={` ${styles.loanInformationHeading}`}>
                Offer letter
              </h2>{" "}
              <p className={`  ${styles.InfoParagraph}`}>
                Below is an Offer letter from us kindly review and upload
              </p>
            </div>
            <div className="">
              <div
                className={`d-flex justify-content-around flex-wrap ${localStyles.offerletterArrangement}`}
              >
                <div className="d-flex flex-column">
                  {offerLetterApplication ? (
                    offerLetterApplication?.offer_letter_details?.map(
                      (legalofferLetter: any) => {
                        return (
                          <div key={legalofferLetter?.id}>
                            {legalofferLetter?.legal_document_url && (
                              <div className="mt-2">
                                <small className="text-muted">
                                  Advancly {legalofferLetter?.document_type}
                                </small>
                                <div className="">
                                  <DownloadOfferLetterDocument
                                    name={
                                      legalofferLetter?.legal_document_filename
                                    }
                                    pathname={
                                      legalofferLetter?.legal_document_url
                                    }
                                    documentName={legalofferLetter?.legal_document_filename.slice(
                                      0,
                                      20
                                    )}
                                    viewHistory={false}
                                  />
                                  <div>
                                    {legalofferLetter?.legal_document_filename?.endsWith(
                                      "pdf" || "PDF"
                                    ) && (
                                      <button
                                        className={
                                          localStyles.viewOfferLetterButton
                                        }
                                        data-toggle="modal"
                                        data-target="#loanViewModal"
                                        onClick={() =>
                                          viewDocument(
                                            legalofferLetter?.legal_document_url
                                          )
                                        }
                                      >
                                        View Document
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div>
                      <p>No Data Available</p>
                    </div>
                  )}
                </div>
                <div className="d-flex  flex-column">
                  <Fragment>
                    {offerLetterApplication?.offer_letter_details?.map(
                      (aggregatorofferLetter: any) => {
                        return !selectedFile &&
                          !aggregatorofferLetter?.customer_document_url ? (
                          <div
                            key={aggregatorofferLetter?.id}
                            onClick={() =>
                              triggerFileUpload(
                                aggregatorofferLetter?.document_type_id,
                                aggregatorofferLetter?.id
                              )
                            }
                          >
                            <div className="color-mid-blue cursor-pointer mt-4">
                              <span className="mr-2">
                                <Upload />
                              </span>
                              <span className="text-underline">
                                Click To Upload Accepted{" "}
                                {aggregatorofferLetter?.document_type}
                              </span>
                            </div>
                          </div>
                        ) : (
                          aggregatorofferLetter?.customer_document_url && (
                            <div className="mt-2">
                              <small className="text-muted">
                                {" "}
                                Customer's {
                                  aggregatorofferLetter.document_type
                                }{" "}
                                Copy
                              </small>
                              <div className="">
                                <DownloadOfferLetterDocument
                                  name={
                                    aggregatorofferLetter?.customer_document_filename
                                  }
                                  pathname={
                                    aggregatorofferLetter?.customer_document_url
                                  }
                                  documentName={aggregatorofferLetter?.customer_document_filename.slice(
                                    0,
                                    20
                                  )}
                                />
                                <div className="d-flex  align-items-center">
                                  <div>
                                    {aggregatorofferLetter?.customer_document_filename?.endsWith(
                                      "pdf" || "PDF"
                                    ) && (
                                      <button
                                        className={
                                          localStyles.viewOfferLetterButton
                                        }
                                        data-toggle="modal"
                                        data-target="#loanViewModal"
                                        onClick={() =>
                                          viewDocument(
                                            aggregatorofferLetter?.legal_document_url
                                          )
                                        }
                                      >
                                        View Document
                                      </button>
                                    )}
                                  </div>

                                  <div className="ml-2">
                                    {aggregatorofferLetter?.approval_status_id ===
                                      1 && (
                                      <span className=" p-2 mt-2 pending_status">
                                        Pending Approval
                                      </span>
                                    )}
                                    {aggregatorofferLetter?.approval_status_id ===
                                      2 && (
                                      <span className="p-2 mt-2 approved_status">
                                        Approved
                                      </span>
                                    )}
                                    {aggregatorofferLetter?.approval_status_id ===
                                      3 && (
                                      <span className="p-2 mt-2 declined_status ">
                                        Declined
                                      </span>
                                    )}

                                    {aggregatorofferLetter?.approval_status_id ===
                                      3 && (
                                      <button
                                        onClick={() =>
                                          triggerFileReUpload(
                                            aggregatorofferLetter?.document_type_id,
                                            aggregatorofferLetter?.id
                                          )
                                        }
                                        className="btn advancly-btn cursor ml-2"
                                      >
                                        Re-Upload
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      }
                    )}
                  </Fragment>
                  {selectedFile && (
                    <>
                      <div className={localStyles.deleteContainer}>
                        <DeleteUploadedDocument
                          name={
                            selectedFile
                              ? (selectedFile as File).name
                              : "Name Unavailable"
                          }
                          deleteFile={handleDelete}
                          offerLetter={true}
                        />
                        <button
                          className={localStyles.UploadButton}
                          onClick={handleUploadOfferLetter}
                          disabled={!selectedFile || uploadLoading}
                        >
                          Upload Document {uploadLoading && <Loader />}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <input
                type="file"
                accept="application/pdf"
                onChange={handleFileSelect}
                ref={fileInputRef}
                className="d-none"
              />
            </div>
          </div>
          {/* buttons would only show when all documents are not all approved */}
          {offerLetterStatus && (
            <div className={localStyles.offerLetterChoiceButtonContainer}>
              <button
                disabled={loadingAcceptOffer}
                onClick={handleAcceptOfferLetter}
              >
                Accept Offer {loadingAcceptOffer && <Loader />}
              </button>
              <button
                data-toggle="modal"
                data-target="#DeclineOfferLetterModal"
              >
                Decline Offer
              </button>
            </div>
          )}
        </div>
      )}
      <input
        type="file"
        accept="application/pdf"
        onChange={handleFileSelectReUpload}
        ref={fileInputRefReupload}
        className="d-none"
      />
      <button
        className="d-none "
        data-toggle="modal"
        data-target="#confirmationModalReUploadOfferLetter"
        ref={triggerConfirmationReUploadModal}
      ></button>

      <LoanViewDocument
        closeDocumentModal={closeDocumentModal}
        isLoadingDocument={isLoadingDocument}
        documentData={documentData}
      />

      <DeclineLoanOfferModal offerLetterId={offerLetterApplication?.id} />
      <ConfirmationModalReUploadOfferLetter
        reUploadOfferLetterDetailsId={reUploadOfferLetterDetailsId}
        offerLetterApplication={offerLetterApplication}
        selectedFileReUpload={selectedFileReUpload}
        reUploadDocumentTypeId={reUploadDocumentTypeId}
      />
    </>
  );
};

export default LoanApplicationOfferLetter;
