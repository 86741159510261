import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { appInsights } from "../../components/AppInsight/AppInsight";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import OnboardingBackButtonComponent from "../../components/OnboardingBackButton/OnboardingBackButton.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import useWalletTransactionLog from "../../custom-hooks/useWalletTransactionLog";

const WalletTransactionLog = () => {
  const location = useLocation();

  const {
    data: transactionLog,
    status,
    error,
  } = useWalletTransactionLog({
    //@ts-ignore
    transaction_ref: location?.state?.data?.maker_checker_reference,
  });
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletTransactionLog.tsx",
      isLoggedIn: true,
    });
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-start">
        <h3 className="page-title d-flex align-items-center">
          <OnboardingBackButtonComponent />
          Wallet Transaction Approval Log
        </h3>
      </div>
      {error && <p className="alert alert-danger small">{error as string}</p>}
      {!error && (
        <div>
          <div className="card">
            {status === "loading" ? (
              <div className="card-body p-3">
                <PageLoader />
              </div>
            ) : (
              <div className="card-body p-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>Email</th>
                        <th>Approval Comment</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionLog?.data?.length ? (
                        transactionLog.data.map(
                          (
                            log: {
                              email: string;
                              approvalComment: string;
                              createdAt: string;
                            },
                            _id: number
                          ) => {
                            const { email, approvalComment, createdAt } = log;
                            return (
                              <tr key={_id}>
                                <td>{email}</td>
                                <td>{approvalComment}</td>
                                <td>{createdAt}</td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(WalletTransactionLog);
