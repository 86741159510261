import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type Taggregator_id = number;
const getEligibilityStatus = async (customer_id: Taggregator_id) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getEligibilityStatus}?customer_id=${customer_id}`,
    true
  );
  return data?.data;
};

export default function useEligibilityStatus(customer_id: Taggregator_id) {
  return useQuery([customer_id, "getEligibilityStatus"], getEligibilityStatus, {
    refetchOnWindowFocus: false,
  });
}
