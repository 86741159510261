import { Fragment, useEffect, useState } from "react";
import "./AccountAndBVNSearch.styles.scss";
import BackButtonComponent from "../../components/BackButton/BackButton.component";
import ToggleTab from "../../components/ToggleTab/ToggleTab.component";
// import BVNSearchForm from "../../components/BVNSearch/BVNSearchForm.component";
import AccountSearchForm from "../../components/AccountSearch/AccountSearchForm.component";
import SearchHistory from "../../components/SearchHistory/SearchHistory.component";
import { appInsights } from "../../components/AppInsight/AppInsight";

const AccountAndBVNSearch = () => {
  const [activeTab, setActiveTab] = useState("account-search");
  useEffect(() => {
    appInsights.trackPageView({
      name: "AccountAndBVNSearch.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
          Account Search
        </h3>
      </div>
      <div className="card mb-5">
        <div className="card-body p-4">
          <div
            className="alignToggleTabItems centralize
           mb-5"
          >
            {/* <ToggleTab
              text="BVN Search"
              id="bvn-search"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            /> */}
            <ToggleTab
              text="Account Search"
              id="account-search"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <ToggleTab
              text="Search History"
              id="search-history"
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="centralizeAccountAndBVNSearchPage">
            {(() => {
              switch (activeTab) {
                // case "bvn-search":
                //   return <BVNSearchForm />;
                case "account-search":
                  return <AccountSearchForm />;
                case "search-history":
                  return <SearchHistory />;

                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AccountAndBVNSearch;
