import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IinvestmentInterestGraph {
  aggregatorId: number;
  filter: number;
  currentCurrencyValue: string;
  activeProductName: string;
}
const getInvestmentInterestGraph = async ({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IinvestmentInterestGraph) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.AGGREGATOR_INVESTMENT_INTEREST_GRAPH}?aggregatorId=${aggregatorId}&currency=${currentCurrencyValue}&filter=${filter}&product=${activeProductName}`
  );
  return data;
};

const useInvestmentInterestGraph = ({
  aggregatorId,
  filter,
  currentCurrencyValue,
  activeProductName,
}: IinvestmentInterestGraph) => {
  return useQuery(
    [
      { aggregatorId, filter, currentCurrencyValue, activeProductName },
      "getInvestmentInterestGraph",
    ],
    getInvestmentInterestGraph,
    {
      enabled: currentCurrencyValue && activeProductName ? true : false,

      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );
};

export default useInvestmentInterestGraph;
