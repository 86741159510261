import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IloanDetails {
  loan_ref: string;
}
const getQueryLoanDetails = async ({ loan_ref }: IloanDetails) => {
  const { data } = await getDataWithDotNet(
    `${apiEndpoints.queryLoanDetails}?externalId=${loan_ref}`
  );
  return data;
};

export default function useQueryLoanDetails({ loan_ref }: IloanDetails) {
  return useQuery([{ loan_ref }, "getQueryLoanDetails"], getQueryLoanDetails, {
    enabled: loan_ref ? true : false,
  });
}
