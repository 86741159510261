import React, { useRef } from "react";
import ChangeMultipleSignatoryComponentPage from "./ChangeMultipleSignatoryComponentPage";

const ChangeMultipleSignatoryLandingPage = ({
  signatories,
  signatoriesStatus,
}: {
  signatories: any;
  signatoriesStatus: string;
}) => {
  const dismissRef = useRef();

  return (
    <div className="modal" id="changeMultipleSignatoryLandingPage">
      <div className="modal-dialog">
        <ChangeMultipleSignatoryComponentPage
          dismissRef={dismissRef}
          signatories={signatories}
          signatoriesStatus={signatoriesStatus}
        />
      </div>
    </div>
  );
};

export default ChangeMultipleSignatoryLandingPage;
