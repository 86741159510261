import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { Fragment, useEffect, useRef, useState } from "react";
import BackButtonComponent from "../BackButton/BackButton.component";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import LoaderColored from "../Loader/LoaderColored";
import { appInsights } from "../AppInsight/AppInsight";
import CustomSelectDropdown from "../CustomHTMLElements/CustomSelectDropdown";
import useCountryBanksList from "../../custom-hooks/useCountryBanksList";
import useValidateBankAccount from "../../custom-hooks/useValidateBankAccount";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useHistory, useLocation } from "react-router-dom";
import { postDataWithDotNet } from "../../apis/dotNetApiMethods";
import moment from "moment";
import { useForm } from "react-hook-form";
import styles from "./Disbursementrequest.module.scss";
import { disbursementLoanRequestSchema } from "../../helpers/loanRequestSchema";
import LoanOfferModalDisbursementRequest from "./LoanOfferModalDisbursementRequest";
const Disbursementrequest = () => {
  const [requestBody, setRequestBody] = useState<any>(null);
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  // Loan offer modal state
  const [loanOfferError, setLoanOfferError] = useState<string | null>(null);
  const [loanOfferSuccess, setLoanOfferSucess] = useState(false);
  const [loanOfferLoading, setLoanOfferLoading] = useState(false);
  const [loanTenureInDays, setLoanTenureInDays] = useState();
  const [loanCurrency, setLoanCurrency] = useState();
  const [totalPrincipal, setTotalPrincipal] = useState();
  const [totalRepayment, setTotalRepayment] = useState();
  const [totalInterestCharged, setTotalInterestCharged] = useState();
  const triggerLoanOfferModal = useRef<any>();
  const history = useHistory();

  const schema = yup.object().shape({
    product: disbursementLoanRequestSchema.product,
    interestRate: disbursementLoanRequestSchema.interestRate,
    amountRemaining: disbursementLoanRequestSchema.amountRemaining,
    bankName: disbursementLoanRequestSchema.bankName,
    accountNumber: disbursementLoanRequestSchema.accountNumber,
    accountName: disbursementLoanRequestSchema.accountName,
    currency: disbursementLoanRequestSchema.currency,
    loanAmount: disbursementLoanRequestSchema.loanAmount,
    loanPurpose: disbursementLoanRequestSchema.loanPurpose,
  });
  const location = useLocation();
  const { state } = location;
  const {
    loan_application_detail_id,
    loan_application_ref,
    currency,
    approved_amount,
    loan_product_detail_id,
    product_name,
    remaining_amount,
    interest_rate,
    loan_tenure,
    repayment_frequency,
  }: any = state;
  const scrollToTop = () => {
    //@ts-ignore
    document.getElementById("topOfPage").scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  let now = moment();
  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useCountryBanksList({
    country_code: "NG",
  });
  const inputtedTenureAmount = watch("tenure");
  const accountNumber = watch("accountNumber");
  const bankCodeValue = watch("bankName");
  const bankCode = bankListData?.find((bank: { code: string }) => {
    return bank?.code === bankCodeValue;
  })?.code;

  useEffect(() => {
    if (state) {
      setValue("product", product_name);
      setValue("interestRate", interest_rate);
      setValue("amountRemaining", remaining_amount);
      setValue("currency", currency);
      setValue("loanAmount", approved_amount);
      setValue("tenure", loan_tenure);
    }
    // eslint-disable-next-line
  }, [state]);

  const {
    data: accountName,
    isLoading: isAccountNameLoading,
    error: accountNameError,
  } = useValidateBankAccount({
    bankCode,
    accountNumber,
  });

  useEffect(() => {
    if (accountName) {
      setValue("accountName", accountName);
    }
    // eslint-disable-next-line
  }, [accountName]);

  const onSubmit = async (data: any) => {
    const {
      interestRate,
      loanAmount,
      tenure,
      loanPurpose,
      bankName,
      accountNumber,
      accountName,
    } = data;

    const reqBody = {
      loan_application_ref,
      loan_application_detail_id,
      loan_amount: loanAmount,
      bank_account_number: accountNumber,
      bank_account_name: accountName,
      bank_code: bankName,
      loan_tenure: tenure,
      loan_purpose: loanPurpose,
      // loan_tenor_input_type: "Default",
    };
    setRequestBody(reqBody);
    //Post for Loan Schedule
    const reqBodyForLoanSchedule = {
      productId: Number(loan_product_detail_id),
      loanTenor: Number(tenure),
      principalAmount: Number(loanAmount),
      interest: Number(interestRate),
      loanEffectiveDate: now.format(),
      repaymentType: repayment_frequency,
    };
    setLoanOfferLoading(true);
    setLoanOfferError(null);
    setLoanOfferSucess(false);

    try {
      const data = await postDataWithDotNet(
        apiEndpoints.LOAN_OFFER_SCHEDULE,
        reqBodyForLoanSchedule
      );

      setLoanOfferLoading(false);
      if (data.status === true) {
        setLoanOfferSucess(data.message);
        setTimeout(() => {
          setLoanOfferSucess(false);
        }, 5000);
        setLoanTenureInDays(data?.data?.loanTermInDays);
        setLoanCurrency(data?.data?.currency?.code);
        setTotalPrincipal(data?.data?.totalPrincipalExpected);
        setTotalRepayment(data?.data?.totalRepaymentExpected);
        setTotalInterestCharged(data?.data?.totalInterestCharged);
        triggerLoanOfferModal.current.click();
      } else {
        setLoanOfferError(data.message);
        setTimeout(() => {
          setLoanOfferError(null);
        }, 7000);
      }
    } catch (error: any) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "DisbursementRequest.tsx" },
      });
      setLoanOfferLoading(false);
      if (error?.response?.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        // setLoanOfferError(errorHandler(error));
        setLoanOfferError(errorHandler(error?.response?.data?.message));

        scrollToTop();
      }
    }
    //loan offer is displayed first
  };
  return (
    <Fragment>
      <div
        id="topOfPage"
        // style={{ width: "40%" }}
      >
        <div>
          {loanOfferSuccess && (
            <p className="alert alert-success small">{loanOfferSuccess}</p>
          )}
          {loanOfferError && (
            <p className="alert alert-danger small">{loanOfferError}</p>
          )}
          {bankListError && (
            <p className="alert alert-danger small">
              {errorHandler(bankListError)}
            </p>
          )}
          {accountNameError && (
            <p className="alert alert-danger mb-4 small ">
              {errorHandler(accountNameError)}
            </p>
          )}
        </div>

        <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mb-3">
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent />
          </h3>
          <h3 className="page-title d-flex align-items-center">
            Disbursement Request
          </h3>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <p className=" text-left text-muted my-3 mb-3">
            Fill out the form below to request a loan.
          </p>
          <div className={`row ${styles.disbursmentrequestContainer}`}>
            <div className="col-12 ">
              <CustomInputField
                name="product"
                type="text"
                maxLength={20}
                ref={register}
                label="Product"
                errors={errors.product}
                readOnly
              />
            </div>

            <div className="col-12 ">
              <CustomInputField
                name="interestRate"
                type="text"
                maxLength={30}
                ref={register}
                label="Interest Rate"
                errors={errors.interestRate}
                readOnly
              />
            </div>
            <div className="col-12 ">
              <CustomInputField
                name="amountRemaining"
                type="text"
                maxLength={30}
                ref={register}
                label="Amount Remaining"
                errors={errors.amountRemaining}
                showRequiredIcon={true}
                readOnly
              />
            </div>
            <div className="col-4">
              <CustomInputField
                name="currency"
                type="currency"
                maxLength={128}
                ref={register}
                label="currency"
                showRequiredIcon={true}
                readOnly
              />
            </div>
            <div className="col-8">
              <CustomInputField
                type="number"
                maxLength={20}
                ref={register}
                label="Loan Amount"
                name="loanAmount"
                errors={errors.loanAmount}
                showRequiredIcon={true}
              />
            </div>
            <div className="col-12">
              <CustomInputField
                name="tenure"
                type="number"
                maxLength={3}
                ref={register}
                label="Tenure (Days)"
                errors={errors.tenure}
                showRequiredIcon={true}
                extraLabel={
                  inputtedTenureAmount > loan_tenure
                    ? `Inputted tenure cannot be greater than ${loan_tenure}`
                    : ""
                }
              />
            </div>

            <div className="col-12">
              <CustomInputField
                name="loanPurpose"
                type="text"
                ref={register}
                label="Loan Purpose"
                placeholder="Enter Loan Purpose"
                errors={errors.loanPurpose}
                showRequiredIcon={true}
              />
            </div>

            <div className="col-12">
              <CustomSelectDropdown
                ref={register}
                label="Bank"
                name="bankName"
                errors={errors.bankName}
                showRequiredIcon={true}
              >
                <option value="">Select Bank</option>

                {bankListData &&
                  bankListData?.length &&
                  bankListData?.map((bank: any) => (
                    <option key={bank.code} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
              </CustomSelectDropdown>
              {bankStatus === "loading" ? (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 ">
              <CustomInputField
                type="number"
                maxLength={20}
                ref={register}
                placeholder="Enter Account Number"
                label="Account Number"
                name="accountNumber"
                errors={errors.accountNumber}
                showRequiredIcon={true}
              />
            </div>

            <div className="col-12 ">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder=" Account Name"
                label="Account Name"
                name="accountName"
                errors={errors.accountName}
                showRequiredIcon={true}
                readOnly
                defaultValue={accountName}
                // value={accountName || ""}
                ref={register}
              />
              {isAccountNameLoading ? (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              ) : (
                ""
              )}
              {accountNameError && (
                <span className="text-danger  small ">
                  {errorHandler(accountNameError)}
                </span>
              )}
            </div>
          </div>
          <button
            type="button"
            data-toggle="modal"
            data-target="#loanOfferModalIndividual"
            style={{ display: "none" }}
            ref={triggerLoanOfferModal}
          />

          <div className="row">
            <div className="col-4 col-lg-2">
              <button
                type="submit"
                className="btn advancly-btn btn-sm transition-3d-hover w-100"
              >
                Make Request
                {loanOfferLoading && <Loader />}
              </button>
            </div>
          </div>
        </form>

        {
          <LoanOfferModalDisbursementRequest
            loanOfferError={loanOfferError}
            loanOfferSuccess={loanOfferSuccess}
            loanOfferLoading={loanOfferLoading}
            loanTenureInDays={loanTenureInDays}
            loanCurrency={loanCurrency}
            totalPrincipal={totalPrincipal}
            totalRepayment={totalRepayment}
            totalInterestCharged={totalInterestCharged}
            success={success}
            error={error}
            loading={loading}
            setLoading={setLoading}
            setError={setError}
            setSucess={setSucess}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            reset={reset}
            scrollToTop={scrollToTop}
            productName={product_name}
          />
        }
      </div>
    </Fragment>
  );
};

export default Disbursementrequest;
