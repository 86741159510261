import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
interface IinvestmentOverview {
  currentCurrencyValue: string;
  // activeProductName: string;
}
const getInvestmentOverview = async ({
  currentCurrencyValue,
}: // activeProductName,
IinvestmentOverview) => {
  const data = await getData(
    `${apiEndpoints.AGGREGATOR_INVESTMENT_SUMMARY}?currency=${currentCurrencyValue}`
  );
  return data?.data;
};

const useInvestmentsOverview = ({
  currentCurrencyValue,
}: // activeProductName,
IinvestmentOverview) => {
  return useQuery(
    [{ currentCurrencyValue }, "getInvestmentOverview"],
    getInvestmentOverview,
    {
      enabled: currentCurrencyValue ? true : false,
      retry: 4,
    }
  );
};

export default useInvestmentsOverview;
