import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useAllCurrencies from "../../custom-hooks/useAllCurrencies";
import useOutstandingLoanCount from "../../custom-hooks/useOutstandingLoanCount";
import CustomSelectDropdown from "../../components/CustomHTMLElements/CustomSelectDropdown";
import Paginator from "../../components/Paginator/Paginator.component";
import { formatMoney } from "../../helpers/formatter";
import NoTableDataAvailable from "../../components/NoTableDataAvailable/NoTableDataAvailable.component";
// import Loader from "../../components/Loader/Loader.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import { appInsights } from "../../components/AppInsight/AppInsight";
import ToolTip from "../../components/ToolTip/ToolTip";

interface IdueLoans {
  loanRef: string;
  borrowerName: string;
  productName: string;
  amountToRepay: number;
  dateDue: string;
  principal: number;
  currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  interest: number;
  daysOverdue: number;
  loanId: number;
}

function DueLoans({
  state,
  setState,
  data,
  pageNumber,
  size,
  error,
  setPageNumber,
  setSize,
  status,
  checkAll,
  setCheckAll,
  searchCurrency,
  setCurrency,
}: {
  state: any;
  setState: any;
  data: {
    data: IdueLoans[];
    totalCount?: number;
  };
  pageNumber: number;
  size: number;
  error: unknown;
  setPageNumber: Dispatch<SetStateAction<number>>;
  setSize: Dispatch<SetStateAction<number>>;
  status: string;

  checkAll: boolean;
  setCheckAll: Dispatch<SetStateAction<boolean>>;
  searchCurrency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  setCurrency: Dispatch<
    SetStateAction<"NGN" | "USD" | "KES" | "#" | "$" | "Ksh">
  >;
}) {
  const [count, setCount] = useState<any>(null);
  const [showDueLoanTip, setShowDueLoanTip] = useState(false);
  if (data) {
    if (count === null) {
      setCount(data.totalCount);
    }
  }

  const { data: currencies } = useAllCurrencies();
  const { data: dueLoanCount, status: dueLoanCountStatus } =
    useOutstandingLoanCount();
  // Multiple Checkbox
  const checkAllPendingRepaymentData = (e: any) => {
    if (checkAll) {
      const allLoans: any = document.querySelectorAll(".loans_for_repayment");
      for (let i = 0; i < allLoans.length; i++) {
        allLoans[i].checked = false;
      }
      setCheckAll(false);
      setState({
        ...state,
        totalAmount: 0,
        dataToStore: [],
      });

      return;
    }

    let totalAmount = 0;
    let loans = [];
    const allLoans: any = document.querySelectorAll(".loans_for_repayment");
    for (let i = 0; i < allLoans.length; i++) {
      allLoans[i].checked = true;
      const loan_Id = Number(allLoans[i].dataset["ref"]);
      const amount = Number(allLoans[i].dataset["amounttorepay"]);
      const duedate = Number(allLoans[i].dataset["duedate"]);
      loans.push({ loan_Id, amount, duedate });
      totalAmount += Number(
        JSON.parse(allLoans[i].dataset["record"]).amountToRepay
      );
    }

    setCheckAll(true);

    setState({
      ...state,
      totalAmount,
      dataToStore: [...state.dataToStore, ...loans],
    });
  };
  // Single Checkbox
  //@ts-ignore
  const checkboxHandler = ({ dataRecord: { amountToRepay }, eventTarget }) => {
    let loans = [];
    const loan_Id = Number(eventTarget.dataset["ref"]);
    const amount = Number(eventTarget.dataset["amounttorepay"]);
    const duedate = Number(eventTarget.dataset["duedate"]);
    loans.push({ loan_Id, amount, duedate });
    if (eventTarget.checked === false) {
      const dataToStore = state.dataToStore;
      const newdataToStore = dataToStore.filter((loan: any) => {
        return (
          Number(loan.duedate) !== Number(duedate) ||
          Number(loan.loan_Id) !== Number(loan_Id)
        );
      });

      return setState({
        ...state,
        dataToStore: [...newdataToStore],
        totalAmount: state.totalAmount
          ? state.totalAmount - Number(amountToRepay)
          : 0,
      });
    } else {
      setState({
        ...state,
        dataToStore: [...state.dataToStore, ...loans],
        totalAmount: state.totalAmount + Number(amountToRepay),
      });
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "DueLoans.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      {error && <p className="alert alert-danger small">{error as string}</p>}

      <div className="">
        <div className="d-flex justify-content-between align-items-center advancly-sub-wrapper">
          {/* <h5 className="page-subtitle">
            Wallet Balance:{" "}
            {walletDetailsStatus === "loading" ? (
              <Loader />
            ) : (
              <Fragment>
                {walletDetails?.summary?.availableBalance
                  ? formatMoney(
                      walletDetails?.summary?.availableBalance,
                      walletDetails?.summary?.currency?.code
                    )
                  : "N/A"}{" "}
              </Fragment>
            )}
          </h5> */}
          <CustomSelectDropdown
            name="currency"
            onChange={(e: any) => setCurrency(e.target?.value)}
            value={searchCurrency}
          >
            <option value="">All Currency </option>
            {currencies &&
              currencies.map((_currency, index) => (
                <option key={index} value={_currency?.currency_code}>
                  {_currency?.currency_name}
                </option>
              ))}
          </CustomSelectDropdown>
          <div className="">
            <p>
              Total Due Loans Left :{" "}
              {dueLoanCountStatus === "loading" ? (
                <span className="text-danger">Loading ...</span>
              ) : (
                <span>{dueLoanCount ? dueLoanCount : "unavailable"} </span>
              )}
            </p>
          </div>

          <div
            className="d-flex align-items-center"
            title="Select the number of loans to repay"
          >
            <p className="pr-2 pt-1">Showing:</p>
            <CustomSelectDropdown
              onChange={(e: any) => setSize(e.target.value)}
              value={size}
            >
              <option value={10}>10 Entries</option>
              <option value={20}>20 Entries</option>
              <option value={50}>50 Entries</option>
              <option value={100}>100 Entries</option>
              <option value={200}>200 Entries</option>
              <option value={300}>300 Entries</option>
              <option value={400}>400 Entries</option>
              <option value={500}>500 Entries</option>
            </CustomSelectDropdown>
          </div>
        </div>

        {status === "loading" ? (
          <div className="d-flex justify-content-center p-5">
            <PageLoader />
          </div>
        ) : (
          !error &&
          status !== "loading" && (
            <Fragment>
              <div
                className="mt-3 advancly-sub-wrapper"
                style={{ position: "relative" }}
              >
                <div className="table-responsive">
                  <table className="table-style">
                    <thead className="">
                      {showDueLoanTip && (
                        <ToolTip
                          tooltipContainerTop={"5rem"}
                          tooltipContainerLeft={"4rem"}
                          tooltipHeading={"Bulk Repayment"}
                          tooltipParagraph={`Make bulk repayments effortlessly! Click the 'All' button to select multiple items for repayment at once. Save time and streamline your repayments in just a few clicks `}
                        />
                      )}
                      <tr>
                        <th className="th-td-style th-style ">S/N</th>

                        {
                          // searchCurrency && (
                          <th
                            className="th-td-style th-style "
                            onMouseEnter={() => setShowDueLoanTip(true)}
                            onMouseLeave={() => setShowDueLoanTip(false)}
                          >
                            <label htmlFor="unchecked ">All</label>
                            <input
                              id="unchecked"
                              className="m-1"
                              type="checkbox"
                              name="unchecked"
                              onClick={(e) => checkAllPendingRepaymentData(e)}
                              checked={checkAll}
                            />
                          </th>
                        }
                        <th className="th-td-style th-style ">Loan Id</th>
                        <th className="th-td-style th-style ">Reference</th>
                        <th className="th-td-style th-style ">Borrower</th>
                        <th className="th-td-style th-style ">Product</th>
                        <th className="th-td-style th-style ">
                          Outstanding Amount
                        </th>
                        <th className="th-td-style th-style ">
                          {" "}
                          Principal Amount
                        </th>
                        <th className="th-td-style th-style ">Interest</th>
                        <th className="th-td-style th-style ">Overdue Days</th>
                        <th className="th-td-style th-style ">Due Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.data?.length ? (
                        data?.data?.map((data: IdueLoans, id: number) => {
                          const {
                            loanRef,
                            borrowerName,
                            productName,
                            amountToRepay,
                            dateDue,
                            principal,
                            currency,
                            interest,
                            daysOverdue,
                            loanId,
                          } = data;
                          return (
                            <tr key={id}>
                              <td className="th-td-style  ">{id + 1}</td>

                              {
                                // searchCurrency &&
                                <td className="th-td-style  ">
                                  <input
                                    type="checkbox"
                                    data-record={JSON.stringify(data)}
                                    data-id={Number(loanId)}
                                    data-ref={Number(loanId)}
                                    data-amounttorepay={Number(amountToRepay)}
                                    data-duedate={Number(daysOverdue)}
                                    defaultChecked={state?.dataToStore?.includes(
                                      Number(loanId)
                                    )}
                                    onClick={(e) =>
                                      checkboxHandler({
                                        dataRecord: data,
                                        eventTarget: e.target,
                                      })
                                    }
                                    className="loans_for_repayment"
                                  />
                                </td>
                              }

                              <td className="th-td-style  ">{loanId}</td>
                              <td className="th-td-style  ">{loanRef}</td>
                              <td
                                className="text-uppercase th-td-style "
                                style={{ width: "10%" }}
                              >
                                {borrowerName}
                              </td>
                              <td className="th-td-style  ">{productName}</td>
                              <td className="th-td-style  ">
                                {formatMoney(amountToRepay, currency)}
                              </td>
                              <td className="th-td-style  ">
                                {principal
                                  ? formatMoney(principal, currency)
                                  : null}
                              </td>
                              <td className="th-td-style  ">
                                {interest
                                  ? formatMoney(interest, currency)
                                  : null}
                              </td>
                              <td
                                className="th-td-style  "
                                style={{ minWidth: "120px" }}
                              >
                                {daysOverdue}
                              </td>
                              <td
                                className="th-td-style  "
                                style={{ minWidth: "120px" }}
                              >
                                {dateDue?.substring(0, 10)}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                <Paginator
                  size={size}
                  page={pageNumber}
                  count={count}
                  changeCurrentPage={(pageNumber) => setPageNumber(pageNumber)}
                />
              </div>
            </Fragment>
          )
        )}
      </div>
    </div>
  );
}
export default DueLoans;
