import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getWorldCountries = async (param: boolean = false) => {
  const { data } = await getData(apiEndpoints.WORLD_COUNTRY_CODES, param);
  return data;
};

const useWorldCountries = (param?: boolean) => {
  return useQuery([param, "getWorldCountries"], getWorldCountries, {
    refetchOnWindowFocus: false,
  });
};
export default useWorldCountries;
