import React, { ChangeEvent, useEffect, useState } from "react";
import { yupValidators } from "../../helpers/yupValidators";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomTextArea from "../CustomHTMLElements/CustomTextArea";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { appInsights } from "../AppInsight/AppInsight";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import styles from "../../Pages/ContactPage/ContactPage.module.scss";
import { useAuth } from "../../Context/auth.context";
import mixpanel from "mixpanel-browser";

const schema = yup.object().shape({
  name: yupValidators.fullName,
  // businessName: yupValidators.businessName,
  phoneNumber: yupValidators.phoneNumber,
  email: yupValidators.email,
  message: yupValidators.contactMessage,
});

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | string>();
  const [success, setSuccess] = useState<string | null>(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const validateEmail = (input: string) => {
    const isValid = /\S+@\S+\.\S+/.test(input); // Basic email validation
    console.log(isValid);
    setIsValidEmail(isValid);
  };
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { aggregatorDetails } = useAuth();

  useEffect(() => {
    if (aggregatorDetails?.biz_name) {
      setValue("name", aggregatorDetails.biz_name);
    }
    if (aggregatorDetails?.biz_email) {
      setValue("email", aggregatorDetails.biz_email);
    }
    if (aggregatorDetails?.biz_phone) {
      setValue("phoneNumber", aggregatorDetails.biz_phone);
    }
  }, [aggregatorDetails, setValue]);
  const onSubmit = async (formData: {
    name: string;
    phoneNumber: string;
    email: string;
    message: string;
  }) => {
    const { name, phoneNumber, email, message } = formData;
    const reqBody = {
      name,
      phone_number: phoneNumber,
      email,
      message,
    };
    setLoading(true);
    setError(false);
    setSuccess(null);

    try {
      await postData(apiEndpoints.CONTACT_FORM, reqBody);
      setLoading(false);
      setSuccess("Message sent");
      setTimeout(() => {
        setSuccess(null);
      }, 3000);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "ContactForm.component.jsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  useEffect(() => {
    mixpanel.track_pageview({ page: "ContactForm-spark" });
  }, []);

  return (
    <div className="w-100">
      <div className="card border-0 ">
        <h3 className="page-title d-flex align-items-center">
          Contact Support
        </h3>
        {error && <p className="alert alert-danger small">{error}</p>}
        {success && <p className="alert alert-success small">{success}</p>}
        <div className={styles.contactCard}>
          <div className={styles.contactCardsub}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    maxLength={60}
                    ref={register}
                    placeholder="John Doe"
                    label="Name"
                    name="name"
                    errors={errors.name}
                  />
                </div>
                {/* <div className="col-md-6 col-12">
              <CustomInputField
                type="text"
                maxLength="128"
                reference={register}
                placeholder="Uber Nigeria"
                label="Business Name"
                name="businessName"
                errors={errors.businessName}
              />
            </div> */}

                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="email"
                    maxLength={128}
                    ref={register}
                    placeholder="e.g johndoe@abc.com"
                    label="Email Address"
                    name="email"
                    errors={errors.email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      validateEmail(e.target.value);
                    }}
                    validateEmailInput={isValidEmail}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    ref={register}
                    placeholder="08000000000"
                    label="Phone Number"
                    name="phoneNumber"
                    errors={errors.phoneNumber}
                  />
                </div>

                <div className="col-12">
                  <CustomTextArea
                    maxLength={300}
                    ref={register}
                    placeholder="Enter message here"
                    label="Message"
                    name="message"
                    errors={errors.message}
                    rows={5}
                  />
                  <div className={styles.textAreaInfo}>
                    <small>This field is limited to 300 words</small>
                  </div>
                </div>

                <div className="col-12 text-left">
                  <button
                    type="submit"
                    value="submit"
                    className="btn advancly-btn  transition-3d-hover"
                    disabled={loading}
                  >
                    Send Message
                    {loading && <Loader />}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
