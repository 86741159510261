import React, { useState } from "react";
import { ReactComponent as Plus } from "../../../../../img/plus_add.svg";
import { ReactComponent as DeleteIcon } from "../../../../../img/DeleteIcon2.svg";
import { ReactComponent as EditIcon } from "../../../../../img/EditIcon.svg";
import SeniorManagersForm from "./SeniorManagersForm";
import { IstepFunctions } from "../GetStartedBusinesInfoMain";
import styles from "../../../GetStarted.module.scss";
import { useAuth } from "../../../../../Context/auth.context";
import useGetOnboardingManagement from "../../../../../custom-hooks/useGetOnboardingManagement";
import PageLoader from "../../../../../components/PageLoader/PageLoader.component";
import { errorHandler } from "../../../../../helpers/errorHandler";
import ConfirmationDeleteModal from "../ConfirmationDeleteModal";
import { useHistory } from "react-router-dom";

const GetStartedSeniorManagementInfo = ({
  handlePrev,
  scrollToTop,
}: IstepFunctions) => {
  const [isAddMoreSeniorManager, setIsAddMoreSeniorManager] = useState(false);
  const [currentSeniorMangagerId, setCurrentSeniorManagerId] = useState<
    number | null
  >(null);
  const [isSeniorManagerComplete, setIsSeniorManagerComplete] = useState(false);
  const [editAndPopulateManagerForm, setEditAndPopulateManagerForm] =
    useState<{} | null>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [addAnotherLoading, setAddAnotherLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { aggregatorDetails } = useAuth();
  const {
    data: seniorManagementData,
    isLoading: seniorManagementLoading,
    error: seniorManagementErrors,
  } = useGetOnboardingManagement({
    funderId: aggregatorDetails?.customer_id,
    funderPeopleType: 5,
  });
  return (
    <section className={`${styles.center_Container}`}>
      <div style={{ width: "11.375rem" }}>
        <small
          className="color-mid-blue  cursor-pointer font-weight-bold  "
          onClick={handlePrev}
        >
          &#8592; Go back
        </small>
      </div>
      <div className={styles.center_form}>
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        {seniorManagementErrors && (
          <p className="alert alert-danger small">
            {errorHandler(seniorManagementErrors)}
          </p>
        )}
        <div>
          <h3 className="color-blue-hover-none">Senior Management</h3>
          <p className="bg-light-grey">Add Senior Management(s) </p>
        </div>
        {seniorManagementLoading ? (
          <div className="py-2">
            <PageLoader />
          </div>
        ) : (
          seniorManagementData?.length >= 1 &&
          seniorManagementData?.map((seniorData: any) => {
            const { role, id, first_name, last_name } = seniorData;

            return (
              <div className="my-4" key={id}>
                <div className="card-border-wrapper d-flex justify-content-between align-items-center">
                  <div className="mt-4">
                    <h5 className="color-dark-purple">{`${first_name} ${last_name}`}</h5>
                    <p className="bg-light-grey">
                      {role ? role : "Unavailable"}
                    </p>
                  </div>
                  <div>
                    <span
                      title="Edit Senior Management"
                      className="cursor-pointer  transition-3d-hover "
                      onClick={() => {
                        setIsAddMoreSeniorManager(true);
                        setEditAndPopulateManagerForm(seniorData);
                      }}
                    >
                      <EditIcon className={`transition-3d-hover  `} />{" "}
                    </span>
                    <span
                      title="Delete Senior Management"
                      className="cursor-pointer  transition-3d-hover "
                      data-toggle="modal"
                      data-target="#confirmationDeleteModal"
                      onClick={() => {
                        setCurrentSeniorManagerId(id);
                      }}
                    >
                      {" "}
                      <DeleteIcon className={`transition-3d-hover  `} />{" "}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {isAddMoreSeniorManager && <div className="horizontal-dash-line"></div>}
        {isAddMoreSeniorManager && (
          <SeniorManagersForm
            isAddMoreSeniorManager={isAddMoreSeniorManager}
            setIsAddMoreSeniorManager={setIsAddMoreSeniorManager}
            isSeniorManagerComplete={isSeniorManagerComplete}
            setIsSeniorManagerComplete={setIsSeniorManagerComplete}
            loading={loading}
            setLoading={setLoading}
            addAnotherLoading={addAnotherLoading}
            setAddAnotherLoading={setAddAnotherLoading}
            setError={setError}
            setSuccess={setSuccess}
            editAndPopulateManagerForm={editAndPopulateManagerForm}
            setEditAndPopulateManagerForm={setEditAndPopulateManagerForm}
            scrollToTop={scrollToTop}
          />
        )}
        {(seniorManagementData?.length === 0 || !seniorManagementData) &&
          !isAddMoreSeniorManager && (
            <div
              className="mb-4"
              onClick={() => setIsAddMoreSeniorManager(true)}
            >
              <button className="advancly-purple-btn ">
                <Plus />
                <span className="p-2"> Add Senior Manager</span>
              </button>
            </div>
          )}
        {seniorManagementData?.length >= 1 && !isAddMoreSeniorManager && (
          <div className="mb-4" onClick={() => setIsAddMoreSeniorManager(true)}>
            <button className="advancly-purple-btn ">
              <Plus />
              <span className="p-2"> Add Another Senior Manager</span>
            </button>
          </div>
        )}
        {!isAddMoreSeniorManager && (
          <div>
            <button
              type="button"
              onClick={() => {
                history.push({
                  pathname: "/get-started/onboarding",
                  state: {
                    successMessage:
                      "Your account has been updated successfully",
                  },
                });
              }}
              className="advancly-btn my-3"
            >
              Save
            </button>
          </div>
        )}
      </div>
      <ConfirmationDeleteModal
        currentId={currentSeniorMangagerId}
        setCurrentId={setCurrentSeniorManagerId}
      />
    </section>
  );
};

export default GetStartedSeniorManagementInfo;
