import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IAllBorrowerLoans {
  id: number;
}

const getAllABorrowerLoans = async ({ id }: IAllBorrowerLoans) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getAllABorrowerLoans}?borrower_id=${id}`
  );
  return data.data;
};

export default function useAllABorrowerLoans({ id }: IAllBorrowerLoans) {
  return useQuery([{ id }, "getAllABorrowerLoans"], getAllABorrowerLoans, {
    refetchOnWindowFocus: false,
  });
}
