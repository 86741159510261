import { FormEvent, Fragment, useEffect, useState } from "react";
import { postData } from "../../apis/apiMethods";
import { decrypt } from "../../helpers/encryptor";
import { ReactComponent as PadlockIcon } from "../../svg/icons/padlock-icon.svg";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import Alert from "../Alert/Alert.component";
import { encrypt } from "../../helpers/encryptor";
import { environmentTypes } from "../../helpers/environmentTypes";
import { appInsights } from "../AppInsight/AppInsight";

const Toggler = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLiveEnvironment, setIsLiveEnvironment] = useState(false);

  const aggregatorDetails = JSON.parse(
    decrypt(sessionStorage.getItem("_aggDt"))
  );
  const envKey = `${aggregatorDetails?.rep_first_name}${aggregatorDetails?.rep_last_name}env`;

  useEffect(() => {
    if (aggregatorDetails) {
      const currentEnvironment = localStorage.getItem(envKey);
      if (currentEnvironment) {
        setIsLiveEnvironment(
          decrypt(currentEnvironment) === environmentTypes.production
            ? true
            : false
        );
      } else {
        // Defaults to Sandbox
        localStorage.setItem(envKey, encrypt(environmentTypes.sandbox));
        setIsLiveEnvironment(false);
      }
    }
  }, [envKey, aggregatorDetails]);

  const toggleEnvironment = async (e: FormEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked;
    setError("");
    setLoading(true);
    try {
      const reqBody = {
        status: isChecked
          ? environmentTypes.production
          : environmentTypes.sandbox,
      };
      await postData(apiEndpoints.toggleEnvironment, reqBody, true);
      setLoading(false);
      setIsLiveEnvironment(!isLiveEnvironment);
      localStorage.setItem(
        envKey,
        isChecked
          ? encrypt(environmentTypes.production)
          : encrypt(environmentTypes.sandbox)
      );
      setSuccess(
        `Environment changed from ${
          isChecked ? environmentTypes.sandbox : environmentTypes.production
        } to ${
          isChecked ? environmentTypes.production : environmentTypes.sandbox
        }`
      );

      // Now refresh the enitre page so the new baseUrl can kick in
      setTimeout(() => {
        window.location.reload();
        // 3000ms because of the alert that pops
      }, 3000);
    } catch (error: any) {
      appInsights.trackException({
        exception: error,
        properties: { fileName: "Toggler.component.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  return (
    <Fragment>
      {error && <Alert message={error} />}
      {success && <Alert message={success} type="success" />}
      <div className="d-flex align-items-center justofy-content-center px-3">
        <span>{environmentTypes.sandbox}</span>
        <div className="custom-control custom-switch ml-3 mr-2">
          <input
            type="checkbox"
            className="custom-control-input"
            id="enviromentSwitch"
            disabled={loading}
            onChange={toggleEnvironment}
            checked={isLiveEnvironment}
          />
          <label
            className="custom-control-label cursor-pointer"
            htmlFor="enviromentSwitch"
          />
        </div>
        <span className="d-flex align-items-center">
          <span className="mr-2">{environmentTypes.production}</span>{" "}
          <PadlockIcon />
        </span>
      </div>
    </Fragment>
  );
};

export default Toggler;
