import React, { useState } from "react";
import styles from "./PrivacyPolicy.module.scss";
import PrivacyPolicyDescription from "./PrivacyPolicyDescription";

const PrivacyPolicy = () => {
  const [activeItem, setActiveItem] = useState("");
  const handleActiveClick = (refName: string) => {
    setActiveItem(refName);
  };
  return (
    <div className={styles.alignPrivacyPage}>
      <h3 className="page-title mb-4">Privacy Policy</h3>

      <div className={styles.privacySubContainer}>
        {/* Start of List items */}
        <div className={styles.privacyContentList}>
          <ol>
            <li>
              <a
                href="#introduction"
                className={
                  activeItem === "introduction" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("introduction")}
              >
                Introduction
              </a>
            </li>

            <li>
              <a
                href="#informationcollection"
                className={
                  activeItem === "informationcollection"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("informationcollection")}
              >
                Information Collection and Use
              </a>
            </li>
            <li>
              <a
                href="#deviceinformation"
                className={
                  activeItem === "deviceinformation" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("deviceinformation")}
              >
                Device information
              </a>
            </li>
            <li>
              <a
                href="#logData"
                className={activeItem === "logData" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("logData")}
              >
                Log Data
              </a>
            </li>
            <li>
              <a
                href="#Cookies"
                className={activeItem === "Cookies" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("Cookies")}
              >
                Cookies
              </a>
            </li>
            <li>
              <a
                href="#automatedcollectedinformation"
                className={
                  activeItem === "automatedcollectedinformation"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() =>
                  handleActiveClick("automatedcollectedinformation")
                }
              >
                Automatically Collected Information / User Provided Information
              </a>
            </li>
            <li>
              <a
                href="#security"
                className={activeItem === "security" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("security")}
              >
                Security
              </a>
            </li>
            <li>
              <a
                href="#linktowebsite"
                className={
                  activeItem === "linktowebsite" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("linktowebsite")}
              >
                Links to Other Websites
              </a>
            </li>
            <li>
              <a
                href="#childrensprivacy"
                className={
                  activeItem === "childrensprivacy" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("childrensprivacy")}
              >
                Children’s Privacy
              </a>
            </li>
            <li>
              <a
                href="#newowner"
                className={activeItem === "newowner" ? `${styles.active}` : ""}
                onClick={() => handleActiveClick("newowner")}
              >
                New owner
              </a>
            </li>
            <li>
              <a
                href="#changetopolicy"
                className={
                  activeItem === "changetopolicy" ? `${styles.active}` : ""
                }
                onClick={() => handleActiveClick("changetopolicy")}
              >
                Changes to This Policy
              </a>
            </li>
            <li>
              <a
                href="#contactusprivacypolicy"
                className={
                  activeItem === "contactusprivacypolicy"
                    ? `${styles.active}`
                    : ""
                }
                onClick={() => handleActiveClick("contactusprivacypolicy")}
              >
                Contact Us
              </a>
            </li>
          </ol>
        </div>
        <PrivacyPolicyDescription />
      </div>

      {/* <p className="h5 text-dark mt-5">Date of last revision: 08/April/2020</p> */}
    </div>
  );
};

export default PrivacyPolicy;
