import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar.component";
import styles from "./AuthLayout.module.scss";
import Header from "../Header/Header.component";

export default function AuthLayout({ children }: { children: ReactNode }) {
  const [sidebar, setSidebar] = useState(true);

  const deviceWidth = window.innerWidth;
  const { pathname } = useLocation();
  useEffect(() => {
    // Setting Sidebar Dynamically based on window screen width
    if (deviceWidth < 992) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [deviceWidth, pathname]);

  useEffect(() => {
    if (sidebar) {
      setSidebar(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const onBoardingHideNavMenu =
    pathname !== "/get-started/onboarding" &&
    pathname !== "/get-started/onboarding/business-info" &&
    pathname !== "/get-started/onboarding/eligiblity-test" &&
    pathname !== "/get-started/onboarding/upload-document" &&
    pathname !== "/get-started/offer-letter";

  return (
    <React.Fragment>
      <div className={styles.authPagesWrapper}>
        {onBoardingHideNavMenu && (
          <div>
            <div
              className={`${styles.sidebar} ${
                sidebar ? "" : styles.hideSidebar
              }`}
            >
              <Sidebar />
            </div>
            <Header />
          </div>
        )}

        <div
          className={`${styles.main} ${sidebar ? "" : styles.noSidebar} ${
            onBoardingHideNavMenu ? styles.onBoardingNoMenu : "0"
          }`}
        >
          <div className={styles.children}>
            {onBoardingHideNavMenu && (
              <div
                className={
                  sidebar
                    ? `${styles.menuToggleBtn} ${styles.open}`
                    : styles.menuToggleBtn
                }
                onClick={toggleSidebar}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <div
              className={`${
                onBoardingHideNavMenu ? styles.manipulateChildren : ""
              }`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
