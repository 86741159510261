import { Fragment, useState, useMemo } from "react";
import Alert from "../../../../components/Alert/Alert.component";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import Loader from "../../../../components/Loader/Loader.component";
import useQueryLoanDetails from "../../../../custom-hooks/useQueryLoanDetails";
import { formatMifosDate, formatMoney } from "../../../../helpers/formatter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styles from "./rollOverSuccessModal.module.scss";
import { ReactComponent as SuccessIcon } from "../../../../svg/icons/checkbox-circle-line.svg";
import { ReactComponent as ErrorIcon } from "../../../../svg/icons/close-circle-line.svg";

import { loanRequestSchema } from "../../../../helpers/loanRequestSchema";
import useAggregatorProducts from "../../../../custom-hooks/useAggregatorProducts";
const schema = yup.object().shape({
  loanTenure: loanRequestSchema.loanTenure,
});
const RollOverModal = ({
  modalActionType,
  // aggregatorDetails,
  submitRepayment,
  dismissRef,
  loanRef,
  setModalActionType,
  submitRollOverRequest,
  loading,
  submitError,
}: any) => {
  const [copied, setCopied] = useState("");
  const { data: loanDetailsData, status: loanDetailsStatus } =
    useQueryLoanDetails({ loan_ref: loanRef });

  const { data: productsData } = useAggregatorProducts({ country_code: "" });
  const copyText = (text: string | number) => {
    setCopied("Copied.");
    navigator.clipboard.writeText(text.toString());
    setTimeout(() => setCopied(""), 3000);
  };
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: { loanTenure: string }) => {
    const { loanTenure } = data;

    const reqBody = {
      loan_tenure: Number(loanTenure),
    };
    submitRollOverRequest(reqBody);
  };
  const product = useMemo(
    () =>
      productsData?.find((product: { core_banking_product_id: number }) => {
        return (
          Number(loanDetailsData?.responseData?.loanProductId) ===
          Number(product.core_banking_product_id)
        );
      }),
    [productsData, loanDetailsData?.responseData?.loanProductId]
  );
  return (
    <div
      className="modal fade"
      id="rollOverModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="rollOverModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          {(() => {
            switch (modalActionType) {
              case "step1":
                return (
                  <Fragment>
                    {loanDetailsStatus === "loading" && (
                      <>
                        <div className="modal-header border-bottom-0">
                          <h6 className=" font-weight-bold w-100">
                            Total Repayment Amount
                          </h6>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={dismissRef}
                          ></button>
                        </div>
                        <hr
                          style={{
                            width: "80%",
                            marginTop: 0,
                          }}
                        ></hr>
                        <div className="modal-body">
                          <div className="mb-5">
                            <Loader />
                          </div>
                        </div>
                      </>
                    )}
                    {loanDetailsData?.responseData &&
                      loanDetailsStatus !== "loading" &&
                      loanDetailsData?.responseData?.summary
                        ?.interestOutstanding > 0 && (
                        <>
                          <div className="modal-header border-bottom-0">
                            <h6 className=" font-weight-bold w-100">
                              Total Repayment Amount
                            </h6>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              ref={dismissRef}
                            ></button>
                          </div>
                          <hr
                            style={{
                              width: "80%",
                              marginTop: 0,
                            }}
                          ></hr>
                          <div className="modal-body">
                            <div className="mb-4">
                              <span className="form-label">
                                Outstanding Principal :{" "}
                                <span className="font-weight-bold">
                                  {formatMoney(
                                    loanDetailsData?.responseData?.summary
                                      ?.principalOutstanding,
                                    loanDetailsData?.responseData?.summary
                                      ?.currency?.code
                                  )}
                                </span>
                              </span>
                            </div>
                            <div className="mb-5">
                              <span className="form-label">
                                Outstanding Interest:{" "}
                                <span className="font-weight-bold">
                                  {formatMoney(
                                    loanDetailsData?.responseData?.summary
                                      ?.interestOutstanding,
                                    loanDetailsData?.responseData?.summary
                                      ?.currency?.code
                                  )}
                                </span>
                              </span>
                            </div>
                            <div className="mb-5">
                              <span className="form-label">
                                Total outstanding:{" "}
                                <span className="font-weight-bold">
                                  {formatMoney(
                                    loanDetailsData?.responseData?.summary
                                      ?.totalOutstanding,
                                    loanDetailsData?.responseData?.summary
                                      ?.currency?.code
                                  )}
                                </span>
                              </span>
                            </div>
                            <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                              <button
                                type="button"
                                className="btn btn-sm advancly-nobg-btn mr-3"
                                data-dismiss="modal"
                                aria-label="Close"
                                ref={dismissRef}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="btn btn-sm advancly-btn"
                                onClick={(e) => {
                                  setModalActionType("step2");
                                }}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    {(!loanDetailsData?.responseData ||
                      loanDetailsData?.responseData?.summary
                        ?.interestOutstanding <= 0) &&
                      loanDetailsStatus !== "loading" && (
                        <>
                          <div className="modal-header border-bottom-0">
                            <h6 className=" font-weight-bold w-100">
                              Request for roll over
                            </h6>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              ref={dismissRef}
                            ></button>
                          </div>
                          <hr
                            style={{
                              width: "80%",
                              marginTop: 0,
                            }}
                          ></hr>
                          <div className="modal-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <p className="form-label mb-4">
                                How long do you want to roll over for?
                              </p>
                              <div className="mb-4">
                                <CustomInputField
                                  type="number"
                                  maxLength={3}
                                  defaultValue={product?.maximum_tenor}
                                  ref={register}
                                  label="Loan Tenure"
                                  name="loanTenure"
                                  placeholder="Enter Loan Tenure"
                                  errors={errors.loanTenure}
                                  extraLabel="(in days)"
                                  showRequiredIcon={true}
                                />
                              </div>
                              <div className="mb-4">
                                <div className="mb-2">
                                  <span className="form-label">
                                    Principal Amount:{" "}
                                  </span>
                                  <span className="page-subtitle">
                                    {" "}
                                    {formatMoney(
                                      loanDetailsData?.responseData?.summary
                                        ?.principalDisbursed,
                                      loanDetailsData?.responseData?.summary
                                        ?.currency?.code
                                    )}
                                  </span>
                                </div>
                                <div className="mb-2">
                                  <span className="form-label">
                                    outstanding Interest:{" "}
                                  </span>
                                  <span className="page-subtitle">
                                    {" "}
                                    {formatMoney(
                                      loanDetailsData?.responseData?.summary
                                        ?.interestOutstanding,
                                      loanDetailsData?.responseData?.summary
                                        ?.currency?.code
                                    )}
                                  </span>
                                </div>
                                <div className="mb-2">
                                  <span className="form-label">
                                    Total outstanding:{" "}
                                  </span>
                                  <span className="page-subtitle">
                                    {" "}
                                    {formatMoney(
                                      loanDetailsData?.responseData?.summary
                                        ?.totalOutstanding,
                                      loanDetailsData?.responseData?.summary
                                        ?.currency?.code
                                    )}
                                  </span>
                                </div>
                                <div className="mb-4">
                                  <span className="form-label">
                                    Effective Date:{" "}
                                  </span>
                                  <span className="page-subtitle">
                                    {loanDetailsData?.responseData?.timeline
                                      ?.expectedMaturityDate
                                      ? formatMifosDate(
                                          loanDetailsData?.responseData
                                            ?.timeline?.expectedMaturityDate
                                        )
                                      : "n/a"}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                                <button
                                  type="button"
                                  className="btn btn-sm advancly-nobg-btn mr-3"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  ref={dismissRef}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-sm advancly-btn"
                                >
                                  Confirm
                                  {loading && <Loader />}
                                </button>
                              </div>
                            </form>
                          </div>
                        </>
                      )}
                  </Fragment>
                );
              case "step2":
                return (
                  <Fragment>
                    <div className="modal-header border-bottom-0">
                      <h6 className=" font-weight-bold w-100">
                        Repayment Via Bank Transfer
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={dismissRef}
                      ></button>
                    </div>
                    <hr
                      style={{
                        width: "80%",
                        marginTop: 0,
                      }}
                    ></hr>
                    <div className="modal-body">
                      {copied && <Alert message={copied} type="success" />}

                      <div className="mb-4">
                        <span className="form-label">Total outstanding: </span>
                        <span className="page-subtitle">
                          {" "}
                          {formatMoney(
                            loanDetailsData?.responseData?.summary
                              ?.totalOutstanding,
                            loanDetailsData?.responseData?.summary?.currency
                              ?.code
                          )}
                        </span>
                      </div>
                      <p className="form-label mb-4">
                        1. Copy the account details below
                      </p>
                      <div className="bg-lighter-grey p-3">
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Name</small>
                          </div>
                          <h6 className="page-subtitle">
                            {/* {aggregatorDetails?.biz_name} */}
                            Advancly Technologies
                          </h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-2">
                            <small>Account Number</small>
                          </div>
                          <h6 className="page-subtitle">
                            {/* {aggregatorDetails?.account_number} */}
                            <span
                              className="font-weight-b cursor-pointer "
                              onClick={() =>
                                // copyText(aggregatorDetails?.account_number)
                                copyText(5072286460)
                              }
                            >
                              <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                              5072286460
                            </span>
                          </h6>
                        </div>
                        <div className="mb-4">
                          <div className="form-label mb-4">
                            <small>Bank Name</small>
                          </div>
                          <h6 className="page-subtitle">
                            {/* {aggregatorDetails?.bank_name} */}
                            Zenith
                          </h6>
                        </div>
                      </div>
                      <h6 className="form-label my-4 text-center text-primary">
                        <span
                          className="font-weight-b cursor-pointer ml-4"
                          onClick={() =>
                            // copyText(
                            //   `${aggregatorDetails?.biz_name},
                            //   ${aggregatorDetails?.account_number},
                            //  ${aggregatorDetails?.bank_name}`
                            // )
                            copyText(
                              `Advancly Technologies,
                               5072286460 ,
                               Zenith
                             `
                            )
                          }
                        >
                          <i className="ri-file-copy-fill color-mid-blue ml-2"></i>
                          Copy Account Details
                        </span>
                      </h6>
                      <p className="form-label mb-4">
                        2. Transfer the selected amount to the account
                        information displayed above.
                      </p>
                      <p className="form-label">
                        3. Your loan will be repaid once payment is confirmed.
                      </p>
                      <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                        <button
                          type="button"
                          className="btn btn-sm advancly-nobg-btn mr-3"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={dismissRef}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm advancly-btn"
                          onClick={(e) => {
                            submitRepayment(e);
                          }}
                        >
                          I have Paid
                          {loading && <Loader />}
                        </button>
                      </div>
                    </div>
                  </Fragment>
                );
              case "step3":
                return (
                  <Fragment>
                    <div className="modal-header border-bottom-0">
                      <h6 className=" font-weight-bold w-100">
                        Request for roll over
                      </h6>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={dismissRef}
                      ></button>
                    </div>
                    <hr
                      style={{
                        width: "80%",
                        marginTop: 0,
                      }}
                    ></hr>
                    <div className="modal-body">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <p className="form-label mb-4">
                          How long do you want to roll over for?
                        </p>
                        <div className="mb-4">
                          <CustomInputField
                            type="number"
                            maxLength={3}
                            ref={register}
                            label="Loan Tenure"
                            name="loanTenure"
                            placeholder="Enter Loan Tenure"
                            errors={errors.loanTenure}
                            extraLabel="(in days)"
                            showRequiredIcon={true}
                          />
                        </div>
                        <div className="mb-4">
                          <span className="form-label">Principal Amount: </span>
                          <span className="page-subtitle">
                            {" "}
                            {formatMoney(
                              loanDetailsData?.responseData?.summary
                                ?.principalDisbursed,
                              loanDetailsData?.responseData?.summary?.currency
                                ?.code
                            )}
                          </span>
                          <span className="form-label">
                            outstanding Interest:{" "}
                          </span>
                          <span className="page-subtitle">
                            {" "}
                            {formatMoney(
                              loanDetailsData?.responseData?.summary
                                ?.interestOutstanding,
                              loanDetailsData?.responseData?.summary?.currency
                                ?.code
                            )}
                          </span>
                          <span className="form-label">
                            Total outstanding:{" "}
                          </span>
                          <span className="page-subtitle">
                            {" "}
                            {formatMoney(
                              loanDetailsData?.responseData?.summary
                                ?.totalOutstanding,
                              loanDetailsData?.responseData?.summary?.currency
                                ?.code
                            )}
                          </span>
                          <span className="form-label">Effective Date: </span>
                          <span className="page-subtitle">
                            {loanDetailsData?.responseData?.timeline
                              ?.expectedMaturityDate
                              ? formatMifosDate(
                                  loanDetailsData?.responseData?.timeline
                                    ?.expectedMaturityDate
                                )
                              : "n/a"}
                          </span>
                        </div>

                        <div className="d-flex justify-content-end align-items-center mt-3 flex-wrap">
                          <button
                            type="button"
                            className="btn btn-sm advancly-nobg-btn mr-3"
                            data-dismiss="modal"
                            aria-label="Close"
                            ref={dismissRef}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-sm advancly-btn"
                          >
                            Confirm
                            {loading && <Loader />}
                          </button>
                        </div>
                      </form>
                    </div>
                  </Fragment>
                );
              case "step4":
                return (
                  <Fragment>
                    <div className="modal-header border-bottom-0">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        ref={dismissRef}
                      ></button>
                    </div>
                    <div className="modal-body">
                      {!submitError ? (
                        <Fragment>
                          <div className={styles.statusIcon}>
                            <SuccessIcon />
                          </div>
                          <p>
                            <span>
                              Your rollover request was successful, your request
                              will be processed shortly.
                            </span>
                          </p>
                          <div className=" mt-3">
                            <button
                              type="button"
                              className="btn btn-sm advancly-btn mr-3"
                              data-dismiss="modal"
                              aria-label="Close"
                              ref={dismissRef}
                            >
                              Done
                            </button>
                          </div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className={styles.statusIcon}>
                            <ErrorIcon />
                            <p className="font-weight-bold text-center mt-5">
                              {submitError}
                            </p>
                            <div className=" mt-3">
                              <button
                                type="button"
                                className="btn btn-sm advancly-btn mr-3"
                                data-dismiss="modal"
                                aria-label="Close"
                                ref={dismissRef}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};
export default RollOverModal;
