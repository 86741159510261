import { yupResolver } from "@hookform/resolvers/yup";
import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import * as yup from "yup";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import Loader from "../Loader/Loader.component";
import PageLoader from "../PageLoader/PageLoader.component";
import useSecurityQuestion from "../../custom-hooks/useSecurityQuestion";
import Alert from "../Alert/Alert.component";
import { appInsights } from "../AppInsight/AppInsight";
import style from "./WalletReset.module.scss";
const schema = yup.object().shape({
  answer: yup
    .string()
    .required("Please enter security  answer")
    .min(3, "Answer must be at least 3 characters")
    .max(64, "Answer cannot have more than 64 characters"),
});
const AnswerSecurityQuestionModal = ({
  setResetSection,
}: {
  setResetSection: Dispatch<SetStateAction<string>>;
}) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: { answer: string }) => {
    const { answer } = data;
    const reqBody = {
      questionId: securityQuestionToAnswerId,
      answer,
    };
    setLoading(true);
    setError("");
    try {
      await postData(apiEndpoints.CONFIRM_SECURITY_QUESTION, reqBody);
      setLoading(false);
      setResetSection("2");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "AnswerSecurityQuestionModal.tsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };
  const {
    data: answerQuestion,
    isLoading,
    error: securityQuestionError,
    isError,
  } = useSecurityQuestion();
  const securityQuestionToAnswer = answerQuestion?.question;
  const securityQuestionToAnswerId = Number(answerQuestion?.id);
  return (
    <Fragment>
      {error && <Alert message={error} />}
      {securityQuestionError && (
        <Alert message={errorHandler(securityQuestionError)} />
      )}
      {isLoading ? (
        <div className="p-5">
          <PageLoader />
        </div>
      ) : (
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseModal />
            </button>
          </div>
          <div>
            <div className="">
              <div className="col-12">
                <h5 className={`modal-title `}>Security Questions</h5>
              </div>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <p className={style.paragraphText}>
                  Kindly confirm your security question and answer before you
                  reset your PIN
                </p>
                <div className="row mt-3">
                  <div className="col-12">
                    <CustomInputField
                      type="text"
                      label="Security Question"
                      defaultValue={securityQuestionToAnswer}
                      readOnly
                    ></CustomInputField>
                  </div>

                  <div className="col-12">
                    <CustomInputField
                      type="text"
                      name="answer"
                      ref={register}
                      maxLength={64}
                      label="Security Question Answer"
                      errors={errors.answer}
                    />
                  </div>
                </div>
                <div className="col-12 pb-4 pt-4">
                  <button
                    className="btn  advancly-btn  w-100"
                    disabled={loading || isLoading || isError}
                  >
                    Save and Continue {loading && <Loader />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AnswerSecurityQuestionModal;
