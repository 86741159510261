import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import MakeLoanRequest from "../../components/MakeLoanRequest/MakeLoanRequest";
import ExistingIndividualBorrowerLoanRequest from "../ExistingBorrowerLoanRequest/ExistingBorrowerLoanRequest";

export default function LoanRequestLandingPage() {
  const match = useRouteMatch();
  return (
    <div className="container space-2">
      <Route path={match.path} exact render={() => <MakeLoanRequest />} />
      <Route
        path={`${match.path}/:id`}
        exact
        render={() => <ExistingIndividualBorrowerLoanRequest />}
      />
    </div>
  );
}
