import axios from "axios";
import { appInsights } from "../components/AppInsight/AppInsight";
import CONFIG from "../components/config";
import { decrypt } from "../helpers/encryptor";
import { environmentTypes } from "./../helpers/environmentTypes";

export const token = () => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    return null;
  }
  return decrypt(token);
};

const getBaseUrl = () => {
  const aggregatorDetails = JSON.parse(
    decrypt(sessionStorage.getItem("_aggDt"))
  );

  if (!aggregatorDetails) {
    return CONFIG.BASE_URL;
  }

  const envKey = `${aggregatorDetails?.rep_first_name}${aggregatorDetails?.rep_last_name}env`;

  if (!envKey) {
    return CONFIG.BASE_URL;
  }

  const currentEnvironment = localStorage.getItem(envKey);
  if (
    currentEnvironment &&
    decrypt(currentEnvironment) === environmentTypes.production
  ) {
    // Return Production URL
    return CONFIG.BASE_URL2;
  } else {
    // Return Sandbox URL
    return CONFIG.SANDBOX_BASE_URL;
  }
};

const axiosInstance = axios.create();
const axiosInstanceProdOnly = axios.create();

axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Token ${token()}`,
      "content-security-policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
    };
    config.baseURL = getBaseUrl();
    // you can also do other modification in config
    return config;
  },
  function (error) {
    appInsights.trackException({ exception: error });

    return Promise.reject(error);
  }
);

axiosInstanceProdOnly.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Authorization: `Token ${token()}`,
      "content-security-policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
    };
    config.baseURL = CONFIG.BASE_URL;
    // you can also do other modification in config
    return config;
  },
  function (error) {
    appInsights.trackException({ exception: error });

    return Promise.reject(error);
  }
);

export const getDataWithDotNet = async (url, prodOnly) => {
  try {
    if (prodOnly && prodOnly === true) {
      const data = await axiosInstanceProdOnly({
        method: "GET",
        url,
      });
      return data.data;
    } else {
      const data = await axiosInstance({
        method: "GET",
        url,
      });
      return data.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.location.href = "/login";
    } else {
      return error;
    }
  }
};

export const getDataWithoutTokenWithDotNet = async (url, prodOnly) => {
  try {
    const data = await axios({
      method: "GET",
      url: `${
        prodOnly && prodOnly === true ? CONFIG.BASE_URL : getBaseUrl()
      }${url}`,
      headers: {
        "Content-Type": "application/json",
        "content-security-policy":
          "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      },
    });
    return data.data;
  } catch (error) {
    return error;
  }
};

export const postDataWithDotNet = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "POST",
      url,
      headers: {
        "content-security-policy":
          "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      },
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "POST",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const postDataWithoutTokenWithDotNet = async (
  url,
  reqBody,
  prodOnly
) => {
  const data = await axios({
    method: "POST",
    url: `${
      prodOnly && prodOnly === true ? CONFIG.BASE_URL : getBaseUrl()
    }${url}`,
    headers: {
      "content-security-policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
    },
    data: reqBody,
  });
  return data.data;
};

export const patchDataWithDotNet = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "PATCH",
      url,

      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "PATCH",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const putDataWithDotNet = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "PUT",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "PUT",
      url,
      data: reqBody,
    });
    return data.data;
  }
};

export const deleteDataWithDotNet = async (url, reqBody, prodOnly) => {
  if (prodOnly && prodOnly === true) {
    const data = await axiosInstanceProdOnly({
      method: "DELETE",
      url,
      data: reqBody,
    });
    return data.data;
  } else {
    const data = await axiosInstance({
      method: "DELETE",
      url,
      data: reqBody,
    });
    return data.data;
  }
};
