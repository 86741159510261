import React, { useState } from "react";
import AnswerSecurityQuestionModal from "./AnswerSecurityQuestionModal";
import ResetPinModal from "./ResetPinModal";

const WalletResetModal = () => {
  const [resetSection, setResetSection] = useState("1");
  return (
    <div>
      <div className="modal fade" id="walletResetModal">
        <div className="modal-dialog modal-dialog-centered">
          {(() => {
            switch (resetSection) {
              case "1":
                return (
                  <AnswerSecurityQuestionModal
                    setResetSection={setResetSection}
                  />
                );
              case "2":
                return <ResetPinModal setResetSection={setResetSection} />;
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </div>
  );
};

export default WalletResetModal;
