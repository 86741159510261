import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getGovernmentDocumentType = async () => {
  const { data } = await getData(apiEndpoints.GOVERNMENT_DOCUMENT_TYPES, true);
  return data;
};

export default function useGovernmentDocumentType() {
  return useQuery(["getGovernmentDocumentTypes"], getGovernmentDocumentType, {
    refetchOnWindowFocus: false, // Refetch on Window focus
  });
}
