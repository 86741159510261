import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
type Taggregator_id = number;

const getGeneratedAggregatorKey = async ({
  aggregator_id,
}: {
  aggregator_id: Taggregator_id;
}) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getGeneratedAggregatorKey}?aggregator_id=${aggregator_id}`,
    true
  );
  return data.data;
};

export default function useGeneratedAggregatorKey({
  aggregator_id,
}: {
  aggregator_id: Taggregator_id;
}) {
  return useQuery(
    [{ aggregator_id }, "getGeneratedAggregatorKey"],
    getGeneratedAggregatorKey,
    {
      refetchOnWindowFocus: true,
    }
  );
}
