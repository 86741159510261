import React, { useEffect, useState } from "react";
import styles from "./LoanApplication.module.scss";
import LoanInformation from "./LoanInformation/LoanInformation";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import Industry from "./Industry/Industry";
import Collateral from "./Collateral/Collateral";
import FinancialInformation from "./FinancialInformation/FinancialInformation";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Context/auth.context";
import FeedBackLoanApplication from "./FeedBackLoanApplication/FeedBackLoanApplication";
import useLoanApplicationById from "../../custom-hooks/useLoanApplicationById";
import loanApplicationStatus from "../../helpers/loanApplicationStatus";
import LoanApplicationOfferLetter from "./LoanOfferLetter/LoanApplicationOfferLetter";
import PreDisbursement from "./PreDisbursement/PreDisbursement";
export interface IstepLoanApp {
  handlePrev?: () => void;
  handleNext?: () => void;
  scrollToTop?: () => void;
}
const LoanApplicationMain = () => {
  const {
    currentLoanApplicationId,
    editLoanApplicationGlobal,
    continueLoanRequestProgressBar,
  } = useAuth();
  const scrollToTop = () => {
    //@ts-ignore
    document?.getElementById("topOfPage")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const [step, setStep] = useState(1);
  const handlePrev = () => setStep((prev: number) => prev - 1);
  const handleNext = () => setStep((next: number) => next + 1);
  const history = useHistory();
  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const title = {
    1: "Loan Information",
    2: "Business Information",
    3: "Industry",
    4: "Financial Information",
    5: "Collateral",
    6: "Appraisal Feedback",
    7: existingLoanInformationData?.status_id > 14 ? "Offer Letter" : null,
    8: existingLoanInformationData?.status_id > 17 ? "Pre Disbursment" : null,
  };
  //progress bar styling
  // const interval = 100 / Object.keys(title).length - 0.6;
  const interval =
    existingLoanInformationData?.status_id > 17
      ? 100 / Object.keys(title).length - 0.6
      : existingLoanInformationData?.status_id > 14
      ? 100 / 7 - 0.65
      : 100 / 6 - 0.6;

  const progress = (step * interval).toFixed(2);
  const clickOnProgressBarHandler = (stepClicked: string | null) => {
    if (stepClicked === "Loan Information") {
      setStep(1);
    }
    if (stepClicked === "Business Information") {
      setStep(2);
    }
    if (stepClicked === "Industry") {
      setStep(3);
    }
    if (stepClicked === "Financial Information") {
      setStep(4);
    }
    if (stepClicked === "Collateral") {
      setStep(5);
    }
    if (stepClicked === "Appraisal Feedback") {
      setStep(6);
    }
    if (stepClicked === "Offer Letter") {
      setStep(7);
    }
    if (stepClicked === "Pre Disbursment") {
      setStep(8);
    }
  };
  const goBackHandler = () => {
    if (step === 1) {
      history.push(`loan-application`);
    } else if (
      step === 2 ||
      step === 3 ||
      step === 4 ||
      step === 5 ||
      step === 6 ||
      step === 7 ||
      step === 8
    ) {
      handlePrev();
    } else {
      history.push(`loan-application`);
    }
  };
  useEffect(() => {
    if (
      currentLoanApplicationId &&
      editLoanApplicationGlobal &&
      continueLoanRequestProgressBar
    ) {
      if (continueLoanRequestProgressBar === 1) {
        setStep(1);
      }
      if (continueLoanRequestProgressBar === 2) {
        setStep(2);
      }
      if (continueLoanRequestProgressBar === 3) {
        setStep(3);
      }
      if (continueLoanRequestProgressBar === 4) {
        setStep(4);
      }
      if (continueLoanRequestProgressBar === 5) {
        setStep(5);
      }
      if (continueLoanRequestProgressBar === 6) {
        setStep(6);
      }
      if (continueLoanRequestProgressBar === 7) {
        setStep(7);
      }
      if (continueLoanRequestProgressBar === 8) {
        setStep(8);
      }
    }
  }, [
    continueLoanRequestProgressBar,
    currentLoanApplicationId,
    editLoanApplicationGlobal,
  ]);

  const steps = Object.values(title).map((stepParams, i) => {
    return (
      <div
        key={i}
        className={`cursor-pointer ${styles.barmarker}`}
        onClick={() => {
          clickOnProgressBarHandler(stepParams);
        }}
      >
        {stepParams}
      </div>
    );
  });

  return (
    <div id="topOfPage">
      <div>
        <div className={`${styles.mainHeadingContainer} pb-3`}>
          <small
            className="color-mid-blue  cursor-pointer font-weight-bold  "
            onClick={goBackHandler}
          >
            &#8592; Go back
          </small>
          <h1 className={styles.headingLoanApplication}>
            {existingLoanInformationData?.status_id ===
              loanApplicationStatus.returnedToCustomer ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.creditReview ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.relationshipManager ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.returnedToCreditAnalyst ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.returnedToRelationshipManager ||
            //start from here
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.creditApprover ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.declinedBycreditAnalyst ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.approvedByCreditAnalyst ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.declinedByCreditApprover ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.approvedByCreditApprover ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.sentToMCC ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.declinedByMCC ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.approvedByMCC ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.offerLetterwithCustomer ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.offerLetterDeclinedByCustomer ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.offerLetterAcceptedByCustomer ||
            existingLoanInformationData?.status_id ===
              loanApplicationStatus.offerLetterExecutedByLegal
              ? `Loan Application`
              : ` New Loan Application`}
          </h1>
        </div>
        <div className={styles.progressContainer}>
          <progress
            className={styles.progress}
            max="100"
            value={progress}
          ></progress>
          <div className={styles.barmarkerContainer}>{steps}</div>
        </div>
      </div>

      {(() => {
        switch (step) {
          case 1:
            return (
              <LoanInformation
                handlePrev={handlePrev}
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 2:
            return (
              <BusinessInformation
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 3:
            return (
              <Industry handleNext={handleNext} scrollToTop={scrollToTop} />
            );
          case 4:
            return (
              <FinancialInformation
                handleNext={handleNext}
                scrollToTop={scrollToTop}
              />
            );
          case 5:
            return <Collateral scrollToTop={scrollToTop} />;

          case 6:
            return <FeedBackLoanApplication scrollToTop={scrollToTop} />;
          case 7:
            return <LoanApplicationOfferLetter scrollToTop={scrollToTop} />;
          case 8:
            return <PreDisbursement />;

          default:
            return null;
        }
      })()}
    </div>
  );
};

export default LoanApplicationMain;
