import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getListOfDocumentsAlreadyUploaded = async () => {
  const { data } = await getDataWithDotNet(
    apiEndpoints.getListOfDocumentsAlreadyUploaded,
    true
  );
  return data;
};

export default function useListOfDocumentsAlreadyUploaded() {
  return useQuery(
    "getListOfDocumentsAlreadyUploaded",
    getListOfDocumentsAlreadyUploaded,
    {
      refetchOnWindowFocus: false,
    }
  );
}
