import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";

const getBusinessSectors = async () => {
  const data = await getDataWithDotNet(apiEndpoints.businessSectors);
  return data.data;
};

export default function useBusinessSectors() {
  return useQuery(["getBusinessSectors"], getBusinessSectors, {
    refetchOnWindowFocus: false,
  });
}
