import React, { ChangeEventHandler } from "react";
import ErrorHandler from "../ErrorHandler/ErrorHandler.component";
import styles from "./CustomFieldStyles.module.scss";
interface ICustomSelectDropdownProps {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: any;
  required?: boolean;
  className?: string;
  disabled?: boolean | any;
  maxLength?: undefined | number;
  defaultValue?: string | number;
  value?: string | number;
  minLength?: undefined | number;
  onChange?: ChangeEventHandler;
  min?: number;
  showRequiredIcon?: boolean;
  hasActionButton?: boolean;
  actionButtonText?: string;
  onClickActionButton?: () => void;
  children?: React.ReactNode;
  extraLabel?: string | null;
  style?: React.CSSProperties;
  customSelectStyle?: React.CSSProperties | any;
  readonly?: any;
}
const CustomSelectDropdown = React.forwardRef(
  (
    {
      name,
      children,
      label,
      errors,
      value,
      onChange,
      showRequiredIcon,
      extraLabel,
      required,
      className,
      disabled,
      style,
      customSelectStyle,
    }: ICustomSelectDropdownProps,
    ref: any
  ) => {
    return (
      <>
        <div className={`form-group ${className} `} style={style}>
          <div className="d-flex align-items-center">
            <label className={`form-label text_label`}>{label}</label>
            {showRequiredIcon && <sup className="ml-1 crimson">*</sup>}
            {extraLabel && extraLabel.length && (
              <label className="form-label ml-2">
                <small className="crimson">{extraLabel}</small>
              </label>
            )}
          </div>
          <select
            className={`form-control ${styles.customInputStyles}`}
            name={name}
            ref={ref}
            value={value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            style={{
              borderRadius: "10px",
              height: "3.13rem",
              ...customSelectStyle,
            }}
          >
            {children}
          </select>
          <ErrorHandler errors={errors} />
        </div>
      </>
    );
  }
);

export default CustomSelectDropdown;
