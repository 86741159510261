import React from "react";
import LoaderColored from "../../Loader/LoaderColored";
import { ReactComponent as CopyIcon } from "../../../svg/icons/copy.svg";
import { ReactComponent as CloseModal } from "../../../svg/icons/modal-close.svg";
import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import styles from "./DeveloperKeys.module.scss";
const DeveloperAPIModal = ({
  generateStatus,
  generateKeys,
  copyText,
  copied,
}: {
  generateStatus: string;
  generateKeys: any;
  copyText: (text: string) => void;
  copied: string;
}) => {
  return (
    <div
      className="modal fade"
      id="devloperModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="developerModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header ">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <CloseModal />
            </button>
          </div>
          <div className="">
            <div className="col-12">
              <h5 className={`modal-title `} id="developerModalLabel">
                Generated Key
              </h5>
            </div>
          </div>

          <div className="modal-body">
            {copied && (
              <span
                className="d-flex justify-content-center text-success

            "
              >
                copied !!!
              </span>
            )}
            {generateStatus === "loading" ? (
              <div className="d-flex justify-content-center align-items-center m-h-100">
                <LoaderColored />
              </div>
            ) : (
              <div className="row">
                <div className="col-12 ">
                  <CustomInputField
                    type="text"
                    readOnly={true}
                    label="Client ID"
                    value={generateKeys?.data?.client_id}
                    extras={
                      <span
                        title="copy"
                        className=" transition-3d-hover"
                        onClick={() => copyText(generateKeys?.data?.client_id)}
                      >
                        <CopyIcon />
                      </span>
                    }
                  />
                </div>
                <div className="col-12 ">
                  <CustomInputField
                    type="text"
                    readOnly={true}
                    label="API key"
                    value={generateKeys?.data?.secret_key}
                    extras={
                      <span
                        title="copy"
                        className=" transition-3d-hover"
                        onClick={() => copyText(generateKeys?.data?.secret_key)}
                      >
                        <CopyIcon />
                      </span>
                    }
                  />
                </div>
                <div
                  className={`d-flex justify-content-start  ${styles.generateKeysMessage}`}
                >
                  <small className="color-mid-blue-hover-none   text-center">
                    {generateKeys?.message}
                  </small>
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn w-100 advancly-btn"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperAPIModal;
