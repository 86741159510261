import styles from "./Sidebar.module.scss";
import { ReactComponent as AdvestRedirectIcon } from "../../svg/icons/AdvestRedirectIcon.svg";
import LoaderColored from "../Loader/LoaderColored";

const SidebarDropdownItems = ({
  img,
  alt,
  productActive,
  aggregatorEmail,
  onClick,
  productSwitchLoader,
  advestRedirectIcon,
}: {
  img: string;
  alt: string;
  productActive: boolean;
  aggregatorEmail: string;
  onClick?: any;
  productSwitchLoader?: boolean;
  advestRedirectIcon?: boolean;
}) => {
  return (
    <div className={styles.dropdownItem_container}>
      <li
        className={`${styles.dropdownItem} ${
          productActive ? styles.active_product : styles.inActive_product
        } `}
        onClick={onClick}
      >
        <div className={styles.dropdownItem_product}>
          {" "}
          <span>{aggregatorEmail}</span>
          <img src={img} alt={alt}></img>
        </div>
        <div
          className={`${
            productActive
              ? styles.dropdownItem_product_active
              : styles.dropdownItem_product_Inactive
          }`}
        ></div>
        {productSwitchLoader && (
          <div className="align-self-center">
            <LoaderColored />
          </div>
        )}
        {advestRedirectIcon && (
          <div className={styles.advestRedirectIcon}>
            {" "}
            <AdvestRedirectIcon />
          </div>
        )}
      </li>
    </div>
  );
};

export default SidebarDropdownItems;
