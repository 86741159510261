import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

type TResponse = {
  currency_code: string;
  currency_name: string;
  minimum_repayment_amount: number;
  minimum_wallet_topup_amount: number;
  tenantInfo: { tenantId: string; fullName: string };
}[];

const getAllCurrencies = async () => {
  const { data } = await getData(apiEndpoints.ALL_CURRENCIES);
  return data;
};

export default function useAllCurrencies() {
  return useQuery<TResponse>("getAllCurrencies", getAllCurrencies, {
    refetchOnWindowFocus: false,
  });
}
