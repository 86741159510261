import { Route, Switch, withRouter } from "react-router-dom";
import BorrowerProfile from "../../components/BorrowerProfile";
import MainRepayment from "./MainRepayment";
import RepayLoans from "../../components/RepayLoan";

function Repayment(props: any) {
  let match = props.match;

  return (
    <div>
      <Switch>
        <Route
          path={match.path + "/repay"}
          render={() => (
            <RepayLoans
              history={props.history}
              data={
                props.location.data ? props.location.data.dataForRepayment : {}
              }
            />
          )}
        />
        <Route
          path={match.path + "/:borrower_id"}
          render={() => <BorrowerProfile />}
        />
        <Route path={match.path} exact render={() => <MainRepayment />} />
      </Switch>
    </div>
  );
}
export default withRouter(Repayment);
