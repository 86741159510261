import React from "react";
import style from "./ToolTip.module.scss";
interface IToolTip {
  tooltipHeading: string;
  tooltipParagraph: string;
  showCloseToolTip?: boolean;
  tooltipContainerLeft?: string;
  tooltipContainerRight?: string;
  tooltipContainerTop?: string;
  tooltipContainerBottom?: string;
  tooltipContainerMarginLeft?: string;
  tooltipContainerMarginRight?: string;
  onClick?: () => void;
}
const ToolTip = ({
  tooltipHeading,
  tooltipParagraph,
  showCloseToolTip = false,
  onClick,
  tooltipContainerLeft,
  tooltipContainerRight,
  tooltipContainerTop,
  tooltipContainerBottom,
  tooltipContainerMarginLeft,
  tooltipContainerMarginRight,
}: IToolTip) => {
  return (
    <div
      className={`${style.toolTipContainer} `}
      style={{
        top: tooltipContainerTop,
        bottom: tooltipContainerBottom,
        left: tooltipContainerLeft,
        right: tooltipContainerRight,
        marginLeft: tooltipContainerMarginLeft,
        marginRight: tooltipContainerMarginRight,
      }}
    >
      {showCloseToolTip && (
        <div className="d-flex justify-content-end" onClick={onClick}>
          <span aria-hidden="true">&times;</span>
        </div>
      )}
      <div className={style.toolTipHeading}>
        <h5>{tooltipHeading}</h5>
      </div>
      <hr />
      <div className={style.toolTipText}>
        <p>{tooltipParagraph}</p>
      </div>
    </div>
  );
};

export default ToolTip;
