import React from "react";
import DashboardCard from "../../components/DashboardCard/DashboardCard.component";
import { formatMoneyNoDecimal } from "../../helpers/formatterNoDecimal";
import { ReactComponent as InvestmentIcon } from "../../svg/icons/progress_icon_top_colored.svg";
// import { ReactComponent as EarningIcon } from "../../svg/icons/TotalEarningsIcon.svg";
import { ReactComponent as InvestorIcon } from "../../svg/icons/InvestorsIcon.svg";
import { ReactComponent as InvestmentCountIcon } from "../../svg/icons/InvestmentCountIcon.svg";
import { InvestmentDashboardGraph } from "../../components/DashboardGraphs/InvestmentDashboardGraph";

const InvestmentDashboardTab = ({
  currentCurrency,
  activeProductName,
  investmentOverview,
  investmentOverviewStatus,
}: {
  currentCurrency: "NGN" | "USD" | "KES" | "#" | "$" | "KSh";
  activeProductName: string;
  investmentOverview: {
    total_investment_amount: number;
    total_investors: number;
    investment_count: number;
  };
  investmentOverviewStatus: string;
}) => {
  return (
    <div>
      {/* DASHBOARD INVESTMENTS */}
      <div className="advancly-wrapper-container mb-3">
        <div className="dashboardComponent advancly-sub-wrapper">
          <DashboardCard
            text="Total Investment Amount"
            value={
              investmentOverview &&
              formatMoneyNoDecimal(
                Math.trunc(investmentOverview?.total_investment_amount),
                currentCurrency
              )
            }
            status={investmentOverviewStatus}
            activeDefaultColor={true}
          >
            {" "}
            <InvestmentIcon />
          </DashboardCard>
          {/* <DashboardCard
          text="Total earnings"
          value={
            transactionsOverview &&
            formatMoneyNoDecimal(
              transactionsOverview?.total_repayment,
              transactionsOverview?.currency
            )
          }
          status={trasactionOverviewStatus}
        >
          <EarningIcon />
        </DashboardCard> */}
          <DashboardCard
            text="Total investors"
            value={
              investmentOverview?.total_investors >= 1
                ? investmentOverview?.total_investors
                : 0
            }
            status={investmentOverviewStatus}
          >
            <InvestorIcon />
          </DashboardCard>
          <DashboardCard
            text="Investment count"
            value={
              investmentOverview?.investment_count >= 1
                ? investmentOverview?.investment_count
                : 0
            }
            status={investmentOverviewStatus}
          >
            <InvestmentCountIcon />
          </DashboardCard>
        </div>
      </div>
      <InvestmentDashboardGraph
        currentCurrency={currentCurrency}
        activeProductName={activeProductName}
      />

      {/* DASHBOARD INVESTMENTS */}
    </div>
  );
};

export default InvestmentDashboardTab;
