import React from "react";
import moment from "moment";
import { formatMoney } from "../../helpers/formatter";

export default function EachRepayment({
  data,
}: {
  data: {
    loan_ref: string;
    borrower_full_name: string;
    repayment_amount: number;
    loan_due_date: string;
    loan_outstanding: number;
    product_name: string;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
  };
}) {
  const {
    loan_ref,
    borrower_full_name,
    repayment_amount,
    loan_due_date,
    loan_outstanding,
    product_name,
    currency,
  } = data;
  return (
    <tr>
      <td className="th-td-style">{loan_ref}</td>
      <td className="text-capitalize th-td-style">
        {borrower_full_name && borrower_full_name.toLowerCase()}
      </td>
      <td className="th-td-style">{product_name}</td>
      <td className="th-td-style">{formatMoney(repayment_amount, currency)}</td>
      <td className="th-td-style">{formatMoney(loan_outstanding, currency)}</td>
      <td className="th-td-style">
        {moment(loan_due_date).format("DD-MM-YYYY")}
      </td>
    </tr>
  );
}
