import React, { ChangeEvent, useEffect, useState } from "react";
import * as yup from "yup";

import styles from "../LoanApplication.module.scss";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
import { useDropzone } from "react-dropzone";
import { ReactComponent as FileUploadIcon } from "../../../img/file_upload.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { IstepLoanApp } from "../LoanApplicationMain";
import { useAuth } from "../../../Context/auth.context";
import Loader from "../../Loader/Loader.component";
import useListOfFinancialDocument from "../../../custom-hooks/useListOfFinancialDocument";
import LoaderColored from "../../Loader/LoaderColored";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { decrypt } from "../../../helpers/encryptor";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
import { useQueryCache } from "react-query";
import ConfirmationDeleteFinancialInfoModal from "./ConfirmationDeleteFinancialInfoModal";
import PageLoader from "../../PageLoader/PageLoader.component";
import ShowFinancialInformationList from "./ShowFinancialInformationList";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";

const schema = yup.object().shape({
  AllDocuments: yup.string().trim().required("Please pick a document type"),
  documentDescription: yup.string().trim().required("Please enter document"),
});
type IdataType = {
  AllDocuments: number;
  documentDescription: string;
};
const FinancialInformation = ({ handleNext, scrollToTop }: IstepLoanApp) => {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentListOfAcceptedFiles, setCurrentListOfAcceptedFiles] =
    useState<any>();
  const [loadingSaveAndContinue, setLoadingSaveAndContinue] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const {
    currentLoanApplicationId,
    setCurrentLoanApplicationId,
    setEditLoanApplicationGlobal,
  } = useAuth();

  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;

  const [finDocAppId, setFinDocAppId] = useState<number | null>(null);
  const [finAppId, setFinId] = useState<number | null>(null);
  const [loanApplicationDocTypeId, setloanApplicationDocTypeId] = useState<
    number | null
  >(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
  });
  useEffect(() => {
    setCurrentListOfAcceptedFiles(acceptedFiles);
  }, [acceptedFiles]);

  useEffect(() => {
    const storedCurrentId = decrypt(sessionStorage.getItem("currentLoanAppId"));
    const storedEditLoanApplicationGlobal = decrypt(
      sessionStorage.getItem("globalEditLoanApplication")
    );
    if (storedCurrentId) {
      setCurrentLoanApplicationId(storedCurrentId);
    }
    if (storedEditLoanApplicationGlobal) {
      setEditLoanApplicationGlobal(storedEditLoanApplicationGlobal);
    }
  }, [setCurrentLoanApplicationId, setEditLoanApplicationGlobal]);
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();

  const {
    data: existingFinancialInformationData,
    status: existingFinancialInformationStatus,
    error: existingFinancialInformationError,
  } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const files = currentListOfAcceptedFiles ? (
    currentListOfAcceptedFiles?.map((file: any) => {
      return (
        <>
          <li key={file.path}>
            {file.path} -{(parseFloat(file.size) / 1024).toFixed(2)}kb
          </li>
        </>
      );
    })
  ) : (
    <div>""</div>
  );

  const {
    data: FinancialInformationListData,
    status: FinancialInformationListStatus,
    error: FinancialInformationListError,
  } = useListOfFinancialDocument();
  const onSubmitHandler = async (data: IdataType) => {
    if (acceptedFiles?.length === 0) {
      setError("Please select a file");

      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      const acceptedBase64 = await convertToBase64(acceptedFiles[0]);

      const { AllDocuments, documentDescription } = data;
      const ReqBody = {
        loan_application_id: currentLoanApplicationId,
        financial_type_id: AllDocuments,
        documents: [
          {
            base64_file_string: acceptedBase64,
            filename_with_extension: acceptedFiles[0]?.name,
            description: documentDescription,
          },
        ],
      };

      try {
        setLoading(true);
        setError(null);
        const res = await postData(
          apiEndpoints.LOAN_APPLICATION_FINANCIAL,
          ReqBody,
          true
        );
        setLoading(false);
        setSuccess(res?.message);
        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        setCurrentListOfAcceptedFiles(null);
        reset();
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setSuccess(null);
        }, 5000);
      } catch (err) {
        appInsights.trackException({
          //@ts-ignore
          exception: err,
          properties: {
            fileName: "FinancialInformation.tsx",
          },
        });
        setLoading(false);
        setSuccess(null);
        setError(errorHandler(err));
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };
  const onSaveAndContinueHandler = async (data: IdataType) => {
    if (acceptedFiles?.length === 0) {
      setError("Please select a file");

      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      setError(null);
      const acceptedBase64 = await convertToBase64(acceptedFiles[0]);

      const { AllDocuments, documentDescription } = data;
      const ReqBody = {
        loan_application_id: currentLoanApplicationId,
        financial_type_id: AllDocuments,
        documents: [
          {
            base64_file_string: acceptedBase64,
            filename_with_extension: acceptedFiles[0]?.name,
            description: documentDescription,
          },
        ],
      };

      try {
        setLoadingSaveAndContinue(true);
        setError(null);
        const res = await postData(
          apiEndpoints.LOAN_APPLICATION_FINANCIAL,
          ReqBody,
          true
        );
        setLoadingSaveAndContinue(false);
        setSuccess(res?.message);
        queryCache.invalidateQueries([
          { id: currentLoanApplicationId },
          "getLoanApplicationById",
        ]);
        setCurrentListOfAcceptedFiles(null);
        reset();

        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setSuccess(null);
          if (handleNext) {
            handleNext();
          }
        }, 2000);
      } catch (err) {
        appInsights.trackException({
          //@ts-ignore
          exception: err,
          properties: {
            fileName: "FinancialInformation.tsx",
          },
        });
        setLoadingSaveAndContinue(false);
        setSuccess(null);
        setError(errorHandler(err));
        if (scrollToTop) {
          scrollToTop();
        }
        setTimeout(() => {
          setError(null);
        }, 5000);
      }
    }
  };

  return (
    <>
      <div className={`${styles.center_container} `}>
        <div className="py-3">
          <h2 className={`${styles.loanInformationHeading}`}>
            Financial Information
          </h2>
          {!LoanApplicationEditOrWrite ? (
            <p className={`${styles.InfoParagraph}`}>
              Select and Upload Required Financial Information provided <br />{" "}
              (you can select multiple document type)
            </p>
          ) : (
            <p className={`${styles.InfoParagraph}`}>
              Below are a list financial documents uploaded by you
            </p>
          )}
        </div>
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
        {FinancialInformationListError && (
          <p className="alert alert-danger small">
            {FinancialInformationListError}
          </p>
        )}
        {existingFinancialInformationError && (
          <p className="alert alert-danger small">
            {existingFinancialInformationError}
          </p>
        )}

        {/* start of list of uploaded financial documents */}

        {existingFinancialInformationStatus === "loading" ? (
          <PageLoader />
        ) : (
          existingFinancialInformationData?.financial_information?.map(
            (financeData: any) => {
              const { financial_type_id } = financeData;

              return (
                <div className="p-1" key={financial_type_id}>
                  <ShowFinancialInformationList
                    setFinDocAppId={setFinDocAppId}
                    setFinId={setFinId}
                    setloanApplicationDocTypeId={setloanApplicationDocTypeId}
                    financeData={financeData}
                  />
                </div>
              );
            }
          )
        )}
        {/* list of uploaded financial documents ends here */}

        <div className="row mt-3">
          {!LoanApplicationEditOrWrite && (
            <form>
              <div className="col-12">
                <CustomSelectDropdown
                  name="AllDocuments"
                  ref={register}
                  label="All Documents"
                  errors={errors.AllDocuments}
                >
                  <option value={""}>Select Document Type</option>
                  {FinancialInformationListData &&
                    FinancialInformationListData?.map(
                      (list: { id: number; name: string }) => {
                        const { id, name } = list;
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      }
                    )}
                </CustomSelectDropdown>
                {FinancialInformationListStatus === "loading" && (
                  <div className="d-flex justify-content-end">
                    <LoaderColored />
                  </div>
                )}
              </div>
              <div className="col-12">
                <CustomTextArea
                  maxLength={200}
                  ref={register}
                  placeholder="Enter Document description...."
                  label={`Document Description `}
                  name="documentDescription"
                  errors={errors.documentDescription}
                  showRequiredIcon={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCount(e.target.value.length)
                  }
                />
                <span className="d-flex justify-content-end color-blue-hover-none">
                  {count}/200
                </span>
              </div>
              <div className="col-12">
                <div {...getRootProps({ className: "dropzone" })}>
                  <div className={styles.financialDocumentUploadContainer}>
                    <input {...getInputProps()} />
                    <FileUploadIcon />
                    <h5>Upload File</h5>
                    <p className={styles.dropParagraph}>
                      Drag & drop your file here or
                      <span
                        className="color-blue"
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        browse
                      </span>{" "}
                    </p>
                    {currentListOfAcceptedFiles?.length > 0 && (
                      <small className="text-muted text-underline cursor-pointer transition-3d-hover mt-4">
                        Click to remove or change selected files
                      </small>
                    )}
                  </div>
                  {files.length > 0 && (
                    <aside className="text-muted mt-2">
                      <h5> Selected file</h5>
                      <ul>{files}</ul>
                    </aside>
                  )}
                </div>
              </div>
              <div className="col-12 mt-2">
                <button
                  type="submit"
                  className="btn advancly-btn w-100"
                  disabled={loading}
                  onClick={handleSubmit(onSubmitHandler)}
                >
                  {" "}
                  Submit & Add Another {loading && <Loader />}
                </button>
              </div>
              <div className="col-12 mt-4">
                <button
                  type="submit"
                  className="btn advancly-white-btn w-100"
                  disabled={loadingSaveAndContinue}
                  onClick={handleSubmit(onSaveAndContinueHandler)}
                >
                  {" "}
                  Save and Continue{" "}
                  {loadingSaveAndContinue && <LoaderColored />}
                </button>
              </div>
              <div className="col-12">
                <hr className={styles.dottedlines} />
              </div>
            </form>
          )}
        </div>
        {LoanApplicationEditOrWrite && (
          <button
            className="btn advancly-white-btn transition-3d-hover m-2 "
            type="button"
            onClick={handleNext}
          >
            Next
          </button>
        )}
      </div>
      <ConfirmationDeleteFinancialInfoModal
        id={finAppId}
        docId={finDocAppId}
        doctypeId={loanApplicationDocTypeId}
      />
    </>
  );
};

export default FinancialInformation;
