// import React, { useState } from "react";
// import styles from "../GetStarted.module.scss";
// import useOfferLetterAndSla from "../../../custom-hooks/useOfferLetterAndSla";
// import useOfferLetterAndSlaHistory from "../../../custom-hooks/useOfferLetterAndSlaHistory";
// // import PageLoader from "../../../components/PageLoader/PageLoader.component";
// import { convertToBase64 } from "../../../helpers/convertToBase64";
// import { postData } from "../../../apis/apiMethods";
// import { apiEndpoints } from "./../../../apis/apiEndpoints";
// import { errorHandler } from "./../../../helpers/errorHandler";
// import Alert from "./../../../components/Alert/Alert.component";
// import { useQueryCache } from "react-query";
// import { Link, useHistory } from "react-router-dom";
// import ROUTES from "../../../helpers/ROUTES";
// import GetStartedOfferLetterCards from "./GetStartedOfferLetterCards";
// import GetStartedViewHistory from "./GetStartedViewHistory";
// import { useAuth } from "../../../Context/auth.context";
// import NoTableDataAvailable from "../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
// import advanclyLogo from "../../../img/advancly_logo.svg";
// import OnboardingBackWordButton from "../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
// import PageLoaderAnimation from "../../../components/PageLoader/PageLoaderAnimation";

// export default function GetStartedOfferLetter() {
//   const [error, setError] = useState(null);
//   const [updating, setUpdating] = useState(false);
//   const [viewHistory, setViewHistory] = useState(false);
//   const [success, setSuccess] = useState(null);
//   const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
//   const { aggregatorDetails } = useAuth();
//   const {
//     data,
//     error: offerLetterError,
//     status,
//   } = useOfferLetterAndSla(viewHistory);
//   const {
//     data: dataHistory,
//     error: historyError,
//     status: historyStatus,
//   } = useOfferLetterAndSlaHistory({
//     id: aggregatorDetails?.aggregator_id,
//     viewHistory,
//   });
//   const queryCache = useQueryCache();
//   const history = useHistory();

//   const onFileChange = async (
//     e,
//     id,
//     document_field_id,
//     offer_letter_sla_document_id
//   ) => {
//     if (!e.target.files[0]) return;
//     setError(null);

//     const fileName = e.target.files[0].name;

//     const isValidFile =
//       fileName.endsWith(".pdf") ||
//       fileName.endsWith(".doc") ||
//       fileName.endsWith(".docx");

//     if (!isValidFile) {
//       return setError("Invalid File type.");
//     }
//     const fileBase64 = await convertToBase64(e.target.files[0]);
//     // Remove file
//     document.getElementById(`file-selector${id}`).value = "";
//     const newFile = {
//       filename_with_extension: fileName,
//       base64_file_string: fileBase64,
//       document_field_id: document_field_id,
//       offer_letter_sla_document_id: offer_letter_sla_document_id,
//     };
//     let newFiles = [...filesToBeUploaded];
//     newFiles[id] = newFile;
//     setFilesToBeUploaded(newFiles);
//   };

//   const removeFile = (id) => {
//     const newFile = {
//       filename_with_extension: "",
//       base64_file_string: "",
//       document_field_id: "",
//       offer_letter_sla_document_id: "",
//     };
//     let newFiles = [...filesToBeUploaded];
//     newFiles[id] = newFile;
//     setFilesToBeUploaded(newFiles);
//   };

//   const fileToSend = filesToBeUploaded.filter(
//     (allFile) =>
//       allFile.document_field_id &&
//       allFile.filename_with_extension &&
//       allFile.base64_file_string &&
//       allFile.offer_letter_sla_document_id
//   );

//   const onSubmit = async ({ message }) => {
//     setUpdating(true);
//     setError(null);
//     setSuccess(null);
//     const reqBody = {
//       comment: message,
//       aggregator_documents: fileToSend,
//     };
//     try {
//       await postData(apiEndpoints.offerLetterAndSla, reqBody, true);
//       queryCache.invalidateQueries("getOfferLetterAndSla");

//       // I intentionally added a setTimeout here so the offer letter can be refetched before users see success response
//       setTimeout(() => {
//         setUpdating(false);
//         setFilesToBeUploaded([]);
//         setSuccess("Submitted successfully.");

//         setTimeout(() => {
//           history.push(ROUTES.GET_STARTED);
//         }, 2000);
//       }, 1500);
//     } catch (error) {
//       setUpdating(false);
//       setError(errorHandler(error));
//     }
//   };

//   if (status === "loading" || historyStatus === "loading")
//     return (
//       <div className="d-flex justify-content-center align-items-center my-auto">
//         {" "}
//         <PageLoaderAnimation />
//       </div>
//     );
//   return (
//     <div className={`ml-5 mt-5  `}>
//       <div className={styles.onboardingLogoLink}>
//         <Link className={styles.sparkLogoLink} to="/">
//           <img
//             className={styles.sparkLogo}
//             src={advanclyLogo}
//             alt="Login logo"
//           />
//         </Link>

//         <OnboardingBackWordButton />
//       </div>
//       <div className={styles.InnerComponentOfferLetter}>
//         {offerLetterError && <Alert message={errorHandler(offerLetterError)} />}
//         {error && <Alert message={error} />}
//         {historyError && <Alert message={historyError} />}
//         {success && <Alert message={success} type="success" />}
//         <div className="d-flex justify-content-between align-items-center mt-4 ml-0 w-100 mb-4">
//           <h3 className="page-title mb-0">Offer Letter and SLAs</h3>

//           {viewHistory && dataHistory ? (
//             <div onClick={(e) => setViewHistory(false)}>
//               <p className={`${styles.viewText} mb-0 mr-5`}>Hide History</p>
//             </div>
//           ) : (
//             <div onClick={(e) => setViewHistory(true)}>
//               <p className={`${styles.viewText} mb-0 mr-5`}>View History</p>
//             </div>
//           )}
//         </div>

//         {viewHistory && dataHistory && dataHistory?.length ? (
//           dataHistory?.map((data, _id) => {
//             return (
//               <>
//                 <GetStartedViewHistory data={data} />
//               </>
//             );
//           })
//         ) : !viewHistory && data ? (
//           <GetStartedOfferLetterCards
//             data={data}
//             filesToBeUploaded={filesToBeUploaded}
//             removeFile={removeFile}
//             onFileChange={onFileChange}
//             viewHistory={viewHistory}
//             onSubmit={onSubmit}
//             updating={updating}
//             dataHistory={false}
//           />
//         ) : (
//           <NoTableDataAvailable />
//         )}
//       </div>
//     </div>
//   );
// }

//OLD IMPLEMENTATION IS KEPT TILL THE OLD ONE IS STEADY

import React, { useEffect, useState } from "react";
import styles from "../GetStarted.module.scss";
import useOfferLetterAndSla from "../../../custom-hooks/useOfferLetterAndSla";
import useOfferLetterAndSlaHistory from "../../../custom-hooks/useOfferLetterAndSlaHistory";
// import PageLoader from "../../../components/PageLoader/PageLoader.component";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { postData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import Alert from "../../../components/Alert/Alert.component";
import { useQueryCache } from "react-query";
import { Link, useHistory } from "react-router-dom";
import ROUTES from "../../../helpers/ROUTES";
import GetStartedOfferLetterCards from "./GetStartedOfferLetterCards";
import GetStartedViewHistory from "./GetStartedViewHistory";
import { useAuth } from "../../../Context/auth.context";
import NoTableDataAvailable from "../../../components/NoTableDataAvailable/NoTableDataAvailable.component";
import advanclyLogo from "../../../img/advancly_logo.svg";
import OnboardingBackWordButton from "../../../components/OnboardingBackWordButton/OnboardingBackWordButton";
import PageLoaderAnimation from "../../../components/PageLoader/PageLoaderAnimation";
import { appInsights } from "../../../components/AppInsight/AppInsight";

export default function GetStartedOfferLetter() {
  const [error, setError] = useState<string | null>(null);
  const [updating, setUpdating] = useState(false);
  const [viewHistory, setViewHistory] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState([]);
  const { aggregatorDetails } = useAuth();
  const [approvalStatus, setApprovalStatus] = useState();
  const [replaceFileId, setReplaceFileId] = useState<string | number>("");
  const [fileMaster, setFileMaster] = useState([]);

  const {
    data,
    error: offerLetterError,
    status,
  } = useOfferLetterAndSla(viewHistory);

  useEffect(() => {
    if (data) {
      let result = data.map((item: any) => {
        let subResult = item.aggregator_documents.map((aggreDocs: any) => {
          return {
            ...aggreDocs,
            isNewFile: false,
            isReplaceFile: false,
          };
        });

        return {
          ...item,
          aggregator_documents: [...subResult],
        };
      });

      setFileMaster(result);
    }
  }, [data]);

  const {
    data: dataHistory,
    error: historyError,
    status: historyStatus,
  } = useOfferLetterAndSlaHistory({
    id: aggregatorDetails?.aggregator_id,
    viewHistory,
  });
  const queryCache = useQueryCache();
  const history = useHistory();

  const onFileChange = async (
    e: any,
    id: number,
    document_field_id: number,
    offer_letter_sla_document_id: number
  ) => {
    if (!e.target.files[0]) return;
    setError(null);
    const fileName = e.target.files[0].name;

    const isValidFile =
      fileName.endsWith(".pdf") ||
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx");

    if (!isValidFile) {
      return setError("Invalid File type.");
    }
    const fileBase64 = await convertToBase64(e.target.files[0]);
    // Remove file
    let fileselect = document.getElementById(
      `file-selector${id}`
    ) as HTMLInputElement;
    if (fileselect) {
      fileselect.value = "";
    }

    const newFile = {
      filename_with_extension: fileName,
      base64_file_string: fileBase64,
      document_field_id: document_field_id,
      offer_letter_sla_document_id: offer_letter_sla_document_id,
      isNewFile: true,
      isReplaceFile: false,
    };

    if (replaceFileId) {
      const fileCategoryToBeReplaced = fileMaster[id] as any;

      const indexOfFileToBeReplaced =
        fileCategoryToBeReplaced?.aggregator_documents?.findIndex(
          (item: { id: number }) => item?.id === Number(replaceFileId)
        );

      let fileCatToBeReplacedDoc = [
        ...fileCategoryToBeReplaced.aggregator_documents,
      ];

      fileCatToBeReplacedDoc[indexOfFileToBeReplaced] = {
        ...fileCatToBeReplacedDoc[indexOfFileToBeReplaced],
        aggregator_file_name: fileName,
        filename_with_extension: fileName,
        base64_file_string: fileBase64,
        document_field_id: document_field_id,
        offer_letter_sla_document_id: offer_letter_sla_document_id,
        isReplaceFile: true,
        isNewFile: false,
      };

      fileCategoryToBeReplaced.aggregator_documents = fileCatToBeReplacedDoc;

      let currentState = [...fileMaster] as any;
      currentState[id] = fileCategoryToBeReplaced;

      return setFileMaster(currentState);
    }

    let newFiles = fileMaster[id] as any;
    let newFilesAggreDocs = [...newFiles.aggregator_documents];

    newFilesAggreDocs[newFilesAggreDocs.length - 1] = {
      ...newFilesAggreDocs[newFilesAggreDocs.length - 1],
      ...newFile,
    };

    const currentState = [...fileMaster] as any;

    currentState[id].aggregator_documents = newFilesAggreDocs;

    return setFileMaster(currentState);
  };

  const replaceFile = (_id: number, id_of_file: number) => {
    setReplaceFileId(id_of_file);

    const replacefileSelector = document.getElementById(`file-selector${_id}`);
    if (replacefileSelector) {
      return replacefileSelector.click();
    }
  };

  const removeFile = (id: number) => {
    //@ts-ignore
    const fileCateToBeRemoved = fileMaster[id].aggregator_documents;

    let result = fileCateToBeRemoved.map((item: any) => {
      return {
        ...item,
        isReplaceFile: false,
        isNewFile: false,
      };
    });

    const aggregator_documents = [...result];
    const currentState = [...fileMaster] as any;
    currentState[id].aggregator_documents = aggregator_documents;
    setFileMaster(currentState);
  };

  const UploadNewfiles = async (payload: any) => {
    try {
      // Call upload endpoint here
      if (approvalStatus !== 3) {
        await postData(apiEndpoints.offerLetterAndSla, payload, true);
        queryCache.invalidateQueries("getOfferLetterAndSla");

        // I intentionally added a setTimeout here so the offer letter can be refetched before users see success response
        setTimeout(() => {
          setUpdating(false);
          setFilesToBeUploaded([]);
          setSuccess("Submitted successfully.");

          setTimeout(() => {
            history.push(ROUTES.GET_STARTED);
          }, 2000);
        }, 1500);
      }

      // catch (error) {
      //   setUpdating(false);
      //   setError(errorHandler(error));
      // }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedOfferLetter.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };

  const ReUploadFiles = async (payload: any) => {
    try {
      // Call re-upload endpoint here
      if (approvalStatus === 3) {
        await postData(apiEndpoints.offerLetterAndSlaReupload, payload, true);
        queryCache.invalidateQueries("getOfferLetterAndSla");

        // I intentionally added a setTimeout here so the offer letter can be refetched before users see success response
        setTimeout(() => {
          setUpdating(false);
          setFilesToBeUploaded([]);
          setSuccess("Submitted successfully.");

          setTimeout(() => {
            history.push(ROUTES.GET_STARTED);
          }, 2000);
        }, 1500);
      }
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "GetStartedOfferLetter.jsx",
        },
      });
      setUpdating(false);
      setError(errorHandler(error));
    }
  };

  const onSubmit = async ({ message }: { message: string }) => {
    setUpdating(true);
    setError(null);
    setSuccess(null);

    const isNewFileUpload = [] as any;
    const isReplaceFileUpload = [] as any;

    fileMaster.forEach((file: any) => {
      const result = file.aggregator_documents.filter((aggreDocs: any) => {
        return aggreDocs?.isNewFile === true;
      });

      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        isNewFileUpload.push(element);
      }
    });
    fileMaster.forEach((file: any) => {
      const result = file.aggregator_documents.filter((aggreDocs: any) => {
        return (
          aggreDocs?.isReplaceFile === true || aggreDocs?.approval_status === 3
        );
      });

      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        isReplaceFileUpload.push(element);
      }
    });

    if (isNewFileUpload?.length > 0) {
      let result = isNewFileUpload
        .filter((item: any) => item?.approval_status !== 2)
        .map((item: any) => {
          const {
            document_field_id,
            // offer_letter_sla_document_id,
            id,
            base64_file_string,
            filename_with_extension,
          } = item;
          return {
            document_field_id: document_field_id,
            // offer_letter_sla_document_id: Number(offer_letter_sla_document_id),
            offer_letter_sla_document_id: Number(id),
            base64_file_string: base64_file_string,
            filename_with_extension: filename_with_extension,
          };
        });

      const reqBody = {
        comment: message,
        aggregator_documents: result,
      };

      // Pass the reqBody to this method and call the endpoint to upload new file inside.
      if (approvalStatus !== 3) {
        return await UploadNewfiles(reqBody);
      }
    }

    if (isReplaceFileUpload.length > 0) {
      let result = isReplaceFileUpload
        .filter((item: any) => item.approval_status === 3)
        .map((newReplaceFileItem: any) => {
          return {
            document_field_id: newReplaceFileItem?.document_field_id,
            offer_letter_sla_document_id: newReplaceFileItem?.id,
            base64_file_string: newReplaceFileItem?.base64_file_string,
            filename_with_extension:
              newReplaceFileItem?.filename_with_extension,
          };
        });

      const reqBodyReupload = {
        offer_letter_slas: result,
      };

      // Pass the reqBody to this method and call the endpoint to repload file inside.
      if (approvalStatus === 3) {
        return await ReUploadFiles(reqBodyReupload);
      }
    }
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedOfferLetter.jsx",
      isLoggedIn: true,
    });
  }, []);
  if (status === "loading" || historyStatus === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoaderAnimation />
      </div>
    );
  return (
    <div className={`ml-5 mt-5  `}>
      <div className={styles.onboardingLogoLink}>
        <Link className={styles.sparkLogoLink} to="/">
          <img
            className={styles.sparkLogo}
            src={advanclyLogo}
            alt="Login logo"
          />
        </Link>

        <OnboardingBackWordButton />
      </div>
      <div className={styles.InnerComponentOfferLetter}>
        {offerLetterError && <Alert message={errorHandler(offerLetterError)} />}
        {error && <Alert message={error} />}
        {historyError && <Alert message={historyError as string} />}
        {success && <Alert message={success} type="success" />}
        <div className="d-flex justify-content-between align-items-center mt-4 ml-0 w-100 mb-4">
          <h3 className="page-title mb-0">Offer Letter and SLAs</h3>

          {viewHistory && dataHistory ? (
            <div onClick={(e) => setViewHistory(false)}>
              <p className={`${styles.viewText} mb-0 mr-5`}>Hide History</p>
            </div>
          ) : (
            <div onClick={(e) => setViewHistory(true)}>
              <p className={`${styles.viewText} mb-0 mr-5`}>View History</p>
            </div>
          )}
        </div>

        {viewHistory && dataHistory && dataHistory?.length ? (
          dataHistory?.map(
            (
              data: {
                adminUploadDate: string;
                adminFileName: string;
                adminUploadPath: string;
                documentName: string;
                aggregatorUploadDate: string;
                aggregatorFileName: string;
                aggregator_upload_path: string;
                adminComment: string;
                aggregatorComment: string;
              },
              _id: number
            ) => {
              return (
                <>
                  <GetStartedViewHistory data={data} />
                </>
              );
            }
          )
        ) : !viewHistory && fileMaster ? (
          <GetStartedOfferLetterCards
            data={fileMaster}
            filesToBeUploaded={filesToBeUploaded}
            removeFile={removeFile}
            onFileChange={onFileChange}
            viewHistory={viewHistory}
            onSubmit={onSubmit}
            updating={updating}
            dataHistory={false}
            setApprovalStatus={setApprovalStatus}
            replaceFile={replaceFile}
          />
        ) : (
          <NoTableDataAvailable />
        )}
      </div>
    </div>
  );
}
