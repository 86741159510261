import React, { CSSProperties } from "react";
import CustomInputField from "../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../components/CustomHTMLElements/CustomSelectDropdown";
import { EligibilityAnswerChoices } from "./EligibilityAnswerChoices";
import styles from "../../GetStarted.module.scss";
export default function EligibilityTestField({
  id,
  isMandatory,
  question,
  questionAnswerType,
  questionAnswers,
  onOptionAnswerChanged,
  onMultipleAnswerChanged,
  onTextAnswerChanged,
  reference,
  questionsAnswered,
}: any) {
  return (
    (questionAnswerType === EligibilityAnswerChoices.optionChoice && (
      <div className="col-12">
        <CustomSelectDropdown
          ref={reference}
          label={question}
          showRequiredIcon={isMandatory}
          name={id}
          onChange={(e) => onOptionAnswerChanged(e, id)}
          required={isMandatory}
        >
          <option value=""></option>
          {questionAnswers && questionAnswers.length
            ? questionAnswers.map((answers: any, _id: number) => {
                const { id, value } = answers;
                return (
                  <option key={id} value={id}>
                    {value}
                  </option>
                );
              })
            : null}
        </CustomSelectDropdown>
      </div>
    )) ||
    (questionAnswerType === EligibilityAnswerChoices.textAreaChoice && (
      <div className="col-12">
        <CustomInputField
          type="text"
          ref={reference}
          label={question}
          showRequiredIcon={isMandatory}
          name={id}
          required={isMandatory}
          onChange={(e: any) => onTextAnswerChanged(e, id)}
        />
      </div>
    )) ||
    (questionAnswerType === EligibilityAnswerChoices.checkboxChoice && (
      <div className="col-12 mb-4">
        <label className="form-label">{question}</label>
        {isMandatory && <sup className="ml-1 crimson">*</sup>}
        {/* Asset Finance  */}

        {questionAnswers && questionAnswers.length
          ? questionAnswers.map((answers: any, _id: number) => {
              return (
                <div
                  className={`form-check ${styles.questionCheckbox}`}
                  key={_id}
                >
                  <input
                    className={`form-check-input multichoice `}
                    ref={reference}
                    type="checkbox"
                    name={id}
                    checked={questionsAnswered.includes(answers.id.toString())}
                    value={answers.id}
                    onChange={(e) => onMultipleAnswerChanged(e, "checkbox")}
                    style={{ accentColor: "#a7dea8" } as CSSProperties}
                  />
                  <label
                    className="form-check-label ml-3"
                    htmlFor={answers.value}
                  >
                    {answers.value}
                  </label>
                </div>
              );
            })
          : null}
      </div>
    ))
  );
}
