import React from "react";
import { Line } from "react-chartjs-2";
import { emptyBarChartSingleOptions } from "../../../helpers/graphOptions";

import NoGraphData from "../../NoGraphData/NoGraphData.component";

export default function EmptyBarChartSingle() {
  const data = {
    labels: [""],
    datasets: [
      {
        data: [0],
        backgroundColor: ["#fff"],
        borderColor: ["#fff"],
        borderWidth: 0,
      },
    ],
  };
  return (
    <div>
      <NoGraphData />
      <Line
        data={data}
        width={100}
        height={300}
        options={emptyBarChartSingleOptions as any}
      />
    </div>
  );
}
