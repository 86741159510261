import React, { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useForm } from "react-hook-form";
import { useQueryCache } from "react-query";
import { postData } from "../../apis/apiMethods";
import { errorHandler } from "../../helpers/errorHandler";
import Loader from "../../components/Loader/Loader.component";
import PageLoader from "../../components/PageLoader/PageLoader.component";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { IDisbursement } from "./WalletDisbursement";
const schema = yup.object({
  pin: yup
    .string()
    .required("Please enter your pin")
    .min(4, "Pin must have at least 4 characters"),
});
const WalletDisbursmentPinModal = ({
  loading,
  setLoading,
  error,
  setError,
  success,
  setSuccess,
  setApprovalSteps,
  commentDetails,
  pendingUpdate,
}: {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
  setApprovalSteps: Dispatch<SetStateAction<string>>;
  commentDetails: string;
  pendingUpdate: IDisbursement;
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const queryCache = useQueryCache();

  const onSubmitHandler = async (data: { pin: string }) => {
    setLoading(true);
    setError("");
    setSuccess("");
    const { pin } = data;
    const reqBody = {
      reference: pendingUpdate?.maker_checker_reference,
      comment: commentDetails,
      pin,
    };
    try {
      const res = await postData(apiEndpoints.APPROVE_DISBURSMENT, reqBody);
      setLoading(false);
      reset();
      // setSuccess(res?.data?.data?.message);
      setSuccess(res?.data?.result?.message);
      setError("");
      queryCache.invalidateQueries("getPendingDisbursement");
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "WalletDisbursmentPinModal.jsx",
        },
      });
      setLoading(false);
      reset();
      setSuccess("");
      setError(errorHandler(error));
    }
  };
  const refreshModal = () => {
    window.location.reload();
    setApprovalSteps("approval");
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "WalletDisbursmentPinModal.jsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <Fragment>
        {success ? (
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex align-items-center justify-content-between flex-column">
                <i className="ri-checkbox-circle-fill text-success ri-5x"></i>
                <h5 className="mt-5 font-weight-bold">{success}</h5>
                <button
                  type="button"
                  className="btn btn-sm advancly-btn mt-5 w-100 "
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshModal}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="modal-content">
            {error && <p className="alert alert-danger small row">{error}</p>}
            <div className="modal-header">
              <h4 className="modal-title">Transaction PIN</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={refreshModal}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <Fragment>
                {loading ? (
                  <PageLoader />
                ) : (
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                      <small
                        className="color-mid-blue text-right   cursor-pointer font-weight-bold pl-2 pb-2"
                        onClick={() => {
                          setApprovalSteps("approval");
                        }}
                      >
                        &#8592; Go back
                      </small>
                    </div>
                    <div className="row">
                      <p className="col-12">Enter transaction PIN to proceed</p>
                      <div className="col-12">
                        <CustomInputField
                          type="password"
                          name="pin"
                          ref={register}
                          label="Transaction PIN"
                          maxLength={4}
                          errors={errors.pin}
                        />
                      </div>
                      <div className="col-12 pb-4 pt-4">
                        <button className="btn btn-sm advancly-btn  w-100">
                          Continue {loading && <Loader />}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Fragment>
            </div>
          </div>
        )}
      </Fragment>
    </div>
  );
};

export default WalletDisbursmentPinModal;
