import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getAdvanclyCurrentCountries = async () => {
  const { data } = await getData(apiEndpoints.ADVANCLY_SPECIFIC_COUNTRIES);
  return data;
};

export default function useAdvanclyCurrentCountries() {
  return useQuery(
    ["getAdvanclyCurrentCountries"],
    getAdvanclyCurrentCountries,
    {
      refetchOnWindowFocus: false,
    }
  );
}
