import moment from "moment";
import style from "../../../components/Comments/comments.module.scss";

const OfferLetterCommentModal = ({
  offerLetterComments,
}: {
  offerLetterComments: {
    first_name: string;
    last_name: string;
    user_type: string;
    comment: string;
    datetime_created: string;
  }[];
}) => {
  return (
    <div
      className="modal fade"
      id="offerlettercommentmodal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="offerLetterCommentsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="offerLetterCommentsLabelTitle">
              Comments
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className={style.commentsContainer}>
              <div className={style.commentsSection}>
                {offerLetterComments?.length ? (
                  offerLetterComments?.map(
                    (
                      comment: {
                        first_name: string;
                        last_name: string;
                        user_type: string;
                        comment: string;
                        datetime_created: string;
                      },
                      index: number
                    ) => (
                      <div key={index} className={style.existingComments}>
                        <p>
                          {comment?.first_name} {comment?.last_name}{" "}
                          <span>({comment?.user_type})</span>
                          <span>
                            {moment(comment?.datetime_created).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </span>
                        </p>
                        <p className={style.comments}>{comment.comment}</p>
                      </div>
                    )
                  )
                ) : (
                  <div>
                    <p>No comments Available</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            className=" advancly-btn p-2 mb-2 w-70 m-1"
            type="button"
            data-dismiss="modal"
          >
            <i className="fas fa-times-circle"></i>
            &nbsp; Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferLetterCommentModal;
