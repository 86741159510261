import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithoutToken } from "../apis/apiMethods";

const getCountryCodes = async () => {
  const data = await getDataWithoutToken(apiEndpoints.countryCodesUrl);
  return data.data;
};

export default function useCountryCodes() {
  return useQuery(["getCountryCodes"], getCountryCodes);
}
