import { Dispatch, Fragment, SetStateAction } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import closeIcon from "../../img/close-square.svg"

const LoanCountModal = ({
  count,
  setShowModal,
}: {
  count: number;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const match = useRouteMatch();
  return (
    <div
      className="popup-box"
      id="walletDetailsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="walletDetailsModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog  " role="document">
        <div className="modal-content remove-overflow">
        <button
              type="button"
              className="close closeIcon"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowModal(false)}
            >
              <img src={closeIcon} alt="" />
            </button>
          <div className="modal_header">
            <h5 className="modal_title">🔔 Reminder</h5>
          </div>
          <div className="modal-body text-center">
            <Fragment>
              <h6 className="action-content">
              Your loans are due! Take control of your finances and make repayments now to stay on track. We’re here to help you every step of the way.
              </h6>

            <div className="view-btn-container">
              <button className="view-btn">
                  <Link
                    to={{
                      pathname: match.path + "repayments",
                      state: { dueLoans: true },
                    }}
                    className="center"
                  >
                    View due loans
                  </Link>
                </button>
            </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoanCountModal;
