import { useRef } from "react";

import moment from "moment";

import style from "./comments.module.scss";

type TComment = {
  id: number;
  datetime_created: string;
  first_name: string;
  last_name: string;
  user_type: string;
  comment: string;
};

type TComments = {
  comments: TComment[];
};

const CommentsModal = ({ comments }: TComments) => {
  const dismissRef = useRef<null | HTMLButtonElement>(null);

  return (
    <div
      className="modal fade"
      id="commentsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="commentsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0" id="topOfPage">
            <div className="row">
              <h5 className="page-title col-12 ">Comments</h5>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            ></button>
          </div>
          <div className="modal-body mb-0 mt-0">
            <div className={style.commentsContainer}>
              <div className={style.commentsSection}>
                {comments?.length &&
                  comments.map((comment, index) => (
                    <div key={index} className={style.existingComments}>
                      <p>
                        {comment?.first_name} {comment?.last_name}{" "}
                        <span>({comment?.user_type})</span>
                        <span>
                          {moment(comment?.datetime_created).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </span>
                      </p>
                      <p className={style.comments}>{comment.comment}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center p-2">
            <button
              type="button"
              className={`btn advancly-btn`}
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentsModal;
