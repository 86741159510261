import React from "react";
import { ReactComponent as AccessDeniedIcon } from "../../svg/icons/access-denied.svg";
import styles from "./AccessDenied.module.scss";
const AccessDenied = () => {
  return (
    <div className={styles.access_denied_container}>
      <AccessDeniedIcon />
      <h2>Access Denied!!</h2>
      <p>We are sorry but you are unathorised to access this page.</p>
    </div>
  );
};

export default AccessDenied;
