import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IrolloverDetails {
  parent_rollover_loan_reference: string;
}
const getRollOverDetails = async ({
  parent_rollover_loan_reference,
}: IrolloverDetails) => {
  let queryParams = `?parent_rollover_loan_reference=${parent_rollover_loan_reference}`;
  const data = await getDataWithDotNet(
    `${apiEndpoints.getRollOverDetails}${queryParams}`
  );
  return data.data;
};

export default function useRollOverDetails({
  parent_rollover_loan_reference,
}: IrolloverDetails) {
  return useQuery(
    [{ parent_rollover_loan_reference }, "getRollOverDetails"],
    getRollOverDetails,
    {
      refetchOnWindowFocus: false,
      enabled: parent_rollover_loan_reference ? true : false,
    }
  );
}
