import React, { useRef, useState } from "react";
import Loader from "../Loader/Loader.component";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { putDataWithDotNet } from "../../apis/dotNetApiMethods";
import { useQueryCache } from "react-query";
import { appInsights } from "../AppInsight/AppInsight";
import { IUser } from "../UsersManagement/UsersManagement";
import styles from "../../components/UsersManagement/UsersManagement.module.scss";
import { ReactComponent as AdvanclyIcon } from "../../svg/icons/advancly-3d-icon-1.svg";
import { ReactComponent as CloseModal } from "../../svg/icons/modal-close.svg";

export default function UpdateUserConfirmationDialog({
  userToUpdate,
}: {
  userToUpdate: Pick<IUser, "isActive" | "id"> | undefined;
}) {
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const updateUserStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await putDataWithDotNet(
        `${apiEndpoints.toggleAggregatorUserStatus}/${userToUpdate?.id}`,
        {},
        true
      );
      if (res.status === true) {
        setSuccess(
          `User has been ${userToUpdate?.isActive ? "disabled" : "enabled"}`
        );
        queryCache.invalidateQueries("getAggregatorUsers");
        setTimeout(() => {
          dismissRef.current?.click();
          setSuccess(null);
        }, 3000);
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "UpdateUserConfirmationDialog.component.tsx",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const hideModal = () => {
    dismissRef.current?.click();
  };

  return (
    <div
      className="modal fade"
      id="updateUserStatusModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="updateUserStatusModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close "
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              {" "}
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div
              className={`d-flex justify-content-center ${styles.advanclyIconDialog}`}
            >
              <AdvanclyIcon />
            </div>
            <h5
              className={`page-subtitle text-center w-100 ${styles.confirmationHeader}`}
              id="updateUserStatusModalLabel"
            >
              Are you sure you want to{" "}
              {userToUpdate && userToUpdate?.isActive ? "disable" : "enable"}{" "}
              user?
            </h5>
            <div className="">
              <button
                className={`btn btn-sm advancly-white-btn transition-3d-hover w-100 ${styles.updateUserConfirmationCancelButton}`}
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm advancly-btn transition-3d-hover w-100"
                disabled={loading}
                onClick={updateUserStatus}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
