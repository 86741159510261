import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithoutToken } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { errorHandler } from "../../helpers/errorHandler";
import CustomInputField from "../CustomHTMLElements/CustomInputField";
import ROUTES from "../../helpers/ROUTES";
import LoaderSignUp from "../LoaderSignUp/LoaderSignUp";
import { appInsights } from "../AppInsight/AppInsight";

const schema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter OTP")
    .matches(/[0-9]/, "Invalid OTP")
    .min(5, "OTP must have exactly 5 characters")
    .max(5, "OTP must have exactly 5 characters"),
});

const StepTwo = ({
  stateToken,
  updateStateToken,
}: {
  stateToken: string;
  updateStateToken: (token: string) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!stateToken) {
      history.goBack();
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async ({ otp }: { otp: string }) => {
    setError(false);
    setLoading(true);

    const reqBody = {
      reset_step: "second_step",
      otp,
      state_token: stateToken,
    };

    try {
      const response = await postDataWithoutToken(
        apiEndpoints.resetPasswordUrl,
        reqBody
      );
      setLoading(false);
      updateStateToken(response.state_token);
      history.push(ROUTES.RESET_PASSWORD_FINAL);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "StepTwo.component.tsx" },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-7">
        <h3 className="text-dark font-weight-bold mb-3 text-capitalize">
          Verify Email Address
        </h3>
        <p className="text-dark">
          Enter the OTP sent to your email address to verify that you're the
          owner of this account
        </p>
      </div>
      {error && <p className="alert alert-danger small mb-4">{error}</p>}

      <CustomInputField
        type="number"
        maxLength={5}
        ref={register}
        name="otp"
        placeholder="12345"
        label="OTP"
        errors={errors.otp}
      />
      <div className="d-flex justify-content-end align-items-center">
        <button
          type="submit"
          value="submit"
          className="btn advancly-btn btn-sm transition-3d-hover"
          disabled={loading}
        >
          Verify
          {loading && <LoaderSignUp />}
        </button>
      </div>
    </form>
  );
};
//@ts-ignore
export default withRouter(StepTwo);
