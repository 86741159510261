import React, { useEffect } from "react";
import { appInsights } from "../../components/AppInsight/AppInsight";
import ContactForm from "../../components/ContactForm/ContactForm.component";
import styles from "./ContactPage.module.scss";

export default function ContactPage() {
  useEffect(() => {
    appInsights.trackPageView({
      name: "ContactPage.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div className={styles.contactAlign}>
      <ContactForm />
    </div>
  );
}
