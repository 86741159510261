import React, { useEffect, useState } from "react";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import { useForm } from "react-hook-form";
import styles from "../LoanApplication.module.scss";
import Loader from "../../Loader/Loader.component";
import { useAuth } from "../../../Context/auth.context";
import QuestionTestField from "../QuestionTestField";
import useLoanApplicationQuestions from "../../../custom-hooks/useLoanApplicationQuestions";
import PageLoader from "../../PageLoader/PageLoader.component";
import { putData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { IstepLoanApp } from "../LoanApplicationMain";
import { decrypt } from "../../../helpers/encryptor";
import loanApplicationStatus from "../../../helpers/loanApplicationStatus";
import useLoanApplicationById from "../../../custom-hooks/useLoanApplicationById";
interface Iquestion {
  position: number;
}

interface IselectedAnswers {
  questionAnswerId: [];
  questionAnswerText: string;
}
interface QuestionsSort {
  conditionalAnswerId: number;
  conditionalQuestionId: number;
  id: number;
  isConditional: number;
  isMandatory: boolean;
  question: string;
  questionAnswerType: string;
  questionAnswers: [];
  selectedAnswer: IselectedAnswers;
}
const Industry = ({ handleNext, scrollToTop }: IstepLoanApp) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [arrayOfAnswers, setArrayOfAnswers] = useState<any>([]);
  const [questionsAnswered, setQuestionsAnswered] = useState<any>([]);
  const {
    aggregatorDetails,
    currentLoanApplicationId,
    setCurrentLoanApplicationId,
    setEditLoanApplicationGlobal,
  } = useAuth();

  const { data: existingLoanInformationData } = useLoanApplicationById({
    id: currentLoanApplicationId,
  });

  const LoanApplicationEditOrWrite =
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.relationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.creditReview ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCustomer ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToRelationshipManager ||
    existingLoanInformationData?.status_id ===
      loanApplicationStatus.returnedToCreditAnalyst;

  const { register, handleSubmit, setValue } = useForm({});
  const {
    data: questionData,
    status: questionStatus,
    error: questiorErrors,
  } = useLoanApplicationQuestions({
    aggregatorID: aggregatorDetails?.aggregator_id,
    questionDomainCategory: 2,
  });

  const onOptionAnswerChanged = (e: any, id: any) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: any) => Number(answer.questionId) !== Number(name)
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [Number(value)],
        comment: "",
      },
    ]);
  };
  const onTextAnswerChanged = (e: any, id: any) => {
    const { name, value } = e.target;
    const remainingObjects = arrayOfAnswers.filter(
      (answer: any) => Number(answer.questionId) !== Number(name)
    );
    setArrayOfAnswers([
      ...remainingObjects,
      {
        questionId: Number(name),
        questionAnswerId: [],
        comment: value,
      },
    ]);
  };

  const onMultipleAnswerChanged = (e: any, inputType: any) => {
    const { name, value } = e.target;
    if (inputType && inputType === "checkbox") {
      if (!questionsAnswered.includes(value)) {
        setQuestionsAnswered([...questionsAnswered, value]);
      } else {
        setQuestionsAnswered(questionsAnswered.filter((v: any) => v !== value));
      }

      const fileToEdit = arrayOfAnswers.find(
        (answer: any) => Number(answer.questionId) === Number(name)
      );
      const remainingObjects = arrayOfAnswers.filter(
        (answer: any) => Number(answer.questionId) !== Number(name)
      );

      if (e.target.checked) {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            questionId: Number(name),
            comment: "",
            questionAnswerId: fileToEdit
              ? [...fileToEdit.questionAnswerId, Number(value)]
              : [Number(value)],
          },
        ]);
      } else {
        setArrayOfAnswers([
          ...remainingObjects,
          {
            ...fileToEdit,
            questionAnswerId: [
              ...fileToEdit.questionAnswerId.filter(
                (answer: any) => Number(answer) !== Number(value)
              ),
            ],
          },
        ]);
      }
    }
  };
  useEffect(() => {
    // this effect is only used to store the state of user information
    // This was done so when the user goes back after an error, the filled value would be there
    if (questionData && questionData?.length) {
      questionData
        .sort((a: Iquestion, b: Iquestion) => a.position - b.position)
        .forEach((obj: QuestionsSort) => {
          const { id, isConditional, selectedAnswer } = obj;
          if (!isConditional && selectedAnswer?.questionAnswerText) {
            setValue(`${id}`, selectedAnswer?.questionAnswerText);
          }
        });
    }
  }, [questionData, setValue]);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Industry.tsx",
      isLoggedIn: true,
    });
  }, []);
  useEffect(() => {
    const storedCurrentId = decrypt(sessionStorage.getItem("currentLoanAppId"));
    const storedEditLoanApplicationGlobal = decrypt(
      sessionStorage.getItem("globalEditLoanApplication")
    );
    if (storedCurrentId) {
      setCurrentLoanApplicationId(storedCurrentId);
    }
    if (storedEditLoanApplicationGlobal) {
      setEditLoanApplicationGlobal(storedEditLoanApplicationGlobal);
    }
  }, [setCurrentLoanApplicationId, setEditLoanApplicationGlobal]);
  const onSubmitHandler = async (data: any) => {
    const newArray: Record<string, any>[] = [];

    for (const [key, value] of Object.entries(data)) {
      const newObj: Record<string, any> = {
        questionId: Number(key),
        questionAnswerId: [],
        comment: value,
      };

      newArray.push(newObj);
    }
    const RequestBody = {
      loan_application_id: currentLoanApplicationId,
      question_domain: 2,
      questionAnswers: newArray ? newArray : arrayOfAnswers,
    };
    try {
      setLoading(true);
      setError(null);
      const response = await putData(
        apiEndpoints.LOAN_APPLICATION_INDUSTRY,
        RequestBody
      );
      setLoading(false);
      setSuccess(response?.message);
      if (scrollToTop) {
        scrollToTop();
      }
      setTimeout(() => {
        if (handleNext) {
          handleNext();
        }
        setSuccess(null);

        setError(null);
      }, 3000);
    } catch (err) {
      appInsights.trackException({
        //@ts-ignore
        exception: err,
        properties: {
          fileName: "Industry.tsx",
        },
      });
      setLoading(false);
      setSuccess(null);
      setError(errorHandler(err));
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  if (questionStatus === "loading")
    return (
      <div className="d-flex justify-content-center align-items-center my-auto">
        {" "}
        <PageLoader />
      </div>
    );
  return (
    <div className={`${styles.center_container} `}>
      <div className="py-3">
        <h2 className={`${styles.loanInformationHeading}`}>Industry </h2>
        <p className={`${styles.InfoParagraph}`}>
          What are the effects of the following events on your business?
        </p>
      </div>
      <div>
        {questiorErrors && (
          <p className="alert alert-danger small">{questiorErrors}</p>
        )}
        {success && <p className="alert alert-success small">{success}</p>}
        {error && <p className="alert alert-danger small">{error}</p>}
      </div>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="row">
          {questionData && questionData.length ? (
            questionData
              .sort((a: Iquestion, b: Iquestion) => a.position - b.position)
              .map((obj: QuestionsSort, _id: number) => {
                const {
                  conditionalAnswerId,
                  conditionalQuestionId,
                  id,
                  isConditional,
                  isMandatory,
                  question,
                  questionAnswerType,
                  questionAnswers,
                } = obj;
                return (
                  <div className="col-xs-12  col-md-12  pl-0 pr-0   " key={_id}>
                    {!isConditional ? (
                      <QuestionTestField
                        id={id}
                        isMandatory={isMandatory}
                        question={question}
                        questionAnswerType={questionAnswerType}
                        questionAnswers={questionAnswers}
                        onOptionAnswerChanged={onOptionAnswerChanged}
                        onMultipleAnswerChanged={onMultipleAnswerChanged}
                        onTextAnswerChanged={onTextAnswerChanged}
                        reference={register}
                        questionsAnswered={questionsAnswered}
                        LoanApplicationEditOrWrite={LoanApplicationEditOrWrite}
                      />
                    ) : (
                      ""
                    )}
                    {/* this is used only when there is a checkbox or radio which contains options */}
                    {isConditional &&
                    arrayOfAnswers &&
                    arrayOfAnswers.length > 0
                      ? // eslint-disable-next-line array-callback-return
                        arrayOfAnswers
                          .filter(
                            (answer: any) =>
                              Number(answer.questionId) ===
                                conditionalQuestionId &&
                              Number(
                                answer.questionAnswerId.includes(
                                  conditionalAnswerId
                                )
                              )
                          )
                          .map(() => {
                            return (
                              <div key={_id}>
                                <QuestionTestField
                                  id={id}
                                  isMandatory={isMandatory}
                                  question={question}
                                  questionAnswerType={questionAnswerType}
                                  questionAnswers={questionAnswers}
                                  onOptionAnswerChanged={onOptionAnswerChanged}
                                  onMultipleAnswerChanged={
                                    onMultipleAnswerChanged
                                  }
                                  onTextAnswerChanged={onTextAnswerChanged}
                                  reference={register}
                                  questionsAnswered={questionsAnswered}
                                  LoanApplicationEditOrWrite={
                                    LoanApplicationEditOrWrite
                                  }
                                />
                              </div>
                            );
                          })
                      : ""}
                  </div>
                );
              })
          ) : (
            <div className="w-100 p-5">
              <h5 className="text-center text-muted">
                Industry questions not available please contact admin.
              </h5>
            </div>
          )}
          <div className={`col-12`}>
            <div className=" d-flex justify-content-between flex-wrap mt-2">
              {!LoanApplicationEditOrWrite && (
                <button
                  type="submit"
                  className="advancly-btn  transition-3d-hover m-2"
                  disabled={loading}
                >
                  Save and Continue
                  {loading && <Loader />}
                </button>
              )}
              <button
                className="btn advancly-white-btn  transition-3d-hover m-2 "
                type="button"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Industry;
