import React from "react";

export default function Loader() {
  return (
    <div
      className="spinner-border text-white  spinner-border-sm ml-2"
      role="status"
    >
      <span className="sr-only">Loading</span>
    </div>
  );
}
