import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useQueryCache } from "react-query";
import styles from "./PreDisbursement.module.scss";
import { ReactComponent as AdvanclyIcon } from "../../../svg/icons/advancly-3d-icon-1.svg";
import { ReactComponent as CloseModal } from "../../../svg/icons/modal-close.svg";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import { appInsights } from "../../AppInsight/AppInsight";
import { errorHandler } from "../../../helpers/errorHandler";
import Loader from "../../Loader/Loader.component";
import { useAuth } from "../../../Context/auth.context";
import { convertToBase64 } from "../../../helpers/convertToBase64";
interface Ipredisbursement {
  currentSelectedFile: File;
  refetchConditions: any;
  loanApplicationConditionId: number;
  setCurrentSelectedFile: Dispatch<SetStateAction<File | null>>;
}

export default function PredisbursementConfirmationUploadModal({
  currentSelectedFile,
  loanApplicationConditionId,
  setCurrentSelectedFile,
  refetchConditions,
}: Ipredisbursement) {
  const dismissRef = useRef<HTMLButtonElement | null>(null);
  const queryCache = useQueryCache();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { currentLoanApplicationId } = useAuth();

  const uploadDocument = async () => {
    setLoading(true);
    setError(null);
    const base64Converted = await convertToBase64(currentSelectedFile);

    const reqBody = {
      loan_application_id: currentLoanApplicationId,
      loan_application_condition_id: loanApplicationConditionId,
      loan_application_condition_documents: [
        {
          base64_file_string: base64Converted,
          filename_with_extension: currentSelectedFile?.name,
        },
      ],
    };

    try {
      const res = await postData(
        `${apiEndpoints.LOAN_APPLICATION_CONDITIONS_UPLOAD}`,
        reqBody,
        true
      );
      if (res.status === true) {
        res?.message
          ? setSuccess(res?.message)
          : setSuccess("uploaded successfully");
        queryCache.invalidateQueries([
          { loanApplicationId: currentLoanApplicationId },
          "getLoanApplicationConditions",
        ]);
        refetchConditions();
        setTimeout(() => {
          dismissRef.current?.click();
          setSuccess(null);
        }, 3000);
        setCurrentSelectedFile(null);
      } else {
        setError(res.message);
      }
      setLoading(false);
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: {
          fileName: "PredisbursmentConfirmationUploadModal",
        },
      });
      setLoading(false);
      setError(errorHandler(error));
    }
  };

  const hideModal = () => {
    dismissRef.current?.click();
  };

  return (
    <div
      className="modal fade"
      id="predisbursementConfirmationUploadModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="predisbursementConfirmationUploadLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="close "
              data-dismiss="modal"
              aria-label="Close"
              ref={dismissRef}
            >
              {" "}
              <CloseModal />
            </button>
          </div>
          <div className="modal-body">
            {error && <p className="alert alert-danger small">{error}</p>}
            {success && <p className="alert alert-success small">{success}</p>}
            <div
              className={`d-flex justify-content-center ${styles.advanclyIconDialog}`}
            >
              <AdvanclyIcon />
            </div>
            <h5
              className={`page-subtitle text-center w-100 ${styles.confirmationHeader}`}
              id="predisbursementConfirmationUploadLabel"
            >
              Are you sure you want to upload this Document?
            </h5>
            <p
              className={` ${styles.predisbursementFileNameText} text-center p-3 `}
            >
              {currentSelectedFile?.name}
            </p>
            <div className="">
              <button
                className={`btn btn-sm advancly-white-btn transition-3d-hover w-100 ${styles.updateUserConfirmationCancelButton}`}
                onClick={hideModal}
              >
                Cancel
              </button>

              <button
                className="btn btn-sm advancly-btn transition-3d-hover w-100"
                disabled={loading}
                onClick={uploadDocument}
              >
                Proceed {loading && <Loader />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
