import { Dispatch, ReactNode, SetStateAction } from "react";
import graphDropdownOptions from "../../../../helpers/graphDropdownOptions";
import CustomSelectDropdown from "../../../CustomHTMLElements/CustomSelectDropdown";
import styles from "./GraphTitleSection.module.scss";

export default function GraphTitleSection({
  children,
  title,
  filter,
  setFilter,
}: {
  children: ReactNode;
  title: string;
  filter: number;
  setFilter: Dispatch<SetStateAction<number>>;
}) {
  return (
    <div className={styles.alignGraphTitle}>
      <div className={styles.leftSection}>
        <h3>{title}</h3>
        <div>{children}</div>
      </div>
      <div className={styles.rightSection}>
        <CustomSelectDropdown
          value={filter}
          onChange={(e: any) => setFilter(e.target.value)}
        >
          <option value={graphDropdownOptions.thisWeek}>This Week</option>
          <option value={graphDropdownOptions.thisMonth}>This Month</option>
          <option value={graphDropdownOptions.threeMonths}>3 Months</option>
          <option value={graphDropdownOptions.thisYear}>This Year</option>
          <option value={graphDropdownOptions.fiveYears}>5 Years</option>
        </CustomSelectDropdown>
      </div>
    </div>
  );
}
