import BackButtonComponent from "../../components/BackButton/BackButton.component";
import { useLocation } from "react-router-dom";
import CustomInputField from "../../components/CustomHTMLElements/CustomInputField";
import { formatMoney } from "../../helpers/formatter";
import { appInsights } from "../../components/AppInsight/AppInsight";
import { useEffect } from "react";

export default function EachFunderInvestment() {
  const location = useLocation();
  type TlocationFunderState = {
    funder_name: string;
    principal_amount: number;
    currency: "NGN" | "USD" | "KES" | "#" | "$" | "Ksh";
    tenure: string;
    monthly_interest_repayment: string;
    effective_date: string;
    maturity_date: string;
    investment_product_name: string;
    investor_type: string;
    interest_repayment_type: string;
    pub_date: string;
    next_repayment_date: string;
    interest_amount: number;
    maturity_amount: number;
    outstanding_interest_amount: number;
    total_interest_paid: number;
  };
  const {
    funder_name,
    principal_amount,
    currency,
    tenure,
    monthly_interest_repayment,
    effective_date,
    maturity_date,
    investment_product_name,
    investor_type,
    interest_repayment_type,
    pub_date,
    next_repayment_date,
    interest_amount,
    maturity_amount,
    outstanding_interest_amount,
    total_interest_paid,
  } =
    //@ts-ignore
    location?.data as TlocationFunderState;
  useEffect(() => {
    appInsights.trackPageView({
      name: "EachFunderInvestment.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
        <h3 className="page-title d-flex align-items-center">
          <BackButtonComponent />
          {funder_name}
        </h3>
      </div>
      <div className="advancly-wrapper-container">
        <div className=" p-3">
          <div className="">
            <h6 className="my-4 page-subtitle">Investment Details</h6>
            <form className="advancly-sub-wrapper">
              <div className="row">
                <div className="col-md-6 col-12 ">
                  <CustomInputField
                    type="text"
                    label="Investment Fund"
                    defaultValue={investment_product_name}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Investor Type"
                    defaultValue={investor_type}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Investment Tenor"
                    defaultValue={tenure}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="string"
                    label="Repayment Type"
                    defaultValue={interest_repayment_type}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-12 col-12">
                  <hr />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="date"
                    label="Date Invested"
                    defaultValue={effective_date.substring(0, 10)}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="data"
                    label="Maturity Date"
                    defaultValue={maturity_date.substring(0, 10)}
                    readOnly={true}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="date"
                    label="Created Date"
                    defaultValue={pub_date}
                    readOnly={true}
                  />
                </div>

                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="data"
                    label="Next Repayment Date"
                    defaultValue={next_repayment_date}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-12 col-12">
                  <hr />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="number"
                    label="Amount Invested"
                    defaultValue={formatMoney(principal_amount, currency)}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="number"
                    label="Total Repayment At Maturity"
                    defaultValue={formatMoney(maturity_amount, currency)}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Monthly Yield"
                    defaultValue={monthly_interest_repayment}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Monthly Interest Repayment"
                    defaultValue={monthly_interest_repayment}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Total Interest At Maturity"
                    defaultValue={interest_amount}
                    readOnly={true}
                  ></CustomInputField>
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Total Paid"
                    defaultValue={total_interest_paid}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6 col-12">
                  <CustomInputField
                    type="text"
                    label="Outstanding Amount"
                    defaultValue={formatMoney(
                      outstanding_interest_amount,
                      currency
                    )}
                    readOnly={true}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
