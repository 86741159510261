import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getDataWithDotNet } from "../apis/dotNetApiMethods";
interface IgetRecentDisbursement {
  currentCurrencyValue: string;
  // activeProductName: string;
}
const getRecentDisbursements = async ({
  currentCurrencyValue,
}: IgetRecentDisbursement) => {
  const data = await getDataWithDotNet(
    `${apiEndpoints.getRecentDisbursements}?currency=${currentCurrencyValue}`
  );
  return data.data;
};

export default function useRecentDisbursements({
  currentCurrencyValue,
}: // activeProductName,
IgetRecentDisbursement) {
  return useQuery(
    [
      {
        currentCurrencyValue,
        // , activeProductName
      },
      "getRecentDisbursements",
    ],
    getRecentDisbursements,
    {
      enabled: currentCurrencyValue ? true : false,

      refetchOnWindowFocus: false,
    }
  );
}
