import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as OnboardingBackButtonIcon } from "../../svg/icons/back-button-black.svg";
import styles from "./OnboardingBackButton.module.scss";

const OnboardingBackButton = ({ history }: { history: any }) => {
  return (
    <span className={styles.backButton} onClick={() => history.goBack()}>
      <OnboardingBackButtonIcon />
    </span>
  );
};
export default withRouter(OnboardingBackButton);
