import React, { ChangeEvent, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../../../helpers/yupValidators";
import CustomTextArea from "../../../../../components/CustomHTMLElements/CustomTextArea";
import CustomInputField from "../../../../../components/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../../../components/CustomHTMLElements/CustomSelectDropdown";
import useBusinessSectorsLive from "../../../../../custom-hooks/useBusinessSectorsLive";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";
import { errorHandler } from "../../../../../helpers/errorHandler";
import useCountryCodesLive from "../../../../../custom-hooks/useCountryCodesLive";
//@ts-ignore
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import useCountryStates from "../../../../../custom-hooks/useCountryStates";
import GetStartedBasicInfoPreview from "./GetStartedBasicInfoPreview";
import { IstepFunctions } from "../GetStartedBusinesInfoMain";
import styles from "../../../GetStarted.module.scss";
import Alert from "../../../../../components/Alert/Alert.component";
import { useHistory } from "react-router-dom";
import useAggregatorDetails from "../../../../../custom-hooks/useAggregatorDetails";
const schema = yup.object().shape({
  businessName: yupValidators.businessName,
  businessEmail: yupValidators.email,
  businessPhoneNumber: yupValidators.phoneNumber,
  businessSector: yupValidators.businessCategory,
  countryCode: yupValidators.countryCode,
  businessAddress: yupValidators.businessAddress,
  businessPurpose: yupValidators.businessPurpose,
  registrationNumber: yupValidators.registrationNumber,
  shortName: yupValidators.shortName,
  dialCode: yupValidators.dialCode,
  businessCity: yupValidators.businessCity,
  customersInformation: yupValidators.customerInformation,
  businessState: yupValidators.businessState,
});

export interface IbusinessInfo {
  businessAddress: string;
  businessEmail: string;
  businessName: string;
  businessPhoneNumber: string;
  businessPurpose: string;
  countryCode: string;
  registrationNumber: string;
  shortName: string;
  businessSector: string;
  dialCode: string;
  businessCity: string;
  customersInformation: string;
  businessState: string;
}

const GetStartedBasicInfo = ({ handleNext, scrollToTop }: IstepFunctions) => {
  const history = useHistory();
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [businessCount, setbusinessCount] = useState(0);

  const [businessRqBody, setBusinessRqBody] = useState<IbusinessInfo | null>(
    null
  );
  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const { data: aggregatorDetails, error: aggregatorDetailsError } =
    useAggregatorDetails();

  const [businessSector, setBusinessSector] = useState(
    aggregatorDetails?.sector_id
  );
  const [countryCode, setCountryCode] = useState(
    aggregatorDetails?.country_code
  );

  const [viewPreview, setViewPreview] = useState<boolean>(false);
  const {
    data: sectorsData,
    isLoading: isLoadingSectors,
    error: sectorsError,
  } = useBusinessSectorsLive();
  const {
    data: countryCodes,
    error: countryCodesError,
    isLoading: isLoadingcountryCodes,
  } = useCountryCodesLive();
  const resolveCountryInfo = countryCodes?.find(
    ({ country_code }: any) => country_code === countryCode
  );
  const resolveCountryName = countryCodes?.find(
    ({ country_code }: any) => country_code === countryCode
  )?.name;
  const resolveBusinessSectorName = sectorsData?.find(
    ({ category_id }: any) => category_id === businessSector
  )?.category_name;
  const { data: states, isLoading: isStatesLoading } = useCountryStates({
    countryISO:
      resolveCountryInfo?.country_code || aggregatorDetails?.country_code,
  });

  useEffect(() => {
    if (aggregatorDetails?.biz_name) {
      setValue("businessName", aggregatorDetails?.biz_name);
    }
    if (aggregatorDetails?.biz_email) {
      setValue("businessEmail", aggregatorDetails?.biz_email);
    }
    if (aggregatorDetails?.rep_personal_phone) {
      setTimeout(() => {
        setValue("businessPhoneNumber", aggregatorDetails?.rep_personal_phone);
      }, 2000);
    }
    if (aggregatorDetails?.rep_phone_dialcode) {
      setTimeout(() => {
        setValue("dialCode", aggregatorDetails?.rep_phone_dialcode);
      }, 2000);
    }
    if (aggregatorDetails?.short_name) {
      setValue("shortName", aggregatorDetails?.short_name);
    }
    if (aggregatorDetails?.business_description) {
      setValue("businessPurpose", aggregatorDetails?.business_description);
    }
    if (aggregatorDetails?.Business_registration_number) {
      setValue(
        "registrationNumber",
        aggregatorDetails?.Business_registration_number
      );
    }
    if (aggregatorDetails?.biz_address) {
      setValue("businessAddress", aggregatorDetails?.biz_address);
    }
    if (aggregatorDetails?.sector_id) {
      setTimeout(() => {
        setValue("businessSector", aggregatorDetails?.sector_id);
      }, 3000);
    }
    if (aggregatorDetails?.country_code) {
      setTimeout(() => {
        setValue("countryCode", aggregatorDetails?.country_code);
      }, 3000);
    }
    if (aggregatorDetails?.state_name) {
      setTimeout(() => {
        setValue("businessState", aggregatorDetails?.state_name);
      }, 4000);
    }

    if (aggregatorDetails?.city) {
      setValue("businessCity", aggregatorDetails?.city);
    }
    if (aggregatorDetails?.business_overview) {
      setValue("customersInformation", aggregatorDetails?.business_overview);
    }
  }, [aggregatorDetails, setValue]);

  // for if customer clicks back not to loose data
  useEffect(() => {
    if (businessRqBody?.businessName) {
      setValue("businessName", businessRqBody?.businessName);
    }
    if (businessRqBody?.businessEmail) {
      setValue("businessEmail", businessRqBody?.businessEmail);
    }
    if (businessRqBody?.businessPhoneNumber) {
      setTimeout(() => {
        setValue("businessPhoneNumber", businessRqBody?.businessPhoneNumber);
      }, 2000);
    }
    if (businessRqBody?.dialCode) {
      setTimeout(() => {
        setValue("dialCode", businessRqBody?.dialCode);
      }, 2000);
    }
    if (businessRqBody?.shortName) {
      setValue("shortName", businessRqBody?.shortName);
    }
    if (businessRqBody?.businessPurpose) {
      setValue("businessPurpose", businessRqBody?.businessPurpose);
    }
    if (businessRqBody?.registrationNumber) {
      setValue("registrationNumber", businessRqBody?.registrationNumber);
    }
    if (businessRqBody?.businessAddress) {
      setValue("businessAddress", businessRqBody?.businessAddress);
    }
    if (businessRqBody?.businessSector) {
      setTimeout(() => {
        setValue("businessSector", businessRqBody?.businessSector);
      }, 3000);
    }
    if (businessRqBody?.countryCode) {
      setTimeout(() => {
        setValue("countryCode", businessRqBody?.countryCode);
      }, 3000);
    }

    if (businessRqBody?.businessState) {
      setTimeout(() => {
        setValue("businessState", businessRqBody?.businessState);
      }, 4000);
    }

    if (businessRqBody?.businessCity) {
      setValue("businessCity", businessRqBody?.businessCity);
    }
    if (businessRqBody?.customersInformation) {
      setValue("customersInformation", businessRqBody?.customersInformation);
    }
  }, [businessRqBody, viewPreview, setValue]);

  const onSubmit = ({
    businessAddress,
    businessEmail,
    businessName,
    businessPhoneNumber,
    businessPurpose,
    countryCode,
    registrationNumber,
    shortName,
    businessSector,
    dialCode,
    businessCity,
    customersInformation,
    businessState,
  }: IbusinessInfo) => {
    const finalBody = {
      businessAddress,
      businessEmail,
      businessName,
      businessPhoneNumber,
      businessPurpose,
      countryCode,
      registrationNumber,
      shortName,
      businessSector,
      dialCode,
      businessCity,
      businessState,
      customersInformation,
    };
    setBusinessRqBody(finalBody);
    setViewPreview(true);
  };
  useEffect(() => {
    appInsights.trackPageView({
      name: "GetStartedBusinesInfo.tsx",
      isLoggedIn: true,
    });
  }, []);
  return (
    <div>
      <div>
        <div className={styles.center_form}>
          {success && <Alert message={success} type="success" />}

          {error && <p className="alert alert-danger small">{error}</p>}
          {sectorsError && (
            <p className="alert alert-danger small">
              {errorHandler(sectorsError)}
            </p>
          )}
          {aggregatorDetailsError && (
            <p className="alert alert-danger small">{aggregatorDetailsError}</p>
          )}
          {countryCodesError && (
            <p className="alert alert-danger small mb-0">
              {errorHandler(countryCodesError)}
            </p>
          )}
        </div>
        {viewPreview ? (
          <div className={`${styles.center_Container}`}>
            <div style={{ width: "11.375rem" }}>
              {" "}
              <small
                className="color-mid-blue  cursor-pointer font-weight-bold  "
                onClick={() => {
                  setViewPreview(false);
                }}
              >
                &#8592; Go back
              </small>
            </div>
            <div className={styles.center_form}>
              <div>
                <h3 className="color-blue-hover-none">
                  Preview Business Information
                </h3>
              </div>
              <GetStartedBasicInfoPreview
                setLoading={setLoading}
                aggregatorDetails={aggregatorDetails}
                setError={setError}
                businessRqBody={businessRqBody}
                setBusinessRqBody={setBusinessRqBody}
                setViewPreview={setViewPreview}
                loading={loading}
                resolveCountryName={resolveCountryName}
                resolveBusinessSectorName={resolveBusinessSectorName}
                handleNext={handleNext}
                setSuccess={setSuccess}
                scrollToTop={scrollToTop}
              />
            </div>
          </div>
        ) : (
          <div className="">
            <div className={`${styles.center_Container}`}>
              <div style={{ width: "11.375rem" }}>
                <small
                  className="color-mid-blue  cursor-pointer font-weight-bold  "
                  onClick={() => {
                    history.push("/get-started/onboarding");
                  }}
                >
                  &#8592; Go back
                </small>
              </div>
              <div className={styles.center_form}>
                <div>
                  <div>
                    <h3 className="color-blue-hover-none">
                      Business Information
                    </h3>
                    <p className="bg-light-grey">Fill business information </p>
                  </div>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            maxLength={30}
                            ref={register}
                            placeholder="Enter Business Name"
                            label="Business Name"
                            name="businessName"
                            defaultValue={aggregatorDetails?.biz_name}
                            errors={errors.businessName}
                            showRequiredIcon={true}
                          />
                        </div>

                        <div className="col-12">
                          <CustomInputField
                            type="email"
                            maxLength={128}
                            ref={register}
                            placeholder="Enter Business Email"
                            label="Business Email Address"
                            name="businessEmail"
                            errors={errors.businessEmail}
                            showRequiredIcon={true}
                          />
                        </div>

                        <div className="col-4">
                          <CustomSelectDropdown
                            label="Business"
                            ref={register}
                            name="dialCode"
                            errors={errors.personal_phone || errors.dialCode}
                            style={{ borderRadius: "10px" }}
                          >
                            {" "}
                            <option value="">Choose</option>
                            {countryCodes?.map(
                              ({
                                dial_code,
                                country_code,
                              }: {
                                dial_code: string | number;
                                country_code: string | number;
                              }) => {
                                return (
                                  <option value={dial_code} key={dial_code}>
                                    {`${getUnicodeFlagIcon(
                                      country_code
                                    )} ${dial_code}`}
                                  </option>
                                );
                              }
                            )}
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-8">
                          <CustomInputField
                            type="text"
                            maxLength={20}
                            ref={register}
                            label=" Phone Number"
                            placeholder="Enter Business Phone Number"
                            name="businessPhoneNumber"
                            errors={
                              errors.businessPhoneNumber || errors.dialCode
                            }
                            showRequiredIcon={true}
                          ></CustomInputField>
                        </div>
                        <div className="col-12">
                          <CustomSelectDropdown
                            ref={register}
                            label="What Sector is your business in?"
                            name="businessSector"
                            errors={errors.businessSector}
                            showRequiredIcon={true}
                            value={businessSector}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setBusinessSector(e.target.value)
                            }
                            extraLabel={isLoadingSectors ? "(Loading...)" : ""}
                          >
                            <option value="">Select Sector</option>
                            {sectorsData && sectorsData.length
                              ? sectorsData?.map((sector: any) => {
                                  const { category_id, category_name } = sector;
                                  return (
                                    <option
                                      key={category_id}
                                      value={category_id}
                                    >
                                      {category_name}
                                    </option>
                                  );
                                })
                              : null}
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-12">
                          <CustomSelectDropdown
                            ref={register}
                            name="countryCode"
                            label="What country is your business located?"
                            errors={errors.countryCode}
                            showRequiredIcon={true}
                            value={countryCode}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setCountryCode(e.target.value)
                            }
                            extraLabel={
                              isLoadingcountryCodes ? "(Loading...)" : ""
                            }
                          >
                            <option value="">Choose Country</option>
                            {countryCodes &&
                              countryCodes.map(
                                (
                                  country: {
                                    country_code: string | number;
                                    name: string;
                                  },
                                  index: string | number
                                ) => {
                                  const { country_code, name } = country;
                                  return (
                                    <option value={country_code} key={index}>
                                      {name}
                                    </option>
                                  );
                                }
                              )}
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-12">
                          <CustomSelectDropdown
                            ref={register}
                            name="businessState"
                            label="What state is your business located?"
                            errors={errors.businessState}
                            showRequiredIcon={true}
                            extraLabel={isStatesLoading ? "(Loading...)" : ""}
                          >
                            <option value="">Select State</option>
                            {states?.map(
                              ({
                                name,
                                id,
                              }: {
                                name: string;
                                id: number | string;
                              }) => {
                                return (
                                  <option value={name} key={id}>
                                    {name}
                                  </option>
                                );
                              }
                            )}
                          </CustomSelectDropdown>
                        </div>
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            maxLength={256}
                            ref={register}
                            placeholder="Enter City"
                            label="Business City"
                            name="businessCity"
                            errors={errors.businessCity}
                            showRequiredIcon={true}
                          />
                        </div>
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            maxLength={256}
                            ref={register}
                            placeholder="Enter Business Address"
                            label="Business Physical Address"
                            name="businessAddress"
                            errors={errors.businessAddress}
                            showRequiredIcon={true}
                          />
                        </div>

                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            maxLength={30}
                            ref={register}
                            placeholder="Enter Business Name"
                            label="Business Short Name"
                            name="shortName"
                            errors={errors.shortName}
                            showRequiredIcon={true}
                          />
                        </div>
                        <div className="col-12">
                          <CustomInputField
                            type="text"
                            maxLength={50}
                            ref={register}
                            placeholder="Business  Registration number"
                            label="Business Registration number "
                            name="registrationNumber"
                            errors={errors.registrationNumber}
                            showRequiredIcon={true}
                          />
                        </div>
                        <div className="col-12">
                          <CustomTextArea
                            maxLength={200}
                            ref={register}
                            placeholder="E.g. We manufacture ball bearings for use by larger machine manufacturing and engineering."
                            label={`What does your business do? `}
                            name="businessPurpose"
                            errors={errors.businessPurpose}
                            showRequiredIcon={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setCount(e.target.value.length)
                            }
                          />
                          <span className="d-flex justify-content-end color-blue-hover-none">
                            {count}/200
                          </span>
                        </div>
                        <div className="col-12">
                          <CustomTextArea
                            maxLength={200}
                            ref={register}
                            placeholder="E.g. Manufacturers of tea processing plants in Kenya, Manufacturers of factory conveyor belts, etc"
                            label={`Who are your Customers ? `}
                            name="customersInformation"
                            errors={errors.customersInformation}
                            showRequiredIcon={true}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setbusinessCount(e.target.value.length)
                            }
                          />
                          <span className="d-flex justify-content-end color-blue-hover-none">
                            {businessCount}/200
                          </span>
                        </div>

                        <div className="col-12">
                          <div className=" d-flex justify-content-between flex-wrap mt-2">
                            <button
                              className="btn advancly-btn btn-sm transition-3d-hover mt-2 mb-4"
                              type="submit"
                            >
                              Proceed
                            </button>
                            <button
                              className="btn advancly-white-btn btn-sm transition-3d-hover mt-2 mb-4"
                              type="button"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GetStartedBasicInfo;
