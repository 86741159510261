import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const getOutstandingLoanCount = async () => {
  const data = await getData(apiEndpoints.OUTSTANDING_LOAN_COUNT);
  return data?.data;
};

export default function useOutstandingLoanCount() {
  return useQuery("getOutstandingLoanCount", getOutstandingLoanCount, {
    refetchOnWindowFocus: false,
  });
}
