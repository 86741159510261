import React from "react";
import { withRouter } from "react-router-dom";
import LoanApplicationTableList from "../../components/LoanApplication/LoanApplicationTableList";

const LoanApplicationLandingPage = () => {
  //   const match = useRouteMatch();

  return (
    <div className="container space-2">
      <LoanApplicationTableList />
    </div>
  );
};

export default withRouter(LoanApplicationLandingPage);
