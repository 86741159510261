import React from "react";
import { useForm } from "react-hook-form";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { IChangeSingleSignatory } from "./ChangeSingleSignatoryComponentPage";
import { ReactComponent as CloseModal } from "../../../svg/icons/modal-close.svg";
import style from "./ChangeSingleSignatory.module.scss";
const ChangeSingleSignatoryModal1 = ({
  usersData,
  usersStatus,
  usersError,
  changeSingleSignatory,
  setChangeSingleSignatory,
  setChangeSinglesSignatoryName,
  step,
  setChangeAddNewUserModal,
  signatories,
}: Pick<
  IChangeSingleSignatory,
  | "usersData"
  | "usersStatus"
  | "usersError"
  | "changeSingleSignatory"
  | "setChangeSinglesSignatoryName"
  | "step"
  | "setChangeAddNewUserModal"
  | "signatories"
  | "setChangeSingleSignatory"
>) => {
  const { handleSubmit, register, errors, watch } = useForm();
  const submitHandler = (data: {
    changeSignatoryName: string;
    levelNo: number;
  }) => {
    const signatoriesBody = [{ email: data.changeSignatoryName, levelNo: 1 }];
    setChangeSinglesSignatoryName(signatoriesBody);
    setChangeSingleSignatory(changeSingleSignatory + step);
  };
  const watchChangeSignatoryNameChange = watch("changeSignatoryName");
  // const { data: users, status, error } = useAggregatorSignatoryUsers();
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={() => {
              setChangeSingleSignatory(2);
            }}
          >
            <CloseModal />
          </button>
        </div>
        <div className="">
          <div className="col-12">
            <h5 className={`modal-title `}>Change Single Signatory Approval</h5>
          </div>
        </div>
        <div className="modal-body">
          <p className={`pt-2 ${style.paragraphText}`}>
            Select one of the following users as a signatory
          </p>
          <form onSubmit={handleSubmit(submitHandler)}>
            {usersStatus === "loading" && (
              <small className="text-primary">Loading...</small>
            )}
            {usersError && (
              <small className="text-dangeralert alert-danger small text-center">
                {errorHandler(usersError)}
              </small>
            )}
            <CustomSelectDropdown
              name="changeSignatoryName"
              ref={register({ required: true })}
            >
              {signatories && signatories[0]?.email && (
                <option value={signatories[0].email}>
                  {signatories[0]?.email}
                </option>
              )}

              <option value={0}>None</option>
              {usersData &&
                usersData?.map(
                  (user: {
                    id: number;
                    firstName: string;
                    lastName: string;
                    email: string;
                  }) => {
                    const { id, firstName, lastName, email } = user;
                    return (
                      <option
                        key={id}
                        value={email}
                      >{`${firstName} ${lastName}`}</option>
                    );
                  }
                )}
            </CustomSelectDropdown>
            {errors.changeSignatoryName && (
              <p className="text-danger">Please select a name</p>
            )}
            <small
              className="color-mid-blue   row cursor-pointer font-weight-bold pl-3 "
              onClick={() => {
                setChangeAddNewUserModal(true);

                setChangeSingleSignatory(changeSingleSignatory - step);
              }}
            >
              Create a new User
            </small>
            <div className="modal-footer">
              <button
                className="btn advancly-btn w-100 mt-3"
                disabled={!watchChangeSignatoryNameChange}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangeSingleSignatoryModal1;
